import { appInsights } from "../settings/app-insights";
import PageUtil from "./page-util";
import { AppConfigProps } from "../settings/app-config";

class TelemetryUtil {
  static trackTrace(message, severityLevel, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: severityLevel,
      properties: properties,
    });
  }

  static trackPageView(pageViewTitle) {
    let headerTitle = PageUtil.getHeadTitle();
    if (
      headerTitle !== "" &&
      headerTitle !== null &&
      pageViewTitle !== null &&
      pageViewTitle !== undefined
    ) {
      appInsights.trackPageView({ name: headerTitle + " | " + pageViewTitle });
    }
  }

  static logException(exception, properties) {
    appInsights.trackException(exception, properties);
  }

  static logVerbose(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Verbose,
      properties: properties,
    });
  }

  static logInformation(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Information,
      properties: properties,
    });
  }

  static logWarning(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Warning,
      properties: properties,
    });
  }

  static logError(message, properties) {
    appInsights.trackTrace({
      message: message,
      severityLevel: AppConfigProps.SeverityLevel.Error,
      properties: properties,
    });
  }
}

export default TelemetryUtil;
