import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import {
  setOrderPublisherKey,
  setQuoteProgressPage,
  resetQuoteEstimatorInfo,
} from "../actions/quote-action";
import AccountCreate from "./account-create";
import OrderCheckout from "./order-checkout";
import QuoteCreate from "./quote-create";
import QuoteSteps from "./quote-steps";
import { withTranslation } from "react-i18next";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import OrderConfirmation from "./order-confirmation";
import PageExitGuard from "../../../pages/common/helpers/page-exit-guard";
import { OperatorConnectURLProps } from "../../common/settings/operator-connect-urls";
import CustomStyles from "../../common/helpers/custom-styles";
import Welcome from "../../common/components/welcome";
import PageHeader from "../../common/helpers/page-header";
import { useLocation } from "react-router-dom";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";

const Quote = (props) => {
  const _intl_ns_oc_quote = "oc_quote";
  let [isPageExitEnabled, setIsPageExitEnabled] = useState(false);
  let [clientSecret, setClientSecret] = useState(null);
  const [isPageDataFetched, setPageDataFetched] = useState(false);
  let [tabSelected, setTabSelected] = useState(null);
  const [websitePageTitle, setWebsitePageTitle] = useState({});
  const _intl_ns_common = "oc_common";
  const paymentQueryString = new URLSearchParams(window.location.search);
  const QueryStringClientSecret = paymentQueryString.get(
    "payment_intent_client_secret"
  );
  const QueryStringTab = paymentQueryString.get("tab");
  const quotePageRef = useRef();
  let location = useLocation();

  useEffect(() => {
    setPageDataFetched(false);
    PageUtil.scrollToTop();
    props.setQuoteProgressPage(0);

    let websiteTitle =
      props.webTemplateConfigData?.websiteTemplate?.contentPages.find(
        (rec) => rec.pageCode?.toLowerCase().trim() === "oc-p3"
      );
    setWebsitePageTitle(websiteTitle);
    if (QueryStringClientSecret !== null) {
      setClientSecret(paymentQueryString.get("payment_intent_client_secret"));
      if (QueryStringTab !== null && QueryStringTab === "1") {
        setTabSelected(
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[1]
        );
      } else if (QueryStringTab !== null && QueryStringTab === "2") {
        setTabSelected(
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[2]
        );
      }
      props.setQuoteProgressPage(
        OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
      );
      props.history.push(OperatorConnectURLProps.orderManagement.quoteAdd);
    }

    if (props?.history?.location?.state?.orderStatus === true) {
      if (
        props?.history?.location?.state &&
        props?.history?.location?.state?.processStatus === "QuoteCreate"
      ) {
        if (
          props?.quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber &&
          props?.quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber !== ""
        ) {
          props.setQuoteProgressPage(
            OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
          );
        } else {
          props.setQuoteProgressPage(
            OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS
          );
        }
      }

      if (
        props?.history?.location?.state &&
        props?.history?.location?.state?.processStatus === "SubscriberCreate"
      ) {
        if (
          props?.quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber &&
          props?.quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber !== ""
        ) {
          props.setQuoteProgressPage(
            OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
          );
        } else {
          props.setQuoteProgressPage(
            OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS
          );
        }
      }
    }
    setPageDataFetched(true);
    return () => {
      if (props?.history?.location?.state) {
        props?.history?.replace({ ...props?.history?.location, state: null });
      }
    };
  }, []);

  useEffect(() => {
    PageUtil.scrollIntoView(quotePageRef);
  }, [props.quoteProgressPage]);

  const handleExitEnabled = (val) => setIsPageExitEnabled(val);

  const renderQuoteStepComponents = () => {
    switch (props.quoteProgressPage) {
      case OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS:
        return (
          <QuoteCreate
            {...props}
            setPageExit={(val) => handleExitEnabled(val)}
          />
        );
      case OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT:
        return (
          <AccountCreate
            {...props}
            setPageExit={(val) => handleExitEnabled(val)}
          />
        );
      case OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT:
        return (
          <OrderCheckout
            {...props}
            clientSecret={clientSecret}
            tabSelected={tabSelected}
            setPageExit={(val) => handleExitEnabled(val)}
          />
        );
      case OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION:
        return (
          <OrderConfirmation
            {...props}
            setPageExit={(val) => handleExitEnabled(val)}
          />
        );

      default:
        return (
          <QuoteCreate
            {...props}
            setPageExit={(val) => handleExitEnabled(val)}
          />
        );
    }
  };

  return (
    <>
      {/* <AppPageTitle pageTitle={IntlUtil.getText(_intl_ns_oc_quote,"title.selectProducts")}/> */}
      {props.webTemplateConfigData?.websiteTemplate?.bannerDisplayStatus
        ?.toLowerCase()
        .trim() === OperatorConnectConstants.GENERAL.YES?.toLowerCase() ? (
        <Welcome {...props} />
      ) : null}
      <PageHeader
        {...props}
        history={props.history}
        showCurrencyStatus={false}
        websitePageTitle={websitePageTitle}
        setPageExit={(val) => handleExitEnabled(val)}
      />
      <div
        id="oc-page-container"
        className={
          CustomStyles.getStyle(
            "page-background",
            props.webTemplateConfigData?.websiteTemplate
          ) + " m-b-30"
        }
      >
        <div ref={quotePageRef}>
          <QuoteSteps />
        </div>
        <div
          style={{ background: "white" }}
          className={CustomStyles.getStyle(
            "page-frame-shadow",
            props.webTemplateConfigData?.websiteTemplate
          )}
        >
          {isPageDataFetched ? (
            renderQuoteStepComponents()
          ) : (
            <PageOverlayLoader
              hidden={isPageDataFetched}
              label={IntlUtil.getText(
                _intl_ns_common,
                "content.loadingInprogress"
              )}
            />
          )}
        </div>
        <div>
          <PageExitGuard
            title={IntlUtil.getText(_intl_ns_oc_quote, "content.order")}
            when={isPageExitEnabled}
            navigate={(path) => {
              props.history.push(path);
            }}
            clearReduxData={() => {
              props.resetQuoteEstimatorInfo(props.quoteEstimatorInfo);
            }}
            shouldBlockNavigation={(location) => {
              if (location.pathname) {
                return true;
              }
              return false;
            }}
            props={props}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  quoteProgressPage: state.quoteStore.quoteProgressPage,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
});
const mapActionToProps = {
  setQuoteProgressPage,
  setOrderPublisherKey,
  resetQuoteEstimatorInfo,
};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(Quote)
);
