import api from "../../../core/helpers/api-services";
import { logMessage } from "../../../core/actions/common-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import { PRODUCT_LIST } from "../../../store/constants";
import i18n from "../../../../i18n";

const actionFileName = "common-actions.js";

export function getProducts(languageCode, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .get(
        AppConfigProps.operatorConnectAPIPrefix +
          "/catalog/products?languageCode=" +
          languageCode,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getProducts()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function setProductRecords(productRecords) {
  return async function (dispatch) {
    try {
      dispatch({
        type: PRODUCT_LIST,
        payload: productRecords,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function getCountryStates(cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .get(
        AppConfigProps.operatorConnectAPIPrefix + "/general/country-states",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getProducts()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function getWebsiteTemplate(cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .get(
        AppConfigProps.operatorConnectAPIPrefix + "/website/template",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getWebsiteTemplate()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function getLanguage() {
  return i18n.language;
}
