import React, { useEffect, useState } from "react";
import {
  DefaultButton,
  mergeStyleSets,
  PrimaryButton,
  TooltipHost,
  TooltipOverflowMode,
  Image,
} from "@fluentui/react";
import {
  AddToShoppingListIcon,
  TextDocumentIcon,
  ChevronUpIcon,
  Link12Icon,
  ChevronDownIcon,
  InfoIcon,
  PhoneIcon,
} from "@fluentui/react-icons";
import axios from "axios";
import classNames from "classnames";
import { connect } from "react-redux";
import CustomStyles from "../../common/helpers/custom-styles";
import PageLoader from "../../common/helpers/page-loader";
import IntlUtil from "../../../core/helpers/intl-util";
import { manageError } from "../../../core/actions/common-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import { getProduct } from "../../products/actions/product-actions";
import { OperatorConnectURLProps } from "../../common/settings/operator-connect-urls";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import {
  setFontFamily,
  handleImageSource,
} from "../../common/actions/general-actions";
import {
  setSelectedProductId,
  quoteEstimatorDataReset,
} from "../../quotes/actions/quote-action";

const ProductList = (props) => {
  const _intl_ns_common = "oc_common";
  const _intl_ns_oc_product = "oc_product";
  const _axiosSource = axios.CancelToken.source();
  const _cancelToken = { cancelToken: _axiosSource.token };
  const [webTemplate, setWebTemplate] = useState(
    props?.webTemplateConfigData?.websiteTemplate
  );
  const [isToggleProductDetail, setToggleProductDetail] = useState(false);
  const [isMoreProductLoading, setIsMoreProductLoading] = useState(false);
  const [productDetail, setProductDetail] = useState(null);

  const productCardContentWrapperStylesT3 = {
    backgroundColor: webTemplate?.generalSettings?.sectionBackgroundColor,
    fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    borderRadius: 3,
  };

  useEffect(() => {
    setIsMoreProductLoading(false);
    setToggleProductDetail(false);
  }, [props.selectedLanguageData.key]);

  const handleSelectProduct = async (id) => {
    setToggleProductDetail(!isToggleProductDetail);
    if (productDetail === null) {
      fetchProductsbyId(id);
    }
  };

  const handleProductView = (product) => {
    props.history.push(
      OperatorConnectURLProps.productManagement.productView.replace(
        ":pid",
        product.productId
      )
    );
  };

  const handleLinkDocuments = async (link) => {
    window.open(link.linkUrl, "_blank");
  };

  const handleResourceDocuments = async (resource) => {
    window.open(resource.resourceUrl, "_blank");
  };

  const fetchProductsbyId = async (id) => {
    setIsMoreProductLoading(true);
    await getProduct(id, props.selectedLanguageData.key, _cancelToken)
      .then((res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          setProductDetail(res.data.result);
          setIsMoreProductLoading(false);
        }
      })
      .catch(async (err) => {
        setIsMoreProductLoading(false);
        await manageError(err, props.history);
      });
    setIsMoreProductLoading(false);
  };

  const loadQuoteCreatePage = (product) => {
    localStorage.removeItem("persist:root");
    props.quoteEstimatorDataReset(null);
    props.setSelectedProductId(product.productId, product.currencyCode);
    return props.history.push(OperatorConnectURLProps.orderManagement.quoteAdd);
  };

  const mergedContentStyles = mergeStyleSets({
    "product-title": {
      color: `${webTemplate?.generalSettings?.primaryColor} !important`,
      fontSize: "large",
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-sub-title": {
      color: `${webTemplate?.generalSettings?.secondaryColor} !important`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "oc-t3-product-text-link": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      "&:hover": {
        color: `${webTemplate?.generalSettings?.secondaryColor}`,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    "oc-t3-product-primary-color": {
      color: `${webTemplate?.generalSettings?.secondaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-primary-color": {
      color: `${webTemplate?.generalSettings?.primaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-text-link": {
      fontFamily: `${webTemplate?.generalSettings?.font}`,
      "&:hover": {
        color: `${webTemplate?.generalSettings?.primaryColor}`,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  });

  const productCardTextStyles = {
    color: webTemplate?.generalSettings?.secondaryColor,
  };

  return (
    <div style={{ ...productCardContentWrapperStylesT3 }} className="m-t-20">
      <div className="oc-t3-item-wrapper p-t-10 p-b-10 m-r-20">
        <div className="a oc-t3-image-wrapper">
          {props?.product?.productIcon ? (
            <Image
              className="oc-t3-product-card-image-wrapper"
              loading="lazy"
              src={handleImageSource(props.product.productIcon)}
            />
          ) : null}
        </div>
        <div
          className={
            "text-fw-semibold text-fs-large-x b" +
            mergedContentStyles["product-title"]
          }
        >
          {props.product.productName}
        </div>
        <div
          className={
            "text-fw-semibold text-fs-large-xx c" +
            mergedContentStyles["product-sub-title"]
          }
        >
          <div className="text-fw-semibold">
            {props.product &&
            props.product.retailPrice !== null &&
            props.product.retailPrice !== undefined ? (
              <>
                <span
                  style={{ ...productCardTextStyles }}
                  className="text-fs-large-xxx"
                >
                  {props.product.currencySymbol}
                  {props.product?.retailPrice ?? "0"}
                  <span
                    style={{ ...productCardTextStyles }}
                    className="text-fs-small"
                  ></span>
                </span>
              </>
            ) : (
              <TooltipHost
                content={IntlUtil.getText(
                  _intl_ns_oc_product,
                  "content.support"
                )}
              >
                <PhoneIcon
                  style={{ ...productCardTextStyles }}
                  className="product-card-icon"
                />
              </TooltipHost>
            )}
          </div>
        </div>
        <div className="oc-t3-button-wrapper d">
          <DefaultButton
            className={`${CustomStyles.getStyle(
              "default-button",
              webTemplate
            )} page-frame-button m-r-10  oc-t3-chevrondown-wrapper`}
            onClick={() => handleSelectProduct(props.product.productId)}
          >
            {isToggleProductDetail ? (
              <ChevronUpIcon className="m-r-8 m-l-8" />
            ) : (
              <ChevronDownIcon className="m-r-8 m-l-8" />
            )}
          </DefaultButton>
          <DefaultButton
            className={`${CustomStyles.getStyle(
              "default-button",
              webTemplate
            )} page-frame-button home-page-button m-r-10`}
            onClick={() => handleProductView(props.product)}
          >
            <InfoIcon className="m-r-5 m-b-0" />
            <TooltipHost
              overflowMode={TooltipOverflowMode.Self}
              hostClassName="text-truncated"
              content={IntlUtil.getText(
                _intl_ns_oc_product,
                "content.moreInfo"
              )}
            >
              {IntlUtil.getText(_intl_ns_oc_product, "content.moreInfo")}
            </TooltipHost>
          </DefaultButton>
          <PrimaryButton
            className={`${CustomStyles.getStyle(
              "primary-button",
              webTemplate
            )} page-frame-button home-page-button m-r-10`}
            onClick={() => loadQuoteCreatePage(props.product)}
          >
            <AddToShoppingListIcon className="m-r-5 page-frame-icon" />
            <TooltipHost
              content={IntlUtil.getText(
                _intl_ns_oc_product,
                "content.createQuote"
              )}
              hostClassName="text-truncated"
              overflowMode={TooltipOverflowMode.Self}
            >
              {IntlUtil.getText(_intl_ns_oc_product, "content.createQuote")}
            </TooltipHost>
          </PrimaryButton>
        </div>
      </div>
      {isToggleProductDetail ? (
        <div
          className={classNames({
            "oc-product-page-loading-wrapper": isMoreProductLoading === true,
          })}
        >
          <PageLoader
            status={!isMoreProductLoading}
            size="medium"
            labelPosition="right"
            label={IntlUtil.getText(
              _intl_ns_common,
              "content.loadingInprogress"
            )}
            type="inline"
          >
            {productDetail ? (
              <div
                className={
                  "oc-t3-product-detail-panel " +
                  (isToggleProductDetail ? "expanded" : "")
                }
              >
                <div
                  className={`${CustomStyles.getStyle(
                    "page-content-separator",
                    webTemplate
                  )} `}
                />
                <div
                  className={`text-bold text-fs-large m-t-15 m-b-10 ${
                    props?.webTemplateConfigData.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() ===
                    OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? mergedContentStyles["oc-t3-product-primary-color"]
                      : mergedContentStyles["product-primary-color"]
                  }`}
                >
                  {IntlUtil.getText(_intl_ns_oc_product, "content.description")}
                </div>
                <div className="product-desc-content">
                  {productDetail?.productDescription}
                </div>
                {productDetail &&
                productDetail?.availableCountries &&
                productDetail?.availableCountries.length > 0 ? (
                  <>
                    <div
                      className={`${CustomStyles.getStyle(
                        "page-content-separator",
                        webTemplate
                      )} `}
                    />
                    <div
                      className={`text-bold text-fs-large m-t-15 m-b-10 ${
                        props?.webTemplateConfigData.websiteTemplate?.templateCode
                          ?.toLowerCase()
                          .trim() ===
                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? mergedContentStyles["oc-t3-product-primary-color"]
                          : mergedContentStyles["product-primary-color"]
                      }`}
                    >
                      {IntlUtil.getText(
                        _intl_ns_oc_product,
                        "content.availableCountries"
                      )}
                    </div>
                    <div className="product-desc-content">
                      <span className="m-r-5">
                        {productDetail.availableCountries
                          .map((country) => country.countryName)
                          .join(", ")}
                      </span>
                    </div>
                  </>
                ) : null}
                {productDetail &&
                productDetail?.links &&
                productDetail?.links.length > 0 ? (
                  <>
                    <div
                      className={`${CustomStyles.getStyle(
                        "page-content-separator",
                        webTemplate
                      )} `}
                    />
                    <div
                      className={`text-bold text-fs-large m-t-15 m-b-10 ${
                        webTemplate?.templateCode?.toLowerCase().trim() ===
                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? mergedContentStyles["oc-t3-product-primary-color"]
                          : mergedContentStyles["product-primary-color"]
                      }`}
                    >
                      {IntlUtil.getText(
                        _intl_ns_oc_product,
                        "content.usefulLinks"
                      )}
                    </div>
                    {productDetail?.links.map((link, index) => (
                      <span
                        key={`key-product-link-${index}`}
                        className="m-b-10"
                      >
                        <span
                          onClick={() => handleLinkDocuments(link)}
                          className={
                            webTemplate?.templateCode?.toLowerCase().trim() ===
                            OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                              ? mergedContentStyles["oc-t3-product-text-link"]
                              : mergedContentStyles["product-text-link"]
                          }
                          href={link.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Link12Icon className="m-r-10" />
                          <span className="m-r-10">{link.linkTitle}</span>
                        </span>
                      </span>
                    ))}
                  </>
                ) : null}
                {productDetail &&
                productDetail?.resources &&
                productDetail?.resources.length > 0 ? (
                  <>
                    <div
                      className={`${CustomStyles.getStyle(
                        "page-content-separator",
                        webTemplate
                      )} `}
                    />
                    <div
                      className={`text-bold  text-fs-large m-t-15 m-b-10 ${
                        webTemplate?.templateCode?.toLowerCase().trim() ===
                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? mergedContentStyles["oc-t3-product-primary-color"]
                          : mergedContentStyles["product-primary-color"]
                      }`}
                    >
                      {IntlUtil.getText(
                        _intl_ns_oc_product,
                        "content.resources"
                      )}
                    </div>
                    <div>
                      {productDetail?.resources.map((resource, index) => (
                        <span key={`key-product-resource-${index}`}>
                          <span
                            onClick={() => handleResourceDocuments(resource)}
                            className={
                              webTemplate?.templateCode
                                ?.toLowerCase()
                                .trim() ===
                              OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                ? mergedContentStyles["oc-t3-product-text-link"]
                                : mergedContentStyles["product-text-link"]
                            }
                            href={resource.linkUrl}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <TextDocumentIcon className="m-r-10 accordion-resource" />
                            <span className="m-r-20">
                              {resource.resourceTitle}
                            </span>
                          </span>
                        </span>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </PageLoader>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
});
const mapActionToProps = {
  setSelectedProductId,
  quoteEstimatorDataReset,
};

export default connect(mapStateToProps, mapActionToProps)(ProductList);
