import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  Modal,
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  Text,
} from "@fluentui/react";
import {
  getCountryList,
  setCurrencyData,
  setSelectedCurrencyData,
} from "../../common/actions/general-actions";
import { resetQuoteEstimatorInfo } from "../actions/quote-action";
import { Col, Grid, Row } from "react-flexbox-grid";
import IntlUtil from "../../../core/helpers/intl-util";
import CustomStyles from "../../common/helpers/custom-styles";
import { ClearIcon, InfoIcon } from "@fluentui/react-icons";
import SearchDropdown from "../../common/helpers/search-dropdown";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import { ConfigProductFormFields } from "../settings/config-product-form-fields";
import { manageError } from "../../../core/actions/common-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import TelemetryUtil from "../../../core/helpers/telemetry-util";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { connect } from "react-redux";
import { QuoteConstants } from "../settings/quote-constants";
import { modalStyles } from "../../common/helpers/styles";
import { getProductsAvailable } from "../actions/quote-action";
import _ from "lodash";

class AddOrEditProductForm extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.contactNameRef = React.createRef();
    this.companyNameRef = React.createRef();
    this.contactEmailRef = React.createRef();
    this.contactPhoneRef = React.createRef();
    this.requestDescriptionRef = React.createRef();
    this.state = {
      isServiceDisabled: false,
      isPageDataFetched: false,
      quoteServiceSubmitStatus: null,
      currencyCode: null,
      isCurrencyEnabled: false,
      isFormFilled: false,
      productServices: [],
      selectedServiceConditions: null,
      countryProductRecords: [],
      connectionList: [],
      communicationPlatformList: [],
      platformDisable: false,
      editedServiceRecord: null,
      selectedCountry: null,
      formInputFields: this.initFormInputFields(),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.setStateAsync({
      isServiceDisabled: this.props.isServiceDisabled,
      isCurrencyEnabled: this.props.isCurrencyEnabled,
      isFormFilled: this.props.isFormFilled,
      platformDisable: this.props.platformDisable,
      connectionList: this.props.connectionList,
      productServices: this.props.productServices,
      currencyCode: this.props.currencyCode,
      communicationPlatformList: this.props.communicationPlatformList,
      editedServiceRecord: this.props.editedServiceRecord,
      selectedServiceConditions: this.props.selectedServiceConditions,
      selectedCountry: this.props.selectedCountry,
    });
    if (this.props.editedServiceRecord) {
      this.setFormFieldValues(this.props.editedServiceRecord);
    }
    await this.checkCommunicationPlatform(this.state.communicationPlatformList);
    this.setFormFieldValue("currency", this.state.currencyCode);
    await this.setStateAsync({
      isFormFilled: await this.isFormFilled(),
    });
  }

  async componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this.props.intlNsCommon,
        "notification.warning.requestCancelled"
      )
    );
  }

  initFormInputFields = () => {
    let refCollection = {
      contactName: this.contactNameRef,
      companyName: this.companyNameRef,
      contactEmail: this.contactEmailRef,
      contactPhone: this.contactPhoneRef,
      requestDescription: this.requestDescriptionRef,
    };
    return ConfigProductFormFields(this.props.intlNsOCQuote, refCollection);
  };

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  setFormFieldValue = async (fieldName, fieldValue) => {
    await this.clearAlert();
    if (fieldName && this.state.formInputFields) {
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          [fieldName]: {
            ...this.state.formInputFields[fieldName],
            value: fieldValue,
          },
        },
      });
    }
  };

  handleFormFieldBlur = async (e) => {
    if (e && e.target.id && this.state.formInputFields) {
      await this.validateFormField(e.target.id);
    }
  };

  validateFormField = async (fieldName) => {
    if (fieldName && this.state.formInputFields) {
      let fieldObj = this.state.formInputFields[fieldName];
      if (fieldObj.isRequired === true) {
        let errorStatus = false;
        if (fieldObj.validate(fieldObj.value) === false) {
          errorStatus = true;
        }
        await this.setStateAsync({
          formInputFields: {
            ...this.state.formInputFields,
            [fieldName]: {
              ...this.state.formInputFields[fieldName],
              isError: errorStatus,
            },
          },
        });
      }
      let formFilled = await this.isFormFilled();
      await this.setStateAsync({ isFormFilled: formFilled });
    }
  };

  clearAlert = async () => {
    await this.setStateAsync({
      quoteServiceSubmitStatus: null,
      quoteServiceError: null,
      productSubmitStatus: null,
      productSubmitErrors: [],
    });
  };

  checkFormFilled = async () => {
    if (this.state.productName === "MACD Fee") {
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          communicationPlatform: {
            ...this.state.formInputFields.communicationPlatform,
            isRequired: false,
          },
        },
      });
    }
  };

  isFormFilled = async () => {
    let isFormFilled = false;
    if (
      this.state.productName !== "MACD Fee" &&
      this.state.formInputFields.communicationPlatform.isRequired === true
    ) {
      if (
        this.state.formInputFields?.service?.value?.toLowerCase()?.trim() !==
          "" &&
        this.state.formInputFields?.connection?.value?.toLowerCase()?.trim() !==
          "" &&
        this.state.formInputFields?.communicationPlatform?.value
          ?.toLowerCase()
          ?.trim() !== "" &&
        this.state.formInputFields?.quantity?.value !== ""
      ) {
        isFormFilled = true;
      }
    } else {
      if (
        this.state.formInputFields?.service?.value?.toLowerCase()?.trim() !==
          "" &&
        this.state.formInputFields?.connection?.value?.toLowerCase()?.trim() !==
          "" &&
        this.state.formInputFields?.quantity?.value !== ""
      ) {
        isFormFilled = true;
      }
    }
    return isFormFilled;
  };

  handleCurrencyFormDropdownChange = async (e, option) => {
    await this.setFormFieldValue(e.target.id, option.key);
    if (option.key !== "" && this.state.formInputFields.country.value !== "") {
      await this.setStateAsync({ isPageDataFetched: true });
      await this.fetchProductsAvailable(
        "country",
        this.state.formInputFields.country?.value,
        option.key?.trim()
      );
      await this.props.setSelectedCurrencyData(option);
      await this.setStateAsync({ isPageDataFetched: false });
      await this.validateFormField(e.target.id);
      await this.clearAlert();
    }
  };

  handleCountryFormDropdownChange = async (e, option) => {
    if (e && e.target) {
      await this.setFormFieldValue("service", "");
      await this.setFormFieldValue("connection", "");
      await this.setFormFieldValue("communicationPlatform", "");
      await this.setFormFieldValue("quantity", "");
      await this.setFormFieldValue(e.target.id, option.key);
      if (
        option.key !== "" &&
        this.state.formInputFields.country.value !== ""
      ) {
        await this.setStateAsync({ isPageDataFetched: true });
        await this.fetchProductsAvailable(
          "country",
          option.key,
          this.state.formInputFields.currency.value.trim()
        );
        await this.setStateAsync({ isPageDataFetched: false });
        await this.validateFormField(e.target.id);
        await this.setStateAsync({
          isFormFilled: await this.isFormFilled(),
        });
        await this.clearAlert();
      }
    }
  };

  checkCommunicationPlatform = async (communicationPlatformList) => {
    if (communicationPlatformList && communicationPlatformList.length === 0) {
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          communicationPlatform: {
            ...this.state.formInputFields.communicationPlatform,
            isRequired: false,
          },
        },
        platformDisable: true,
      });
    } else {
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          communicationPlatform: {
            ...this.state.formInputFields.communicationPlatform,
            isRequired: true,
          },
        },
        platformDisable: false,
      });
    }
  };

  handleConfigProductDropdownChange = async (e, option) => {
    if (option.key !== this.props.selectedProductId.productId)
      this.props.resetSelectedProductId();
    await this.setFormFieldValue(e.target.id, option.key);
    if (e.target.id === "service") {
      await this.setStateAsync({ productName: option.productName });
      await this.setSelectedProduct(option.key);
    }
    await this.checkFormFilled();
    await this.validateFormField(e.target.id);
    await this.setStateAsync({
      isFormFilled: await this.isFormFilled(),
    });
    await this.clearAlert();
  };

  setSelectedProduct = async (productId) => {
    let connectionValue = "";
    let selectedServiceConditions = "";
    let connectionList = [];
    let communicationPlatformList = [];
    let filteredCommunicationPlatform = "";

    if (
      this.props.quoteEstimatorInfo?.productRecords &&
      this.props.quoteEstimatorInfo?.productRecords.length > 0
    ) {
      await this.props.quoteEstimatorInfo?.productRecords.forEach((rec) => {
        if (rec.productId === productId) {
          selectedServiceConditions = rec.conditions;
          if (rec.communicationPlatforms) {
            rec.communicationPlatforms.forEach((platform) => {
              if (
                this.props?.webTemplateConfigData?.websiteType ===
                "Operator Connect"
              ) {
                filteredCommunicationPlatform = platform.name;
              }
              communicationPlatformList.push({
                key: platform.name,
                text: platform.name,
              });
            });
          }
          if (rec.planTypes && rec.planTypes.length > 0) {
            rec.planTypes.forEach((planType) => {
              if (rec.planTypes.length === 1) {
                connectionValue = planType.planName;
              } else {
                connectionValue = "";
              }
              connectionList.push({
                key: planType.planName,
                text: planType.planName,
                onRenderField: (props, render) => {
                  return (
                    <div>
                      <div>{render(props)}</div>
                      {props?.text?.toLowerCase()?.trim() ===
                      QuoteConstants.FORMS.ACTIVATION?.toLowerCase()?.trim() ? (
                        <div className="connection-option-tag">
                          {`(${IntlUtil.getText(
                            this.props.intlNsOCQuote,
                            "content.newNumber"
                          )})`}
                        </div>
                      ) : (
                        <></>
                      )}
                      {props?.text?.toLowerCase()?.trim() ===
                      QuoteConstants.FORMS.PORTING?.toLowerCase()?.trim() ? (
                        <div className="connection-option-tag">
                          {`(${IntlUtil.getText(
                            this.props.intlNsOCQuote,
                            "content.useExistingNumber"
                          )})`}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                },
              });
            });
            connectionList = _.orderBy(
              connectionList,
              (plan) => plan?.text?.toLowerCase(),
              ["asc"]
            );
          }
        }
      });
    }
    await this.checkCommunicationPlatform(communicationPlatformList);
    await this.setStateAsync({ isServiceDisabled: false });
    await this.setFormFieldValue("connection", connectionValue);
    await this.setFormFieldValue(
      "communicationPlatform",
      filteredCommunicationPlatform
    );
    await this.setStateAsync({
      editedServiceRecord: null,
      selectedServiceConditions: selectedServiceConditions,
    });
    await this.setStateAsync({ connectionList: connectionList });
    await this.setStateAsync({
      communicationPlatformList: communicationPlatformList,
    });
  };

  fetchProductsAvailable = async (
    availabilityType,
    availabilityName,
    currencyCode
  ) => {
    await this.setStateAsync({
      isPageDataFetched: true,
      connectionList: [],
      communicationPlatformList: [],
    });
    await getProductsAvailable(
      this.props.selectedLanguageData?.key,
      availabilityType,
      availabilityName,
      currencyCode,
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let productList = [];
          const records = res.data.records.map((rec) => {
            if (
              rec.productGroup?.toLowerCase() !==
              OperatorConnectConstants.PRODUCT_GROUP_ADDON.toLowerCase()
            ) {
              productList.push({
                key: rec.productId,
                text: `${rec.productName} (${rec.productCode})`,
                productName: rec.productName,
              });
            }
            return { ...rec };
          });
          await this.props.setQuoteEstimatorInfoProductRecords(
            this.props.quoteEstimatorInfo,
            records
          );
          productList = _.orderBy(productList, "text", "asc");
          if (this.props.selectedProductId) {
            const fiilterProductList = productList.filter(
              (obj) => obj.key === this.props.selectedProductId.productId
            );
            if (fiilterProductList.length > 0) {
              const product = fiilterProductList[0];
              const e = { target: { id: "service" } };
              this.handleConfigProductDropdownChange(e, product);
            }
          }
          await this.setStateAsync({ countryProductRecords: records });
          await this.setStateAsync({ productServices: productList });
          await this.setStateAsync({ isPageDataFetched: false });
        } else {
          await this.setStateAsync({ isPageDataFetched: false });
          await this.props.setQuoteEstimatorInfoProductRecords(
            this.props.quoteEstimatorInfo,
            []
          );
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isPageDataFetched: false });
        await this.props.setPageExit(false);
        await manageError(err, this.props.history);
      });
  };

  setFormFieldValues = async (editedServiceRecord) => {
    if (editedServiceRecord) {
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          service: {
            ...this.state.formInputFields.service,
            value: editedServiceRecord?.productId ?? "",
          },
          country: {
            ...this.state.formInputFields.country,
            value: editedServiceRecord?.availabilityName ?? "",
          },
          currency: {
            ...this.state.formInputFields.currency,
            value: editedServiceRecord?.currencyCode ?? "",
          },
          connection: {
            ...this.state.formInputFields.connection,
            value: editedServiceRecord?.connection ?? "",
          },
          communicationPlatform: {
            ...this.state.formInputFields.communicationPlatform,
            value: editedServiceRecord?.communicationPlatform ?? "",
          },
          quantity: {
            ...this.state.formInputFields.quantity,
            value: editedServiceRecord?.quantity ?? "",
          },
        },
      });
    }
  };

  handleAddServiceAndConnection = async (e) => {
    this.props.setPageExit(true);
    let serviceAvailableStatus = true;
    let errorMessage = null;
    let presentService = this.props.quoteEstimatorInfo?.productRecords.find(
      (rec) => rec.productId === this.state.formInputFields.service.value
    );
    let previousService = "";
    await this.setStateAsync({ isEdited: false });
    await this.setStateAsync({ isServiceEdited: false });

    if (
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords &&
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
    ) {
      for (let rec of this.props.quoteEstimatorInfo
        ?.serviceAndConnectionRecords) {
        if (
          rec.connection?.toLowerCase()?.trim() ===
            this.state.formInputFields.connection.value
              ?.toLowerCase()
              ?.trim() &&
          rec?.availabilityName?.toLowerCase()?.trim() ===
            this.state.formInputFields.country.value?.toLowerCase()?.trim() &&
          rec.communicationPlatform?.toLowerCase()?.trim() ===
            this.state.formInputFields.communicationPlatform.value
              ?.toLowerCase()
              ?.trim() &&
          rec.productId?.toLowerCase()?.trim() ===
            this.state.formInputFields.service.value?.toLowerCase()?.trim()
        ) {
          if (
            rec.productName &&
            this.state.formInputFields.country.value &&
            this.state.formInputFields.connection.value &&
            this.state.formInputFields.communicationPlatform.value
          ) {
            errorMessage = {
              presentProductName: `${rec.productName} (${rec.productCode})`,
              country: this.state.formInputFields.country.value,
              connection: this.state.formInputFields.connection.value,
              communicationPlatform:
                this.state.formInputFields.communicationPlatform.value,
              error: "notification.error.serviceIsAlreadyAdded",
            };
          } else {
            errorMessage = {
              presentProductName: `${rec.productName} (${rec.productCode})`,
              country: this.state.formInputFields.country.value,
              connection: this.state.formInputFields.connection.value,
              communicationPlatform:
                this.state.formInputFields.communicationPlatform.value,
              error: "notification.error.serviceIsAlreadyWithOutPlatFormAdded",
            };
          }

          serviceAvailableStatus = false;
          break;
        }
      }
    }
    if (serviceAvailableStatus === true) {
      await this.setStateAsync({ isPageDataFetched: true });
      await this.setProductDetails(
        OperatorConnectConstants.AZURE_APPLICATION_INSIGHTS_LOG_MESSAGE
          .QUOTE_PRODUCT_DATA_ADDED,
        this.props.quoteUserFormInputFields
      );
      await this.handleAddServices();
      await this.setStateAsync({ isPageDataFetched: false });
    } else {
      await this.setStateAsync({
        quoteServiceSubmitStatus:
          OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
      });
      await this.setStateAsync({
        quoteServiceError: errorMessage,
      });
    }
  };

  handleAddServices = async () => {
    let serviceRecords = [];
    let orderEstimatorRecords = [];
    let allServiceRecords = [];
    let rules = [];
    let quantityStatus = true;
    let errorMessage = null;
    let ruleValue = null;

    if (
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords &&
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
    ) {
      serviceRecords = _.cloneDeep(
        this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
      );
    }
    if (
      this.props.quoteEstimatorInfo?.productRecords &&
      this.props.quoteEstimatorInfo?.productRecords.length > 0
    ) {
      await this.props.quoteEstimatorInfo?.productRecords.forEach((item) => {
        if (item.productId === this.state.formInputFields.service.value) {
          let nrcSellingPrice = 0;
          let mrcSellingPrice = 0;
          let pricing = [];
          let planId = "";
          if (item.planTypes) {
            item.planTypes.forEach((planType) => {
              if (
                planType.planName ===
                this.state.formInputFields.connection.value
              ) {
                planId = planType.planId;
                rules =
                  planType && planType?.rules?.length > 0 ? planType.rules : [];
                planType?.pricing?.forEach(async (price) => {
                  if (
                    price.chargeType?.toLowerCase().trim() ===
                    OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                  ) {
                    mrcSellingPrice = this.handleCalculateDiscount(
                      parseFloat(price?.retailPrice ?? "0").toFixed(2),
                      parseFloat(0).toFixed(2)
                    );
                    pricing.push({
                      chargeType: price.chargeType,
                      pricingId: price.pricingId,
                      chargeName: price.chargeName,
                      maximumPrice: price.maximumPrice,
                      sellingPrice: mrcSellingPrice,
                      discountPercent: parseFloat(0),
                      mrcPartnerBuyingPrice: price?.buyingPrice,
                    });
                  } else if (
                    price.chargeType?.toLowerCase().trim() ===
                    OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                  ) {
                    nrcSellingPrice = this.handleCalculateDiscount(
                      parseFloat(price?.retailPrice ?? "0").toFixed(2),
                      parseFloat(0).toFixed(2)
                    );
                    pricing.push({
                      chargeType: price.chargeType,
                      chargeName: price.chargeName,
                      pricingId: price.pricingId,
                      maximumPrice: price.maximumPrice,
                      sellingPrice: nrcSellingPrice,
                      discountPercent: parseFloat(0),
                      nrcPartnerBuyingPrice: price?.buyingPrice,
                    });
                  }
                });
              }
            });
          }

          orderEstimatorRecords = {
            ...item,
            connection: this.state.formInputFields.connection.value,
            planId: planId,
            pricing: pricing,
            rules: rules,
            communicationPlatform:
              this.state.formInputFields.communicationPlatform.value,
            quantity: parseInt(this.state.formInputFields.quantity.value),
            selectedCountry: this.state.selectedCountry,
            forms: [],
          };
        }
      });
    }
    if (rules && rules.length > 0) {
      for(let rule of rules){
        if (rule.condition?.toLowerCase() === "minimum") {
          if (parseInt(rule.value) > parseInt(this.state.formInputFields.quantity.value)) {
            quantityStatus = false;
            errorMessage = 
              {quantity:rule.value,error:"notification.error.quantityMinimumRequired"};
            break;

          }
        }
        if (rule.condition?.toLowerCase() === "maximum") {
          if (parseInt(rule.value) < parseInt(this.state.formInputFields.quantity.value)) {
            quantityStatus = false;
            errorMessage = 
              {quantity:rule.value,error:"notification.error.quantityMaximumRequired"};

            break;
          }
        }
        if (rule.condition?.toLowerCase() === "pack") {
          if (parseInt(this.state.formInputFields.quantity.value) % parseInt(rule.value) !== 0) {
            quantityStatus = false;
            errorMessage = 
             {quantity:rule.value,error:"notification.error.quantityPackRequired"}

            break;

          }
        }
      }
      }
    if (quantityStatus === false) {
      await this.setStateAsync({
        quoteServiceSubmitStatus:
          OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
      });
      await this.setStateAsync({
        quoteServiceError: errorMessage,
      });
    }
    if (quantityStatus === true) {
      serviceRecords.push(orderEstimatorRecords);
      await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
        this.props.quoteEstimatorInfo,
        serviceRecords
      );
      let nrcSubTotal = 0;
      let mrcSubTotal = 0;
      let total = 0;

      if (
        this.props.quoteEstimatorInfo?.serviceAndConnectionRecords &&
        this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
      ) {
        await this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.forEach(
          async (obj, index) => {
            let mrcCount = 0;
            let nrcCount = 0;
            let nrcretailPrice = 0;
            let mrcretailPrice = 0;
            let mrcDidCost = null;
            if (obj.pricing && obj.pricing.length > 0) {
              obj.pricing.forEach((price) => {
                if (
                  price.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                ) {
                  if (
                    price?.chargeName?.toLowerCase()?.trim() === "did charges"
                  ) {
                    mrcDidCost = price.retailPrice;
                  }
                  mrcretailPrice = price.retailPrice;
                  mrcCount =
                    mrcCount +
                    parseFloat(obj?.quantity ?? "0") *
                      parseFloat(price?.sellingPrice ?? "0");
                } else if (
                  price.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                ) {
                  nrcretailPrice = price.retailPrice;
                  nrcCount =
                    nrcCount +
                    parseFloat(obj?.quantity ?? "0") *
                      parseFloat(price?.sellingPrice ?? "0");
                }
              });
            } else {
            }
            nrcSubTotal = nrcSubTotal + nrcCount;
            mrcSubTotal = mrcSubTotal + mrcCount;
            allServiceRecords.push({
              ...obj,
              id: index,
              mrc: mrcCount.toString(),
              nrc: nrcCount.toString(),
              nrcretailPrice: nrcretailPrice?.toString(),
              mrcretailPrice: mrcretailPrice?.toString(),
              mrcDidCost: mrcDidCost?.toString(),
            });
          }
        );
        total = total + nrcSubTotal + mrcSubTotal;
        await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
          this.props.quoteEstimatorInfo,
          [...allServiceRecords]
        );
        await this.props.setQuoteEstimatorInfoServiceAndConnectionCost(
          this.props.quoteEstimatorInfo,
          nrcSubTotal.toString(),
          mrcSubTotal.toString(),
          total.toString()
        );
        await this.setStateAsync({
          isServiceDisabled: true,
          connectionList: [],
          connectionValue: "",
          editedServiceRecord: null,
        });
      }
      let productFormData = {
        isCurrencyEnabled: true,
        currencyCode: this.state.formInputFields.currency.value,
        platformDisable: this.state.platformDisable,
      };
      await this.props.setProductFormState(productFormData);
      await this.setStateAsync({
        formInputFields: this.initFormInputFields(),
        isFormFilled: false,
        isDiscountFieldEnabled: false,
        isCurrencyEnabled: true,
        selectedCountry: "",
        countryProductRecords: [],
        isHostEnabled: false,
        platformDisable: false,
      });
      await this.props.handleAddOrEditProductAndServicesModalClose();
    }
  };

  handleEditServiceAndConnection = async () => {
    this.props.setPageExit(true);
    let serviceAvailableStatus = true;
    let errorMessage = null;
    let presentService = this.props.quoteEstimatorInfo?.productRecords.find(
      (rec) => rec.productId === this.state.formInputFields.service.value
    );
    let previousService = "";
    await this.clearAlert();
    for (let rec of this.props.quoteEstimatorInfo
      ?.serviceAndConnectionRecords) {
      if (
        rec.connection?.toLowerCase().trim() ===
          this.state.formInputFields.connection.value?.toLowerCase()?.trim() &&
        rec.availabilityName?.toLowerCase().trim() ===
          this.state.formInputFields.country.value?.toLowerCase()?.trim() &&
        rec.communicationPlatform?.toLowerCase().trim() ===
          this.state.formInputFields.communicationPlatform.value
            ?.toLowerCase()
            ?.trim() &&
        rec.productId?.toLowerCase().trim() ===
          this.state.formInputFields.service.value?.toLowerCase()?.trim() &&
        rec.id !== this.state.editedServiceRecord.id
      ) {
        if (
          rec.productName &&
          this.state.formInputFields.country.value &&
          this.state.formInputFields.connection.value &&
          this.state.formInputFields.communicationPlatform.value
        ) {
          errorMessage = {
            presentProductName: `${rec.productName} (${rec.productCode})`,
            country: this.state.formInputFields.country.value,
            connection: this.state.formInputFields.connection.value,
            communicationPlatform:
              this.state.formInputFields.communicationPlatform.value,
            error: "notification.error.serviceIsAlreadyAdded",
          };
        } else {
          errorMessage = {
            presentProductName: `${rec.productName} (${rec.productCode})`,
            country: this.state.formInputFields.country.value,
            connection: this.state.formInputFields.connection.value,
            communicationPlatform:
              this.state.formInputFields.communicationPlatform.value,
            error: "notification.error.serviceIsAlreadyWithOutPlatFormAdded",
          };
        }
        serviceAvailableStatus = false;
        break;
      }
    }
    if (serviceAvailableStatus === true) {
      await this.setProductDetails(
        OperatorConnectConstants.AZURE_APPLICATION_INSIGHTS_LOG_MESSAGE
          .QUOTE_PRODUCT_DATA_UPDATED,
        this.props.quoteUserFormInputFields
      );
      await this.handleAddQuoteEditServices();
    } else {
      await this.setStateAsync({
        quoteServiceSubmitStatus:
          OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
      });
      await this.setStateAsync({
        quoteServiceError: errorMessage,
      });
      await this.setStateAsync({ isServiceDisabled: true });
    }
  };

  handleCalculateDiscount = (amount, discount) => {
    let discountedAmount = 0;
    let disc = discount ? discount : 0;
    let amt = amount ? amount : 0;
    discountedAmount = amount - (amt * disc) / 100;
    return parseFloat(Number(discountedAmount).toFixed(2));
  };

  setProductDetails = async (logStatusMessage, quoteUserFormInputFields) => {
    if (quoteUserFormInputFields && this.state.configProductFormInputFields) {
      let presentService = this.props.quoteEstimatorInfo?.productRecords.find(
        (rec) =>
          rec.productId ===
          this.state.configProductFormInputFields.service.value
      );
      const customProperties = {
        CustomerData: {
          customerName: quoteUserFormInputFields?.quoteName?.value,
          customerEmail: quoteUserFormInputFields?.email?.value,
          customerPhone: quoteUserFormInputFields?.phone?.value,
        },
        ProductData: {
          countryName: this.state.formInputFields.country.value,
          productName: `${presentService.productName} ${presentService.productCode}`,
          planName: this.state.formInputFields.connection.value,
          communicationPlatform:
            this.state.formInputFields.communicationPlatform.value,
          quantity: parseInt(this.state.formInputFields.quantity.value),
        },
      };
      TelemetryUtil.logVerbose(logStatusMessage, customProperties);
    }
  };

  handleAddQuoteEditServices = async () => {
    if (
      this.state.editedServiceRecord &&
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
    ) {
      let editedOrderEstimatorRecord = [];
      let nrcSubTotal = 0;
      let mrcSubTotal = 0;
      let total = 0;
      let connectionList = [];
      let pricing = [];
      let rules = [];
      let planId = "";
      let quantityStatus = true;
      let errorMessage = null;
      let serviceConnectionRecords = _.cloneDeep(
        this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
      );
      editedOrderEstimatorRecord = await serviceConnectionRecords.map((obj) => {
        let mrcCount = 0;
        let nrcCount = 0;
        let nrcretailPrice = 0;
        let mrcretailPrice = 0;
        if (this.state.editedServiceRecord.id === obj.id) {
          let quantity =
            this.state.formInputFields.quantity.value !== ""
              ? this.state.formInputFields.quantity.value
              : "0";
          if (obj.planTypes) {
            obj.planTypes.forEach((planType) => {
              connectionList.push({
                key: planType.planName,
                text: planType.planName,
              });

              if (planType.planName === obj.connection) {
                planId = planType.planId;
                planType?.pricing?.forEach((price) => {
                  if (
                    price.chargeType?.toLowerCase().trim() ===
                    OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                  ) {
                    let mrcSellingPrice = 0;
                    mrcSellingPrice = this.handleCalculateDiscount(
                      parseFloat(price?.retailPrice ?? "0").toFixed(2),
                      parseFloat("0").toFixed(2)
                    );
                    pricing.push({
                      chargeType: price.chargeType,
                      chargeName: price.chargeName,
                      maximumPrice: price.maximumPrice,
                      pricingId: price.pricingId,
                      sellingPrice: mrcSellingPrice,
                      discountPercent: parseFloat("0"),
                      mrcPartnerBuyingPrice: price.buyingPrice,
                    });
                    mrcretailPrice = price.retailPrice;
                    mrcCount =
                      mrcCount +
                      parseFloat(quantity ?? "0") *
                        parseFloat(price?.retailPrice ?? "0");
                  } else if (
                    price.chargeType?.toLowerCase().trim() ===
                    OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                  ) {
                    let nrcSellingPrice = 0;
                    nrcSellingPrice = this.handleCalculateDiscount(
                      parseFloat(price?.retailPrice ?? "0").toFixed(2),
                      parseFloat("0").toFixed(2)
                    );
                    pricing.push({
                      chargeType: price.chargeType,
                      maximumPrice: price.maximumPrice,
                      chargeName: price.chargeName,
                      sellingPrice: nrcSellingPrice,
                      pricingId: price.pricingId,
                      discountPercent: parseFloat("0"),
                      nrcPartnerBuyingPrice: price.buyingPrice,
                    });
                    nrcretailPrice = price.retailPrice;
                    nrcCount =
                      nrcCount +
                      parseFloat(quantity ?? "0") *
                        parseFloat(price?.retailPrice ?? "0");
                  }
                });
                if (planType.rules && planType.rules.length > 0) {
                  rules = planType.rules;
                }
              }
            });
           
          }
          nrcCount = this.handleCalculateDiscount(
            parseFloat(nrcCount).toFixed(2),
            parseFloat("0").toFixed(2)
          );
          mrcCount = this.handleCalculateDiscount(
            parseFloat(mrcCount).toFixed(2),
            parseFloat("0").toFixed(2)
          );
          nrcSubTotal = nrcSubTotal + nrcCount;
          mrcSubTotal = mrcSubTotal + mrcCount;
          obj.pricing = pricing;
          if (obj.rules && obj.rules.length > 0) {
            rules = obj.rules;
          }
          return {
            ...obj,
            planId: planId,
            connection: this.state.formInputFields.connection.value,
            communicationPlatform:
              this.state.formInputFields.communicationPlatform.value,
            quantity: parseInt(this.state.formInputFields.quantity.value),
            mrc: mrcCount.toString(),
            nrc: nrcCount.toString(),
            nrcretailPrice: nrcretailPrice?.toString(),
            mrcretailPrice: mrcretailPrice?.toString(),
            nrcDiscount: "0",
            mrcDiscount: "0",
          };
        } else {
          nrcSubTotal = nrcSubTotal + parseFloat(obj.nrc);
          mrcSubTotal = mrcSubTotal + parseFloat(obj.mrc);
          return obj;
        }
      });
      total = total + nrcSubTotal + mrcSubTotal;
      if (rules && rules.length > 0) {
        for(let rule of rules){
          if (rule.condition?.toLowerCase() === "minimum") {
            if (parseInt(rule.value) > parseInt(this.state.formInputFields.quantity.value)) {
              quantityStatus = false;
              errorMessage = 
                {quantity:rule.value,error:"notification.error.quantityMinimumRequired"};
              break;
  
            }
          }
          if (rule.condition?.toLowerCase() === "maximum") {
            if (parseInt(rule.value) < parseInt(this.state.formInputFields.quantity.value)) {
              quantityStatus = false;
              errorMessage = 
                {quantity:rule.value,error:"notification.error.quantityMaximumRequired"};
  
              break;
            }
          }
          if (rule.condition?.toLowerCase() === "pack") {
            if (parseInt(this.state.formInputFields.quantity.value) % parseInt(rule.value) !== 0) {
              quantityStatus = false;
              errorMessage = 
               {quantity:rule.value,error:"notification.error.quantityPackRequired"}
  
              break;
  
            }
          }
        }
        }
      if (quantityStatus === false) {
        await this.setStateAsync({
          quoteServiceSubmitStatus:
            OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
        });
        await this.setStateAsync({
          quoteServiceError: errorMessage,
        });
      }
      if (quantityStatus === true) {
        await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
          this.props.quoteEstimatorInfo,
          [...editedOrderEstimatorRecord]
        );
        await this.props.setQuoteEstimatorInfoServiceAndConnectionCost(
          this.props.quoteEstimatorInfo,
          nrcSubTotal.toString(),
          mrcSubTotal.toString(),
          total.toString()
        );
        let productFormData = {
          isCurrencyEnabled: true,
          currencyCode: this.state.formInputFields.currency.value,
          platformDisable: this.state.platformDisable,
        };
        await this.props.setProductFormState(productFormData);
        await this.setStateAsync({
          formInputFields: this.initFormInputFields(),
          connectionList: connectionList,
          editedServiceRecord: null,
          isServiceDisabled: true,
        });
        await this.setStateAsync({
          selectedCountry: "",
          countryProductRecords: [],
          connectionList: [],
          platformDisable: false,
        });
        await this.setFormFieldValue("country", "");

        await this.props.handleAddOrEditProductAndServicesModalClose();
      }
    }
  };

  handleChoiceGroupChange = async (e, option) => {
    if (e && e.target) {
      await this.setFormFieldValue(e.target.name, option.key);
    }
    await this.validateFormField(e.target.name);
    await this.setStateAsync({
      isFormFilled: await this.isFormFilled(),
    });
    await this.clearAlert();
  };

  handleFormFieldChange = async (e) => {
    if (e && e.target) {
      if (e.target.id === "quantity") {
        const regex = new RegExp("^[0-9]*$");
        if (regex.test(e.target.value) === true) {
          if (e.target.value.startsWith("0") === true) {
            e.target.value = "";
          }
        } else {
          e.target.value = "";
        }
      }
      await this.setFormFieldValue(e.target.id, e.target.value);
      await this.validateFormField(e.target.id);
      await this.setStateAsync({
        isFormFilled: await this.isFormFilled(),
      });
    }
    await this.clearAlert();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isAddOrEditProductModalOpen}
        className={`addOrEdit-Product-modal ${modalStyles.container}`}
        layerProps={{ eventBubblingEnabled: true }}
        forceFocusInsideTrap={false}
      >
        <div className="modal-wrapper">
          <div
            className={
              modalStyles.header +
              ` modal-header-wrapper ${CustomStyles.getStyle(
                this.props.websiteTemplate?.templateCode
                  ?.toLowerCase()
                  .trim() !==
                  OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? "primary-background-color"
                  : "secondary-background-color",
                this.props.websiteTemplate
              )}`
            }
          >
            <div className="p-t-5 p-b-5">
              {IntlUtil.getText(
                this.props.intlNsOCQuote,
                this.state.editedServiceRecord
                  ? "content.updateProductOrService"
                  : "content.addProductOrService"
              )}
            </div>
            <ClearIcon
              onClick={async () => {
                await this.clearAlert();
                await this.setStateAsync({
                  formInputFields: this.initFormInputFields(),
                });
                await this.props.resetSelectedProductId();
                await this.props.handleAddOrEditProductAndServicesModalClose();
              }}
              className="m-l-10 m-r-10 m-t-10 m-b-10 cursor-pointer"
            />
          </div>
          <PageOverlayLoader
            hidden={!this.state.isPageDataFetched}
            label={IntlUtil.getText(
              this.props.intlNsCommon,
              "content.loadingInprogress"
            )}
          />
          <div className="modal-content">
            <div className="m-t-10">
              {this.state.quoteServiceSubmitStatus ===
              OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE ? (
                <div className=" p-l-15 p-r-15">
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    className="notification-messagebar"
                  >
                    <span>
                      {IntlUtil.getSubstituteText(
                        this.props.intlNsOCQuote,
                        this.state.quoteServiceError?.error,
                        [
                          {
                            key: "<QUANTITY>",
                            value: this.state.quoteServiceError?.quantity,
                          },
                          {
                            key: "<NEW_SERVICE>",
                            value:
                              this.state.quoteServiceError?.presentProductName,
                          },
                          {
                            key: "<OLD_SERVICE>",
                            value:
                              this.state.quoteServiceError?.previousProductName,
                          },
                          {
                            key: "<COUNTRY>",
                            value: this.state.quoteServiceError?.country,
                          },
                          {
                            key: "<PLATFORM>",
                            value: this.state.quoteServiceError
                              ?.communicationPlatform
                              ? this.state.quoteServiceError
                                  ?.communicationPlatform
                              : " ",
                          },
                          {
                            key: "<CONNECTION>",
                            value: this.state.quoteServiceError?.connection,
                          },
                          {
                            key: "<PRODUCT_CODE>",
                            value: this.state.quoteServiceError?.productCode,
                          },
                        ]
                      )}
                    </span>
                  </MessageBar>
                </div>
              ) : null}
            </div>
            <Grid fluid className="modal-form modal-form-grid">
              <Row>
                {this.props.currencyData.length > 1 ? (
                  <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                    <Dropdown
                      id="currency"
                      name="currency"
                      required={this.state.formInputFields.currency.isRequired}
                      defaultSelectedKey={
                        this.state.formInputFields.currency.value
                      }
                      selectedKey={this.state.formInputFields.currency.value}
                      label={IntlUtil.getText(
                        this.props.intlNsOCQuote,
                        "content.currency"
                      )}
                      className="modal-form-dropdown"
                      onChange={(e, option) =>
                        this.handleCurrencyFormDropdownChange(e, option)
                      }
                      disabled={
                        this.state.editedServiceRecord ||
                        this.state.isCurrencyEnabled
                      }
                      // errorMessage={
                      //   this.state.formInputFields.currency.isError
                      //     ? IntlUtil.getText(
                      //         this.props.intlNsOCQuote,
                      //         this.state.formInputFields.currency.errorMessage
                      //       )
                      //     : null
                      // }
                      options={this.props.currencyData ?? []}
                    />
                    <div>
                      <InfoIcon />
                      <span className="info-text">
                        {IntlUtil.getText(
                          this.props.intlNsOCQuote,
                          "content.infoText.chooseCurrency"
                        )}
                      </span>
                    </div>
                  </Col>
                ) : null}
                <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                  <SearchDropdown
                    id="country"
                    name="country"
                    required={this.state.formInputFields.country.isRequired}
                    defaultSelectedKey={
                      this.state.formInputFields.country.value
                    }
                    selectedKey={this.state.formInputFields.country.value}
                    label={IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.country"
                    )}
                    className="modal-form-dropdown"
                    onChange={(e, option) =>
                      this.handleCountryFormDropdownChange(e, option)
                    }
                    disabled={
                      this.state.editedServiceRecord ||
                      this.state.formInputFields.currency.value.trim() === ""
                    }
                    // errorMessage={
                    //   this.state.formInputFields.country.isError
                    //     ? IntlUtil.getText(
                    //         this.props.intlNsOCQuote,
                    //         this.state.formInputFields.country.errorMessage
                    //       )
                    //     : null
                    // }
                    options={getCountryList(
                      _.orderBy(this.props.countryList, "text", "asc"),
                      {
                        showFavouriteCountries: true,
                        showBlankValue: false,
                        showDivider: true,
                      }
                    )}
                  />
                  <div>
                    <InfoIcon className="" />
                    <span className=" info-text">
                      {IntlUtil.getText(
                        this.props.intlNsOCQuote,
                        "content.infoText.country"
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                  <SearchDropdown
                    id="service"
                    name="service"
                    searchType={OperatorConnectConstants.SEARCH_TYPE.CONTAINS}
                    required={this.state.formInputFields.service.isRequired}
                    label={IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.productOrService"
                    )}
                    className="modal-form-dropdown"
                    selectedKey={this.state.formInputFields.service.value}
                    onChange={(e, option) =>
                      this.handleConfigProductDropdownChange(e, option)
                    }
                    disabled={
                      this.state.editedServiceRecord ||
                      this.state.formInputFields.country.value === ""
                    }
                    options={this.state.productServices}
                    // errorMessage={
                    //   this.state.formInputFields.service.isError
                    //     ? IntlUtil.getText(
                    //         this.props.intlNsOCQuote,
                    //         this.state.formInputFields.service.errorMessage
                    //       )
                    //     : null
                    // }
                  />
                  <div>
                    <InfoIcon className="" />
                    <span className="info-text">
                      {IntlUtil.getText(
                        this.props.intlNsOCQuote,
                        "content.infoText.product"
                      )}
                    </span>
                  </div>
                </Col>
                {this.state.formInputFields.service.value !== "" ? (
                  <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                    <ChoiceGroup
                      id="connection"
                      name="connection"
                      required={
                        this.state.formInputFields.connection.isRequired
                      }
                      disabled={this.state.editedServiceRecord}
                      selectedKey={this.state.formInputFields.connection.value}
                      label={IntlUtil.getText(
                        this.props.intlNsOCQuote,
                        "content.planOrConnection"
                      )}
                      onChange={(e, option) =>
                        this.handleChoiceGroupChange(e, option)
                      }
                      className="modal-form-radio-button"
                      options={this.state.connectionList}
                      // errorMessage={
                      //   this.state.formInputFields.connection.isError
                      //     ? IntlUtil.getText(
                      //         this.props.intlNsOCQuote,
                      //         this.state.formInputFields.connection.errorMessage
                      //       )
                      //     : null
                      // }
                    />
                    <div>
                      <InfoIcon className="" />
                      <span className=" info-text">
                        {IntlUtil.getText(
                          this.props.intlNsOCQuote,
                          "content.infoText.plan"
                        )}
                      </span>
                    </div>
                  </Col>
                ) : null}
              </Row>
              {this.state.formInputFields.service.value !== "" ? (
                <Row>
                  {this.props.webTemplateConfigData?.websiteType ===
                  "Operator Connect" ? (
                    <Fragment>
                      <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                        <TextField
                          required={
                            this.state.formInputFields.quantity.isRequired
                          }
                          id="quantity"
                          name="quantity"
                          maxLength={5}
                          value={this.state.formInputFields.quantity.value}
                          onChange={this.handleFormFieldChange}
                          label={IntlUtil.getText(
                            this.props.intlNsOCQuote,
                            "content.quantity"
                          )}
                          // errorMessage={
                          //   this.state.formInputFields?.quantity.isError
                          //     ? IntlUtil.getText(
                          //         this.props.intlNsOCQuote,
                          //         this.state.formInputFields?.quantity
                          //           ?.errorMessage
                          //       )
                          //     : null
                          // }
                          className="modal-form-textfield"
                        />
                        <div>
                          <InfoIcon className="" />
                          <span className=" info-text">
                            {IntlUtil.getText(
                              this.props.intlNsOCQuote,
                              "content.infoText.quantity"
                            )}
                          </span>
                        </div>
                      </Col>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                        <Dropdown
                          id="communicationPlatform"
                          required={
                            this.state.formInputFields.communicationPlatform
                              .isRequired
                          }
                          defaultSelectedKey={
                            this.state.formInputFields.communicationPlatform
                              .value
                          }
                          label={IntlUtil.getText(
                            this.props.intlNsOCQuote,
                            "content.platform"
                          )}
                          disabled={this.state.platformDisable}
                          className="modal-form-dropdown"
                          onChange={(e, option) =>
                            this.handleConfigProductDropdownChange(e, option)
                          }
                          // errorMessage={
                          //   this.state.formInputFields.communicationPlatform
                          //     .isError
                          //     ? IntlUtil.getText(
                          //         this.props.intlNsOCQuote,
                          //         this.state.formInputFields
                          //           .communicationPlatform.errorMessage
                          //       )
                          //     : null
                          // }
                          options={this.state.communicationPlatformList}
                        />
                        <div>
                          <InfoIcon className="" />
                          <span className="info-text">
                            {IntlUtil.getText(
                              this.props.intlNsOCQuote,
                              "content.infoText.platform"
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} xs={12} className="m-b-15">
                        <TextField
                          required={
                            this.state.formInputFields.quantity.isRequired
                          }
                          id="quantity"
                          name="quantity"
                          maxLength={5}
                          value={this.state.formInputFields.quantity.value}
                          onChange={this.handleFormFieldChange}
                          label={IntlUtil.getText(
                            this.props.intlNsOCQuote,
                            "content.quantity"
                          )}
                          // errorMessage={
                          //   this.state.formInputFields?.quantity.isError
                          //     ? IntlUtil.getText(
                          //         this.props.intlNsOCQuote,
                          //         this.state.formInputFields?.quantity
                          //           ?.errorMessage
                          //       )
                          //     : null
                          // }
                          className="modal-form-textfield"
                        />
                        <div>
                          <InfoIcon className="" />
                          <span className=" info-text">
                            {IntlUtil.getText(
                              this.props.intlNsOCQuote,
                              "content.infoText.quantity"
                            )}
                          </span>
                        </div>
                      </Col>
                    </Fragment>
                  )}
                </Row>
              ) : null}
            </Grid>
            <div className="modal-footer-frame-separator"></div>
            <div className="modal-form-actions">
              {this.state.editedServiceRecord ? (
                <PrimaryButton
                  className="m-r-10"
                  text={IntlUtil.getText(
                    this.props.intlNsOCQuote,
                    "content.save"
                  )}
                  disabled={
                    !this.state.isFormFilled || this.state.isServiceDisabled
                  }
                  onClick={this.handleEditServiceAndConnection}
                />
              ) : (
                <PrimaryButton
                  className="m-r-10"
                  text={IntlUtil.getText(
                    this.props.intlNsOCQuote,
                    "content.save"
                  )}
                  disabled={
                    !this.state.isFormFilled || this.state.isServiceDisabled
                  }
                  onClick={this.handleAddServiceAndConnection}
                />
              )}
              <DefaultButton
                text={IntlUtil.getText(
                  this.props.intlNsOCQuote,
                  "content.close"
                )}
                onClick={async () => {
                  await this.clearAlert();
                  await this.setStateAsync({
                    formInputFields: this.initFormInputFields(),
                  });
                  await this.props.resetSelectedProductId();
                  await this.props.handleAddOrEditProductAndServicesModalClose();
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCurrencyData: state.generalStore.selectedCurrencyData,
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  countryStatesData: state.generalStore.countryStatesData,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  productRecords: state.productStore.productRecords,
  currencyData: state.generalStore.currencyData,
});

const mapActionToProps = {
  setCurrencyData,
  setSelectedCurrencyData,
  resetQuoteEstimatorInfo,
};

export default connect(mapStateToProps, mapActionToProps)(AddOrEditProductForm);
