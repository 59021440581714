import React from "react";
import { Modal, Text, DefaultButton, IconButton } from "@fluentui/react";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import CustomStyles from "../../common/helpers/custom-styles";
import { modalStyles } from "../../common/helpers/styles";
import IntlUtil from "../../../core/helpers/intl-util";
import { ClearIcon } from "@fluentui/react-icons";
import { connect } from "react-redux";

const SignInDialog = (props) => {
  const handlePartnerPortal = () => {
    window.open(process.env.REACT_APP_PARTNER_PORTAL_URL, "_blank");
  };

  const handleCreateOrderPage = async () => {
    props.loadQuoteCreatePage();
    props.setPageExit();
    props.handleSignInDialogClose();
  };

  return (
    <Modal
      className="custom-modal-wrapper"
      isOpen={!props.isSignInDialogHidden}
    >
      <div className="custom-modal-wrapper-width">
        <div
          className={`${
            modalStyles.header
          } header-wrapper ${CustomStyles.getStyle(
            props.websiteTemplate?.templateCode?.toLowerCase().trim() !==
              OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
              ? "primary-background-color"
              : "secondary-background-color",
            props.websiteTemplate
          )}`}
        >
          <div className="header-text">
            {IntlUtil.getText(props.intlNamespace, "content.signInInfo")}
          </div>
          <ClearIcon
            onClick={() => {
              props.handleSignInDialogClose();
            }}
            className="m-l-10 m-r-10 m-t-10 m-b-10 cursor-pointer"
          />
        </div>
        <div className="content-wrapper">
          <div className="m-t-15">
            <Text
              className={`text-fw-semibold ${CustomStyles.getStyle(
                "font-family",
                props.websiteTemplate
              )}`}
            >
              {IntlUtil.getText(
                props.intlNamespace,
                "content.alreadyAccountInfo"
              )}
            </Text>
          </div>

          <DefaultButton
            className="m-t-15 m-b-15"
            onClick={() => handlePartnerPortal()}
          >
            {IntlUtil.getText(props.intlNamespace, "content.signInInfo")}
          </DefaultButton>
          <div>
            <Text
              className={`text-fs-small-plus ${CustomStyles.getStyle(
                "font-family",
                props.websiteTemplate
              )}`}
            >
              {IntlUtil.getText(
                props.intlNamespace,
                "content.websiteRedirectedInfo"
              )}
            </Text>
          </div>
          {props?.productRecords?.records?.length > 0 ? (
            <>
              <div className="m-t-45">
                <Text
                  className={`text-fw-semibold ${CustomStyles.getStyle(
                    "font-family",
                    props.websiteTemplate
                  )}`}
                >
                  {IntlUtil.getText(
                    props.intlNamespace,
                    "content.createAccountInfo"
                  )}
                </Text>
              </div>

              <DefaultButton
                className="m-t-15 m-b-25"
                onClick={() => handleCreateOrderPage()}
              >
                {IntlUtil.getText(props.intlNamespace, "content.createOrder")}
              </DefaultButton>
            </>
          ) : (
            <div className="m-b-25"></div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  productRecords: state.productStore.productRecords,
});
const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(SignInDialog);
