import { OperatorConnectURLProps } from "../../pages/common/settings/operator-connect-urls";
import TokenUtil from "../helpers/token-util";
import { AppConfigProps } from "../settings/app-config";
import { renewIdentitySession } from "./identity-actions";

export function logMessage(type, path, statusCode, message) {
  if (AppConfigProps.log.messages === true) {
    console.log(type, path, statusCode, message);
  }
}

export function manageError(err, history) {
  return new Promise(function (resolve, reject) {
    console.error(err, history);
    let path = "";
    if (history?.location?.pathname?.length > 0)
      path = path + history?.location?.pathname;
    if (history?.location?.search?.length > 0)
      path = path + history?.location?.search;
    if (history?.location?.hash?.length > 0)
      path = path + history?.location?.hash;
    let statusCode = "";
    if (err?.status) statusCode = err.status;
    let message = "";
    if (err?.data?.message) message = err.data.message;
    let endPoint = "";
    if (err && err.data && err.config && err.config.baseURL && err.config.url)
      endPoint = err.config.baseURL + err.config.url;
    let method = "";
    if (err && err.data && err.config && err.config.method)
      method = err.config.method;

    logMessage(
      AppConfigProps.log.severityLevel.error,
      path,
      statusCode,
      message
    );
    if (
      err &&
      (err.status === AppConfigProps.httpStatusCode.notFound ||
        err.status === AppConfigProps.httpStatusCode.unauthorized)
    ) {
      history.push(OperatorConnectURLProps.pageNotFound, {
        state: {
          pageURL: path,
          method: method,
          statusCode: statusCode,
          errMessage: message,
          endPoint: endPoint,
        },
      });
    } else if (err?.status === AppConfigProps.httpStatusCode.requestTimeout) {
    } else {
      history.push(OperatorConnectURLProps.error, {
        state: {
          pageURL: path,
          method: method,
          statusCode: statusCode,
          errMessage: message,
          endPoint: endPoint,
        },
      });
    }
  });
}

export function checkIdentitySession(cancelToken) {
  const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
  if (
    tokenData &&
    tokenData.partnerId &&
    tokenData.expiry >= Date.now() / 1000
  ) {
    if (
      tokenData.expiry - parseInt(Date.now() / 1000) <=
      AppConfigProps.identitySession.expiryCheckRemainingSeconds
    ) {
      let operatorWebsiteUrl = window.location.origin;
      let template = "3";
      if (window.location.origin.includes("http://localhost") === true) {
        if (template === "1") {
          operatorWebsiteUrl = "https://oc-dev.operatorconnect.io";
        } else if (template === "2") {
          operatorWebsiteUrl = "https://oc-ui-app-dev.azurewebsites.net";
        } else if (template === "3") {
          operatorWebsiteUrl = "https://oc-ui-app-dev-uk.azurewebsites.net";
        }
      }
      // console.log("refresh After 5 min");
      renewIdentitySession(operatorWebsiteUrl, cancelToken);
    }
    return true;
  } else {
    let operatorWebsiteUrl = window.location.origin;
    if (window.location.origin.includes("http://localhost") === true) {
      let template = "3";
      if (template === "1") {
        operatorWebsiteUrl = "https://oc-dev.operatorconnect.io";
      } else if (template === "2") {
        operatorWebsiteUrl = "https://oc-ui-app-dev.azurewebsites.net";
      } else if (template === "3") {
        operatorWebsiteUrl = "https://oc-ui-app-dev-uk.azurewebsites.net";
      }
    }
    // console.log("refreshed");
    renewIdentitySession(operatorWebsiteUrl, cancelToken);
    return true;
  }
}
