import React, { Component, createRef } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import {
  Checkbox,
  DefaultButton,
  IconButton,
  PrimaryButton,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { DownloadIcon, UploadIcon, InfoIcon } from "@fluentui/react-icons";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { QuotePOFormFields } from "../settings/quote-user-form-fields";
import IntlUtil from "../../../core/helpers/intl-util";
import CustomStyles from "../../common/helpers/custom-styles";

class OrderCheckoutCompanyPO extends Component {
  constructor(props) {
    super(props);
    this.quoteFromUploadRef = createRef();
    this.companyPORef = createRef();
    this.quoteNameRef = createRef();
    this.emailRef = createRef();
    this.phoneRef = createRef();
    this.state = {
      fileAttachment: null,
      quoteFileErrors: null,
      isTermAgreeChecked: false,
      companyPoFormInputFIelds: this.initCompanyPOFormInputFields(),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.loadPageData();
  }

  loadPageData = async () => {
    if (
      this.props.quoteEstimatorInfo &&
      this.props.quoteEstimatorInfo.subscriberDetails
    ) {
      await this.setFormFieldValues(
        this.props.quoteEstimatorInfo.subscriberDetails
      );
    }
  };

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  initCompanyPOFormInputFields = () => {
    let refCollection = {
      comapnyPO: this.companyPORef,
      quoteName: this.quoteNameRef,
      email: this.emailRef,
      phone: this.phoneRef,
    };
    return QuotePOFormFields(refCollection);
  };

  setFormFieldValues = async (serviceRecord) => {
    if (serviceRecord) {
      await this.setStateAsync({
        companyPoFormInputFIelds: {
          ...this.state.companyPoFormInputFIelds,
          quoteName: {
            ...this.state.companyPoFormInputFIelds["quoteName"],
            value: serviceRecord?.quoteName ?? "",
          },
          email: {
            ...this.state.companyPoFormInputFIelds["email"],
            value: serviceRecord?.email ?? "",
          },
          phone: {
            ...this.state.companyPoFormInputFIelds["phone"],
            value: serviceRecord?.phone ?? "",
          },
        },
      });
    }
  };

  setFormFieldValue = async (fieldName, fieldValue) => {
    if (fieldName && this.state.companyPoFormInputFIelds) {
      await this.setStateAsync({
        companyPoFormInputFIelds: {
          ...this.state.companyPoFormInputFIelds,
          [fieldName]: {
            ...this.state.companyPoFormInputFIelds[fieldName],
            value: fieldValue,
          },
        },
      });
    }
  };

  validateCompanyPOFormField = async (fieldName) => {
    if (fieldName && this.state.companyPoFormInputFIelds) {
      let fieldObj = this.state.companyPoFormInputFIelds[fieldName];
      if (fieldObj.isRequired === true) {
        let errorStatus = false;
        if (fieldObj.validate(fieldObj.value) === false) {
          errorStatus = true;
        }
        await this.setStateAsync({
          companyPoFormInputFIelds: {
            ...this.state.companyPoFormInputFIelds,
            [fieldName]: {
              ...this.state.companyPoFormInputFIelds[fieldName],
              isError: errorStatus,
            },
          },
        });
      }
    }
  };

  isFormFilled = async () => {
    let isFormFilled = true;
    for (const fieldName in this.state.companyPoFormInputFIelds) {
      await this.validateCompanyPOFormField(fieldName);
    }
    for (const fieldName in this.state.companyPoFormInputFIelds) {
      let fieldObj = this.state.companyPoFormInputFIelds[fieldName];
      if (fieldObj.isRequired === true && fieldObj.isError === true) {
        isFormFilled = false;
        break;
      }
    }
    return isFormFilled;
  };

  handleFormFieldChange = async (e) => {
    if (e && e.target) {
      if (e.target.id === "phone") {
        const regExp = /[0-9]*$/;
        if (regExp.test(e.target.value) === true) {
          e.target.value = e.target.value.replace(/\D/g, "");
        }
        await this.setFormFieldValue(e.target.id, e.target.value);
      } else {
        await this.setFormFieldValue(e.target.id, e.target.value);
      }
      await this.setStateAsync({ quoteFileErrors: null });
      await this.props.setPageExit(true);
    }
  };

  handleFormFieldBlur = async (e) => {
    if (e && e.target) {
      await this.validateCompanyPOFormField(e.target.id);
    }
  };

  handleSaveCompanyPo = async () => {
    let formFilled = await this.isFormFilled();
    if (formFilled === true) {
      let obj = {
        companyPoFormInputFIelds: this.state.companyPoFormInputFIelds,
        isPaymentDialogHidden: false,
        attachmentsFile:
          this.state.attachment !== "" && this.state.attachment !== null
            ? [
                {
                  ...this.state.fileAttachment,
                  fileTitle: "Service Order Form",
                },
              ]
            : [],
      };
      await this.props.setCompanyFormData(obj);
    } else {
      for (const fieldName in this.state.companyPoFormInputFIelds) {
        let fieldObj = this.state.companyPoFormInputFIelds[fieldName];
        if (fieldObj.isError === true) {
          fieldObj.refObject?.current?.focus();
          break;
        }
      }
    }
  };

  isValidFileType = async (file) => {
    let isValid = false;
    if (file) {
      const fileName = file.name;
      if (fileName && fileName.length > 0) {
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ATTACHMENT_FILE_TYPES.forEach(
          (fileType) => {
            if (
              fileName
                .substr(fileName.length - fileType.length, fileType.length)
                .toLowerCase() === fileType.toLowerCase()
            ) {
              isValid = true;
            }
          }
        );
      }
    }
    return isValid;
  };

  isValidFileSize = async (file) => {
    let isValid = false;
    if (file) {
      if (
        file.size <=
        parseInt(
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ATTACHMENT_FILE_MAX_SIZE
        ) *
          1024
      ) {
        isValid = true;
      }
    }
    return isValid;
  };

  uploadOrderCheckoutFile = async (readerEvent) => {
    let binaryString = readerEvent.target.result;
    let base64String = btoa(binaryString);
    await this.setStateAsync({
      fileAttachment: {
        ...this.state.fileAttachment,
        fileContent: base64String,
      },
    });

    await this.setStateAsync({ quoteFileErrors: null });
  };

  handleOrderFormAttachmentChange = async (e) => {
    if (e && e.target.files && e.target.files.length === 1) {
      await this.setStateAsync({ quoteFileErrors: null });
      let file = e.target.files[0];
      e.target.value = "";
      if (file) {
        let fileType = file.type;
        if (file.type === "") {
          let fileNameExtension = file.name.split(".");
          if (fileNameExtension[1] && fileNameExtension[1].includes("docx")) {
            fileType =
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          } else if (
            fileNameExtension[1] &&
            fileNameExtension[1].includes("doc")
          ) {
            fileType = "application/msword";
          }
        }
        const isValidFileType = await this.isValidFileType(file);
        const isValidFileSize = await this.isValidFileSize(file);
        if (isValidFileType === true && isValidFileSize === true) {
          const fileObj = { fileName: file.name, fileType: fileType };
          await this.setStateAsync({ fileAttachment: fileObj });

          const reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = await this.uploadOrderCheckoutFile.bind(this);
        } else {
          let errorMessage = "";
          if (isValidFileType === false) {
            errorMessage = "notification.error.attachmentFileTypeInvalid";
          } else if (isValidFileSize === false) {
            errorMessage = "notification.error.attachmentFileSizeInvalid";
          }
          await this.setStateAsync({
            quoteFileErrors: errorMessage,
          });
        }
      }
    }
  };

  handleCheckboxChange = async (e, option) => {
    await this.setStateAsync({ isTermAgreeChecked: option });
  };

  handleDownloadFile = () => {
    window.open(
      OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.SERVICE_ORDER_FORM_URL,
      "_blank"
    );
  };

  handleOrderFormAttachmentClick = async (e) => {
    this.quoteFromUploadRef?.current.click();
  };

  render() {
    const { intlNamespace } = this.props;
    return (
      <>
        <Grid fluid className="m-0 p-l-5">
          <Row>
            <Col xl={3} lg={4} md={4} xs={12} className="m-t-15">
              <TextField
                required
                id="companyPO"
                name="companyPO"
                value={this.state.companyPoFormInputFIelds.companyPO.value}
                componentRef={this.companyPORef}
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(intlNamespace, "content.companyPo")}
                className="page-form-textfield"
                errorMessage={
                  this.state.companyPoFormInputFIelds.companyPO.isError
                    ? IntlUtil.getText(
                        intlNamespace,
                        this.state.companyPoFormInputFIelds.companyPO
                          .errorMessage
                      )
                    : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xl={3} lg={4} md={4} xs={12} className="m-t-10">
              <TextField
                maxLength={50}
                value={this.state.companyPoFormInputFIelds.quoteName.value}
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                componentRef={this.quoteNameRef}
                required
                id="quoteName"
                name="quoteName"
                label={IntlUtil.getText(intlNamespace, "content.name")}
                className="page-form-textfield"
                errorMessage={
                  this.state.companyPoFormInputFIelds.quoteName.isError
                    ? IntlUtil.getText(
                        intlNamespace,
                        this.state.companyPoFormInputFIelds.quoteName
                          .errorMessage
                      )
                    : null
                }
              />
            </Col>

            <Col xl={3} lg={4} md={4} xs={12} className="m-t-10">
              <TextField
                maxLength={100}
                value={this.state.companyPoFormInputFIelds.email.value}
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                componentRef={this.emailRef}
                required
                id="email"
                name="email"
                errorMessage={
                  this.state.companyPoFormInputFIelds.email.isError
                    ? IntlUtil.getText(
                        intlNamespace,
                        this.state.companyPoFormInputFIelds.email.errorMessage
                      )
                    : null
                }
                label={IntlUtil.getText(intlNamespace, "content.email")}
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={4} md={4} xs={12} className="m-t-10">
              <TextField
                maxLength={20}
                value={this.state.companyPoFormInputFIelds.phone.value}
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                componentRef={this.phoneRef}
                id="phone"
                name="phone"
                prefix={"+"}
                label={
                  <span>
                    {IntlUtil.getText(intlNamespace, "content.phone")}
                    <TooltipHost
                      content={
                        <>
                          <div>
                            {IntlUtil.getText(
                              intlNamespace,
                              "content.tooltip.phone"
                            )}
                          </div>
                          <div>
                            {IntlUtil.getText(
                              intlNamespace,
                              "content.tooltip.phoneExample"
                            )}
                          </div>
                        </>
                      }
                    >
                      <InfoIcon className="m-l-10" />
                    </TooltipHost>
                  </span>
                }
                className="page-form-textfield"
                errorMessage={
                  this.state.companyPoFormInputFIelds.phone.isError
                    ? IntlUtil.getText(
                        intlNamespace,
                        this.state.companyPoFormInputFIelds.phone.errorMessage
                      )
                    : null
                }
              />
            </Col>
          </Row>
          <Row className="">
            <Col xl={12} className="m-t-20">
              <span
                className={
                  "text-fw-semibold text-fs-medium " +
                  CustomStyles.getStyle(
                    this.props.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    this.props.websiteTemplate
                  )
                }
              >
                {IntlUtil.getText(intlNamespace, "content.serviceOrderForm")}
              </span>
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              className={`m-t-10 ${CustomStyles.getStyle(
                "font-family",
                this.props.websiteTemplate
              )}`}
            >
              {IntlUtil.getText(intlNamespace, "content.downloadTheForm")}
            </Col>
          </Row>
          <Row>
            <Col xs={2} sm={2} md={2} lg={2} xl={12} className="m-t-20">
              <div className="quote-attachment-wrapper">
                <div className="quote-btn-wrapper">
                  <DefaultButton
                    onClick={this.handleOrderFormAttachmentClick}
                    className="page-frame-button oc-order-create-page-button"
                  >
                    <UploadIcon className="page-frame-icon m-r-5" />
                    {IntlUtil.getText(intlNamespace, "content.upload")}
                  </DefaultButton>
                  <input
                    type="file"
                    name="image"
                    id="file"
                    accept=".doc,.docx"
                    onChange={async (e) => {
                      await this.handleOrderFormAttachmentChange(e);
                      await this.props.setPageExit(true);
                    }}
                    title=""
                    className="picture-edit-file"
                    ref={this.quoteFromUploadRef}
                  />
                </div>
                <span className="m-t-5 m-l-10">
                  {this.state.fileAttachment?.fileName}
                </span>
                {this.state.fileAttachment?.fileName ? (
                  <div className="m-l-10">
                    <IconButton
                      iconProps={{
                        iconName: "Cancel",
                        color: "white",
                      }}
                      onClick={() => {
                        this.setStateAsync({ fileAttachment: null });
                      }}
                    ></IconButton>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          {this.state.quoteFileErrors ? (
            <Row className="m-t-10">
              <Col xl={12} className="text-fc-error">
                {IntlUtil.getText(intlNamespace, this.state.quoteFileErrors)}
              </Col>
            </Row>
          ) : null}
        </Grid>
        <div className="term-text-wrapper p-b-30">
          <Checkbox
            checked={this.state.isTermAgreeChecked}
            className="m-t-20 m-l-5 m-r-10"
            onChange={(e, option) => this.handleCheckboxChange(e, option)}
            label={IntlUtil.getText(intlNamespace, "content.termAgree")}
          />
        </div>
        <div
          className={`${CustomStyles.getStyle(
            "page-content-separator",
            this.props.websiteTemplate
          )}`}
        ></div>
        <div>
          <PrimaryButton
            onClick={this.handleSaveCompanyPo}
            disabled={!this.state.isTermAgreeChecked}
            className="m-l-5 m-t-20 page-frame-button"
          >
            {IntlUtil.getText(intlNamespace, "content.submit")}
          </PrimaryButton>
        </div>
      </>
    );
  }
}

export default OrderCheckoutCompanyPO;
