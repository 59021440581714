import { Dialog, mergeStyleSets, Spinner, SpinnerSize } from "@fluentui/react"
import { connect } from "react-redux";

 const PageOverlayLoader=({hidden,label,className,labelPosition,...props})=>{
  const mergedStyles=mergeStyleSets({
    "page-overlay-loader-wrapper":{
      "div":{
      color:`${props?.webTemplateConfigData?.websiteTemplate?.generalSettings?.primaryColor}`,
      borderLeftColor:`${props?.webTemplateConfigData?.websiteTemplate?.generalSettings?.primaryColor} !important`,
      borderTopColor:`${props?.webTemplateConfigData?.websiteTemplate?.generalSettings?.primaryColor} !important`
    }
    },
  })
    
    return(<>
    <Dialog className={`oc-page-overlay ${className}`} hidden={hidden} >
      <div className="m-t-20">
      <Spinner className={mergedStyles["page-overlay-loader-wrapper"]}  size={SpinnerSize.large} labelPosition={labelPosition}  label={label}/>
      </div>
    </Dialog>
    </>)
  }

  const mapStateToProps = (state) => ({
    webTemplateConfigData: state.generalStore.webTemplateConfigData,
  });
  const mapActionToProps = {
  };
  
  export default connect(mapStateToProps, mapActionToProps)(PageOverlayLoader);
  