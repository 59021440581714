import { OperatorConnectConstants } from "../../pages/common/settings/operator-connect-constants";
import IntlUtil from "./intl-util";

class PageUtil {
  static scrollToTop(currProps = null, prevProps = null) {
    if (currProps && prevProps) {
      if (currProps.location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  // Scroll page to top
  static scrollIntoView(objRef) {
    if (objRef && objRef.current) {
      objRef.current.scrollIntoView();
    }
  }
  static getHeadTitle(title = null) {
    if (title) {
      return `${title}`;
    } else {
      return IntlUtil.getText("oc_common", "title.appName");
    }
  }
  static setLocaleFormat(value, type, minFractionDigits, maxFractionDigits) {
    if (
      value &&
      type === OperatorConnectConstants.GENERAL.FORMATTER_TYPE.NUMBER
    ) {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
      });
    } else {
      return value;
    }
  }
}

export default PageUtil;
