import axios from "axios";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import Footer from "../../app/pages/common/components/footer";
import Header from "../../app/pages/common/components/header";
import {
  authenticateToken,
  checkTokenSession,
} from "../core/actions/identity-actions";
import { AppPageTitle } from "../core/components/app-page-title";
import IntlUtil from "../core/helpers/intl-util";
import RequestUtil from "../core/helpers/request-util";
import TokenUtil from "../core/helpers/token-util";
import { AppConfigProps } from "../core/settings/app-config";
import { getWebsiteTemplate } from "../pages/common/actions/common-actions";
import {
  setCountryStatesData,
  setLanguageData,
  setSelectedLanguageData,
  setTenantId,
  setWebTemplateConfigData,
  setFontFamily,
  handleImageSource,
} from "../pages/common/actions/general-actions";
import PageOverlayLoader from "../pages/common/helpers/page-overlay-loader";
import OperatorConnectRoutes from "../routes/operator-connect-routes";
import { withTranslation } from "react-i18next";
import { manageError } from "../core/actions/common-actions";
import ErrorPage from "../core/views/error-page";
import ErrorPageNotFound from "../core/views/error-page-not-found";
import { OperatorConnectConstants } from "../pages/common/settings/operator-connect-constants";
import { get } from "lodash";
import { OperatorConnectURLProps } from "../pages/common/settings/operator-connect-urls";
import { createTheme, loadTheme } from "@fluentui/react";
import { Helmet } from "react-helmet-async";
import PageUtil from "../core/helpers/page-util";
import ScrollTop from "../pages/common/helpers/scroll-top";
import i18n from "../../i18n";

const FullLayout = (props) => {
  const [isAppDataFetched, setAppDataFetched] = useState(false);
  const _intl_ns_common = "oc_common";
  const _axiosSource = axios.CancelToken.source();
  const _cancelToken = { cancelToken: _axiosSource.token };
  const history = useHistory();
  const layoutRef = useRef();
  let _isMounted = true;
  const [isLoading, setLoadingStatus] = useState(true);
  const [isSessionValid, setSessionValid] = useState(checkTokenSession());

  useEffect(() => {
    const getData = async () => {
      setAppDataFetched(false);
      const params = new URLSearchParams(window.location.search); // id=123
      let tenantId = null;
      for (let [name, value] of params) {
        if (name?.toLowerCase() === "tenantid") {
          tenantId = value;
          break;
        }
      }
      if (props.tenantId === null) {
        props.setTenantId(tenantId);
      }

      if (isSessionValid === false) {
        await fetchToken();
      } else {
        let tokenData = TokenUtil.getIdentitySippioAccessTokenData();
        if (
          tokenData.expiry - parseInt(Date.now() / 1000) <=
          AppConfigProps.identitySession.expiryCheckRemainingSeconds
        ) {
          await fetchToken();
        }
      }
      if (
        props?.webTemplateConfigData !== undefined &&
        props?.webTemplateConfigData !== null
      ) {
        let languageList = [];
        await props?.webTemplateConfigData?.websiteLanguages.forEach((lang) => {
          let language = getLanguageValue(lang.languageCode);
          languageList.push({
            key: language.languageCode,
            text: `${language.languageCode} (${language.text})`,
          });
        });
        await props.setLanguageData(languageList);
        if (props.selectedLanguageData) {
          await props.setSelectedLanguageData(props.selectedLanguageData);
          i18n.changeLanguage(props.selectedLanguageData?.key);
        }
      } else {
        await fetchWebsiteTemplateConfig();
        let languageList = [];
        await props?.webTemplateConfigData?.websiteLanguages.forEach((lang) => {
          let language = getLanguageValue(lang.languageCode);
          languageList.push({
            key: language.languageCode,
            text: language.text,
          });
        });
        await props.setLanguageData(languageList);
        await props.setSelectedLanguageData({
          key: "en",
          text: "en (English)",
        });
      }
      setAppDataFetched(true);
      setLoadingStatus(false);
    };
    if (_isMounted) {
      getData();
    }
    return async () => {
      _isMounted = false;
      await cleanUp();
    };
  }, [isSessionValid]);

  const cleanUp = async () => {
    _axiosSource.cancel(
      IntlUtil.getText(_intl_ns_common, "notification.warning.requestCancelled")
    );
  };

  const getLanguageValue = (languageCode) => {
    let languageObject = "";
    OperatorConnectConstants.LANGUAGE_DATA.forEach((rec) => {
      if (rec.key?.toLowerCase().trim() === languageCode.toLowerCase().trim()) {
        languageObject = { languageCode: rec.key, text: rec.language };
      }
    });
    return languageObject;
  };

  const fetchWebsiteTemplateConfig = async () => {
    await getWebsiteTemplate(_cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          // let template="OC-T2";
          // let templateData=""
          // if(template==="OC-T1"){
          //   templateData=Template1Data

          // }else  if(template==="OC-T2"){
          //   templateData=Template2Data

          // } else  if(template===OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()){
          //   templateData=Template3Data
          // }
          await props.setWebTemplateConfigData(res.data.result);
          let languageList = [];
          await props?.webTemplateConfigData?.websiteLanguages.forEach(
            (lang) => {
              let language = getLanguageValue(lang.languageCode);
              if (language) {
                languageList.push({
                  key: language.languageCode,
                  text: `${language.languageCode} (${language.text})`,
                });
              }
            }
          );
          await props.setLanguageData(languageList);
          await props.setSelectedLanguageData({
            key: "en",
            text: "en (English)",
          });
        } else {
          await manageError(res, history);
        }
      })
      .catch(async (err) => {
        await manageError(err, history);
      });
  };

  const fetchToken = async () => {
    let operatorWebsiteUrl = window.location.origin;
    if (window.location.origin.includes("http://localhost") === true) {
      let template = "2";
      if (template === "1") {
        operatorWebsiteUrl = "https://oc-dev.sippio.io";
      } else if (template === "2") {
        operatorWebsiteUrl = "https://oc-dev.operatorconnect.io";
      } else if (template === "3") {
        operatorWebsiteUrl = "https://oc-dev.ensarsolutions.com";
      } else if (template === "4") {
        operatorWebsiteUrl = " https://oc-ui-app-dev-uk.azurewebsites.net";
      }
    }
    await authenticateToken(operatorWebsiteUrl, _cancelToken)
      .then(async (res) => {
        if (res && res.data && res.data.result) {
          TokenUtil.setIdentityToken(res.data.result.accessToken);
          RequestUtil.setRequestHeaders();
          setAppDataFetched(true);
        }
      })
      .catch(async (err) => {
        setAppDataFetched(true);
        await manageError(err, props.history);
      });
  };

  const appTheme = createTheme({
    palette: {
      themePrimary:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      themeSecondary:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.secondaryColor,
    },
    defaultFontStyle: {
      fontFamily: setFontFamily(
        props.webTemplateConfigData?.websiteTemplate?.generalSettings?.font
      ),
      color:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.pageForegroundColor,
      font: props.webTemplateConfigData?.websiteTemplate?.generalSettings?.font,
    },
    semanticColors: {
      primaryButtonBackground:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      primaryButtonTextDisabled: "black",
      primaryButtonBackgroundHovered: `${props.webTemplateConfigData?.webTemplateConfigData?.generalSettings?.sectionBackgroundColor}`,
      primaryButtonBorder:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      primaryButtonBackgroundDisabled: "#D3D3D3",
      primaryButtonBackgroundPressed:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      primaryButtonTextPressed: "white",
      buttonBackground: "white",
      buttonBackgroundDisabled: "#D3D3D3",
      buttonBorder:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      buttonBorderDisabled: "black",
      buttonText:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      buttonBackgroundHovered:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.sectionBackgroundColor,
      buttonTextHovered:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
      buttonTextDisabled: "black",
      focusBorder:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.sectionBorderColor,
      bodyDivider:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.sectionBorderColor,
      bodyFrameDivider:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.sectionBorderColor,
      buttonBackgroundPressed:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.sectionBackgroundColor,
      buttonTextPressed:
        props.webTemplateConfigData?.websiteTemplate?.generalSettings
          ?.primaryColor,
    },
  });
  loadTheme(appTheme);
  return (
    <>
      <div id="oc-layout-wrapper" ref={layoutRef}>
        <AppPageTitle pageTitle={""} />
        <ScrollTop>
          {isLoading === false ? (
            <>
              <Helmet
                link={[
                  {
                    href: handleImageSource(
                      props.webTemplateConfigData?.websiteTemplate
                        ?.generalSettings?.favicon
                    ),
                    rel: "icon",
                    type: "image/x-icon",
                  },
                ]}
              />

              {props.webTemplateConfigData?.websiteTemplate?.headerDisplayStatus
                ?.toLowerCase()
                .trim() ===
              OperatorConnectConstants.GENERAL.YES?.toLowerCase().trim() ? (
                <Header
                  {...props}
                  headerSettings={
                    props?.webTemplateConfigData?.websiteTemplate
                      ?.headerSettings
                  }
                  showLanguage={true}
                  showWelcomePage={true}
                  showHrLine={true}
                  languageDisableStatus={
                    history.location.pathname ===
                    OperatorConnectURLProps.orderManagement.quoteAdd
                      ? true
                      : false
                  }
                  key={`router-key-header`}
                />
              ) : null}
              <Switch>
                {OperatorConnectRoutes &&
                  OperatorConnectRoutes.length > 0 &&
                  OperatorConnectRoutes.map((route, index) => {
                    return (
                      <Route
                        key={`router-key-${index}`}
                        path={route.url}
                        exact
                        component={route.component}
                      />
                    );
                  })}
                <Route path="*" component={ErrorPageNotFound} />
              </Switch>
              {props.webTemplateConfigData?.websiteTemplate?.footerDisplayStatus
                ?.toLowerCase()
                .trim() ===
              OperatorConnectConstants.GENERAL.YES?.toLowerCase().trim() ? (
                <Footer
                  {...props}
                  logoDisplay={
                    props?.webTemplateConfigData?.websiteTemplate
                      ?.footerSettings?.logoDisplay
                  }
                  key={`router-key-footer`}
                />
              ) : null}
            </>
          ) : null}
        </ScrollTop>
      </div>
      <PageOverlayLoader
        hidden={isAppDataFetched}
        label={IntlUtil.getText(_intl_ns_common, "content.loadingInprogress")}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  countryStatesData: state.generalStore.countryStatesData,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  languageData: state.generalStore.languageData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  tenantId: state.generalStore.tenantId,
});

const mapActionToProps = {
  setCountryStatesData,
  setWebTemplateConfigData,
  setLanguageData,
  setSelectedLanguageData,
  setTenantId,
};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(FullLayout)
);
