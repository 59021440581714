import React, { createRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageUtil from "../../../core/helpers/page-util";

const ScrollTop = (props) => {
  const { children } = props;
  const scrollRef=createRef();

  const location = useLocation();

  useEffect(() => {
    PageUtil.scrollIntoView(scrollRef);
  }, [location]);

  return <main ref={scrollRef}>{children}</main>;
};

export default ScrollTop;