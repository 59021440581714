import { mergeStyleSets } from "@fluentui/react";
import { OperatorConnectConstants } from "../settings/operator-connect-constants";
import { setFontFamily } from "../../common/actions/general-actions";

class CustomStyles {
  static getStyle(style, website) {
    const mergedStyles = mergeStyleSets({
      "text-link": {
        fontFamily: setFontFamily(website?.generalSettings?.font),
        "&:hover": {
          color: `${
            website?.templateCode?.toLowerCase().trim() ===
            OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
              ? website?.generalSettings?.secondaryColor
              : website?.generalSettings?.primaryColor
          }`,
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
      "customer-text-link": {
        fontFamily: setFontFamily(website?.generalSettings?.font),
        "&:hover": {
          color: `${
            website?.templateCode?.toLowerCase().trim() ===
            OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
              ? website?.generalSettings?.secondaryColor
              : website?.generalSettings?.primaryColor
          }`,
          cursor: "pointer",
          background: "#fafafa",
          textDecoration: "unset !important",
        },
      },
      "main-header-home-icon": {
        border: `1px solid ${website?.generalSettings?.primaryColor}`,
        padding: "5px 8px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        borderRadius: "2px",
        color: `${website?.generalSettings?.primaryColor} !important`,
        "&:hover": {
          backgroundColor: `${website?.generalSettings?.sectionBackgroundColor} !important`,
        },
        svg: {
          marginTop: "-1px",
        },
      },
      "page-title-wrapper": {
        paddingLeft: 40,
        paddingRight: 40,
        backgroundColor: `${
          website?.generalSettings?.sectionBackgroundColor ?? ""
        }`,
        alignItems: "center",
        display: "grid",
        minHeight: 25,
        paddingTop: 10,
        paddingBottom: 10,
        "@media (max-width:767px)": {
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
      "page-heading": {
        padding: 0,
        fontSize: "x-large",
        fontFamily: setFontFamily(website?.generalSettings?.font),
        margin: 0,
        color: `${website?.generalSettings?.primaryColor}`,
        wordWrap: "break-word",
        display: "inline-flex",
      },
      "page-caption": {
        padding: 0,
        paddingBottom: 10,
        margin: 0,
        fontFamily: setFontFamily(website?.generalSettings?.font),
        wordWrap: "break-word",
        display: "flex",
        flexDirection: "column",
        "div > P": {
          margin: "0px",
        },
        "span > p": {
          margin: "0px",
        },
      },

      "page-frame-shadow": {
        padding: "20px",
        marginTop: "40px",
        boxShadow:
          "0px 2px 8px 0px " + website?.generalSettings?.sectionBorderColor,
        border: `1px solid ${website?.generalSettings?.sectionBorderColor}`,
        opacity: 1,
      },
      "page-frame-table-footer-row": {
        borderTop: `1px solid ${website?.generalSettings?.sectionBorderColor}`,
        backgroundColor: `${website?.generalSettings?.sectionBackgroundColor} !important`,
        ":first-child": {
          borderTop: "none !important",
        },
      },
      "page-frame-table": {
        border: `1px solid ${website?.generalSettings?.sectionBorderColor}`,
        "div[role=presentation]": {
          "div[role=row]": {
            ":hover": {
              background: "white",
            },
            borderBottom: `1px solid ${website?.generalSettings?.sectionBorderColor} !important`,
            "div[role=columnheader]": {
              color: "black",
              ":hover": {
                background: "white",
              },
              ":active": {
                background: "white",
              },
            },
          },
          "div[data-automationid=DetailsHeader]": {
            borderBottom: `1px solid ${website?.generalSettings?.sectionBorderColor} !important`,
            color: "black",
          },
          "ms-DetailsRow": {
            borderBottom: `1px solid ${website?.generalSettings?.sectionBorderColor} !important`,
          },
        },
      },

      "font-family": {
        fontFamily: `${setFontFamily(
          website?.generalSettings?.font
        )} !important`,
      },
      "page-background": {
        backgroundColor: `${website?.generalSettings?.pageBackgroundColor} !important`,
      },
      "section-background": {
        backgroundColor: `${website?.generalSettings?.sectionBackgroundColor}`,
      },
      "section-border": {
        border: `1px solid ${website?.generalSettings?.sectionBorderColor}`,
      },
      "page-content-separator": {
        height: "1px !important",
        backgroundColor: `${website?.generalSettings?.sectionBorderColor}`,
        marginTop: "25px",
        marginRight: "-20px !important",
        marginLeft: "-20px !important",
      },
      "text-fc-primary": {
        color: `${website?.generalSettings?.primaryColor}`,
        fontFamily: setFontFamily(website?.generalSettings?.font),
      },
      "text-fc-secondary": {
        color: `${website?.generalSettings?.secondaryColor}`,
        fontFamily: setFontFamily(website?.generalSettings?.font),
      },
      "primary-background-color": {
        backgroundColor: `${website?.generalSettings?.primaryColor}`,
      },
      "secondary-background-color": {
        backgroundColor: `${website?.generalSettings?.secondaryColor}`,
      },

      title: {
        color: `${website?.generalSettings?.primaryColor}`,
      },
      "icon-button-primary": {
        color: `${website?.generalSettings?.primaryColor}`,
        i: {
          color: `${website?.generalSettings?.primaryColor}`,
        },
      },
      "icon-button-secondary": {
        color: `${website?.generalSettings?.secondaryColor}`,
        i: {
          color: `${website?.generalSettings?.secondaryColor}`,
        },
      },

      "default-button": {
        backgroundColor: "white",
        border: `1px solid ${website?.generalSettings?.primaryColor}`,
        ":disabled": {
          backgroundColor: `${"#e2e2e2"}`,
          span: {
            color: "black !important",
          },
          opacity: 0.5,
        },
        i: {
          color: `${website?.generalSettings?.primaryColor}`,
        },
        span: {
          color: `${website?.generalSettings?.primaryColor}`,
        },
        "&:hover": {
          backgroundColor: `#f8f8f8`,
        },
      },
      "primary-button": {
        backgroundColor: `${website?.generalSettings?.primaryColor}`,
        border: "none",
        ":disabled": {
          backgroundColor: `${"#e2e2e2"}`,
          span: {
            color: "black !important",
          },
          opacity: 0.5,
        },
        "&:hover": {
          opacity: 0.9,
          backgroundColor: `${website?.generalSettings?.primaryColor}`,
          border: "none",
        },
        span: {
          color: "white",
        },
      },
    });
    return mergedStyles[`${style}`];
  }
}

export default CustomStyles;
