import IntlUtil from "../../../core/helpers/intl-util";

export const QuoteOrderTableColumns = (intlNamespace) => {
  return [
    {
      key: "actions",
      name: IntlUtil.getText(intlNamespace, "content.actions"),
      fieldName: "actions",
      data: "string",
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "item",
      name: IntlUtil.getText(intlNamespace, "content.item"),
      fieldName: "productName",
      data: "string",
      minWidth: 405,
      maxWidth: 475,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "quantity",
      name: IntlUtil.getText(intlNamespace, "content.quantity"),
      fieldName: "quantity",
      headerClassName: "quote-service-table-text",
      className: "text-align-center",
      data: "string",
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "chargeName",
      name: IntlUtil.getText(intlNamespace, "content.chargeName"),
      fieldName: "chargeName",
      data: "string",
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "chargeType",
      name: IntlUtil.getText(intlNamespace, "content.chargeType"),
      fieldName: "chargeType",
      data: "string",
      minWidth: 90,
      maxWidth: 90,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "customerPriceUnitPrice",
      name: IntlUtil.getText(intlNamespace, "content.unitPrice"),
      headerClassName: "quote-estimator-main-header-text1",

      fieldName: "customerPriceUnitPrice",
      data: "string",
      className: "quote-estimator-main-header-text1",

      minWidth: 85,
      maxWidth: 85,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "customerPriceAmount",
      name: IntlUtil.getText(intlNamespace, "content.amount"),

      fieldName: "customerPriceAmount",
      data: "string",
      minWidth: 127,
      maxWidth: 127,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
  ];
};
