import React, { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { checkIdentitySession } from "../actions/common-actions";
import { checkTokenSession, setTokenData } from "../actions/identity-actions";
import TokenUtil from "../helpers/token-util";
import { connect } from "react-redux";
import axios from "axios";

const TokenCheck = (props) => {
  let location = useLocation();
  const _axiosSource = axios.CancelToken.source();
  const _cancelToken = { cancelToken: _axiosSource.token };
  const isAuthenticated = checkTokenSession();
  const tokenData = TokenUtil.getIdentitySippioAccessTokenData();

  const checkTokenExpiry = useCallback(() => {
    checkIdentitySession(_cancelToken);
  }, []);

  useEffect(() => {
    let timer = setTimeout;

    if (isAuthenticated) {
      if (tokenData?.expiry) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeToExpire = (tokenData.expiry - currentTime) * 1000;
        timer = setTimeout(() => {
          checkTokenExpiry();
        }, Math.max(0, timeToExpire));
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [location, tokenData, isAuthenticated, checkTokenExpiry]);

  return props.children;
};

const mapStateToProps = (state) => ({
  tokenExpiryTime: state.generalStore.tokenExpiryTime,
});
const mapActionToProps = {
  setTokenData,
};

export default connect(mapStateToProps, mapActionToProps)(TokenCheck);
