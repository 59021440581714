/**
Project: Operator Connect (c)
Title: Store
Description: Redux store for maintaining the state
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const logger = createLogger();

export const persistConfig = {
  key: "root",
  storage,
  whitelist: ["quoteStore", "generalStore"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function configureStore() {
  let middleware = [thunk];

  if (process.env.REACT_APP_ENV !== "production") {
    middleware = [thunk, logger];
  }

  const store = createStore(persistedReducer, applyMiddleware(...middleware));

  let persistor = persistStore(store);

  return { store, persistor };
}
