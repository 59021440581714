/**
Project: Voice Panel (c)
Title: Page Exit Guard
Description: Component for displaying the dialog box for unsaved data
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { useEffect, useState, memo } from "react";
import { Prompt, useHistory } from "react-router-dom";
import {
  Text,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";
import IntlUtil from "../../../core/helpers/intl-util";
import CustomDialog from "./custom-dialog";

const PageExitGuard = ({
  when,
  title,
  navigate,
  shouldBlockNavigation,
  clearReduxData,
}) => {
  const _intl_ns_spp_common = "oc_common";
  const [dialogHidden, setDialogStatus] = useState(true);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const closeModal = () => {
    setDialogStatus(true);
  };
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setDialogStatus(false);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setDialogStatus(false);
    setConfirmedNavigation(true);
    if (clearReduxData) {
      clearReduxData();
    }
    if (history.location.pathname === lastLocation.pathname) {
      window.location.reload();
    }
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation);
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}
      <CustomDialog
        hidden={dialogHidden}
        title={title}
        onPrimaryButtonClick={handleConfirmNavigationClick}
        onDefaultButtonClick={closeModal}
      >
        {
          <>
            <Text>
              {IntlUtil.getText(
                _intl_ns_spp_common,
                "notification.warning.promptUnsavedPageExit"
              )}
            </Text>
          </>
        }
      </CustomDialog>
    </>
  );
};

export default memo(PageExitGuard);
