import React, { useEffect, useState } from "react";
import axios from "axios";
import IntlUtil from "../../../core/helpers/intl-util";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import { loadStripe } from "@stripe/stripe-js";
import { PrimaryButton, Link } from "@fluentui/react";
import { Elements } from "@stripe/react-stripe-js";
import PageLoader from "../../common/helpers/page-loader";
import { connect } from "react-redux";
import {
  setOrderPaymentInfo,
  setOrderConnectedAccount,
  setOrderPublisherKey,
  renderTotalAmount,
} from "../actions/quote-action";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { AppConfigProps } from "../../../core/settings/app-config";
import {
  setStripePaymentIntent,
  updatePaymentInfo,
} from "../actions/quote-action";
import { getLanguage } from "../../common/actions/common-actions";
import { Col, Grid, Row } from "react-flexbox-grid";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Text, MessageBar, MessageBarType } from "@fluentui/react";
import classNames from "classnames";
import CustomStyles from "../../common/helpers/custom-styles";

const _intl_ns_oc_common = "oc_common";
const _intl_ns_oc_quote = "oc_quote";
const _axiosSource = axios.CancelToken.source();
const _cancelToken = { cancelToken: _axiosSource.token };

const OrderCheckOutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSaveAchData = async () => {
    if (paymentStatus && paymentStatus.paymentDetails) {
      let obj = {
        paymentDetails: paymentStatus.paymentDetails,
        isPaymentDialogHidden: false,
      };
      props.setpaymentAchFormData(obj);
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!props.clientSecret) {
      return;
    }

    renderData();

    return () => {
      resertData();
    };
  }, [stripe]);

  const renderData = () => {
    setPaymentStatus(null);
    if (
      !props.orderPaymentInfo.paymentProcessStatus &&
      props.orderPaymentInfo?.transactionNumber &&
      props.orderPaymentInfo?.paymentId
    ) {
      updatePaymentData(props.orderPaymentInfo?.paymentId, {
        transactionNumber: props.orderPaymentInfo?.transactionNumber,
      });
    }
  };

  const updatePaymentData = async (paymentId, paymentObject) => {
    setIsLoading(true);
    await updatePaymentInfo(paymentId, paymentObject, _cancelToken)
      .then(async (res) => {
        if (res && res.data && res.data.result) {
          props.setTabDisabled(true);
          setPaymentStatus({
            status: res.data.result?.processStatus,
            paymentDetails: {
              status: res.data.result?.processStatus,
              amount: res.data.result?.paymentAmount,
              transactionId: res.data.result?.id,
              paymentMethodType: res.data.result?.paymentMethod.toString(),
              paymentNumber: res.data.result?.paymentNumber,
              paymentDescription: res.data.result?.paymentDescription,
              currencyCode: res.data.result?.currencyCode,
              currencySymbol: res.data.result?.currencySymbol,
            },
          });
          setErrorMessage(res.data.result?.transactionMessage);
        }
        setIsLoading(false);
      })
      .catch(async (err) => {
        setPaymentStatus({
          status: "Failed",
        });
        props.setTabDisabled(false);
        setIsLoading(false);
        setErrorMessage(err.data.errors[0].message);
      });
    setIsLoading(false);
  };

  const resertData = () => {
    setPaymentStatus(null);
    setErrorStatus(null);
    setErrorMessage(null);
    setIsLoading(true);
  };

  const handleSubmit = async (e) => {
    let paymentInfo = {
      ...props.orderPaymentInfo,
      paymentProcessStatus: false,
    };
    props.setOrderPaymentInfo(paymentInfo);
    e.preventDefault();
    setErrorStatus(null);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + "/quotes/add?tab=2",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error") {
      setErrorMessage(error.message);
      setErrorStatus(OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE);
    }

    setIsLoading(false);
  };

  if (
    (paymentStatus !== null &&
      paymentStatus.status?.toLowerCase() ===
        OperatorConnectConstants.PAYMENT_STATUS.FAILED.toLowerCase()) ||
    (paymentStatus &&
      paymentStatus.status?.toLowerCase() ===
        OperatorConnectConstants.PAYMENT_STATUS.SUCCEEDED.toLowerCase()) ||
    (paymentStatus &&
      paymentStatus.status?.toLowerCase() ===
        OperatorConnectConstants.PAYMENT_STATUS.PENDING.toLowerCase())
  ) {
    return (
      <div>
        <Grid fluid className="m-0 p-l-5 p-t-20">
          <Row>
            <Col xl={12}>
              {paymentStatus.status?.toLowerCase() ===
              OperatorConnectConstants.PAYMENT_STATUS.SUCCEEDED.toLowerCase() ? (
                <MessageBar
                  messageBarType={MessageBarType.success}
                  className="notification-messagebar"
                >
                  {IntlUtil.getText(
                    _intl_ns_oc_quote,
                    "notification.success.paymentSuccessful"
                  )}
                </MessageBar>
              ) : paymentStatus.status?.toLowerCase() ===
                OperatorConnectConstants.PAYMENT_STATUS.FAILED.toLowerCase() ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  className="notification-messagebar"
                >
                  <span className="text-ff-semibold">
                    {IntlUtil.getText(
                      _intl_ns_oc_quote,
                      "notification.error.paymentFailed"
                    )}
                  </span>{" "}
                  <Link
                    onClick={() => {
                      setIsLoading(true);
                      let paymentInfo = {
                        ...props.orderPaymentInfo,
                        paymentProcessStatus: true,
                      };
                      props.setOrderPaymentInfo(paymentInfo);
                      props.retryIntent();
                    }}
                    className="m-l-0 p-l-0 text-fc-primary text-underline cursor-pointer"
                  >
                    {IntlUtil.getText(
                      _intl_ns_oc_quote,
                      "notification.info.processingRetrySuffix"
                    )}
                  </Link>
                </MessageBar>
              ) : (
                <MessageBar
                  messageBarType={MessageBarType.info}
                  className="notification-messagebar"
                >
                  <span className="text-ff-semibold">
                    {IntlUtil.getText(
                      _intl_ns_oc_quote,
                      "notification.info.processingPrefix"
                    )}
                  </span>{" "}
                  <Link
                    onClick={() => {
                      setIsLoading(true);
                      let paymentInfo = {
                        ...props.orderPaymentInfo,
                        paymentProcessStatus: true,
                      };
                      props.setOrderPaymentInfo(paymentInfo);
                      if (
                        props.orderPaymentInfo?.transactionNumber &&
                        props.orderPaymentInfo?.paymentId
                      ) {
                        updatePaymentData(props.orderPaymentInfo?.paymentId, {
                          transactionNumber:
                            props.orderPaymentInfo?.transactionNumber,
                        });
                      }
                    }}
                    className="m-l-0 p-l-0 text-fc-primary text-underline cursor-pointer"
                  >
                    {IntlUtil.getText(
                      _intl_ns_oc_quote,
                      "notification.info.processingSuffix"
                    )}
                  </Link>
                </MessageBar>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getSubstituteText(
                  _intl_ns_oc_quote,
                  "content.amountPaid",
                  [
                    {
                      key: "<AMOUNT_PAID>",
                      value: renderTotalAmount(
                        props?.subscriberQuoteRecords?.products[0]
                          ?.currencyCode,
                        parseFloat(
                          paymentStatus?.paymentDetails?.amount
                        ).toFixed(2)
                      ),
                    },
                  ]
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              {paymentStatus?.paymentDetails?.paymentMethodType && (
                <Text className="text-fs-medium">
                  {IntlUtil.getSubstituteText(
                    _intl_ns_oc_quote,
                    "content.paymentType",
                    [
                      {
                        key: "<PAYMENT_TYPE>",
                        value: "ACH Bank Debit",
                      },
                    ]
                  )}
                </Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getSubstituteText(
                  _intl_ns_oc_quote,
                  "content.transactionId",
                  [
                    {
                      key: "<TRANSACTION_ID>",
                      value: paymentStatus?.paymentDetails?.transactionId,
                    },
                  ]
                )}
              </Text>
            </Col>
          </Row>
          {paymentStatus.status.toLowerCase() !==
          OperatorConnectConstants.PAYMENT_STATUS.FAILED.toLowerCase() ? (
            <>
              <div className="secondary-section-border-border m-t-10"></div>
              <Row>
                <Col xl={12} className="m-t-10">
                  <Text className="text-fs-medium">
                    {IntlUtil.getText(
                      _intl_ns_oc_quote,
                      "notification.info.paymentACHDebitInfo1"
                    )}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col xl={12} className="m-t-10">
                  <Text className="text-fs-medium">
                    {IntlUtil.getText(
                      _intl_ns_oc_quote,
                      "notification.info.paymentACHDebitInfo2"
                    )}
                  </Text>
                </Col>
              </Row>
            </>
          ) : null}
        </Grid>
        <div
          className={`${CustomStyles.getStyle(
            "page-content-separator",
            props.websiteTemplate
          )}`}
        ></div>
        <PrimaryButton
          className="m-t-20 page-frame-button"
          disabled={
            paymentStatus.status.toLowerCase() ===
              OperatorConnectConstants.PAYMENT_STATUS.PENDING.toLowerCase() ||
            paymentStatus.status.toLowerCase() ===
              OperatorConnectConstants.PAYMENT_STATUS.FAILED.toLowerCase()
          }
          onClick={handleSaveAchData}
        >
          {IntlUtil.getText(_intl_ns_oc_quote, "content.continue")}
        </PrimaryButton>
        <PageOverlayLoader
          hidden={!isLoading}
          label={IntlUtil.getText(
            _intl_ns_oc_common,
            "content.loadingInprogress"
          )}
        />
      </div>
    );
  } else {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Grid fluid className="m-0 p-l-5 p-t-20">
            <Row>
              <Col xl={3} md={6}>
                <span
                  className={
                    "text-fw-semibold text-fs-medium " +
                    CustomStyles.getStyle(
                      props.websiteTemplate?.templateCode
                        ?.toLowerCase()
                        .trim() !==
                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                        ? "text-fc-primary"
                        : "text-fc-secondary",
                      props.websiteTemplate
                    )
                  }
                >
                  {IntlUtil.getText(
                    _intl_ns_oc_quote,
                    "content.makeYourPayment"
                  )}
                </span>
              </Col>
              <Col xl={3} md={6}>
                <div className="quote-checkout-price">
                  <div
                    className={`text-fw-semibold text-fs-medium ${CustomStyles.getStyle(
                      "font-family",
                      props.websiteTemplate
                    )}`}
                  >
                    {IntlUtil.getText(_intl_ns_oc_quote, "content.totalAmount")}
                  </div>
                  <div
                    className={
                      "text-fw-semibold text-fs-large " +
                      CustomStyles.getStyle(
                        props.websiteTemplate?.templateCode
                          ?.toLowerCase()
                          .trim() !==
                          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? "text-fc-primary"
                          : "text-fc-secondary",
                        props.websiteTemplate
                      )
                    }
                  >
                    {renderTotalAmount(
                      props?.subscriberQuoteRecords?.products[0]?.currencyCode,
                      props.subscriberQuoteRecords.totalCost
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={6} className="m-t-10">
                <PaymentElement />
              </Col>
            </Row>
            <Row>
              <Col xl={6} className="m-t-10">
                {paymentStatus &&
                paymentStatus.status?.toLowerCase() === "failed" ? (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    className="notification-messagebar"
                  >
                    {errorMessage ? errorMessage : null}
                  </MessageBar>
                ) : null}
              </Col>
            </Row>
          </Grid>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              props.websiteTemplate
            )}`}
          ></div>
          <PrimaryButton className="m-t-20 page-frame-button" type="submit">
            {IntlUtil.getText(_intl_ns_oc_quote, "content.submit")}
          </PrimaryButton>
        </form>
        <PageOverlayLoader
          hidden={!isLoading}
          label={IntlUtil.getText(
            _intl_ns_oc_common,
            "content.loadingInprogress"
          )}
        />
      </div>
    );
  }
};

const OrderCheckoutBank = (props) => {
  let stripePromise = null;
  if (props.publisherKey !== null) {
    if (props.orderConnectedAccount !== null) {
      stripePromise = loadStripe(props.publisherKey, {
        stripeAccount: props.orderConnectedAccount,
      });
    } else {
      stripePromise = loadStripe(props.publisherKey);
    }
  }
  const [clientSecret, setclientSecret] = useState(props.clientSecret);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (clientSecret === null) {
      resertData();
      getStripeIntent();
    } else {
      setIsPageLoading(true);
    }

    return () => {
      resertData();
    };
  }, []);

  const resertData = () => {
    setErrorStatus(null);
    setclientSecret(null);
    setIsPageLoading(false);
  };

  const getStripeIntent = async () => {
    setclientSecret(null);
    props.setOrderConnectedAccount(null);
    props.setOrderPaymentInfo(null);
    props.setOrderPublisherKey(null);
    if (
      props &&
      props.subscriberQuoteRecords &&
      props.subscriberQuoteRecords.customerEmail &&
      props.subscriberQuoteRecords.quoteNumber
    ) {
      if (
        parseFloat(props.subscriberQuoteRecords?.totalCost ?? "0").toFixed(2) >
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.MINIMUM_PAYABLE_AMOUNT
      ) {
        if (
          parseFloat(props.subscriberQuoteRecords?.totalCost ?? "0").toFixed(
            2
          ) <
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.MAXIMUM_PAYABLE_AMOUNT
        ) {
          setIsPageLoading(false);
          let amount = {
            paymentAmount: parseFloat(
              props.subscriberQuoteRecords.totalCost
            ).toFixed(2),
            currencyCode:
              props.subscriberQuoteRecords?.products[0]?.currencyCode?.toLowerCase(),
            paymentMethod: "us_bank_account",
            customerEmail: props.subscriberQuoteRecords.customerEmail,
            customerName: props.subscriberQuoteRecords.customerName,
            quoteNumber: props.subscriberQuoteRecords.quoteNumber,
          };
          await setStripePaymentIntent(amount, _cancelToken)
            .then(async (res) => {
              if (
                res &&
                res.status === AppConfigProps.httpStatusCode.ok &&
                res.data &&
                res.data.result
              ) {
                setclientSecret(res.data.result.secretKey);
                props.setOrderConnectedAccount(
                  res.data.result.connectAccountId
                );
                let paymentInfo = {
                  ...res.data.result,
                  paymentProcessStatus: true,
                };
                props.setOrderPaymentInfo(paymentInfo);
                props.setOrderPublisherKey(res.data.result.publisherKey);
              }
              setIsPageLoading(true);
            })
            .catch(async (err) => {
              setErrorStatus(
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE
              );
              let errors = err.data.errors;
              errors = errors.map((err) => {
                if (err.code === "E14108") {
                  err.message = IntlUtil.getSubstituteText(
                    _intl_ns_oc_quote,
                    "notification.error.paymentAccountUnavailable",
                    [
                      {
                        key: "<CURRENCY>",
                        value:
                          props.subscriberQuoteRecords?.products[0]
                            ?.currencyCode,
                      },
                    ]
                  );
                }
                return err;
              });

              setErrorMessage(errors);
              setIsPageLoading(true);
            });
        } else {
          setErrorStatus(OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE);
          setErrorMessage([
            {
              message: IntlUtil.getSubstituteText(
                _intl_ns_oc_quote,
                "notification.error.paymentAmountLessFailure",
                [
                  {
                    key: "<AMOUNT>",
                    value:
                      props.subscriberQuoteRecords?.products[0]
                        ?.currencySymbol +
                      parseFloat(
                        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT
                          .MAXIMUM_PAYABLE_AMOUNT
                      ).toFixed(2),
                  },
                ]
              ),
            },
          ]);
          setIsPageLoading(true);
        }
      }
    }
    setIsPageLoading(true);
  };

  const handleZeroAmountSubmit = async () => {
    let paymentStatus = {
      status: OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE,
      paymentDetails: {
        status: OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE,
        amount: parseFloat(
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ZERO_AMOUNT
        ).toFixed(2),
        transactionId: null,
        paymentMethodType:
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ORDER_TYPE
            .ACH_BANK_DEBIT,
        paymentNumber: null,
        paymentDescription: null,
        currencyCode: props.subscriberQuoteRecords?.products[0]?.currencyCode,
        currencySymbol:
          props.subscriberQuoteRecords?.products[0]?.currencySymbol,
      },
    };
    let obj = {
      paymentDetails: paymentStatus.paymentDetails,
      isPaymentDialogHidden: true,
    };
    await props.setpaymentAchFormData(obj);
  };

  const options = {
    clientSecret: clientSecret,
    loader: "auto",
    locale: getLanguage(),
  };

  return (
    <>
      {errorStatus === OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE ? (
        <MessageBar
          messageBarType={MessageBarType.error}
          className="notification-messagebar m-t-20"
        >
          {errorMessage && errorMessage.length > 0 ? (
            <span className="text-ff-semibold">
              {errorMessage.map((error, index) => {
                return (
                  <div key={`key-profile-error-${index}`}>{error.message}</div>
                );
              })}
            </span>
          ) : null}
        </MessageBar>
      ) : parseFloat(props.subscriberQuoteRecords?.totalCost ?? "0").toFixed(
          2
        ) <
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.MINIMUM_PAYABLE_AMOUNT ? (
        <div className="m-l-5">
          <div className="m-t-10">
            <Text className="text-fs-medium m-b-10">
              {IntlUtil.getSubstituteText(
                _intl_ns_oc_quote,
                "content.amountPaid",
                [
                  {
                    key: "<AMOUNT_PAID>",
                    value: renderTotalAmount(
                      props?.subscriberQuoteRecords?.products[0]?.currencyCode,
                      "0.00"
                    ),
                  },
                ]
              )}
            </Text>
          </div>
          <div className="m-t-15">
            <Text className="text-fs-medium">
              {IntlUtil.getText(
                _intl_ns_oc_quote,
                "notification.info.submitInfo"
              )}
            </Text>
          </div>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              props.websiteTemplate
            )}`}
          ></div>
          <PrimaryButton
            className="m-t-20 page-frame-button"
            onClick={handleZeroAmountSubmit}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.submit")}
          </PrimaryButton>
        </div>
      ) : (
        <div
          className={classNames({
            "p-t-15 p-b-30": isPageLoading === false,
          })}
        >
          <PageLoader
            status={isPageLoading}
            size="medium"
            labelPosition="right"
            label={IntlUtil.getText(
              _intl_ns_oc_common,
              "content.loadingInprogress"
            )}
            type="inline"
          >
            {options.clientSecret &&
            props.orderPaymentInfo !== null &&
            stripePromise !== null ? (
              <Elements options={options} stripe={stripePromise}>
                <OrderCheckOutForm
                  {...props}
                  retryIntent={() => {
                    getStripeIntent();
                  }}
                />
              </Elements>
            ) : (
              <MessageBar
                className="m-t-20"
                messageBarType={MessageBarType.info}
              >
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.achBankPaymentOptionNotAvailable"
                )}
              </MessageBar>
            )}
          </PageLoader>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  publisherKey: state.quoteStore.orderPublisherKey,
  orderPaymentInfo: state.quoteStore.orderPaymentInfo,
  orderConnectedAccount: state.quoteStore.orderConnectedAccount,
});
const mapActionToProps = {
  setOrderPaymentInfo,
  setOrderConnectedAccount,
  setOrderPublisherKey,
};

export default connect(mapStateToProps, mapActionToProps)(OrderCheckoutBank);
