import {
  DefaultButton,
  Image,
  Label,
  mergeStyleSets,
  PrimaryButton,
  Text,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import IntlUtil from "../../../core/helpers/intl-util";
import axios from "axios";
import {
  AddToShoppingListIcon,
  ChevronLeftMedIcon,
  TextDocumentIcon,
  ChevronUpIcon,
  Link12Icon,
  ChevronRightMedIcon,
  ChevronDownIcon,
  InfoIcon,
  PhoneIcon,
} from "@fluentui/react-icons";
import _ from "lodash";
import Carousel from "react-elastic-carousel";
// import LumenMap from "../../../../assets/images/Lumen-image.png";
import { OperatorConnectURLProps } from "../settings/operator-connect-urls";
import PageUtil from "../../../core/helpers/page-util";
import { AppPageTitle } from "../../../core/components/app-page-title";
import { getProducts, setProductRecords } from "../actions/common-actions";
import {
  setCurrencyData,
  setSelectedCurrencyData,
} from "../actions/general-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import PageOverlayLoader from "../helpers/page-overlay-loader";
import { connect } from "react-redux";
import { OperatorConnectConstants } from "../settings/operator-connect-constants";
import { manageError } from "../../../core/actions/common-actions";
import Banner from "./banner";
import CustomStyles from "../helpers/custom-styles";
import ProductList from "../../products/components/product-items";
import SignInDialog from "../helpers/sigin-page-modal";
import {
  setFontFamily,
  handleImageSource,
} from "../../common/actions/general-actions";
import {
  setSelectedProductId,
  quoteEstimatorDataReset,
} from "../../quotes/actions/quote-action";

const Home = (props) => {
  let [isSignInDialogHidden, setSignInDialogHidden] = useState(true);
  const [isPageDataFetched, setPageDataFetched] = useState(false);
  const [products, setProducts] = useState([]);
  const _intl_ns_oc_home = "oc_home";
  const _intl_ns_common = "oc_common";
  const _axiosSource = axios.CancelToken.source();
  const _cancelToken = { cancelToken: _axiosSource.token };
  const [webTemplate, setWebTemplate] = useState(
    props?.webTemplateConfigData?.websiteTemplate
  );
  const [websitePageTitle, setWebsitePageTitle] = useState({});

  useEffect(() => {
    const getData = async () => {
      setPageDataFetched(false);
      PageUtil.scrollToTop();
      let websiteTitle = webTemplate?.contentPages.find(
        (rec) => rec.pageCode?.toLowerCase().trim() === "oc-p1"
      );
      setWebsitePageTitle(websiteTitle);
      if (
        props.productRecords?.info?.filter?.languageCode ===
        props.selectedLanguageData?.key &&
        props.productRecords?.records?.length > 0 &&
        props.currencyData.length > 0
      ) {
        props?.setSelectedCurrencyData(setCurrencySelecteValue(props.currencyData[0]));
        if (props.currencyData.length > 1) {
          await setProductCurrencyList(
            props.productRecords?.records,
            props.selectedCurrencyData ?? props.currencyData[0]
          );
        } else {
          await setProductList();
        }
        setPageDataFetched(true);
      } else {
        await fetchProducts();
        setPageDataFetched(true);
      }
    };
    getData();
  }, [props.selectedLanguageData?.key, props.selectedCurrencyData]);


  const cleanUp = async () => {
    _axiosSource.cancel(
      IntlUtil.getText(_intl_ns_common, "notification.warning.requestCancelled")
    );
  };

  const handleSignInDialogOpen = () => {
    setSignInDialogHidden(false);
  };

  const handleSignInDialogClose = () => {
    setSignInDialogHidden(true);
  };

  const renderSignInDialog = () => {
    if (isSignInDialogHidden === false) {
      return (
        <SignInDialog
          {...props}
          history={props.history}
          websiteTemplate={props.webTemplateConfigData.websiteTemplate}
          isSignInDialogHidden={isSignInDialogHidden}
          handleSignInDialogClose={() => handleSignInDialogClose()}
          intlNamespace={_intl_ns_common}
          loadQuoteCreatePage={() => loadQuoteCreatePage()}
        />
      );
    }
  };

  const setProductList = async () => {
    let productRecords = [];
    if (
      props?.productRecords.records &&
      props?.productRecords.records?.length > 0
    ) {
      productRecords = [...props?.productRecords.records];
      setProducts(productRecords);
    }
  };

  const productCardTitleWrapperStyles = {
    backgroundColor: webTemplate?.generalSettings?.primaryColor,
  };
  const productCardContentWrapperStyles = {
    backgroundColor: webTemplate?.generalSettings?.sectionBackgroundColor,
    border: `1px solid ${webTemplate?.generalSettings?.sectionBorderColor}`,
  };

  const productCardContentWrapperStylesT2 = {
    backgroundColor: webTemplate?.generalSettings?.sectionBackgroundColor,
    fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
  };

  const defaultButtonStyles = {};
  const productCardImageWrapperStyles = {
    backgroundColor: webTemplate?.generalSettings?.pageBackgroundColor,
    border: `1px solid ${webTemplate?.generalSettings?.sectionBorderColor}`,
  };
  const mergedContentStyles = mergeStyleSets({
    "page-background": {
      backgroundColor: `${webTemplate?.generalSettings?.pageBackgroundColor}`,
    },
    "product-caption": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      "@media (max-width:767px)": {
        paddingTop: 10,
      },
    },
    "oc-t3-icon-button": {
      minWidth: "25px",
      backgroundColor: `${webTemplate?.generalSettings?.primaryColor}`,
      border: `1px solid ${webTemplate?.generalSettings?.primaryColor}`,
      color: `${webTemplate?.generalSettings?.pageBackgroundColor}`,
      svg: {
        marginRight: "-5px",
        color: `${webTemplate?.generalSettings?.pageBackgroundColor}`,
        fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      },
      "&:hover": {
        backgroundColor: `${webTemplate?.generalSettings?.primaryColor}`,
        border: `1px solid ${webTemplate?.generalSettings?.primaryColor}`,
        opacity: 0.9,
      },
    },
    "oc-t3-product-text-link": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      "&:hover": {
        color: `${webTemplate?.generalSettings?.secondaryColor}`,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    "oc-t2-default-button": {
      border: `1px solid ${webTemplate?.generalSettings?.primaryColor}`,
      backgroundColor: `${"white"}`,
      width: "auto",
      span: {
        color: `${webTemplate?.generalSettings?.primaryColor}`,
        fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      },
    },
    "oc-t3-default-button": {
      border: `1px solid ${webTemplate?.generalSettings?.primaryColor}`,
      backgroundColor: `${webTemplate?.generalSettings?.pageBackgroundColor}`,
      minWidth: "auto",
      span: {
        color: `${webTemplate?.generalSettings?.primaryColor}`,
        fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      },
      "oc-t3-primary-button": {
        border: `none`,
        backgroundColor: `${webTemplate?.generalSettings?.primaryColor}`,
        width: "auto",
        span: {
          color: `${webTemplate?.generalSettings?.primaryColor}`,
          fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
        },
      },
    },
    "oc-t3-product-text-link": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      "&:hover": {
        color: `${webTemplate?.generalSettings?.secondaryColor}`,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    "oc-t3-product-primary-color": {
      color: `${webTemplate?.generalSettings?.secondaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },

    "default-button": {
      backgroundColor: `${"white"}`,
      border: `1px solid ${webTemplate?.generalSettings?.primaryColor}`,
      i: {
        color: webTemplate?.generalSettings?.sectionBorderColor,
      },
      span: {
        fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
        color: webTemplate?.generalSettings?.primaryColor,
      },
    },
    "primary-button": {
      backgroundColor: webTemplate?.generalSettings?.primaryColor,
      color: webTemplate?.generalSettings?.pageBackgroundColor,
      border: `none`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),

      i: {
        color: webTemplate?.generalSettings?.pageBackgroundColor,
      },
      span: {
        fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
        color: "white",
      },
      "&:hover": {
        backgroundColor: webTemplate?.generalSettings?.primaryColor,
        opacity: 0.9,
        color: webTemplate?.generalSettings?.pageBackgroundColor,
      },
    },
    "oc-t2-primary-button": {
      backgroundColor: webTemplate?.generalSettings?.primaryColor,
      color: webTemplate?.generalSettings?.pageBackgroundColor,
      border: `1px solid ${webTemplate?.generalSettings?.pageBackgroundColor}`,
      i: {
        color: webTemplate?.generalSettings?.pageBackgroundColor,
      },
      span: {
        fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      },
      "&:hover": {
        backgroundColor: webTemplate?.generalSettings?.primaryColor,
        opacity: 0.9,
        color: webTemplate?.generalSettings?.pageBackgroundColor,
      },
    },
    "product-primary-color": {
      color: `${webTemplate?.generalSettings?.primaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-title": {
      color: `${webTemplate?.generalSettings?.primaryColor} !important`,
      fontSize: "large",
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-sub-title": {
      color: `${webTemplate?.generalSettings?.secondaryColor} !important`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-carousel-container": {
      rec: {
        "rec-arrow-left": {
          ":hover:enabled": {
            backgroundColor: `${webTemplate?.generalSettings?.primaryColor} !important`,
            boxShadow: `0 0 1px 1px ${webTemplate?.generalSettings?.primaryColor} !important`,
          },
        },
        "rec.rec-arrow-right": {
          ":hover:enabled": {
            backgroundColor: `${webTemplate?.generalSettings?.primaryColor} !important`,
            boxShadow: `0 0 1px 1px ${webTemplate?.generalSettings?.primaryColor} !important`,
          },
        },
      },
      "rec-arrow": {
        backgroundColor: `${webTemplate?.generalSettings?.sectionBackgroundColor} !important`,
      },
      "rec-arrow:focus": {
        outline: "none",
        backgroundColor: `${webTemplate?.generalSettings?.primaryColor} !important`,
      },
    },
    "product-font": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
  });

  const productCardTextStyles = {
    color: webTemplate?.generalSettings?.secondaryColor,
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 496, itemsToShow: 2, pagination: false },
    { width: 960, itemsToShow: 3, pagination: false },
    { width: 850, itemsToShow: 3, pagination: false },
    { width: 1024, itemsToShow: 3, pagination: false },
    { width: 1150, itemsToShow: 4, pagination: false },
    { width: 1450, itemsToShow: 4, pagination: false },
    { width: 1750, itemsToShow: 4, pagination: false },
  ];
  const handleProductView = (product) => {
    props.history.push(
      OperatorConnectURLProps.productManagement.productView.replace(
        ":pid",
        product.productId
      )
    );
  };

  const fetchProducts = async () => {
    await getProducts(props.selectedLanguageData.key, _cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await props.setProductRecords(res?.data);
          await setProductCurrenciesList(res.data.records);
          await setProductCurrencyList(res.data.records, props.selectedCurrencyData);
        }
      })
      .catch(async (err) => {
        await manageError(err, props.history);
      });
  };

  const setProductCurrencyList = async (productRecords, selectedCurrency) => {
    props?.setSelectedCurrencyData(setCurrencySelecteValue(selectedCurrency));
    let records = [];
    if (productRecords && productRecords.length > 0) {
      records = productRecords.filter(
        (rec) =>
          rec.currencyCode?.toLowerCase() ===
          selectedCurrency?.key?.toLowerCase()
      );
    }
    setProducts(records);
  };

  const handleSelectedLanguageData = (records, selectedlanguageCode) => {
    let filteredText = "";
    if (records && records.length > 0) {
      records.forEach((language) => {
        if (language.languageCode === selectedlanguageCode) {
          filteredText = language.translation;
        }
      });
    }
    return filteredText;
  };

  const countryMenu = (productCountries) => {
    let items = [];
    if (productCountries && productCountries.length > 0) {
      productCountries?.forEach((country, index) => {
        let obj = {
          key: index,
          text: country?.countryName,
        };
        items.push(obj);
      });
    }
    items = _.orderBy(items, "text", "asc");
    return items;
  };

  const mergeContentStyles = mergeStyleSets({
    dropdown: {
      minWidth: "10px !important",
      padding: "2px",
      button: {
        minWidth: "10px !important",
      },
    },
  });

  const setProductCurrenciesList = async (productRecords) => {
    let productsCurrenciesList = [];
    if (productRecords && productRecords.length > 0) {
      productRecords.forEach((rec) => {
        productsCurrenciesList.push({
          key: rec.currencyCode,
          text: `${rec.currencyCode} (${rec.currencySymbol})`,
        });
      });
    }
    let productsCurrenciesUniqueList = _.uniqBy(
      productsCurrenciesList,
      (rec) => rec.key
    );

    productsCurrenciesUniqueList = _.orderBy(
      productsCurrenciesUniqueList,
      [(rec) => rec.key?.toLowerCase()],
      ["asc"]
    );

    props?.setCurrencyData(productsCurrenciesUniqueList);
    props?.setSelectedCurrencyData(setCurrencySelecteValue(productsCurrenciesUniqueList.length > 1 ? props.selectedCurrencyData : productsCurrenciesUniqueList[0]) ?? "undefined -2");
  };

  const loadQuoteCreatePage = (product) => {
    if (product) {
      localStorage.removeItem("persist:root");
      props.quoteEstimatorDataReset(null);
      props.setSelectedProductId(product.productId, product.currencyCode);
      props.history.push(OperatorConnectURLProps.orderManagement.quoteAdd);
      return;
    } else {
      localStorage.removeItem("persist:root");
      props.quoteEstimatorDataReset(null);
      props.history.push(OperatorConnectURLProps.orderManagement.quoteAdd);
      return;
    }
  };

  const setCurrencySelecteValue = (selectedvalue) => {
    let selectedCurrency = "";
    if (props.currencyData && props.currencyData.length > 0) {
      let selectedData = props.currencyData.find((rec) => rec.text?.toLowerCase() === selectedvalue?.text?.toLowerCase())
      selectedCurrency = selectedData
    }
    return selectedCurrency;
  }

  return (
    <>
      <AppPageTitle
        pageTitle={handleSelectedLanguageData(
          websitePageTitle?.pageTitle,
          props.selectedLanguageData?.key
        )}
      />
      {props.webTemplateConfigData.websiteTemplate.bannerDisplayStatus
        ?.toLowerCase()
        .trim() === OperatorConnectConstants.GENERAL.YES?.toLowerCase() ? (
        <Banner {...props} />
      ) : null}

      {webTemplate.templateCode?.toLowerCase().trim() ===
        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_ONE?.toLowerCase().trim() ? (
        <div
          id="oc-page-container"
          className={CustomStyles.getStyle(
            "page-background",
            props.webTemplateConfigData?.websiteTemplate
          )}
        >
          {isPageDataFetched === true ? (
            <>
              {websitePageTitle.pageCode?.toLowerCase().trim() === "oc-p1" ? (
                <>
                  <div className="m-t-20">
                    <div className="oc-product-section">
                      <div
                        className={
                          "oc-welcome-text " +
                          CustomStyles.getStyle(
                            "page-heading",
                            props.webTemplateConfigData?.websiteTemplate
                          )
                        }
                        dangerouslySetInnerHTML={{
                          __html: handleSelectedLanguageData(
                            websitePageTitle?.heading,
                            props.selectedLanguageData?.key
                          ),
                        }}
                      ></div>
                      <div>
                        {props.currencyData.length > 1 ? (
                          <DefaultButton
                            className={`${mergeContentStyles["dropdown"]} m-r-10`}
                            text={setCurrencySelecteValue(props.selectedCurrencyData)?.text ?? ""}
                            menuProps={{
                              items: props.currencyData,
                              alignTargetEdge: true,
                              shouldFocusOnMount: true,
                              beakWidth: 0,
                              onItemClick: (e, option) => {
                                setProductCurrencyList(
                                  props.productRecords?.records,
                                  option
                                );
                              },
                            }}
                          />
                        ) : null}
                        <DefaultButton
                          onClick={() => handleSignInDialogOpen()}
                          text={IntlUtil.getText(
                            _intl_ns_common,
                            "content.signInInfo"
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "m-t-5 " +
                        CustomStyles.getStyle(
                          "page-caption",
                          props.webTemplateConfigData.websiteTemplate
                        )
                      }
                      dangerouslySetInnerHTML={{
                        __html: handleSelectedLanguageData(
                          websitePageTitle?.caption,
                          props.selectedLanguageData?.key
                        ),
                      }}
                    ></div>
                  </div>
                </>
              ) : null}
              <div className="oc-main-content-wrapper">
                <Carousel
                  className={
                    mergedContentStyles["product-carousel-container"] +
                    " product-carousel-container"
                  }
                  breakPoints={breakPoints}
                >
                  {products.map((product, index) => {
                    return (
                      <>
                        <div key={index} className="product-card-wrapper">
                          <div
                            style={{ ...productCardTitleWrapperStyles }}
                            className="product-card-title-wrapper"
                          >
                            <span>{product.productName}</span>
                          </div>
                          <div
                            style={{ ...productCardContentWrapperStyles }}
                            className="product-card-content-wrapper"
                          >
                            <div
                              style={{ ...productCardImageWrapperStyles }}
                              className="product-card-image-wrapper"
                            >
                              {product?.productIcon ? (
                                <Image
                                  loading="lazy"
                                  src={handleImageSource(product.productIcon)}
                                />
                              ) : null}
                              <div className="text-fw-semibold p-10">
                                {product &&
                                  product.retailPrice !== null &&
                                  product.retailPrice !== undefined ? (
                                  <>
                                    <span
                                      style={{ ...productCardTextStyles }}
                                      className="text-fs-large-xxx "
                                    >
                                      {product.currencySymbol}
                                      {PageUtil.setLocaleFormat(
                                        product?.retailPrice,
                                        OperatorConnectConstants.GENERAL
                                          .FORMATTER_TYPE.NUMBER,
                                        0,
                                        2
                                      ) ?? "0"}
                                      <span
                                        style={{ ...productCardTextStyles }}
                                        className="text-fs-small"
                                      ></span>
                                    </span>
                                  </>
                                ) : (
                                  <TooltipHost
                                    content={IntlUtil.getText(
                                      _intl_ns_oc_home,
                                      "content.support"
                                    )}
                                  >
                                    <PhoneIcon
                                      style={{ ...productCardTextStyles }}
                                      className="product-card-icon"
                                    />
                                  </TooltipHost>
                                )}
                              </div>
                            </div>

                            <div className="m-t-40 home-carousel-button">
                              {product?.availability &&
                                product?.availability.length > 0 ? (
                                <div className="m-b-15">
                                  <DefaultButton
                                    style={{ ...defaultButtonStyles }}
                                    className={
                                      CustomStyles.getStyle(
                                        "default-button",
                                        webTemplate
                                      ) + " text-available-country"
                                    }
                                    text={IntlUtil.getText(
                                      _intl_ns_oc_home,
                                      "content.availableCountries"
                                    )}
                                    menuProps={{
                                      items: countryMenu(product?.availability),
                                      shouldFocusOnMount: true,
                                      useTargetWidth: true,
                                    }}
                                  />
                                </div>
                              ) : null}

                              <DefaultButton
                                className={
                                  CustomStyles.getStyle(
                                    "default-button",
                                    webTemplate
                                  ) + " page-frame-button"
                                }
                                onClick={() => handleProductView(product)}
                              >
                                <InfoIcon className="m-r-5 m-b-0" />
                                {IntlUtil.getText(
                                  _intl_ns_oc_home,
                                  "content.moreInfo"
                                )}
                              </DefaultButton>
                            </div>
                            <div className="m-t-15 home-carousel-button">
                              <PrimaryButton
                                className={
                                  CustomStyles.getStyle(
                                    "primary-button",
                                    webTemplate
                                  ) + " page-frame-button"
                                }
                                onClick={() => loadQuoteCreatePage(product)}
                              >
                                <AddToShoppingListIcon className="m-r-5 page-frame-icon" />
                                {IntlUtil.getText(
                                  _intl_ns_oc_home,
                                  "content.createQuote"
                                )}
                              </PrimaryButton>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Carousel>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {webTemplate.templateCode?.toLowerCase().trim() ===
        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_TWO?.toLowerCase().trim() ? (
        <div
          id="oc-page-container"
          className={CustomStyles.getStyle(
            "page-background",
            props.webTemplateConfigData?.websiteTemplate
          )}
        >
          {isPageDataFetched === true ? (
            <>
              {websitePageTitle.pageCode?.toLowerCase().trim() === "oc-p1" ? (
                <>
                  <div className="m-t-20">
                    <div className="oc-product-section">
                      <div
                        className={
                          "oc-welcome-text " +
                          CustomStyles.getStyle(
                            "page-heading",
                            props.webTemplateConfigData.websiteTemplate
                          )
                        }
                        dangerouslySetInnerHTML={{
                          __html: handleSelectedLanguageData(
                            websitePageTitle?.heading,
                            props.selectedLanguageData?.key
                          ),
                        }}
                      ></div>
                      <div>
                        {props.currencyData.length > 1 ? (
                          <DefaultButton
                            className={`${mergeContentStyles["dropdown"]} m-r-10`}
                            text={setCurrencySelecteValue(props.selectedCurrencyData)?.text ?? ""}
                            menuProps={{
                              items: props.currencyData,
                              alignTargetEdge: true,
                              shouldFocusOnMount: true,
                              beakWidth: 0,
                              onItemClick: (e, option) => {
                                setProductCurrencyList(
                                  props.productRecords?.records,
                                  option
                                );
                              },
                            }}
                          />
                        ) : null}

                        <DefaultButton
                          onClick={() => handleSignInDialogOpen()}
                          text={IntlUtil.getText(
                            _intl_ns_common,
                            "content.signInInfo"
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "m-t-5 " +
                        CustomStyles.getStyle(
                          "page-caption",
                          props.webTemplateConfigData.websiteTemplate
                        )
                      }
                      dangerouslySetInnerHTML={{
                        __html: handleSelectedLanguageData(
                          websitePageTitle?.caption,
                          props.selectedLanguageData?.key
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="oc-t2-content-wrapper m-t-40">
                    {products.map((product, index) => {
                      return (
                        <>
                          <div
                            style={{ ...productCardContentWrapperStylesT2 }}
                            className="oc-t2-item-wrapper"
                          >
                            <div className="oc-t2-image-wrapper a">
                              {product?.productIcon ? (
                                <Image
                                  className="oc-t2-product-card-image-wrapper"
                                  loading="lazy"
                                  src={handleImageSource(product.productIcon)}
                                />
                              ) : null}
                            </div>
                            <div className="b oc-t2-info-wrapper">
                              <div
                                className={
                                  "m-t-20 text-fw-semibold text-fs-large-x " +
                                  mergedContentStyles["product-title"]
                                }
                              >
                                {product.productName}
                              </div>
                              <div
                                className={
                                  "m-t-15 text-fw-semibold text-fs-large-xx " +
                                  mergedContentStyles["product-sub-title"]
                                }
                              >
                                <div className="text-fw-semibold">
                                  {product &&
                                    product.retailPrice !== null &&
                                    product.retailPrice !== undefined ? (
                                    <>
                                      <span
                                        style={{ ...productCardTextStyles }}
                                        className="text-fs-large-xxx "
                                      >
                                        {product.currencySymbol}
                                        {PageUtil.setLocaleFormat(
                                          product?.retailPrice,
                                          OperatorConnectConstants.GENERAL
                                            .FORMATTER_TYPE.NUMBER,
                                          0,
                                          2
                                        ) ?? "0"}
                                        <span
                                          style={{ ...productCardTextStyles }}
                                          className="text-fs-small"
                                        ></span>
                                      </span>
                                    </>
                                  ) : (
                                    <TooltipHost
                                      content={IntlUtil.getText(
                                        _intl_ns_oc_home,
                                        "content.support"
                                      )}
                                    >
                                      <PhoneIcon
                                        style={{ ...productCardTextStyles }}
                                        className="product-card-icon"
                                      />
                                    </TooltipHost>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className={"oc-t2-home-carousel-button c"}>
                                <DefaultButton
                                  className={
                                    CustomStyles.getStyle(
                                      "default-button",
                                      webTemplate
                                    ) + " m-b-20 page-frame-button m-r-10"
                                  }
                                  onClick={() => handleProductView(product)}
                                >
                                  <InfoIcon className="m-r-5 m-b-0" />
                                  <TooltipHost
                                    overflowMode={TooltipOverflowMode.Self}
                                    hostClassName="text-truncated"
                                    content={IntlUtil.getText(
                                      _intl_ns_oc_home,
                                      "content.moreInfo"
                                    )}
                                  >
                                    {IntlUtil.getText(
                                      _intl_ns_oc_home,
                                      "content.moreInfo"
                                    )}
                                  </TooltipHost>
                                </DefaultButton>
                                <PrimaryButton
                                  className={
                                    CustomStyles.getStyle(
                                      "primary-button",
                                      webTemplate
                                    ) + " m-b-20 page-frame-button"
                                  }
                                  onClick={() => loadQuoteCreatePage(product)}
                                >
                                  <AddToShoppingListIcon className="m-r-5 page-frame-icon" />
                                  <TooltipHost
                                    overflowMode={TooltipOverflowMode.Self}
                                    hostClassName="text-truncated"
                                    content={IntlUtil.getText(
                                      _intl_ns_oc_home,
                                      "content.createQuote"
                                    )}
                                  >
                                    {IntlUtil.getText(
                                      _intl_ns_oc_home,
                                      "content.createQuote"
                                    )}
                                  </TooltipHost>
                                </PrimaryButton>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
      {webTemplate.templateCode?.toLowerCase().trim() ===
        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim() ? (
        <div
          id="oc-page-container"
          className={CustomStyles.getStyle(
            "page-background",
            props.webTemplateConfigData?.websiteTemplate
          )}
        >
          {isPageDataFetched === true ? (
            <>
              {websitePageTitle.pageCode?.toLowerCase().trim() === "oc-p1" ? (
                <>
                  <div className="m-t-20">
                    <div className="oc-product-section">
                      <div
                        className={
                          "oc-welcome-text " +
                          CustomStyles.getStyle(
                            "page-heading",
                            props.webTemplateConfigData?.websiteTemplate
                          )
                        }
                        dangerouslySetInnerHTML={{
                          __html: handleSelectedLanguageData(
                            websitePageTitle?.heading,
                            props.selectedLanguageData?.key
                          ),
                        }}
                      ></div>
                      <div>
                        {props.currencyData.length > 1 ? (
                          <DefaultButton
                            className={`${mergeContentStyles["dropdown"]} m-r-10`}
                            text={setCurrencySelecteValue(props.selectedCurrencyData)?.text ?? ""}
                            menuProps={{
                              items: props.currencyData,
                              alignTargetEdge: true,
                              shouldFocusOnMount: true,
                              beakWidth: 0,
                              onItemClick: (e, option) => {
                                setProductCurrencyList(
                                  props.productRecords?.records,
                                  option
                                );
                              },
                            }}
                          />
                        ) : null}
                        <DefaultButton
                          onClick={() => handleSignInDialogOpen()}
                          text={IntlUtil.getText(
                            _intl_ns_common,
                            "content.signInInfo"
                          )}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "m-t-5 " +
                        CustomStyles.getStyle(
                          "page-caption",
                          props.webTemplateConfigData?.websiteTemplate
                        )
                      }
                      dangerouslySetInnerHTML={{
                        __html: handleSelectedLanguageData(
                          websitePageTitle?.caption,
                          props.selectedLanguageData?.key
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="oc-t3-content-wrapper m-t-40">
                    {products.map((product, index) => {
                      return (
                        <ProductList
                          {...props}
                          key={`product-list-${index}`}
                          product={product}
                          webTemplate={webTemplate}
                          history={props.history}
                        />
                      );
                    })}
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
      {renderSignInDialog()}
      <PageOverlayLoader
        hidden={isPageDataFetched}
        label={IntlUtil.getText(_intl_ns_common, "content.loadingInprogress")}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isProductRecordsDataFetched: state.productStore.isProductRecordsDataFetched,
  productRecordsDataFetchTimestamp:
    state.productStore.productRecordsDataFetchTimestamp,
  productRecords: state.productStore.productRecords,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  selectedCurrencyData: state.generalStore.selectedCurrencyData,
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  currencyData: state.generalStore.currencyData,
});

const mapActionToProps = {
  setSelectedCurrencyData,
  setProductRecords,
  setCurrencyData,
  setSelectedProductId,
  quoteEstimatorDataReset,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
