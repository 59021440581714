import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import React from "react";
import IntlUtil from "../../../core/helpers/intl-util";
import { OperatorConnectConstants } from "../settings/operator-connect-constants";

export default function CustomDialog({
  id,
  title,
  minWidth,
  maxWidth,
  showCloseButton,
  buttonType,
  children,
  hidden,
  primaryButtonName,
  defaultButtonName,
  onPrimaryButtonClick,
  onDefaultButtonClick,
  dialogContentClassName,
  primaryButtonClassName,
  modalPropsClassName,
  dialogContentType,
  primaryButtonDisabled,
  defaultButtonDisabled,
  defaultButtonClassName,
  onDismiss
}) {
  const _intl_ns_spp_common = "oc_common";
  return (
    <Dialog
      id={id}
      minWidth={minWidth}
      maxWidth={maxWidth}
      onDismiss={onDismiss}
      hidden={hidden}
      modalProps={{
        className: `custom-dialog-wrapper ${modalPropsClassName}`,
      }}
      dialogContentProps={{
        className: dialogContentClassName,
        type: dialogContentType ?? DialogType.normal,
        showCloseButton: showCloseButton ?? false,
        title: title,
        subText: children,
      }}
    >
      <DialogFooter>
        {buttonType === OperatorConnectConstants.BUTTON_TYPE.SINGLE ? (
          <DefaultButton
            className={`${defaultButtonClassName}`}
            disabled={defaultButtonDisabled}
            onClick={onDefaultButtonClick}
          >
            {defaultButtonName ??
              IntlUtil.getText(_intl_ns_spp_common, "content.no")}
          </DefaultButton>
        ) : (
          <>
            <PrimaryButton
              className={`m-r-5 ${primaryButtonClassName}`}
              onClick={onPrimaryButtonClick}
              disabled={primaryButtonDisabled}
            >
              {primaryButtonName ??
                IntlUtil.getText(_intl_ns_spp_common, "content.yes")}
            </PrimaryButton>
            <DefaultButton
              className={`${defaultButtonClassName}`}
              onClick={onDefaultButtonClick}
              disabled={defaultButtonDisabled}
            >
              {defaultButtonName ??
                IntlUtil.getText(_intl_ns_spp_common, "content.no")}
            </DefaultButton>
          </>
        )}
      </DialogFooter>
    </Dialog>
  );
}
