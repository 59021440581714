import {
  TooltipOverflowMode,
  TooltipHost,
  DetailsRow,
  DetailsList,
  Sticky,
  StickyPositionType,
  ConstrainMode,
  SelectionMode,
  DetailsListLayoutMode,
} from "@fluentui/react";
import React, { memo } from "react";
import { connect } from "react-redux";
import { QuoteEstimatorTableColumns } from "../settings/quote-estimator-table-columns";
import { css } from "@fluentui/react/lib/Utilities";
import IntlUtil from "../../../core/helpers/intl-util";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { InfoIcon } from "@fluentui/react-icons";
import { withTranslation } from "react-i18next";
import CustomStyles from "../../common/helpers/custom-styles";
import { redirectUrl } from "../../common/actions/general-actions";
import PageUtil from "../../../core/helpers/page-util";

/**
Project: Operator Connect (c)
Title: Quote Builder Estimator  
Description: Building the Quote 
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/

//import { InfoIcon } from "@fluentui/react-icons";

const QuoteBuilderEstimator = ({
  intlNamespace,
  quoteEstimatorInfo,
  showTitle,
  webTemplateConfigData,
}) => {
  const _intl_ns_oc_common = "oc_common";

  const handleQuoteHeaderColumns = () => {
    let tableHeaders = [];
    QuoteEstimatorTableColumns(intlNamespace).map((header) => {
      if (header.fieldName === "nrc") {
        header.name = (
          <TooltipHost
            content={IntlUtil.getText(intlNamespace, "content.tooltip.nrc")}
          >
            <span>
              {IntlUtil.getText(intlNamespace, header.name)}
              <InfoIcon className="m-l-5" />
            </span>
          </TooltipHost>
        );
      } else if (header.fieldName === "mrc") {
        header.name = (
          <TooltipHost
            content={IntlUtil.getText(intlNamespace, "content.tooltip.mrc")}
          >
            <span>
              {IntlUtil.getText(intlNamespace, header.name)}{" "}
              <InfoIcon className="m-l-5" />
            </span>
          </TooltipHost>
        );
      } else {
        header.name = IntlUtil.getText(intlNamespace, header.name);
      }

      tableHeaders.push({ ...header });
    });
    return tableHeaders;
  };
  const handleTermLinkClick = async (item) => {
    if (item.termLink) {
      redirectUrl(item.termLink);
    }
  };
  const handleQuoterBuilderItemRender = (item, index, column) => {
    let recurringList = [];
    let oneTimeList = [];
    switch (column.key) {
      case "termPlan":
        return (
          <>
            {item.termPlan?.toLowerCase().trim() ===
            OperatorConnectConstants.GENERAL.YES?.toLowerCase() ? (
              item?.termLink !== null && item?.termLink !== "" ? (
                <span
                  onClick={() => handleTermLinkClick(item)}
                  className={`cursor-pointer ${CustomStyles.getStyle(
                    "text-link",
                    webTemplateConfigData?.websiteTemplate
                  )}`}
                >
                  {" "}
                  {IntlUtil.getText(intlNamespace, "content.yes")}
                </span>
              ) : (
                <span>
                  {IntlUtil.getText(_intl_ns_oc_common, "content.yes")}
                </span>
              )
            ) : (
              IntlUtil.getText(_intl_ns_oc_common, "content.no")
            )}
          </>
        );

      case "productName":
        let communicationPlatform = item.communicationPlatform
          ? " - " + item.communicationPlatform
          : "";
        item &&
          item.pricing &&
          item.pricing.forEach((price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              oneTimeList.push(price.chargeType);
            }
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              recurringList.push(price.chargeType);
            }
          });
        return (
          <TooltipHost
            overflowMode={TooltipOverflowMode.Self}
            hostClassName="quote-description-text text-truncated"
            content={`${item.availabilityName} - ${item?.productName} (${item?.productCode}) - ${item?.connection}${communicationPlatform}`}
          >
            <div className="oc-product-align">{`${item.availabilityName} - ${item?.productName} (${item?.productCode}) - ${item?.connection}${communicationPlatform}`}</div>
          </TooltipHost>
        );
      case "nrc":
        return (
          <>
            {item &&
              item.pricing &&
              item.pricing.map((price, index) => {
                if (
                  price?.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                ) {
                  if (
                    price?.chargeName?.toLowerCase().trim() ===
                      OperatorConnectConstants.QUOTE.CHARGE_NAME.ACTIVATION_FEE?.toLowerCase().trim() ||
                    price?.chargeName?.toLowerCase().trim() ===
                      OperatorConnectConstants.QUOTE.CHARGE_NAME.PORTING_FEE?.toLowerCase().trim()
                  ) {
                    let pricing =
                      price.sellingPrice !== null &&
                      price.sellingPrice !== undefined
                        ? item?.currencySymbol +
                          PageUtil.setLocaleFormat(
                            parseFloat(item?.quantity ?? "0") *
                              parseFloat(price.sellingPrice),
                            OperatorConnectConstants.GENERAL.FORMATTER_TYPE
                              .NUMBER,
                            2,
                            2
                          )
                        : "";
                    return (
                      <>
                        <span
                          className={
                            CustomStyles.getStyle(
                              webTemplateConfigData?.websiteTemplate?.templateCode
                                ?.toLowerCase()
                                .trim() !==
                                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                ? "text-fc-primary"
                                : "text-fc-secondary",
                              webTemplateConfigData?.websiteTemplate
                            ) + " text-ff-semibold"
                          }
                        >
                          {" "}
                          <TooltipHost
                            hostClassName={css("m-0 p-0")}
                            key={`q-${index}`}
                            content={price?.chargeName}
                          >
                            {pricing}{" "}
                          </TooltipHost>
                        </span>
                      </>
                    );
                  }
                } else {
                  return <></>;
                }
                return <></>;
              })}
            {item &&
              item.pricing &&
              item.pricing.map((price, index) => {
                if (
                  price?.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                ) {
                  if (
                    price?.chargeName?.toLowerCase().trim() !==
                      OperatorConnectConstants.QUOTE.CHARGE_NAME.ACTIVATION_FEE?.toLowerCase().trim() &&
                    price?.chargeName?.toLowerCase().trim() !==
                      OperatorConnectConstants.QUOTE.CHARGE_NAME.PORTING_FEE?.toLowerCase().trim()
                  ) {
                    let pricing =
                      price.sellingPrice !== null &&
                      price.sellingPrice !== undefined
                        ? item?.currencySymbol +
                          PageUtil.setLocaleFormat(
                            parseFloat(item?.quantity ?? "0") *
                              parseFloat(price.sellingPrice),

                            OperatorConnectConstants.GENERAL.FORMATTER_TYPE
                              .NUMBER,
                            2,
                            2
                          )
                        : "";

                    return (
                      <>
                        {oneTimeList.length > 1 ? " + " : ""}{" "}
                        <div
                          className={
                            CustomStyles.getStyle(
                              webTemplateConfigData?.websiteTemplate?.templateCode
                                ?.toLowerCase()
                                .trim() !==
                                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                ? "text-fc-primary"
                                : "text-fc-secondary",
                              webTemplateConfigData?.websiteTemplate
                            ) + " text-ff-semibold"
                          }
                        >
                          {" "}
                          <TooltipHost
                            hostClassName={css("m-0 p-0")}
                            key={`q-${index}`}
                            content={price?.chargeName}
                          >
                            {pricing}
                          </TooltipHost>
                        </div>
                      </>
                    );
                  }
                } else {
                  return <></>;
                }
                return <></>;
              })}
          </>
        );
      case "mrc":
        return (
          <>
            {item &&
              item.pricing &&
              item.pricing.map((price, index) => {
                if (
                  price?.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                ) {
                  if (
                    price?.chargeName?.toLowerCase().trim() ===
                    OperatorConnectConstants.QUOTE.CHARGE_NAME.PLATFORM_CHARGES?.toLowerCase().trim()
                  ) {
                    let pricing =
                      price.sellingPrice !== null &&
                      price.sellingPrice !== undefined
                        ? item?.currencySymbol +
                          PageUtil.setLocaleFormat(
                            parseFloat(item?.quantity ?? "0") *
                              parseFloat(price.sellingPrice),

                            OperatorConnectConstants.GENERAL.FORMATTER_TYPE
                              .NUMBER,
                            2,
                            2
                          )
                        : "";
                    return (
                      <>
                        <div
                          className={
                            CustomStyles.getStyle(
                              webTemplateConfigData?.websiteTemplate?.templateCode
                                ?.toLowerCase()
                                .trim() !==
                                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                ? "text-fc-primary"
                                : "text-fc-secondary",
                              webTemplateConfigData?.websiteTemplate
                            ) + " text-ff-semibold"
                          }
                        >
                          {" "}
                          <TooltipHost
                            hostClassName={css("m-0 p-0")}
                            key={`q-${index}`}
                            content={price?.chargeName}
                          >
                            {pricing}{" "}
                          </TooltipHost>
                        </div>
                      </>
                    );
                  } else {
                    return <></>;
                  }
                } else {
                  return <></>;
                }
              })}
            {item &&
              item.pricing &&
              item.pricing.map((price, index) => {
                if (
                  price?.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                ) {
                  if (
                    price?.chargeName?.toLowerCase().trim() !==
                    OperatorConnectConstants.QUOTE.CHARGE_NAME.PLATFORM_CHARGES?.toLowerCase().trim()
                  ) {
                    let pricing =
                      price.sellingPrice !== null &&
                      price.sellingPrice !== undefined
                        ? item?.currencySymbol +
                          PageUtil.setLocaleFormat(
                            parseFloat(item?.quantity ?? "0") *
                              parseFloat(price.sellingPrice),
                            OperatorConnectConstants.GENERAL.FORMATTER_TYPE
                              .NUMBER,
                            2,
                            2
                          )
                        : "";

                    return (
                      <>
                        <div
                          className={
                            CustomStyles.getStyle(
                              webTemplateConfigData?.websiteTemplate?.templateCode
                                ?.toLowerCase()
                                .trim() !==
                                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                ? "text-fc-primary"
                                : "text-fc-secondary",
                              webTemplateConfigData?.websiteTemplate
                            ) + " text-ff-semibold"
                          }
                        >
                          {" "}
                          <TooltipHost
                            hostClassName={css("m-0 p-0")}
                            key={`q-${index}`}
                            content={price?.chargeName}
                          >
                            {pricing}
                          </TooltipHost>
                        </div>
                      </>
                    );
                  }
                } else {
                  return <></>;
                }
                return <></>;
              })}
          </>
        );
      case "quantity":
        let quantity = PageUtil.setLocaleFormat(
          item[column.fieldName],
          OperatorConnectConstants.GENERAL.FORMATTER_TYPE.NUMBER,
          0,
          0
        );
        return (
          <TooltipHost
            overflowMode={TooltipOverflowMode.Self}
            hostClassName="user-description-text"
            content={quantity}
          >
            {quantity}
          </TooltipHost>
        );

      default:
        return (
          <div>
            <TooltipHost
              overflowMode={TooltipOverflowMode.Self}
              hostClassName="user-description-text"
              content={item[column.fieldName]}
            >
              {item[column.fieldName]}
            </TooltipHost>
          </div>
        );
    }
  };
  // total item row
  const renderTotalDetailsFooterItemColumn = (item, index, column) => {
    switch (column.key) {
      case "termPlan":
        return (
          <div>
            <div>
              <b>{IntlUtil.getText(intlNamespace, "content.total")}</b>
            </div>
          </div>
        );

      case "mrc":
        return (
          <div
            className={
              CustomStyles.getStyle(
                webTemplateConfigData?.websiteTemplate?.templateCode
                  ?.toLowerCase()
                  .trim() !==
                  OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? "text-fc-primary"
                  : "text-fc-secondary",
                webTemplateConfigData?.websiteTemplate
              ) + " text-fw-semibold"
            }
          >
            <div>
              {quoteEstimatorInfo?.serviceTotalCost &&
              quoteEstimatorInfo.serviceAndConnectionRecords &&
              quoteEstimatorInfo.serviceAndConnectionRecords.length > 0
                ? quoteEstimatorInfo.serviceAndConnectionRecords[0]
                    ?.currencySymbol +
                  PageUtil.setLocaleFormat(
                    parseFloat(quoteEstimatorInfo?.serviceTotalCost),

                    OperatorConnectConstants.GENERAL.FORMATTER_TYPE.NUMBER,
                    2,
                    2
                  )
                : null}
            </div>
          </div>
        );

      default:
        return (
          <div>
            <TooltipHost
              overflowMode={TooltipOverflowMode.Self}
              hostClassName="user-description-text"
              content={item[column.fieldName]}
            >
              {item[column.fieldName]}
            </TooltipHost>
          </div>
        );
    }
  };
  // sub item row
  const renderSubTotalDetailsFooterItemColumn = (item, index, column) => {
    switch (column.key) {
      case "termPlan":
        return (
          <div className="">
            <div>
              <b>{IntlUtil.getText(intlNamespace, "content.subTotal")}</b>
            </div>
          </div>
        );
      case "nrc":
        return (
          <div>
            <div
              className={
                CustomStyles.getStyle(
                  webTemplateConfigData?.websiteTemplate?.templateCode
                    ?.toLowerCase()
                    .trim() !==
                    OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                    ? "text-fc-primary"
                    : "text-fc-secondary",
                  webTemplateConfigData?.websiteTemplate
                ) + " text-ff-semibold"
              }
            >
              {quoteEstimatorInfo?.serviceNrcSubTotalCost &&
              quoteEstimatorInfo?.serviceAndConnectionRecords &&
              quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
                ? quoteEstimatorInfo?.serviceAndConnectionRecords[0]
                    ?.currencySymbol +
                  PageUtil.setLocaleFormat(
                    parseFloat(quoteEstimatorInfo?.serviceNrcSubTotalCost),
                    OperatorConnectConstants.GENERAL.FORMATTER_TYPE.NUMBER,
                    2,
                    2
                  )
                : null}
            </div>
          </div>
        );
      case "mrc":
        return (
          <div>
            <div
              className={
                CustomStyles.getStyle(
                  webTemplateConfigData?.websiteTemplate?.templateCode
                    ?.toLowerCase()
                    .trim() !==
                    OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                    ? "text-fc-primary"
                    : "text-fc-secondary",
                  webTemplateConfigData?.websiteTemplate
                ) + " text-ff-semibold"
              }
            >
              {!isNaN(quoteEstimatorInfo?.serviceMrcSubTotalCost) &&
              quoteEstimatorInfo?.serviceAndConnectionRecords &&
              quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
                ? quoteEstimatorInfo?.serviceAndConnectionRecords[0]
                    ?.currencySymbol +
                  PageUtil.setLocaleFormat(
                    parseFloat(quoteEstimatorInfo?.serviceMrcSubTotalCost),
                    OperatorConnectConstants.GENERAL.FORMATTER_TYPE.NUMBER,
                    2,
                    2
                  )
                : "-"}
            </div>
          </div>
        );

      default:
        return (
          <div>
            <TooltipHost
              overflowMode={TooltipOverflowMode.Self}
              hostClassName="user-description-text"
              content={item[column.fieldName]}
            >
              {item[column.fieldName]}
            </TooltipHost>
          </div>
        );
    }
  };
  // footer detail row
  const onRenderQuoterBuilderDetailsFooter = (detailsFooterProps) => {
    return (
      <div className="page-frame-table-footer">
        <DetailsRow
          {...detailsFooterProps}
          columns={detailsFooterProps.columns}
          className={
            "page-frame-table-footer-row " +
            CustomStyles.getStyle(
              "page-frame-table-footer-row",
              webTemplateConfigData?.websiteTemplate
            )
          }
          item={{}}
          itemIndex={-1}
          groupNestingDepth={detailsFooterProps.groupNestingDepth}
          selectionMode={SelectionMode.none}
          selection={detailsFooterProps.selection}
          onRenderItemColumn={renderSubTotalDetailsFooterItemColumn}
        />
        <DetailsRow
          {...detailsFooterProps}
          columns={detailsFooterProps.columns}
          className={
            "page-frame-table-footer-row " +
            CustomStyles.getStyle(
              "page-frame-table-footer-row",
              webTemplateConfigData?.websiteTemplate
            )
          }
          item={{}}
          itemIndex={-1}
          groupNestingDepth={detailsFooterProps.groupNestingDepth}
          selectionMode={SelectionMode.none}
          selection={detailsFooterProps.selection}
          onRenderItemColumn={renderTotalDetailsFooterItemColumn}
        />
      </div>
    );
  };
  // render detail list
  const renderQuoterBuilderEstimatorList = () => {
    return (
      <>
        <DetailsList
          className={
            "page-frame-table " +
            CustomStyles.getStyle(
              "page-frame-table",
              webTemplateConfigData?.websiteTemplate
            )
          }
          columns={handleQuoteHeaderColumns()}
          items={quoteEstimatorInfo?.serviceAndConnectionRecords || []}
          compact={false}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onRenderItemColumn={handleQuoterBuilderItemRender}
          onRenderDetailsHeader={(detailsHeaderProps, defaultRender) => (
            <Sticky
              stickyPosition={StickyPositionType.Header}
              isScrollSynced
              stickyClassName="page-frame-table-header-sticky"
            >
              {defaultRender(detailsHeaderProps)}
            </Sticky>
          )}
          constrainMode={ConstrainMode.unconstrained}
          selectionMode={SelectionMode.none}
          onRenderDetailsFooter={onRenderQuoterBuilderDetailsFooter}
        />
      </>
    );
  };

  return (
    <>
      <div className="quote-estimator-page-content">
        <div className="quote-page-table-wrapper">
          {renderQuoterBuilderEstimatorList()}
          <div
            className={`${CustomStyles.getStyle(
              "font-family",
              webTemplateConfigData?.websiteTemplate
            )}  quote-page-table-footer-text p-b-30`}
          >
            <span>
              {IntlUtil.getText(intlNamespace, "content.taxesApplicable")}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
});

const mapActionToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(memo(QuoteBuilderEstimator))
);
