import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPageNotFound from "../core/views/error-page-not-found";
import ErrorPage from "../core/views/error-page";
import Error404 from "../core/views/error-404";
import Header from "../pages/common/components/header";
import Footer from "../pages/common/components/footer";

class BlankLayout extends Component {
  render() {
    return (
      <>
        <div id="oc-layout-wrapper">
          <Switch>
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/404" exact component={Error404} />
            <Route path="/page-not-found" exact component={ErrorPageNotFound} />
          </Switch>
        </div>
      </>
    );
  }
}

export default BlankLayout;
