/**
Project: Voice Panel (c)
Title: Onboard steps 
Description: Component to indicate the Onboard steps progress
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React, { useState } from "react";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { withTranslation } from "react-i18next";
import { mergeStyleSets } from "@fluentui/react";
import { setFontFamily } from "../../common/actions/general-actions";

const QuoteSteps = (props) => {
  const _intl_ns_oc_quote = "oc_quote";

  const [websiteTemplate, setWebsiteTemplate] = useState(
    props?.webTemplateConfigData?.websiteTemplate
  );

  const quoteStepsStyles = mergeStyleSets({
    "font-family": {
      fontFamily: `${setFontFamily(
        websiteTemplate?.generalSettings?.font
      )} !important`,
    },
    title: {
      color: `${websiteTemplate?.generalSettings?.secondaryColor}`,
    },
    "title-active": {
      color: `${websiteTemplate?.generalSettings?.primaryColor} !important`,
    },
    "title-completed": {
      color: `${websiteTemplate?.generalSettings?.primaryColor} !important`,
    },
    div: {
      fontFamily: `${setFontFamily(
        websiteTemplate?.generalSettings?.font
      )} !important`,
      color: `${websiteTemplate?.generalSettings?.pageForegroundColor}`,
    },
    steps: {
      "&:before": {
        backgroundColor: `${websiteTemplate?.generalSettings?.secondaryColor}`,
      },
      "&:after": {
        backgroundColor: `${websiteTemplate?.generalSettings?.secondaryColor}`,
      },
    },
    active: {
      "&:before": {
        background: `${websiteTemplate?.generalSettings?.primaryColor} !important`,
        color: "white",
      },
      "&:after": {
        background: `${websiteTemplate?.generalSettings?.primaryColor} !important`,
        color: "white",
      },
    },
    inactive: {
      "&:before": {
        backgroundColor: `${websiteTemplate?.generalSettings?.secondaryColor}`,
        color: "white",
      },
      "&:after": {
        backgroundColor: `${websiteTemplate?.generalSettings?.secondaryColor}`,
        color: "white",
      },
    },
    completed: {
      "&:before": {
        background: `${websiteTemplate?.generalSettings?.primaryColor} !important`,
        color: "white",
      },
      "&:after": {
        background: `${websiteTemplate?.generalSettings?.primaryColor} !important`,
        color: "white",
      },
    },
  });

  return (
    <div
      className={
        "frame-content-progress-steps " + quoteStepsStyles["font-family"]
      }
    >
      <ul className={"quote-progress"}>
        <li
          className={`${
            props.quoteProgressPage ===
            OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS
              ? quoteStepsStyles["active"] + " active "
              : ""
          }
            ${
              props.quoteProgressPage >
              OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS
                ? quoteStepsStyles["completed"] + " completed "
                : ""
            } steps`}
        >
          <span
            className={`${
              props.quoteProgressPage ===
              OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS
                ? quoteStepsStyles["title-active"] + " active "
                : ""
            }
              ${
                props.quoteProgressPage >
                OperatorConnectConstants.QUOTE.PROGRESS.SELECT_PRODUCTS
                  ? quoteStepsStyles["title-completed"] + " completed "
                  : ""
              } steps title ${quoteStepsStyles["title"]} `}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.configureProducts")}
          </span>
        </li>

        <li
          className={`${
            props.quoteProgressPage ===
            OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
              ? quoteStepsStyles["active"] + " active "
              : ""
          }
            ${
              props.quoteProgressPage >
              OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
                ? quoteStepsStyles["completed"] + " completed "
                : ""
            } ${
            props.quoteProgressPage <=
            OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
              ? ` steps ${quoteStepsStyles["steps"]}`
              : ""
          } ${
            props.quoteProgressPage !==
              OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT &&
            props.quoteProgressPage <
              OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
              ? quoteStepsStyles["inactive"]
              : ""
          } steps`}
        >
          <span
            className={`${
              props.quoteProgressPage ===
              OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
                ? quoteStepsStyles["title-active"] + " active "
                : ""
            }
              ${
                props.quoteProgressPage >
                OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
                  ? quoteStepsStyles["title-completed"] + " completed "
                  : ""
              } steps title ${quoteStepsStyles["title"]}`}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.createAccount")}
          </span>
        </li>
        <li
          className={`${
            props.quoteProgressPage ===
            OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
              ? quoteStepsStyles["active"] + " active "
              : ""
          }
  ${
    props.quoteProgressPage > OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
      ? quoteStepsStyles["completed"] + " completed "
      : ""
  } ${
            props.quoteProgressPage <=
            OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
              ? ` steps ${quoteStepsStyles["steps"]}`
              : ""
          } ${
            props.quoteProgressPage !==
              OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT &&
            props.quoteProgressPage <
              OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
              ? quoteStepsStyles["inactive"]
              : ""
          } steps`}
        >
          <span
            className={`${
              props.quoteProgressPage ===
              OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
                ? quoteStepsStyles["title-active"] + " active "
                : ""
            }
              ${
                props.quoteProgressPage >
                OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
                  ? quoteStepsStyles["title-completed"] + " completed "
                  : ""
              } steps title ${quoteStepsStyles["title"]}`}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.checkout")}
          </span>
        </li>
        <li
          className={`${
            props.quoteProgressPage ===
            OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
              ? quoteStepsStyles["active"] + " active "
              : ""
          }
  ${
    props.quoteProgressPage >
    OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
      ? quoteStepsStyles["completed"] + " completed "
      : ""
  } ${
            props.quoteProgressPage <=
            OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
              ? ` steps `
              : ""
          } ${
            props.quoteProgressPage !==
              OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION &&
            props.quoteProgressPage <
              OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
              ? quoteStepsStyles["inactive"]
              : ""
          }`}
        >
          <span
            className={`${
              props.quoteProgressPage ===
              OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
                ? quoteStepsStyles["title-active"] + " active "
                : ""
            }
              ${
                props.quoteProgressPage >
                OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
                  ? quoteStepsStyles["title-completed"] + " completed "
                  : ""
              } steps title ${quoteStepsStyles["title"]}`}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.orderConfirmation")}
          </span>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  quoteProgressPage: state.quoteStore.quoteProgressPage,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  languageData: state.generalStore.languageData,
});

const mapActionToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(QuoteSteps)
);
