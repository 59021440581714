import {
  Text,
  DefaultButton,
  PrimaryButton,
  TextField,
  TooltipHost,
  Modal,
  Link,
} from "@fluentui/react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import TokenUtil from "../../../core/helpers/token-util";
import { checkIdentitySession } from "../../../core/actions/common-actions";
import _ from "lodash";
import {
  getProductsAvailable,
  setQuoteEstimatorInfoProductRecords,
  setQuoteEstimatorInfoServiceAndConnectionRecords,
  setQuoteEstimatorInfoServiceAndConnectionCost,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setQuoteEstimatorInfoOnboardServiceDocumentRecords,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  getDocuments,
  resetQuoteEstimatorInfo,
  setQuoteProgressPage,
  saveQuote,
  getResources,
  getQuote,
  getQuotesOrdersSearch,
  setQuoteEstimatorInfoResourceRecords,
  quoteDownloadPdf,
} from "../actions/quote-action";
import axios from "axios";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { QuoteEstimatorTableColumns } from "../settings/quote-estimator-table-columns";
import { QuoteUserFormFields } from "../settings/quote-user-form-fields";
import {
  ChevronRightMedIcon,
  DownloadIcon,
  InfoIcon,
  PreviewIcon,
  TextDocumentIcon,
  SkypeMessageIcon,
} from "@fluentui/react-icons";
import { modalStyles } from "../../common/helpers/styles";
import { manageError } from "../../../core/actions/common-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import moment from "moment";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import {
  redirectUrl,
  setCountryStatesData,
  setCurrencyData,
  setSelectedCurrencyData,
} from "../../common/actions/general-actions";
import AddOrEditProductForm from "../helper/add-Product-form";
import TelemetryUtil from "../../../core/helpers/telemetry-util";
import CustomStyles from "../../common/helpers/custom-styles";
import CustomerRepresentativeForm from "../helper/customer-representative-form";
import {
  getCountryStates,
  getProducts,
  setProductRecords,
} from "../../common/actions/common-actions";
import { setSelectedProductId } from "../actions/quote-action";
import QuoteServiceTable from "../helper/quote-service-table";
import { QuoteConstants } from "../settings/quote-constants";
import CustomDialog from "../../common/helpers/custom-dialog";

class QuoteCreate extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_oc_quote = "oc_quote";
  _intl_ns_oc_common = "oc_common";

  constructor(props) {
    super(props);
    this.state = {
      quoteUserFormInputFields: this.initQuoteUserFormInputFields(),
      connectionList: [],
      productServices: [],
      communicationPlatformList: [],
      currencyCode: "",
      countryProductRecords: [],
      isCurrencyEnabled: false,
      isFormFilled: false,
      isServiceDisabled: false,
      isCustomerRepresentative: false,
      platformDisable: false,
      editedServiceRecord: null,
      selectedServiceConditions: null,
      isProductErrorMessage: false,
      isUserFormFilled: false,
      isPreviewModalOpen: false,
      refreshReCaptcha: false,
      quoteServiceSubmitStatus: null,
      quoteServiceError: null,
      quoteProductDetails: null,
      quotesRecords: null,
      productName: null,
      selectedCountry: null,
      isProductFieldsEnabled: true,
      isProductQuoteSearch: false,
      deleteServiceRecord: null,
      isServiceDeleteDialogHidden: true,
      isPageDataFetched: false,
      quoteRecord: null,
      isSaveQuoteDialogHidden: true,
      isQuoteEditWarningDialogHidden: true,
      processType: null,
      serviceErrorObject: null,
      isAvailable: false,
      websiteTemplate: null,
      countryList: [],
      isAddOrEditProductModalOpen: false,
      existingQuoteProductDetails: null,
      existingResourceRecords: [],
      existingOnboardDocumentRecords: [],
      viewExistingOrderDetails: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    TelemetryUtil.trackPageView(
      IntlUtil.getText(this._intl_ns_oc_quote, "title.selectProducts")
    );
    await this.setStateAsync({
      websiteTemplate: this.props.webTemplateConfigData?.websiteTemplate,
    });
    await this.fetchCountries();
    if (
      this.props.productRecords &&
      this.props.productRecords?.records &&
      this.props.productRecords?.records.length > 0
    ) {
      await this.setCountryList();
      await this.setProductCurrenciesList(this.props.productRecords.records);
    } else {
      await this.fetchProducts();
    }
    await this.props.resetQuoteEstimatorInfo(this.props.quoteEstimatorInfo);
    await this.setStateAsync({ isPageDataFetched: true });
    if (this.props.selectedProductId?.productId) {
      await this.setStateAsync({ isAddOrEditProductModalOpen: true });
    }
  }

  fetchDocuments = async (serviceAndConnectionRecords) => {
    let resourceObject = [];
    let productObject = [];
    if (serviceAndConnectionRecords && serviceAndConnectionRecords.length > 0) {
      await serviceAndConnectionRecords.forEach((rec) => {
        resourceObject.push({ productId: rec.productId });
        productObject.push({
          productId: rec.productId,
          planId: rec.planId,
          availabilityType: "country",
          availabilityName: rec.availabilityName,
        });
      });

      let productResourceObject = {
        languageCode: this.props.selectedLanguageData.key,
        products: resourceObject,
      };
      let productDocumentObject = {
        languageCode: this.props.selectedLanguageData.key,
        products: productObject,
      };
      await axios
        .all([
          getResources(productResourceObject, this._cancelToken),
          getDocuments(productDocumentObject, this._cancelToken),
        ])
        .then(
          axios.spread(async (resource, document) => {
            if (
              resource &&
              resource.status === AppConfigProps.httpStatusCode.ok &&
              resource.data &&
              document &&
              document.status === AppConfigProps.httpStatusCode.ok &&
              document.data
            ) {
              if (resource.data.records) {
                await this.props.setQuoteEstimatorInfoResourceRecords(
                  this.props.quoteEstimatorInfo,
                  resource.data.records
                );
              }
              if (document.data.records) {
                await this.props.setQuoteEstimatorInfoOnboardServiceDocumentRecords(
                  this.props.quoteEstimatorInfo,
                  document.data.records
                );
              }
            }
          })
        )
        .catch(async (err) => {
          await this.setStateAsync({ isPageDataFetched: true });
          await manageError(err, this.props.history);
          await this.props.setPageExit(false);
        });
    }
  };

  setCountryList = async () => {
    let countries = [];
    if (this.props.productRecords?.records.length > 0) {
      this.props.productRecords?.records.forEach((rec) => {
        if (rec?.availableCountries?.length > 0) {
          rec.availableCountries.forEach((country) => {
            countries.push({
              key: country?.countryName,
              text: country?.countryName,
            });
          });
        }
      });
      countries = _.uniqBy(countries, "key");
    }
    await this.setStateAsync({
      countryList: countries,
    });
  };

  fetchProducts = async () => {
    await getProducts(this.props.selectedLanguageData.key, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.props.setProductRecords(res?.data);
          await this.setProductCurrenciesList(res.data.records);
          await this.setCountryList();
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }
  isUserFormFilled = () => {
    let isFormFilled = false;
    if (
      this.state.quoteUserFormInputFields.quoteName.value?.trim() !== "" &&
      this.state.quoteUserFormInputFields.quoteName.isError === false &&
      this.state.quoteUserFormInputFields.email.value?.trim() !== "" &&
      this.state.quoteUserFormInputFields.email.isError === false &&
      this.state.quoteUserFormInputFields.phone.isError === false
    ) {
      isFormFilled = true;
    }
    return isFormFilled;
  };

  validateUserFormField = async (fieldName) => {
    if (fieldName && this.state.quoteUserFormInputFields) {
      let fieldObj = this.state.quoteUserFormInputFields[fieldName];
      if (fieldObj.isRequired === true) {
        let errorStatus = false;
        if (fieldObj.validate(fieldObj.value) === false) {
          errorStatus = true;
        }
        await this.setStateAsync({
          quoteUserFormInputFields: {
            ...this.state.quoteUserFormInputFields,
            [fieldName]: {
              ...this.state.quoteUserFormInputFields[fieldName],
              isError: errorStatus,
            },
          },
        });
      }
      let formFilled = this.isUserFormFilled();
      await this.setStateAsync({ isUserFormFilled: formFilled });
    }
  };

  handleEditServiceRecord = async (e, item) => {
    let connectionList = [];
    let planTypes = [];
    if (item) {
      await this.clearAlert();
      await this.fetchProductsAvailable(
        "country",
        item.availabilityName,
        item.currencyCode
      );
      await this.setUserFormFieldValue("country", item.availabilityName);
      if (
        this.props.quoteEstimatorInfo?.productRecords &&
        this.props.quoteEstimatorInfo?.productRecords.length > 0
      ) {
        if (this.props.pageType === "edit") {
          let communicationPlatformList = [];
          let communicationPlatforms = [];
          let filteredCommunicationPlatforms = [];
          let selectedServiceConditions = "";
          await this.props.quoteEstimatorInfo?.productRecords.forEach((rec) => {
            if (rec.productId === item.productId) {
              selectedServiceConditions = rec.conditions;
              if (rec.communicationPlatforms) {
                rec.communicationPlatforms.forEach((platform) => {
                  communicationPlatforms.push({
                    key: platform.name,
                    text: platform.name,
                  });
                });
                planTypes = rec.planTypes;
                rec.planTypes.forEach((planType) => {
                  if (
                    planType.planName?.toLowerCase().trim() ===
                    item.connection?.toLowerCase().trim()
                  ) {
                    connectionList.push({
                      key: planType.planName,
                      text: planType.planName,
                    });
                  } else {
                    connectionList.push({
                      key: planType.planName,
                      text: planType.planName,
                    });
                  }
                });
              }
            }
          });
          let serviceAndConnectionRecords = [];
          await this.props.quoteEstimatorInfo.serviceAndConnectionRecords.forEach(
            (rec) => {
              if (rec.productId === item.productId) {
                rec.planTypes = planTypes;
                serviceAndConnectionRecords.push({ ...rec });
              } else {
                serviceAndConnectionRecords.push(rec);
              }
            }
          );
          await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
            this.props.quoteEstimatorInfo,
            serviceAndConnectionRecords
          );
          communicationPlatforms = Array.from(new Set(communicationPlatforms));
          communicationPlatformList = _.orderBy(
            communicationPlatforms,
            ["key"],
            "asc"
          );
          await this.setStateAsync({
            communicationPlatformList: communicationPlatformList,
            connectionList: connectionList,
            selectedServiceConditions: selectedServiceConditions,
          });
          await this.handleAddOrEditProductAndServicesModalOpen(item);
          if (filteredCommunicationPlatforms.length === 0) {
            await this.setStateAsync({ platformDisable: true });
          } else {
            await this.setStateAsync({ platformDisable: false });
          }
        }
      }
      await this.setStateAsync({ editedServiceRecord: null });
      await this.setStateAsync({ isHostEnabled: true });
      if (this.props.pageType !== "edit") {
        let communicationPlatformList = [];
        let communicationPlatforms = [];
        let filteredCommunicationPlatforms = [];
        let selectedServiceConditions = "";
        let availableProductStatus = false;
        let productService = this.props.quoteEstimatorInfo?.productRecords.find(
          (rec) =>
            rec.productId === item.productId &&
            rec.availabilityName.toLowerCase() ===
              item.availabilityName.toLowerCase()
        );
        if (productService) {
          let planTypes = productService.planTypes.find(
            (rec) =>
              rec.planName?.toLowerCase() === item.connection?.toLowerCase()
          );
          if (planTypes) {
            selectedServiceConditions = productService.conditions;
            if (productService.communicationPlatforms) {
              productService.communicationPlatforms.forEach((platform) => {
                communicationPlatforms.push({
                  key: platform.name,
                  text: platform.name,
                });
                filteredCommunicationPlatforms.push(platform.name);
              });
            }
            availableProductStatus = false;
          } else {
            let errorMessage = {
              error: "notification.error.productIsNotAvailable",
            };
            this.setStateAsync({
              isProductErrorMessage: true,
              isServiceDisabled: true,
              quoteServiceSubmitStatus:
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
              quoteServiceError: errorMessage,
            });
            availableProductStatus = true;
          }
        } else {
          let errorMessage = {
            error: "notification.error.productIsNotAvailable",
          };
          this.setStateAsync({
            isProductErrorMessage: true,
            isServiceDisabled: true,
            quoteServiceSubmitStatus:
              OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
            quoteServiceError: errorMessage,
          });
          availableProductStatus = true;
        }

        communicationPlatforms = Array.from(new Set(communicationPlatforms));
        communicationPlatformList = _.orderBy(
          communicationPlatforms,
          ["key"],
          "asc"
        );
        await this.setStateAsync({
          communicationPlatformList: communicationPlatformList,
          connectionList: connectionList,
          selectedServiceConditions: selectedServiceConditions,
        });
        if (availableProductStatus === false) {
          await this.handleAddOrEditProductAndServicesModalOpen(item);
        } else {
          await this.handleQuoteEditWarningDeleteDialogOpen(item);
        }
        if (filteredCommunicationPlatforms.length === 0) {
          await this.setStateAsync({ platformDisable: true });
        } else {
          await this.setStateAsync({ platformDisable: false });
        }
        if (item.planTypes) {
          await item.planTypes.forEach(async (planType) => {
            if (
              planType.planName?.toLowerCase().trim() ===
              item.connection?.toLowerCase().trim()
            ) {
              connectionList.push({
                key: planType.planName,
                text: planType.planName,
              });
            } else {
              connectionList.push({
                key: planType.planName,
                text: planType.planName,
              });
            }
          });
          if (availableProductStatus === false) {
            await this.handleAddOrEditProductAndServicesModalOpen(item);
          } else {
            await this.handleQuoteEditWarningDeleteDialogOpen(item);
          }
        }
      }
      await this.setStateAsync({ connectionList: connectionList });
      await this.setStateAsync({
        editedServiceRecord: item,
        isServiceDisabled: false,
        selectedCountry: item.selectedCountry,
      });
    }
  };

  handleUserFormFieldBlur = async (e) => {
    if (e?.target) {
      await this.validateUserFormField(e?.target.id);
    }
  };

  setUserFormFieldValue = async (fieldName, fieldValue) => {
    if (fieldName && this.state.quoteUserFormInputFields) {
      await this.setStateAsync({
        quoteUserFormInputFields: {
          ...this.state.quoteUserFormInputFields,
          [fieldName]: {
            ...this.state.quoteUserFormInputFields[fieldName],
            value: fieldValue,
          },
        },
      });
    }
  };

  initQuoteUserFormInputFields = () => {
    let collectionObject = {};
    return QuoteUserFormFields(this._intl_ns_oc_quote, collectionObject);
  };

  clearAlert = async () => {
    await this.setStateAsync({
      isProductErrorMessage: false,
      quoteServiceError: null,
      quoteServiceSubmitStatus: null,
    });
  };

  handleUserFormFieldChange = async (e) => {
    if (e && e.target) {
      if (e.target.id === "phone") {
        const regExp = /[0-9]*$/;
        if (regExp.test(e.target.value) === true) {
          e.target.value = e.target.value.replace(/\D/g, "");
        }
        await this.setUserFormFieldValue(e.target.id, e.target.value);
        await this.props.setQuoteEstimatorInfoQuoteSubscriberDetails(
          this.props.quoteEstimatorInfo,
          {
            ...this.props.quoteEstimatorInfo?.subscriberDetails,
            [e.target.id]: e.target.value,
          }
        );
      } else {
        await this.setUserFormFieldValue(e.target.id, e.target.value);
        await this.props.setQuoteEstimatorInfoQuoteSubscriberDetails(
          this.props.quoteEstimatorInfo,
          {
            ...this.props.quoteEstimatorInfo?.subscriberDetails,
            [e.target.id]: e.target.value,
          }
        );
      }
      await this.setStateAsync({ isUserFormFilled: this.isUserFormFilled() });
      const formFilled = this.isUserFormFilled();
    }

    await this.props.setPageExit(true);
    await this.clearAlert();
  };

  fetchQuotesOrdersList = async (languageCode, Keyword, extractType) => {
    await this.setStateAsync({ isPageDataFetched: false });
    await getQuotesOrdersSearch(
      languageCode,
      Keyword,
      extractType,
      this._cancelToken
    )
      .then((res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records && res.data.records.length > 0) {
            let records = res.data.records.filter(
              (rec) =>
                rec.processStatus?.toLowerCase() ===
                  OperatorConnectConstants.PROCESS_STATUS.QUOTE_CREATE.toLowerCase() ||
                rec.processStatus?.toLowerCase() ===
                  OperatorConnectConstants.PROCESS_STATUS.SUBSCRIBER_CREATE.toLowerCase()
            );

            if (records.length > 0) {
              this.setStateAsync({
                isPageDataFetched: true,
                isProductFieldsEnabled: false,
                isProductQuoteSearch: true,
                isSaveQuoteDialogHidden: false,
                quotesRecords: res.data.records[0],
              });
            } else {
              this.setStateAsync({
                isPageDataFetched: true,
                isProductFieldsEnabled: true,
                isProductQuoteSearch: false,
                isSaveQuoteDialogHidden: false,
                quotesRecords: null,
              });
            }
          } else {
            this.setStateAsync({
              isPageDataFetched: true,
              isProductFieldsEnabled: true,
              isProductQuoteSearch: false,
              isSaveQuoteDialogHidden: false,
              quotesRecords: null,
            });
          }
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isPageDataFetched: true });
        await manageError(err, this.props.history);
      });
  };

  handleQuoteProductDetails = async (item, actionStatus) => {
    if (item) {
      await this.setStateAsync({ isPageDataFetched: false });
      await this.getQuoteDetails(item.quoteId);
      if (actionStatus === "viewQuote") {
        let orderEstimatorRecords = await this.setProductQuote(
          this.state.quoteProductDetails
        );
        await this.setStateAsync({
          isPageDataFetched: true,
          existingQuoteProductDetails: orderEstimatorRecords,
          viewExistingOrderDetails: true,
        });
        await this.handleQuoteProductPreview();
      } else {
        await this.setProductQuoteSubmit(this.state.quoteProductDetails);
        await this.handleQuoteProductSubmit(this.state.quoteProductDetails);
      }
      await this.setStateAsync({ isPageDataFetched: true });
    }
  };

  handleQuoteProductPreview = async () => {
    await this.setStateAsync({ previewStatus: "viewQuote" });
    await this.setStateAsync({ isPreviewModalOpen: true });
  };

  handleQuoteProductSubmit = async (item) => {
    if (
      item.processStatus?.toLowerCase() ===
      OperatorConnectConstants.PROCESS_STATUS.QUOTE_CREATE.toLowerCase()
    ) {
      await this.props.setQuoteProgressPage(
        OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
      );
    }
    if (
      item.processStatus?.toLowerCase() ===
      OperatorConnectConstants.PROCESS_STATUS.SUBSCRIBER_CREATE.toLowerCase()
    ) {
      await this.props.setQuoteProgressPage(
        OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
      );
    }
  };

  getQuoteDetails = async (quoteId) => {
    await getQuote(
      this.props.selectedLanguageData?.key,
      quoteId,
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ quoteProductDetails: res.data.result });
          await this.setStateAsync({ isPageDataFetched: true });
        } else {
          await this.setStateAsync({ isPageDataFetched: true });
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isPageDataFetched: true });
        await manageError(err, this.props.history);
      });
  };

  getOrderEstimatorRecords = async (productRecords) => {
    let productList = [];
    let orderEstimatorRecords = [];

    await productRecords.forEach((item) => {
      item.selectedCountry = item.availabilityName;
      item.connection = item.planName.trim();
      productList.push({ ...item });
      let nrcSellingPrice = 0;
      let mrcSellingPrice = 0;
      let pricing = [];
      item.pricing?.forEach(async (price, index) => {
        if (
          price.chargeType?.toLowerCase().trim() ===
          OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
        ) {
          mrcSellingPrice = this.handleCalculateDiscount(
            parseFloat(price?.retailPrice ?? "0").toFixed(2),
            parseFloat(0).toFixed(2)
          );
          pricing.push({
            chargeType: price.chargeType,
            pricingId: price.pricingId,
            chargeName: price.chargeName,
            maximumPrice: price.maximumPrice,
            sellingPrice: mrcSellingPrice,
            discountPercent: parseFloat(0),
            mrcPartnerBuyingPrice: price?.buyingPrice,
          });
        } else if (
          price.chargeType?.toLowerCase().trim() ===
          OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
        ) {
          nrcSellingPrice = this.handleCalculateDiscount(
            parseFloat(price?.retailPrice ?? "0").toFixed(2),
            parseFloat(0).toFixed(2)
          );
          pricing.push({
            chargeType: price.chargeType,
            chargeName: price.chargeName,
            pricingId: price.pricingId,
            maximumPrice: price.maximumPrice,
            sellingPrice: nrcSellingPrice,
            discountPercent: parseFloat(0),
            nrcPartnerBuyingPrice: price?.buyingPrice,
          });
        }
      });
      orderEstimatorRecords.push({ ...item, pricing: pricing });
    });

    return { productList, orderEstimatorRecords };
  };

  setProductQuote = async (result) => {
    let quoteEstimatorInfo = null;
    let allServiceRecords = [];
    let infoSubscriberQuoteRecords = [];
    let infoServiceAndConnectionRecords = [];
    let infoServiceAndConnectionCost = null;
    let infoQuoteSubscriberDetails = null;

    if (result.products && result.products.length > 0) {
      result.products.forEach((item) => {
        item.selectedCountry = item?.availabilityName;
        item.connection = item?.planName?.trim();
        let nrcSellingPrice = 0;
        let mrcSellingPrice = 0;
        let pricing = [];
        if (item.pricing && item.pricing.length > 0) {
          item.pricing?.forEach(async (price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              mrcSellingPrice = this.handleCalculateDiscount(
                parseFloat(price?.retailPrice ?? "0").toFixed(2),
                parseFloat(0).toFixed(2)
              );
              pricing.push({
                chargeType: price.chargeType,
                pricingId: price.pricingId,
                chargeName: price.chargeName,
                maximumPrice: price.maximumPrice,
                sellingPrice: mrcSellingPrice,
                discountPercent: parseFloat(0),
                mrcPartnerBuyingPrice: price?.buyingPrice,
              });
            } else if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              nrcSellingPrice = this.handleCalculateDiscount(
                parseFloat(price?.retailPrice ?? "0").toFixed(2),
                parseFloat(0).toFixed(2)
              );
              pricing.push({
                chargeType: price.chargeType,
                chargeName: price.chargeName,
                pricingId: price.pricingId,
                maximumPrice: price.maximumPrice,
                sellingPrice: nrcSellingPrice,
                discountPercent: parseFloat(0),
                nrcPartnerBuyingPrice: price?.buyingPrice,
              });
            }
          });
        }
        infoServiceAndConnectionRecords.push({ ...item, pricing: pricing });
      });
    }

    if (
      infoServiceAndConnectionRecords &&
      infoServiceAndConnectionRecords.length > 0
    ) {
      let nrcSubTotal = 0;
      let mrcSubTotal = 0;
      let total = 0;
      infoServiceAndConnectionRecords.forEach(async (obj, index) => {
        let mrcCount = 0;
        let nrcCount = 0;
        let nrcretailPrice = 0;
        let mrcretailPrice = 0;
        let mrcDidCost = null;
        if (obj.pricing && obj.pricing.length > 0) {
          obj.pricing.forEach((price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              if (price?.chargeName?.toLowerCase()?.trim() === "did charges") {
                mrcDidCost = price.retailPrice;
              }
              mrcretailPrice = price.retailPrice;
              mrcCount =
                mrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
            } else if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              nrcretailPrice = price.retailPrice;
              nrcCount =
                nrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
            }
          });
        }
        nrcSubTotal = nrcSubTotal + nrcCount;
        mrcSubTotal = mrcSubTotal + mrcCount;
        allServiceRecords.push({
          ...obj,
          id: index,
          mrc: mrcCount.toString(),
          nrc: nrcCount.toString(),
          nrcretailPrice: nrcretailPrice?.toString(),
          mrcretailPrice: mrcretailPrice?.toString(),
          mrcDidCost: mrcDidCost?.toString(),
        });
      });
      total = total + nrcSubTotal + mrcSubTotal;
      infoServiceAndConnectionCost = {
        nrcSubTotal: nrcSubTotal?.toString(),
        mrcSubTotal: mrcSubTotal?.toString(),
        total: total?.toString(),
      };

      infoQuoteSubscriberDetails = {
        quoteName: result.customerName,
        email: result.customerEmail,
        phone: result.customerPhone,
      };
      quoteEstimatorInfo = {
        ...quoteEstimatorInfo,
        productRecords: result.products,
        serviceAndConnectionRecords: [...allServiceRecords],
        serviceNrcSubTotalCost: infoServiceAndConnectionCost?.nrcSubTotal,
        serviceMrcSubTotalCost: infoServiceAndConnectionCost?.mrcSubTotal,
        serviceTotalCost: infoServiceAndConnectionCost?.total,
        subscriberDetails: infoQuoteSubscriberDetails,
      };
    }

    let quoteRecord = { ...result, products: result.products };
    let resourceRecords = [];
    let onboardServiceDocumentRecords = [];
    if (
      quoteRecord &&
      quoteRecord.resources &&
      quoteRecord.resources.length > 0
    ) {
      quoteRecord.resources.forEach((rec) => {
        resourceRecords.push(rec);
      });
    }
    if (
      quoteRecord &&
      quoteRecord.evidences &&
      quoteRecord.evidences.length > 0
    ) {
      const groups = _.groupBy(quoteRecord.evidences, "category");
      Object.entries(groups).forEach(([category, documents]) => {
        onboardServiceDocumentRecords.push({
          category: category,
          documents: documents,
        });
      });
    }
    let nrcSubTotal = 0;
    let mrcSubTotal = 0;
    let total = 0;
    if (
      quoteRecord &&
      quoteRecord.products &&
      quoteRecord.products.length > 0
    ) {
      quoteRecord.products.forEach((obj, index) => {
        let mrcCount = 0;
        let nrcCount = 0;
        let nrcPartnerCost = 0;
        let mrcPartnerCost = 0;
        let mrcDiscount = 0;
        let nrcDiscount = 0;
        if (obj.pricing && obj.pricing.length > 0) {
          obj.pricing.forEach((price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              nrcCount =
                nrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
              nrcPartnerCost = price?.retailPrice ?? "0";
            }
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              mrcCount =
                mrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
              mrcPartnerCost = price?.retailPrice ?? "0";
            }
          });
        }

        nrcSubTotal = nrcSubTotal + nrcCount;
        mrcSubTotal = mrcSubTotal + mrcCount;
        allServiceRecords.push({
          ...obj,
          connection: obj?.planName,
          nrcPartnerCost: nrcPartnerCost,
          mrcPartnerCost: mrcPartnerCost,
          nrcDiscount: nrcDiscount,
          mrcDiscount: mrcDiscount,
          id: index,
          mrc: mrcCount?.toString(),
          nrc: nrcCount?.toString(),
        });
      });

      total = total + nrcSubTotal + mrcSubTotal;

      infoSubscriberQuoteRecords = {
        ...quoteRecord,
        products: allServiceRecords,
        nrcSubTotal: nrcSubTotal,
        mrcSubTotal: mrcSubTotal,
        totalCost: total,
        resourceRecords: resourceRecords,
        onboardServiceDocumentRecords: onboardServiceDocumentRecords,
      };

      quoteEstimatorInfo = {
        ...quoteEstimatorInfo,
        serviceNrcSubTotalCost: infoServiceAndConnectionCost?.nrcSubTotal,
        serviceMrcSubTotalCost: infoServiceAndConnectionCost?.mrcSubTotal,
        serviceTotalCost: infoServiceAndConnectionCost?.total,
        subscriberQuoteRecords: infoSubscriberQuoteRecords,
        subscriberDetails: infoQuoteSubscriberDetails,
        resourceRecords: resourceRecords,
        onboardServiceDocumentRecords: onboardServiceDocumentRecords,
      };
    }

    return quoteEstimatorInfo;
  };

  setProductQuoteSubmit = async (result) => {
    let productList = [];
    let orderEstimatorRecords = [];
    let allServiceRecords = [];
    await this.props.setQuoteEstimatorInfoProductRecords(
      this.props.quoteEstimatorInfo,
      result.products
    );

    if (
      this.props.quoteEstimatorInfo?.productRecords &&
      this.props.quoteEstimatorInfo?.productRecords.length > 0
    ) {
      const orderData = await this.getOrderEstimatorRecords(
        this.props.quoteEstimatorInfo?.productRecords
      );
      productList = (await orderData).productList;
      orderEstimatorRecords = orderData.orderEstimatorRecords;
    }

    await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
      this.props.quoteEstimatorInfo,
      orderEstimatorRecords
    );

    if (
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords &&
      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
    ) {
      let nrcSubTotal = 0;
      let mrcSubTotal = 0;
      let total = 0;
      await this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.forEach(
        async (obj, index) => {
          let mrcCount = 0;
          let nrcCount = 0;
          let nrcretailPrice = 0;
          let mrcretailPrice = 0;
          let mrcDidCost = null;
          if (obj.pricing && obj.pricing.length > 0) {
            obj.pricing.forEach((price) => {
              if (
                price.chargeType?.toLowerCase().trim() ===
                OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
              ) {
                if (
                  price?.chargeName?.toLowerCase()?.trim() === "did charges"
                ) {
                  mrcDidCost = price.retailPrice;
                }
                mrcretailPrice = price.retailPrice;
                mrcCount =
                  mrcCount +
                  parseFloat(obj?.quantity ?? "0") *
                    parseFloat(price?.sellingPrice ?? "0");
              } else if (
                price.chargeType?.toLowerCase().trim() ===
                OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
              ) {
                nrcretailPrice = price.retailPrice;
                nrcCount =
                  nrcCount +
                  parseFloat(obj?.quantity ?? "0") *
                    parseFloat(price?.sellingPrice ?? "0");
              }
            });
          }
          nrcSubTotal = nrcSubTotal + nrcCount;
          mrcSubTotal = mrcSubTotal + mrcCount;
          allServiceRecords.push({
            ...obj,
            id: index,
            mrc: mrcCount.toString(),
            nrc: nrcCount.toString(),
            nrcretailPrice: nrcretailPrice?.toString(),
            mrcretailPrice: mrcretailPrice?.toString(),
            mrcDidCost: mrcDidCost?.toString(),
          });
        }
      );
      total = total + nrcSubTotal + mrcSubTotal;
      await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
        this.props.quoteEstimatorInfo,
        [...allServiceRecords]
      );
      await this.props.setQuoteEstimatorInfoServiceAndConnectionCost(
        this.props.quoteEstimatorInfo,
        nrcSubTotal.toString(),
        mrcSubTotal.toString(),
        total.toString()
      );

      let customerDetailsObject = {
        quoteName: result.customerName,
        email: result.customerEmail,
        phone: result.customerPhone,
      };
      await this.props.setQuoteEstimatorInfoQuoteSubscriberDetails(
        this.props.quoteEstimatorInfo,
        customerDetailsObject
      );
    }

    let quoteRecord = { ...result, products: productList };
    let resourceRecords = [];
    let onboardServiceDocumentRecords = [];
    if (
      quoteRecord &&
      quoteRecord.resources &&
      quoteRecord.resources.length > 0
    ) {
      quoteRecord.resources.forEach((rec) => {
        resourceRecords.push(rec);
      });
    }
    if (
      quoteRecord &&
      quoteRecord.evidences &&
      quoteRecord.evidences.length > 0
    ) {
      const groups = _.groupBy(quoteRecord.evidences, "category");
      Object.entries(groups).forEach(([category, documents]) => {
        onboardServiceDocumentRecords.push({
          category: category,
          documents: documents,
        });
      });
    }
    let nrcSubTotal = 0;
    let mrcSubTotal = 0;
    let total = 0;
    if (
      quoteRecord &&
      quoteRecord.products &&
      quoteRecord.products.length > 0
    ) {
      await quoteRecord.products.forEach((obj, index) => {
        let mrcCount = 0;
        let nrcCount = 0;
        let nrcPartnerCost = 0;
        let mrcPartnerCost = 0;
        let mrcDiscount = 0;
        let nrcDiscount = 0;
        obj.pricing.forEach((price) => {
          if (
            price.chargeType?.toLowerCase().trim() ===
            OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
          ) {
            nrcCount =
              nrcCount +
              parseFloat(obj?.quantity ?? "0") *
                parseFloat(price?.sellingPrice ?? "0");
            nrcPartnerCost = price?.retailPrice ?? "0";
          }
          if (
            price.chargeType?.toLowerCase().trim() ===
            OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
          ) {
            mrcCount =
              mrcCount +
              parseFloat(obj?.quantity ?? "0") *
                parseFloat(price?.sellingPrice ?? "0");
            mrcPartnerCost = price?.retailPrice ?? "0";
          }
        });

        nrcSubTotal = nrcSubTotal + nrcCount;
        mrcSubTotal = mrcSubTotal + mrcCount;
        allServiceRecords.push({
          ...obj,
          connection: obj?.planName,
          nrcPartnerCost: nrcPartnerCost,
          mrcPartnerCost: mrcPartnerCost,
          nrcDiscount: nrcDiscount,
          mrcDiscount: mrcDiscount,
          id: index,
          mrc: mrcCount.toString(),
          nrc: nrcCount.toString(),
        });
      });
      total = total + nrcSubTotal + mrcSubTotal;
      await this.setStateAsync({
        quoteRecord: {
          ...quoteRecord,
          products: allServiceRecords,
          nrcSubTotal: nrcSubTotal,
          mrcSubTotal: mrcSubTotal,
          totalCost: total,
          resourceRecords: resourceRecords,
          onboardServiceDocumentRecords: onboardServiceDocumentRecords,
        },
      });
      await this.props.setQuoteEstimatorInfoSubscriberQuoteRecords(
        this.props.quoteEstimatorInfo,
        this.state.quoteRecord
      );
      await this.props.setQuoteEstimatorInfoResourceRecords(
        this.props.quoteEstimatorInfo,
        resourceRecords
      );
      await this.props.setQuoteEstimatorInfoOnboardServiceDocumentRecords(
        this.props.quoteEstimatorInfo,
        onboardServiceDocumentRecords
      );
    }
  };

  closeDeleteConfirm = async () => {
    await this.setStateAsync({ isServiceDeleteDialogHidden: true });
  };

  renderQuoteDeleteDialog = () => {
    if (this.state.isServiceDeleteDialogHidden === false) {
      return (
        <CustomDialog
          hidden={this.state.isServiceDeleteDialogHidden}
          title={IntlUtil.getText(
            this._intl_ns_oc_quote,
            "notification.warning.serviceAndConnection"
          )}
          modalPropsClassName="quote-dialog-wrapper"
          onPrimaryButtonClick={this.handleDeleteServiceRecord}
          onDefaultButtonClick={this.closeDeleteConfirm}
        >
          {
            <>
              <Text>
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "notification.warning.deleteServiceTextPrefix"
                )}{" "}
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "notification.warning.deleteServiceTextSuffix"
                )}
              </Text>
            </>
          }
        </CustomDialog>
      );
    }
  };

  closeSaveConfirm = async () => {
    await this.setStateAsync({
      isSaveQuoteDialogHidden: true,
      isProductQuoteSearch: false,
    });
  };

  handleDocumentsAndQuoteForm = async () => {
    await this.setStateAsync({
      isSaveQuoteDialogHidden: true,
      isPreviewModalOpen: false,
      refreshReCaptcha: !this.state.refreshReCaptcha,
    });
    await this.setStateAsync({ isPageDataFetched: false });
    await this.handleQuoteFormSubmit();
    await this.setStateAsync({ isPageDataFetched: true });
  };

  handleDocumentsAndQuoteView = async () => {
    await this.setStateAsync({
      isSaveQuoteDialogHidden: true,
      isPreviewModalOpen: false,
    });
    await this.setStateAsync({ isPageDataFetched: false });
    await this.fetchDocuments(
      this.props.quoteEstimatorInfo.serviceAndConnectionRecords
    );
    await this.handleQuoteFormSubmit();
    await this.setStateAsync({ isPageDataFetched: true });
  };

  handleQuoteFormSubmit = async () => {
    if (
      this.props.quoteEstimatorInfo &&
      this.props.quoteEstimatorInfo.serviceAndConnectionRecords &&
      this.props.quoteEstimatorInfo.serviceAndConnectionRecords.length > 0
    ) {
      PageUtil.scrollToTop();
      let products = [];
      let documents = [];
      let resources = [];
      await this.props.quoteEstimatorInfo.serviceAndConnectionRecords.forEach(
        (service) => {
          let serviceObj = {
            productId: service.productId,
            availabilityName: service.availabilityName,
            availabilityType: "country",
            planId: service.planId,
            communicationPlatform: service?.communicationPlatform ?? null,
            quantity: service.quantity,
            forms: service.forms,
            pricing: service.pricing,
          };
          products.push(serviceObj);
        }
      );

      let productList = {
        languageCode: this.props.selectedLanguageData.key,
        customerName:
          this.props.quoteEstimatorInfo &&
          this.props.quoteEstimatorInfo.subscriberDetails &&
          this.props.quoteEstimatorInfo.subscriberDetails.quoteName,
        customerEmail:
          this.props.quoteEstimatorInfo &&
          this.props.quoteEstimatorInfo.subscriberDetails &&
          this.props.quoteEstimatorInfo.subscriberDetails.email,
        customerPhone:
          this.props.quoteEstimatorInfo &&
          this.props.quoteEstimatorInfo.subscriberDetails &&
          this.props.quoteEstimatorInfo.subscriberDetails.phone,
        products: products,
        // evidences: documents,
        // resources: resources,
        quoteNotes: "",
        headerNotes: null,
        footerNotes: null,
        companyLogo: null,
        captchaToken: this.state.captchaToken,
      };
      await saveQuote(productList, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.result
          ) {
            let customerDetailsObject = {
              quoteName: res.data.result.customerName,
              email: res.data.result.customerEmail,
              phone: res.data.result.customerPhone,
              quoteNumber: res.data.result.quoteNumber,
              quoteId: res.data.result.quoteId,
              partnerId: res.data.result.partnerId,
              subscriberAccount: null,
            };
            await this.props.setQuoteEstimatorInfoQuoteSubscriberDetails(
              this.props.quoteEstimatorInfo,
              customerDetailsObject
            );
            await this.props.setQuoteEstimatorInfoSubscriberQuoteRecords(
              this.props.quoteEstimatorInfo,
              res.data.result
            );
            let resourceRecords = [];
            let onboardServiceDocumentRecords = [];
            if (
              res.data.result &&
              res.data.result.resources &&
              res.data.result.resources.length > 0
            ) {
              res.data.result.resources.forEach((rec) => {
                resourceRecords.push(rec);
              });
            }
            if (
              res.data.result &&
              res.data.result.evidences &&
              res.data.result.evidences.length > 0
            ) {
              const groups = _.groupBy(res.data.result.evidences, "category");
              Object.entries(groups).forEach(([category, documents]) => {
                onboardServiceDocumentRecords.push({
                  category: category,
                  documents: documents,
                });
              });
            }

            await this.props.setQuoteEstimatorInfoResourceRecords(
              this.props.quoteEstimatorInfo,
              resourceRecords
            );
            await this.props.setQuoteEstimatorInfoOnboardServiceDocumentRecords(
              this.props.quoteEstimatorInfo,
              onboardServiceDocumentRecords
            );
            await this.props.setQuoteProgressPage(
              OperatorConnectConstants.QUOTE.PROGRESS.CREATE_ACCOUNT
            );
          } else {
            await this.setStateAsync({ isPageDataFetched: true });
          }
        })
        .catch(async (err) => {
          if (
            err &&
            err.status &&
            err.status === AppConfigProps.httpStatusCode.badRequest
          ) {
            if (err.data && err.data.errors) {
              await this.setStateAsync({ quoteServiceError: err.data.errors });
            }
            await this.setStateAsync({
              quoteServiceSubmitStatus:
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
              isPageDataFetched: true,
            });
          } else {
            await this.setStateAsync({ isPageDataFetched: true });
            await manageError(err, this.props.history);
            await this.props.setPageExit(false);
          }
        });
    }
  };

  handleSaveQuoteDialog = async (value) => {
    if (
      this.props.quoteEstimatorInfo &&
      this.props.quoteEstimatorInfo.serviceAndConnectionRecords &&
      this.props.quoteEstimatorInfo.serviceAndConnectionRecords.length > 0
    ) {
      await this.setStateAsync({
        refreshReCaptcha: !this.state.refreshReCaptcha,
      });
      await this.setProductDetails("Submit Quote");
      this.handleSubscriberDetails();
      await this.setStateAsync({
        processType: value,
      });
    } else {
      await this.setStateAsync({
        processType: null,
      });
    }
  };

  renderQuoteSaveDialog = () => {
    if (this.state.isSaveQuoteDialogHidden === false) {
      return (
        <CustomDialog
          hidden={this.state.isSaveQuoteDialogHidden}
          title={
            this.state.isProductQuoteSearch
              ? IntlUtil.getText(this._intl_ns_oc_quote, "content.confirmation")
              : this.state.processType === "quoteSave"
              ? IntlUtil.getText(this._intl_ns_oc_quote, "content.submitQuote")
              : IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "notification.warning.orderCreate"
                )
          }
          showCloseButton={this.state.isProductQuoteSearch ? true : null}
          onDismiss={this.closeSaveConfirm}
          modalPropsClassName="quote-dialog-wrapper quote-save-confirm-dialog"
          onPrimaryButtonClick={
            this.state.isProductQuoteSearch
              ? () => {
                  this.handleQuoteProductDetails(
                    this.state.quotesRecords,
                    "viewQuote"
                  );
                }
              : this.handleDocumentsAndQuoteForm
          }
          onDefaultButtonClick={
            this.state.isProductQuoteSearch
              ? this.handleDocumentsAndQuoteForm
              : this.closeSaveConfirm
          }
          defaultButtonId={this.props.reCaptchaButtonId}
          primaryButtonId={this.props.reCaptchaButtonId}
          primaryButtonName={
            this.state.isProductQuoteSearch
              ? IntlUtil.getText(this._intl_ns_oc_quote, "content.viewOldQuote")
              : null
          }
          defaultButtonName={
            this.state.isProductQuoteSearch
              ? IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.createNewQuote"
                )
              : null
          }
        >
          {
            <>
              {this.state.isProductQuoteSearch ? (
                <div>{this.previousOrderDetails()}</div>
              ) : (
                <>
                  {" "}
                  {this.state.processType === "quoteSave" ? (
                    <Text>
                      {IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        "notification.warning.quoteSaveTextPrefix"
                      )}
                      {"?"}
                    </Text>
                  ) : (
                    <Text>
                      {IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        "notification.warning.orderCreateTextPrefix"
                      )}
                      {"?"}
                    </Text>
                  )}
                </>
              )}
            </>
          }
        </CustomDialog>
      );
    }
  };

  handleQuoteEditWarningDeleteDialogOpen = async (item) => {
    await this.setStateAsync({
      deleteServiceRecord: item,
      isQuoteEditWarningDialogHidden: false,
    });
  };

  handleQuoteEditWarningDeleteDialogClose = async () => {
    await this.setStateAsync({
      isQuoteEditWarningDialogHidden: true,
      deleteServiceRecord: null,
    });
  };

  renderQuoteEditWarningDeleteDialog = () => {
    if (this.state.isQuoteEditWarningDialogHidden === false) {
      return (
        <CustomDialog
          hidden={this.state.isQuoteEditWarningDialogHidden}
          title={IntlUtil.getText(
            this._intl_ns_oc_quote,
            "content.updateProductOrService"
          )}
          buttonType={OperatorConnectConstants.BUTTON_TYPE.SINGLE}
          modalPropsClassName="quote-dialog-wrapper"
          onDefaultButtonClick={this.handleDeleteServiceRecord}
          defaultButtonName={IntlUtil.getText(
            this._intl_ns_oc_common,
            "content.ok"
          )}
        >
          <Text>
            {IntlUtil.getText(
              this._intl_ns_oc_quote,
              this.state.quoteServiceError?.error
            )}
          </Text>
        </CustomDialog>
      );
    }
  };

  handleDeleteServiceRecord = async () => {
    let deleteRecord = [];
    let nrcSubTotal = 0;
    let mrcSubTotal = 0;
    let total = 0;
    if (this.state.deleteServiceRecord) {
      await this.setStateAsync({
        isServiceDeleteDialogHidden: true,
        isFormFilled: false,
      });
      await this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.forEach(
        (obj) => {
          if (this.state.deleteServiceRecord?.id !== obj?.id) {
            if (obj.pricing && obj.pricing.length > 0) {
              let mrcCount = 0;
              let nrcCount = 0;
              let nrcretailPrice = 0;
              let mrcretailPrice = 0;

              obj.pricing.forEach((price) => {
                if (
                  price.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                ) {
                  mrcretailPrice = price.retailPrice;
                  mrcCount =
                    mrcCount +
                    parseFloat(obj?.quantity ?? "0") *
                      parseFloat(price?.sellingPrice ?? "0");
                } else if (
                  price.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                ) {
                  nrcretailPrice = price.retailPrice;
                  nrcCount =
                    nrcCount +
                    parseFloat(obj?.quantity ?? "0") *
                      parseFloat(price?.sellingPrice ?? "0");
                }
              });

              nrcSubTotal = nrcSubTotal + nrcCount;
              mrcSubTotal = mrcSubTotal + mrcCount;
              deleteRecord.push({
                ...obj,
                mrc: mrcCount.toString(),
                nrc: nrcCount.toString(),
                nrcretailPrice: nrcretailPrice,
                mrcretailPrice: mrcretailPrice,
              });
            }
          }
        }
      );
    }
    total = total + nrcSubTotal + mrcSubTotal;

    await this.props.setQuoteEstimatorInfoServiceAndConnectionRecords(
      this.props.quoteEstimatorInfo,
      deleteRecord
    );
    await this.props.setQuoteEstimatorInfoServiceAndConnectionCost(
      this.props.quoteEstimatorInfo,
      nrcSubTotal.toString(),
      mrcSubTotal.toString(),
      total.toString()
    );
    await this.setStateAsync({ deleteServiceRecord: null });
    await this.setStateAsync({
      // serviceFormInputFields: this.initServiceFormFields(),
      editedServiceRecord: null,
      isFormFilled: false,
      connectionList: [],
      isServiceDisabled: true,
      isCurrencyEnabled: true,
      selectedServiceConditions: null,
      activationFileRecords: [],
      portingFileRecords: [],
    });
    if (
      this.props.quoteEstimatorInfo &&
      (!this.props.quoteEstimatorInfo.serviceAndConnectionRecords ||
        this.props.quoteEstimatorInfo.serviceAndConnectionRecords.length === 0)
    ) {
      this.props.setPageExit(false);
      await this.props.resetQuoteEstimatorInfo(this.props.quoteEstimatorInfo);
      await this.props.setQuoteEstimatorInfoResourceRecords(
        this.props.quoteEstimatorInfo,
        []
      );
      await this.setStateAsync({
        isCurrencyEnabled: false,
      });
      await this.props.setQuoteEstimatorInfoOnboardServiceDocumentRecords(
        this.props.quoteEstimatorInfo,
        []
      );
    }
    await this.handleQuoteEditWarningDeleteDialogClose();
    await this.setStateAsync({ selectedCountry: "" });
    await this.setStateAsync({
      countryProductRecords: [],
    });
    await this.clearAlert();
  };

  handleCalculateDiscount = (amount, discount) => {
    let discountedAmount = 0;
    let disc = discount ? discount : 0;
    let amt = amount ? amount : 0;
    discountedAmount = amount - (amt * disc) / 100;
    return parseFloat(Number(discountedAmount).toFixed(2));
  };

  renderCustomerRepresentativeForm = () => {
    if (this.state.isCustomerRepresentative === true) {
      return (
        <CustomerRepresentativeForm
          {...this.props}
          websiteTemplate={this.state.websiteTemplate}
          captchaToken={this.state.captchaToken}
          quoteUserFormInputFields={this.state.quoteUserFormInputFields}
          intlNsCommon={this._intl_ns_oc_common}
          intlNsOCQuote={this._intl_ns_oc_quote}
          isCustomerRepresentative={this.state.isCustomerRepresentative}
          handleCustomerRepresentativeModalClose={() => {
            this.handleCustomerRepresentativeModalClose();
          }}
          handleRefreshReCaptcha={() => {
            this.setStateAsync({
              refreshReCaptcha: !this.state.refreshReCaptcha,
            });
          }}
        />
      );
    }
  };

  renderPreviewQuoteModal = () => {
    if (this.state.isPreviewModalOpen === true) {
      let documents = [];
      let onboardDocuments = null;
      let resourceRecords = null;

      if (this.state.viewExistingOrderDetails === true) {
        onboardDocuments =
          this.state.existingQuoteProductDetails?.onboardServiceDocumentRecords;
        resourceRecords =
          this.state.existingQuoteProductDetails?.resourceRecords;
      } else {
        onboardDocuments =
          this.props.quoteEstimatorInfo?.onboardServiceDocumentRecords;
        resourceRecords = this.props.quoteEstimatorInfo?.resourceRecords;
      }

      if (onboardDocuments && onboardDocuments.length > 0) {
        onboardDocuments.forEach((rec) => {
          if (rec.documents && rec.documents.length > 0) {
            rec.documents.forEach((doc) => {
              documents.push(rec);
            });
          }
        });
      }

      return (
        <>
          <Modal
            isOpen={this.state.isPreviewModalOpen}
            className={`quote-customize-modal-content ${modalStyles.container} `}
            layerProps={{ eventBubblingEnabled: true }}
          >
            <div className={modalStyles.body}>
              <div
                className={
                  modalStyles.header +
                  ` ${CustomStyles.getStyle(
                    this.state.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "primary-background-color"
                      : "secondary-background-color",
                    this.state.websiteTemplate
                  )}`
                }
              >
                <span className="m-l-10">
                  {IntlUtil.getText(
                    this._intl_ns_oc_quote,
                    "content.previewQuote"
                  )}
                </span>
              </div>
              <div className={modalStyles.content}>
                <div className="modal-frame-content-wrapper">
                  <div className="m-l-5 m-r-5 m-b-5">
                    <QuoteServiceTable
                      websiteTemplate={this.state.websiteTemplate}
                      history={this.props.history}
                      quoteType={this.state.selectedQuoteTypeKey}
                      isEditable={false}
                      intlNamespace={this._intl_ns_oc_quote}
                      showTitle={true}
                      existingQuoteProductDetails={
                        this.state.existingQuoteProductDetails
                          ?.serviceAndConnectionRecords
                      }
                      quotePreview={true}
                    />
                  </div>
                  <Grid fluid className="m-0 p-0">
                    <Row>
                      {this.state.isPageDataFetched === true ? (
                        <>
                          <Col xl={12} className="m-0 p-0">
                            {resourceRecords?.length > 0 ? (
                              <Row className={`m-t-10 m-l-5 m-r-5`}>
                                <Col xl={12} className="m-t-5 m-l-5">
                                  <span
                                    className={`m-t-5 ${
                                      modalStyles.modalContentLabel
                                    } ${CustomStyles.getStyle(
                                      this.state.websiteTemplate?.templateCode
                                        ?.toLowerCase()
                                        .trim() !==
                                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                        ? "text-fc-primary"
                                        : "text-fc-secondary",
                                      this.state.websiteTemplate
                                    )}`}
                                  >
                                    {IntlUtil.getText(
                                      this._intl_ns_oc_quote,
                                      "content.resources"
                                    )}
                                  </span>
                                  <ul
                                    className={`p-5 quote-create-list-wrapper ${CustomStyles.getStyle(
                                      "section-background",
                                      this.state.websiteTemplate
                                    )}`}
                                  >
                                    {resourceRecords?.length > 0 &&
                                      resourceRecords.map((service, index) => {
                                        return (
                                          <li key={`service-${index}`}>
                                            <span
                                              className={CustomStyles.getStyle(
                                                "text-link",
                                                this.state.websiteTemplate
                                              )}
                                            >
                                              <ChevronRightMedIcon className="m-r-5" />

                                              <span
                                                onClick={(e) =>
                                                  this.handleDocumentClick(
                                                    e,
                                                    service.resourceUrl
                                                  )
                                                }
                                              >
                                                {service.resourceTitle}
                                              </span>
                                            </span>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </Col>
                              </Row>
                            ) : null}
                            {onboardDocuments.length > 0 &&
                            documents.length > 0 ? (
                              <Row className="m-t-10 m-l-5 m-r-0">
                                <Col xl={12} className="m-t-5 m-l-0">
                                  <span
                                    className={`m-t-5 text-fw-semibold p-b-0 ${
                                      modalStyles.modalContentLabel
                                    } ${CustomStyles.getStyle(
                                      this.state.websiteTemplate?.templateCode
                                        ?.toLowerCase()
                                        .trim() !==
                                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                                        ? "text-fc-primary"
                                        : "text-fc-secondary",
                                      this.state.websiteTemplate
                                    )}
                                `}
                                  >
                                    {IntlUtil.getText(
                                      this._intl_ns_oc_quote,
                                      "content.requiredDocToOnboardService"
                                    )}
                                  </span>

                                  <ul className="p-5 bg-grey quote-create-list-wrapper">
                                    {onboardDocuments.map((onboard, index) => {
                                      return (
                                        <>
                                          {onboard &&
                                          onboard.documents &&
                                          onboard.documents.length > 0 ? (
                                            <>
                                              <li
                                                key={`country-${index}`}
                                                className="quote-header-text"
                                              >
                                                <span className="text-fw-semibold">
                                                  {onboard.category}
                                                </span>
                                              </li>
                                              {onboard.documents.map(
                                                (doc, index) => {
                                                  return (
                                                    <li
                                                      key={`onboard-${index}`}
                                                    >
                                                      <span
                                                        className={CustomStyles.getStyle(
                                                          "text-link",
                                                          this.state
                                                            .websiteTemplate
                                                        )}
                                                      >
                                                        <ChevronRightMedIcon className="m-r-5" />

                                                        <span
                                                          onClick={(e) =>
                                                            this.handleDocumentClick(
                                                              e,
                                                              doc.documentUrl
                                                            )
                                                          }
                                                        >
                                                          {doc.documentTitle}
                                                        </span>
                                                      </span>
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </ul>
                                </Col>
                              </Row>
                            ) : null}
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </Grid>
                </div>
                <div className="m-t-10 oc-quote-page-footer-actions">
                  <div className="modal-page-frame-separator"></div>

                  <DefaultButton
                    onClick={() => {
                      if (this.state.viewExistingOrderDetails === true) {
                        this.handleSavePDF(
                          this.state.existingQuoteProductDetails
                        );
                      } else {
                        this.handleSavePDF(this.props.quoteEstimatorInfo);
                      }
                    }}
                    iconProps={{ iconName: "Download" }}
                    className={`oc-quote-page-footer-button page-frame-button`}
                    disabled={
                      this.props.quoteEstimatorInfo
                        ?.serviceAndConnectionRecords === null ||
                      this.props.quoteEstimatorInfo
                        ?.serviceAndConnectionRecords === undefined ||
                      this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
                        .length === 0
                    }
                  >
                    {IntlUtil.getText(
                      this._intl_ns_oc_quote,
                      "content.downloadQuote"
                    )}
                  </DefaultButton>
                  {this.state.isProductQuoteSearch && (
                    <DefaultButton
                      className={`oc-quote-page-footer-button page-frame-button`}
                      onClick={() =>
                        this.handleQuoteProductDetails(
                          this.state.quotesRecords,
                          "submitPreviousQuote"
                        )
                      }
                    >
                      <div className="hide-icon"></div>
                      <span className="text-fw-semibold">
                      {IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        "content.submitOldQuote"
                      )}</span>
                    </DefaultButton>
                  )}

                  <DefaultButton
                    className={`oc-quote-page-footer-button page-frame-button`}
                    onClick={() =>
                      this.setStateAsync({
                        isPreviewModalOpen: false,
                        existingQuoteProductDetails: null,
                        viewExistingOrderDetails: false,
                      })
                    }
                  >
                    <div className="hide-icon"></div>
                    <span className="text-fw-semibold">{IntlUtil.getText(this._intl_ns_oc_quote, "content.close")}</span>
                  </DefaultButton>
                </div>
              </div>
            </div>
          </Modal>
        </>
      );
    }
  };

  renderAddUpdateModal = () => {
    if (this.state.isAddOrEditProductModalOpen === true) {
      return (
        <AddOrEditProductForm
          {...this.props}
          websiteTemplate={this.state.websiteTemplate}
          setPageExit={async (value) => await this.props.setPageExit(value)}
          platformDisable={this.state.platformDisable}
          editedServiceRecord={this.state.editedServiceRecord}
          isServiceDisabled={this.state.isServiceDisabled}
          connectionList={this.state.connectionList}
          productServices={this.state.productServices}
          selectedCountry={this.state.selectedCountry}
          isFormFilled={this.state.isFormFilled}
          currencyCode={this.state.currencyCode}
          countryProductRecords={this.state.countryProductRecords}
          selectedServiceConditions={this.state.selectedServiceConditions}
          communicationPlatformList={this.state.communicationPlatformList}
          isCurrencyEnabled={this.state.isCurrencyEnabled}
          countryList={this.state.countryList}
          isAddOrEditProductModalOpen={this.state.isAddOrEditProductModalOpen}
          quoteUserFormInputFields={this.state.quoteUserFormInputFields}
          intlNsCommon={this._intl_ns_oc_common}
          intlNsOCQuote={this._intl_ns_oc_quote}
          setProductFormState={async (productFormData) => {
            await this.setStateAsync({
              isCurrencyEnabled: productFormData?.isCurrencyEnabled,
              currencyCode: productFormData?.currencyCode,
            });
          }}
          handleAddOrEditProductAndServicesModalClose={async () => {
            await this.setStateAsync({
              isProductErrorMessage: false,
            });
            await this.handleAddOrEditProductAndServicesModalClose();
          }}
          selectedProductId={this.props.selectedProductId}
          resetSelectedProductId={() =>
            this.props.setSelectedProductId(null, null)
          }
        />
      );
    }
  };

  handleDeleteServiceRecordDialog = async (item) => {
    await this.setStateAsync({ deleteServiceRecord: item });
    await this.setStateAsync({
      isServiceDeleteDialogHidden: false,
    });
  };

  handleDocumentClick = (e, option) => {
    window.open(option, "_blank");
  };

  handleSavePDF = async (quoteEstimatorInfo) => {
    let checkTokenSessionExpired = checkIdentitySession(this._cancelToken);
    if (checkTokenSessionExpired === true) {
      if (quoteEstimatorInfo !== null) {
        await this.setProductDetails("Download Quote");
        await quoteDownloadPdf(
          this.state.websiteTemplate,
          quoteEstimatorInfo,
          this._intl_ns_oc_quote,
          this._intl_ns_oc_common
        );
      }
    }
  };

  handleTermLinkClick = async (item) => {
    if (item.termLink) {
      redirectUrl(item.termLink);
    }
  };

  setProductDetails = async (activity) => {
    if (this.props.quoteEstimatorInfo) {
      const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
      let products =
        this.props.quoteEstimatorInfo?.serviceAndConnectionRecords.map(
          (rec) => ({
            countryName: rec.availabilityName,
            productName: rec.productName,
            productCode: rec.productCode,
            planName: rec.connection,
            communicationPlatform: rec.communicationPlatform,
            quantity: rec.quantity,
          })
        );
      const customProperties = {
        accountNumber: tokenData.accountNumber,
        websiteUrl: window.location.origin,
        activity: activity,
        timestamp: moment(),
        CustomerData: {
          customerName: this.state.quoteUserFormInputFields.quoteName.value,
          customerEmail: this.state.quoteUserFormInputFields.email.value,
          customerPhone: this.state.quoteUserFormInputFields.phone.value,
        },
        products: products,
      };
      let customPropertiesData = { OperatorWebsiteData: customProperties };
      TelemetryUtil.logVerbose("Operator Website Quote", customPropertiesData);
    }
  };

  handleSubscriberDetails = async () => {
    await this.setStateAsync({ isCurrencyEnabled: false });
    await this.fetchQuotesOrdersList(
      this.props.selectedLanguageData.key,
      this.state.quoteUserFormInputFields.email.value,
      "latest"
    );
  };

  handleCustomerRepresentativeModalOpen = async () => {
    await this.setStateAsync({ isCustomerRepresentative: true });
    await this.setStateAsync({
      refreshReCaptcha: !this.state.refreshReCaptcha,
    });
  };

  handleCustomerRepresentativeModalClose = async () => {
    await this.setStateAsync({ isCustomerRepresentative: false });
  };

  setProductCurrenciesList = async (productRecords) => {
    let productsCurrenciesList = [];
    if (productRecords && productRecords.length > 0) {
      productRecords.forEach((rec) => {
        productsCurrenciesList.push({
          key: rec.currencyCode,
          text: `${rec.currencyCode} (${rec.currencySymbol})`,
        });
      });
    }
    let productsCurrenciesUniqueList = _.uniqBy(
      productsCurrenciesList,
      (rec) => rec.key
    );

    productsCurrenciesUniqueList = _.orderBy(
      productsCurrenciesUniqueList,
      [(rec) => rec.key?.toLowerCase()],
      ["asc"]
    );

    await this.props.setCurrencyData(productsCurrenciesUniqueList);
    await this.props.setSelectedCurrencyData(
      productsCurrenciesUniqueList.length > 1
        ? this.props.selectedCurrencyData
        : productsCurrenciesUniqueList[0]
    );
    await this.setStateAsync({
      currencyCode: this.props.selectedCurrencyData?.key,
    });
  };

  renderSubscriberDetails = () => {
    return (
      <>
        <Grid fluid className="m-0 p-0 m-t-15">
          <Row className="m-0 p-0">
            <Col xl={12}>
              <div className="oc-flex-space-between">
                <div>
                  <div
                    className={
                      "text-fw-semibold text-fs-medium " +
                      CustomStyles.getStyle(
                        this.state.websiteTemplate?.templateCode
                          ?.toLowerCase()
                          .trim() !==
                          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? "text-fc-primary"
                          : "text-fc-secondary",
                        this.state.websiteTemplate
                      )
                    }
                  >
                    {IntlUtil.getText(
                      this._intl_ns_oc_quote,
                      "content.nameYourQuote"
                    )}{" "}
                  </div>
                  <div className="m-t-5">
                    {IntlUtil.getText(
                      this._intl_ns_oc_quote,
                      "content.ProvideNameAndEmail"
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-0 p-0 height-110">
            <Col xl={3} lg={3} md={3} xs={12} className="m-t-10">
              <TextField
                required
                maxLength={50}
                id="quoteName"
                name="quoteName"
                value={this.state.quoteUserFormInputFields.quoteName.value}
                onChange={this.handleUserFormFieldChange}
                onBlur={this.handleUserFormFieldBlur}
                label={IntlUtil.getText(this._intl_ns_oc_quote, "content.name")}
                errorMessage={
                  this.state.quoteUserFormInputFields.quoteName.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.quoteUserFormInputFields.quoteName
                          .errorMessage
                      )
                    : null
                }
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={3} xs={12} className="m-t-10">
              <TextField
                required
                id="email"
                name="email"
                maxLength={100}
                value={this.state.quoteUserFormInputFields.email.value}
                onChange={this.handleUserFormFieldChange}
                onBlur={this.handleUserFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.email"
                )}
                errorMessage={
                  this.state.quoteUserFormInputFields.email.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.quoteUserFormInputFields.email.errorMessage
                      )
                    : null
                }
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={3} xs={12} className="m-t-10">
              <TextField
                id="phone"
                name="phone"
                maxLength={20}
                prefix={"+"}
                label={
                  <span>
                    {IntlUtil.getText(this._intl_ns_oc_quote, "content.phone")}
                    <TooltipHost
                      content={
                        <>
                          <div>
                            {IntlUtil.getText(
                              this._intl_ns_oc_quote,
                              "content.tooltip.phone"
                            )}
                          </div>
                          <div>
                            {IntlUtil.getText(
                              this._intl_ns_oc_quote,
                              "content.tooltip.phoneExample"
                            )}
                          </div>
                        </>
                      }
                    >
                      <InfoIcon className="m-l-10" />
                    </TooltipHost>
                  </span>
                }
                value={this.state.quoteUserFormInputFields.phone.value}
                onChange={this.handleUserFormFieldChange}
                onBlur={this.handleUserFormFieldBlur}
                errorMessage={
                  this.state.quoteUserFormInputFields.phone.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.quoteUserFormInputFields.phone.errorMessage
                      )
                    : null
                }
                className="page-form-textfield"
              />
            </Col>
          </Row>
          {this.state.quoteServiceSubmitStatus ===
            OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE &&
          this.state.isProductErrorMessage === false ? (
            <Row className="m-0 p-l-0 p-r-0">
              <Col
                xl={12}
                lg={12}
                md={12}
                xs={12}
                className="text-fc-error m-b-40"
              >
                {this.state.quoteServiceError &&
                this.state.quoteServiceError.length > 0 ? (
                  <span>
                    {this.state.quoteServiceError.map((error, index) => {
                      return (
                        <div key={`key-quote-error-${index}`}>
                          {error.message}
                        </div>
                      );
                    })}
                  </span>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </Grid>
      </>
    );
  };

  fetchCountries = async () => {
    if (
      this.props.countryStatesData === null ||
      this.props.countryStatesData.length === 0
    ) {
      await getCountryStates(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            await this.props.setCountryStatesData(res.data.records);
            await this.setStateAsync({ isPageDataFetched: true });
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
  };

  handleAddOrEditProductAndServicesModalOpen = async (item) => {
    await this.setStateAsync({
      isAddOrEditProductModalOpen: true,
      platformDisable: true,
      editedServiceRecord: item,
      selectedCountry: item?.selectedCountry,
    });
  };

  handleAddOrEditProductAndServicesModalClose = async () => {
    await this.setStateAsync({
      isAddOrEditProductModalOpen: false,
      platformDisable: false,
    });
    this.props.setSelectedProductId(null, null);
  };

  fetchProductsAvailable = async (
    availabilityType,
    availabilityName,
    currencyCode
  ) => {
    await this.setStateAsync({
      isPageDataFetched: false,
      connectionList: [],
      communicationPlatformList: [],
    });
    await getProductsAvailable(
      this.props.selectedLanguageData?.key,
      availabilityType,
      availabilityName,
      currencyCode,
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let productList = [];
          const records = res.data.records.map((rec) => {
            if (
              rec.productGroup?.toLowerCase() !==
              OperatorConnectConstants.PRODUCT_GROUP_ADDON.toLowerCase()
            ) {
              productList.push({
                key: rec.productId,
                text: `${rec.productName} (${rec.productCode})`,
                productName: rec.productName,
              });
            }
            return { ...rec };
          });
          await this.props.setQuoteEstimatorInfoProductRecords(
            this.props.quoteEstimatorInfo,
            records
          );
          productList = _.orderBy(productList, "text", "asc");
          await this.setStateAsync({ countryProductRecords: records });
          await this.setStateAsync({ productServices: productList });
          await this.setStateAsync({ isPageDataFetched: true });
        } else {
          await this.setStateAsync({ isPageDataFetched: true });
          await this.props.setQuoteEstimatorInfoProductRecords(
            this.props.quoteEstimatorInfo,
            []
          );
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isPageDataFetched: true });
        await this.props.setPageExit(false);
        await manageError(err, this.props.history);
      });
  };

  renderProducts = () => {
    return (
      <>
        <Grid fluid className="m-l-0 m-r-0 p-l-0 p-r-0 m-b-10">
          <Row className="product-contact-container m-0 p-0 ">
            <Col md={7} xs={12} xl={8} lg={8}>
              <Row>
                <Col xl={12}>
                  <Text
                    className={
                      "text-fw-semibold text-fs-medium " +
                      CustomStyles.getStyle(
                        this.state.websiteTemplate?.templateCode
                          ?.toLowerCase()
                          .trim() !==
                          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? "text-fc-primary"
                          : "text-fc-secondary",
                        this.state.websiteTemplate
                      )
                    }
                  >
                    {IntlUtil.getText(
                      this._intl_ns_oc_quote,
                      "content.addProductsAndServices"
                    )}{" "}
                  </Text>
                </Col>
              </Row>
              <Row className=" m-t-10 m-b-15">
                <Col xl={12} lg={12} md={12} xs={12}>
                  <DefaultButton
                    onClick={async () => {
                      await this.props.setSelectedProductId(null, null);
                      await this.setStateAsync({
                        isProductErrorMessage: false,
                      });
                      await this.handleAddOrEditProductAndServicesModalOpen(
                        null
                      );
                    }}
                    iconProps={{ iconName: "CirclePlus" }}
                    disabled={this.props.productRecords?.records?.length === 0}
                  >
                    {IntlUtil.getText(
                      this._intl_ns_oc_quote,
                      "content.addProduct"
                    )}
                  </DefaultButton>
                  <span className="p-l-10">
                    {IntlUtil.getText(
                      this._intl_ns_oc_quote,
                      "content.chooseProductText"
                    )}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={5} xs={12} xl={4} lg={4} className="contact-div">
              <Link
                onClick={() => this.handleCustomerRepresentativeModalOpen()}
                className={`oc-customer-link ${CustomStyles.getStyle(
                  "customer-text-link",
                  this.state.websiteTemplate
                )} text-fw-semibold text-fs-medium`}
              >
                <SkypeMessageIcon />
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.customerRepresentative"
                )}
              </Link>
            </Col>
          </Row>
        </Grid>
      </>
    );
  };

  handlePreviewOpen = async () => {
    let checkTokenSessionExpired = checkIdentitySession(this._cancelToken);
    if (checkTokenSessionExpired === true) {
      await this.setStateAsync({ previewStatus: "previewQuote" });
      await this.setStateAsync({ isPreviewModalOpen: true });
      await this.setProductDetails("Preview Quote");
      await this.setStateAsync({ isPageDataFetched: false });
      await this.fetchDocuments(
        this.props.quoteEstimatorInfo.serviceAndConnectionRecords
      );
      await this.setStateAsync({ isPageDataFetched: true });
    }
  };

  handleExecuteRecaptcha = async (token) => {
    await this.setStateAsync({ captchaToken: token });
  };

  previousOrderDetails = () => {
    return (
      <>
        {this.state.isProductQuoteSearch && (
          <Grid fluid className="m-l-0 m-r-0 p-l-5 p-r-5 ">
            <Row className="m-0 p-0">
              <Col className="m-b-10 m-t-10">
                <span>
                  {IntlUtil.getSubstituteText(
                    this._intl_ns_oc_quote,
                    "notification.info.quotesSubmittedInfo1",
                    [
                      {
                        key: "<QUOTE_NUMBER>",
                        value: this.state.quotesRecords?.quoteNumber,
                      },
                      {
                        key: "<DATE>",
                        value: new Date(
                          this.state.quotesRecords?.modifyTimestamp
                        ).toLocaleDateString(),
                      },
                    ]
                  )}
                </span>
              </Col>
            </Row>
            <Row className="m-0 p-0">
              <Col className="m-b-10 m-t-10">
                <span>
                  {IntlUtil.getText(
                    this._intl_ns_oc_quote,
                    "notification.info.quotesSubmittedInfo3"
                  )}
                </span>
              </Col>
            </Row>
          </Grid>
        )}
      </>
    );
  };

  render() {
    return (
      <div
        className={
          "page-frame-content frame-content-quotes " +
          CustomStyles.getStyle("font-family", this.state.websiteTemplate)
        }
      >
        {this.renderProducts()}
        <QuoteServiceTable
          websiteTemplate={this.state.websiteTemplate}
          history={this.props.history}
          quoteType={this.state.selectedQuoteTypeKey}
          isEditable={true}
          handleQuoteEdit={(e, item) => {
            this.handleEditServiceRecord(e, item);
          }}
          handleQuoteDelete={(item) =>
            this.handleDeleteServiceRecordDialog(item)
          }
          intlNamespace={this._intl_ns_oc_quote}
          showTitle={true}
        />
        {this.props.quoteEstimatorInfo?.serviceAndConnectionRecords?.length >
          0 && (
          <>
            {" "}
            <div
              className={`m-t-20 m-b-20 ${CustomStyles.getStyle(
                "page-content-separator",
                this.state.websiteTemplate
              )}`}
            ></div>
            {this.renderSubscriberDetails()}
          </>
        )}
        <div
          className={`m-t-20  ${CustomStyles.getStyle(
            "page-content-separator",
            this.state.websiteTemplate
          )}`}
        ></div>
        <div className="m-t-10 m-b-5">
          <DefaultButton
            onClick={() => this.handlePreviewOpen()}
            className={`oc-quote-page-footer-actions page-frame-button `}
            disabled={
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords ===
                null ||
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords ===
                undefined ||
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
                .length === 0
            }
            iconProps={{ iconName: "Preview" }}
          >
            {IntlUtil.getText(this._intl_ns_oc_quote, "content.previewQuote")}
          </DefaultButton>
          <DefaultButton
            onClick={async () => {
              await this.props.setQuoteEstimatorInfoResourceRecords(
                this.props.quoteEstimatorInfo,
                []
              );
              await this.props.setQuoteEstimatorInfoOnboardServiceDocumentRecords(
                this.props.quoteEstimatorInfo,
                []
              );
              await this.handleSavePDF(this.props.quoteEstimatorInfo);
            }}
            className={`oc-quote-page-footer-actions page-frame-button `}
            disabled={
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords ===
                null ||
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords ===
                undefined ||
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
                .length === 0
            }
            iconProps={{ iconName: "Download" }}
          >
            {IntlUtil.getText(this._intl_ns_oc_quote, "content.downloadQuote")}
          </DefaultButton>
          <PrimaryButton
            className={`oc-quote-page-footer-actions page-frame-button`}
            onClick={() => this.handleSaveQuoteDialog("quoteSave")}
            disabled={
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords ===
                null ||
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords ===
                undefined ||
              this.props.quoteEstimatorInfo?.serviceAndConnectionRecords
                .length === 0 ||
              this.state.isUserFormFilled === false
            }
            iconProps={{ iconName: "TextDocument" }}
          >
            {IntlUtil.getText(this._intl_ns_oc_quote, "content.submitQuote")}
          </PrimaryButton>
        </div>
        {this.renderPreviewQuoteModal()}
        {this.renderCustomerRepresentativeForm()}
        {this.renderQuoteDeleteDialog()}
        {this.renderQuoteSaveDialog()}
        {this.renderAddUpdateModal()}
        {this.renderQuoteEditWarningDeleteDialog()}

        <PageOverlayLoader
          hidden={this.state.isPageDataFetched}
          label={IntlUtil.getText(
            this._intl_ns_oc_common,
            "content.loadingInprogress"
          )}
        />
        <GoogleReCaptcha
          action="CreateQuote"
          onVerify={this.handleExecuteRecaptcha}
          refreshReCaptcha={this.state.refreshReCaptcha}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCurrencyData: state.generalStore.selectedCurrencyData,
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  countryStatesData: state.generalStore.countryStatesData,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  productRecords: state.productStore.productRecords,
  currencyData: state.generalStore.currencyData,
  selectedProductId: state.quoteStore.selectedProductId,
});

const mapActionToProps = {
  setQuoteEstimatorInfoProductRecords,
  setQuoteEstimatorInfoServiceAndConnectionRecords,
  setQuoteEstimatorInfoServiceAndConnectionCost,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setQuoteEstimatorInfoOnboardServiceDocumentRecords,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  resetQuoteEstimatorInfo,
  setQuoteProgressPage,
  setCountryStatesData,
  setQuoteEstimatorInfoResourceRecords,
  setCurrencyData,
  setProductRecords,
  setSelectedCurrencyData,
  setSelectedProductId,
};

export default connect(mapStateToProps, mapActionToProps)(QuoteCreate);
