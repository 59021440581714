import IntlUtil from "../../../core/helpers/intl-util";

export const CustomerRepresentativeFormFields = (
  intlNamespace,
  refCollection
) => {
  return {
    contactName: {
      value: "",
      isRequired: true,
      validate: (val) => {
        if (val && val.length > 0) return true;
        else return false;
      },
      isError: false,
      refObject: refCollection["contactName"],
      errorMessage: IntlUtil.getText(
        intlNamespace,
        "notification.error.contactNameRequired"
      ),
    },
    companyName: {
      value: "",
      isRequired: true,
      validate: (val) => {
        if (val && val.trim().length > 0) return true;
        else return false;
      },
      isError: false,
      refObject: refCollection["companyName"],
      errorMessage: IntlUtil.getText(
        intlNamespace,
        "notification.error.companyNameRequired"
      ),
    },
    contactEmail: {
      value: "",
      isRequired: true,
      validate: (val) => {
        const reg =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(val).toLowerCase());
      },
      isError: false,
      refObject: refCollection["contactEmail"],
      errorMessage: IntlUtil.getText(
        intlNamespace,
        "notification.error.contactEmailRequired"
      ),
    },
    contactPhone: {
      value: "",
      isRequired: false,
      validate: (val) => {
        if (val && val.trim().length > 0) {
          let regex = new RegExp("^[0-9]+$");
          let isValid = regex.test(val);
          if (isValid === true) {
            return true;
          } else {
            return false;
          }
        }
      },
      isError: false,
      refObject: refCollection["contactPhone"],
      errorMessage: IntlUtil.getText(
        intlNamespace,
        "notification.error.phoneNumberNotValid"
      ),
    },
    requestDescription: {
      value: "",
      isRequired: true,
      validate: (val) => {
        if (val && val.length > 0) return true;
        else return false;
      },
      isError: false,
      refObject: refCollection["requestDescription"],
      errorMessage: IntlUtil.getText(
        intlNamespace,
        "notification.error.requestDescription"
      ),
    },
  };
};
