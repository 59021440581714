import axios from "axios";
import TokenUtil from "../helpers/token-util";
import { authenticateToken } from "../actions/identity-actions";

const instance = axios;

const _axiosSource = instance.CancelToken.source();
const _cancelToken = { cancelToken: _axiosSource.token };

instance.interceptors.request.use(
  async (config) => {
    const token = TokenUtil.getIdentitySippioAccessToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      let operatorWebsiteUrl = window.location.origin;
      if (window.location.origin.includes("http://localhost") === true) {
        let template = "3";
        if (template === "1") {
          operatorWebsiteUrl = "https://oc-dev.operatorconnect.io";
        } else if (template === "2") {
          operatorWebsiteUrl = "https://oc-ui-app-dev.azurewebsites.net";
        } else if (template === "3") {
          operatorWebsiteUrl = " https://oc-ui-app-dev-uk.azurewebsites.net";
        }
      }
      try {
        let res = await authenticateToken(operatorWebsiteUrl, _cancelToken);
        TokenUtil.setIdentityToken(res.data.result.accessToken);
        const token = TokenUtil.getIdentitySippioAccessToken();
        originalRequest.headers["Authorization"] = token;
        axios.defaults.headers.common["Authorization"] = token;
        return instance(originalRequest);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
