import React, { useEffect, useState } from "react";
import axios from "axios";
import IntlUtil from "../../../core/helpers/intl-util";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import { loadStripe } from "@stripe/stripe-js";
import { PrimaryButton } from "@fluentui/react";
import { Elements } from "@stripe/react-stripe-js";
import PageLoader from "../../common/helpers/page-loader";
import { connect } from "react-redux";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { AppConfigProps } from "../../../core/settings/app-config";
import {
  renderTotalAmount,
  setStripePaymentIntent,
  updatePaymentInfo,
} from "../actions/quote-action";
import {
  setOrderPaymentInfo,
  setOrderConnectedAccount,
  setOrderPublisherKey,
} from "../actions/quote-action";
import { getLanguage } from "../../common/actions/common-actions";
import { Col, Grid, Row } from "react-flexbox-grid";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Text, MessageBar, MessageBarType } from "@fluentui/react";
import classNames from "classnames";
import CustomStyles from "../../common/helpers/custom-styles";
import { Link } from "react-router-dom";

const _intl_ns_oc_common = "oc_common";
const _intl_ns_oc_quote = "oc_quote";
const _axiosSource = axios.CancelToken.source();
const _cancelToken = { cancelToken: _axiosSource.token };

const OrderCheckOutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveCardData = async (status) => {
    if (paymentStatus && paymentStatus.paymentDetails) {
      let obj = {
        paymentDetails: paymentStatus.paymentDetails,
        isPaymentDialogHidden: status,
      };
      await props.setpaymentCardFormData(obj);
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!props.clientSecret) {
      return;
    }
    if (
      props.orderPaymentInfo?.transactionNumber &&
      props.orderPaymentInfo?.paymentId
    ) {
      updatePaymentData(props.orderPaymentInfo?.paymentId, {
        transactionNumber: props.orderPaymentInfo?.transactionNumber,
      });
    }
    return () => {
      resertData();
    };
  }, [stripe]);

  const resertData = () => {
    setPaymentStatus(null);
    setErrorStatus(null);
    setErrorMessage(null);
  };

  const handleRefreshPaymentInfo = async () => {
    setIsLoading(true);
    if (
      props.orderPaymentInfo?.transactionNumber &&
      props.orderPaymentInfo?.paymentId
    ) {
      await updatePaymentData(props.orderPaymentInfo?.paymentId, {
        transactionNumber: props.orderPaymentInfo?.transactionNumber,
      });
    }
  };

  useEffect(() => {
    let timer = setTimeout;
    if (paymentStatus !== null) {
      if (paymentStatus?.status === "Succeeded") {
        timer = setTimeout(() => {
          handleSaveCardData(true);
        }, 3000);
      }
    }
    return () => clearTimeout(timer);
  }, [paymentStatus]);

  const updatePaymentData = async (paymentId, paymentObject) => {
    await updatePaymentInfo(paymentId, paymentObject, _cancelToken)
      .then(async (res) => {
        if (res && res.data && res.data.result) {
          props.setTabDisabled(true);
          setPaymentStatus({
            status: res.data.result?.processStatus,
            paymentDetails: {
              status: res.data.result?.processStatus,
              amount: res.data.result?.paymentAmount,
              transactionId: res.data.result?.id,
              paymentMethodType: res.data.result?.paymentMethod,
              paymentNumber: res.data.result?.paymentNumber,
              paymentDescription: res.data.result?.paymentDescription,
              currencyCode: res.data.result?.currencyCode,
              currencySymbol: res.data.result?.currencySymbol,
            },
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setPaymentStatus({
          status: "Failed",
        });
        setIsLoading(false);
        setErrorMessage(err.data.errors[0].message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorStatus(null);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + "/quotes/add?tab=1",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error") {
      setErrorMessage(error.message);
      setErrorStatus(OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE);
    }

    setIsLoading(false);
  };

  if (
    paymentStatus === null ||
    (paymentStatus &&
      paymentStatus.status?.toLowerCase()?.trim() ===
        OperatorConnectConstants.PAYMENT_STATUS.FAILED.toLowerCase().trim())
  ) {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Grid fluid className="m-0 p-l-5 p-t-20">
            <Row>
              <Col xl={3} md={6}>
                <span
                  className={
                    "text-fw-semibold text-fs-medium " +
                    CustomStyles.getStyle(
                      props.websiteTemplate?.templateCode
                        ?.toLowerCase()
                        .trim() !==
                        OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                        ? "text-fc-primary"
                        : "text-fc-secondary",
                      props.websiteTemplate
                    )
                  }
                >
                  {IntlUtil.getText(
                    _intl_ns_oc_quote,
                    "content.makeYourPayment"
                  )}
                </span>
              </Col>
              <Col xl={3} md={6}>
                <div className="quote-checkout-price">
                  <div
                    className={`text-fw-semibold text-fs-medium ${CustomStyles.getStyle(
                      "font-family",
                      props.websiteTemplate
                    )}`}
                  >
                    {IntlUtil.getText(_intl_ns_oc_quote, "content.totalAmount")}
                  </div>
                  <div
                    className={
                      "text-fw-semibold text-fs-large " +
                      CustomStyles.getStyle(
                        props.websiteTemplate?.templateCode
                          ?.toLowerCase()
                          .trim() !==
                          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                          ? "text-fc-primary"
                          : "text-fc-secondary",
                        props.websiteTemplate
                      )
                    }
                  >
                    {renderTotalAmount(
                      props?.subscriberQuoteRecords?.products[0]?.currencyCode,
                      props.subscriberQuoteRecords.totalCost
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={6} className="m-t-10">
                <PaymentElement />
              </Col>
            </Row>
            <Row>
              <Col xl={6} className="m-t-10">
                {errorStatus ===
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE ? (
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    className="notification-messagebar"
                  >
                    {errorMessage ? errorMessage : null}
                  </MessageBar>
                ) : null}
              </Col>
            </Row>
          </Grid>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              props.websiteTemplate
            )}`}
          ></div>
          <PrimaryButton className="m-t-20 page-frame-button" type="submit">
            {IntlUtil.getText(_intl_ns_oc_quote, "content.submit")}
          </PrimaryButton>
        </form>
        <PageOverlayLoader
          hidden={!isLoading}
          label={IntlUtil.getText(
            _intl_ns_oc_common,
            "content.loadingInprogress"
          )}
        />
      </div>
    );
  } else if (
    paymentStatus.status?.toLowerCase()?.trim() ===
    OperatorConnectConstants.PAYMENT_STATUS.SUCCEEDED.toLowerCase().trim()
  ) {
    return (
      <div>
        <Grid fluid className="m-0 p-l-5 p-t-20">
          <Row>
            <Col xl={12}>
              <MessageBar
                messageBarType={MessageBarType.success}
                className="notification-messagebar"
              >
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.success.paymentSuccessful"
                )}
              </MessageBar>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className={"text-fs-medium"}>
                {IntlUtil.getSubstituteText(
                  _intl_ns_oc_quote,
                  "content.amountPaid",
                  [
                    {
                      key: "<AMOUNT_PAID>",
                      value: renderTotalAmount(
                        props?.subscriberQuoteRecords?.products[0]
                          ?.currencyCode,
                        parseFloat(
                          paymentStatus?.paymentDetails?.amount
                        ).toFixed(2)
                      ),
                    },
                  ]
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              {paymentStatus?.paymentDetails?.paymentMethodType && (
                <Text className="text-fs-medium">
                  {IntlUtil.getSubstituteText(
                    _intl_ns_oc_quote,
                    "content.paymentType",
                    [
                      {
                        key: "<PAYMENT_TYPE>",
                        value: "Credit Card",
                      },
                    ]
                  )}
                </Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getSubstituteText(
                  _intl_ns_oc_quote,
                  "content.transactionId",
                  [
                    {
                      key: "<TRANSACTION_ID>",
                      value: paymentStatus?.paymentDetails?.transactionId,
                    },
                  ]
                )}
              </Text>
            </Col>
          </Row>
          <div className="secondary-section-border-border m-t-10"></div>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.paymentCreditCardInfo1"
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.paymentCreditCardInfo2"
                )}
              </Text>
            </Col>
          </Row>
        </Grid>
        <div
          className={`${CustomStyles.getStyle(
            "page-content-separator",
            props.websiteTemplate
          )}`}
        ></div>
        <PrimaryButton
          className="m-t-20 page-frame-button"
          onClick={() => handleSaveCardData(false)}
        >
          {IntlUtil.getText(_intl_ns_oc_quote, "content.continue")}
        </PrimaryButton>
        <PageOverlayLoader
          hidden={!isLoading}
          label={IntlUtil.getText(
            _intl_ns_oc_common,
            "content.loadingInprogress"
          )}
        />
      </div>
    );
  } else if (
    paymentStatus.status?.toLowerCase().trim() ===
      OperatorConnectConstants.PAYMENT_STATUS.PENDING.toLowerCase().trim() ||
    paymentStatus.status?.toLowerCase().trim() ===
      OperatorConnectConstants.PAYMENT_STATUS.PROCESSING.toLowerCase().trim()
  ) {
    return (
      <>
        <Row className="m-t-20">
          <Col xl={12}>
            <MessageBar
              messageBarType={MessageBarType.info}
              className="notification-messagebar"
            >
              <span className="text-ff-semibold">
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.processingPrefix"
                )}
              </span>{" "}
              <Link
                onClick={() => {
                  setIsLoading(true);
                  if (
                    props.orderPaymentInfo?.transactionNumber &&
                    props.orderPaymentInfo?.paymentId
                  ) {
                    updatePaymentData(props.orderPaymentInfo?.paymentId, {
                      transactionNumber:
                        props.orderPaymentInfo?.transactionNumber,
                    });
                  }
                }}
                className="m-l-0 p-l-0 text-fc-primary text-underline cursor-pointer"
              >
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.processingSuffix"
                )}
              </Link>
            </MessageBar>
          </Col>
        </Row>
        <div className="m-t-20">
          <Row>
            <Col xl={12}>
              <Text className="text-fs-medium">
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.paymentProcessingInfo"
                )}
              </Text>
            </Col>
          </Row>
          {paymentStatus?.paymentDetails && (
            <Row>
              <Col xl={12} className="m-t-10">
                <Text className="text-fs-medium">
                  {IntlUtil.getSubstituteText(
                    _intl_ns_oc_quote,
                    "content.paymentNumber",
                    [
                      {
                        key: "<PAYMENT_NUMBER>",
                        value: paymentStatus?.paymentDetails?.paymentNumber,
                      },
                    ]
                  )}
                </Text>
              </Col>
            </Row>
          )}
          {paymentStatus?.paymentDetails?.currencySymbol &&
            paymentStatus?.paymentDetails?.amount && (
              <Row>
                <Col xl={12} className="m-t-10">
                  <Text className="text-fs-medium">
                    {IntlUtil.getSubstituteText(
                      _intl_ns_oc_quote,
                      "content.amountPaid",
                      [
                        {
                          key: "<AMOUNT_PAID>",
                          value: renderTotalAmount(
                            props?.subscriberQuoteRecords?.products[0]
                              ?.currencyCode,
                            parseFloat(
                              paymentStatus?.paymentDetails?.amount
                            ).toFixed(2)
                          ),
                        },
                      ]
                    )}
                  </Text>
                </Col>
              </Row>
            )}
          {paymentStatus?.paymentDetails?.paymentDescription && (
            <Row>
              <Col xl={12} className="m-t-10">
                <Text className="text-fs-medium">
                  {IntlUtil.getSubstituteText(
                    _intl_ns_oc_quote,
                    "notification.info.paymentDescription",
                    [
                      {
                        key: "<PAYMENT_DESCRIPTION>",
                        value:
                          paymentStatus?.paymentDetails?.paymentDescription,
                      },
                    ]
                  )}
                </Text>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.paymentCreditCardInfo1"
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col xl={12} className="m-t-10">
              <Text className="text-fs-medium">
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.paymentCreditCardInfo2"
                )}
              </Text>
            </Col>
          </Row>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              props.websiteTemplate
            )}`}
          ></div>
          <PrimaryButton
            className="m-t-20 page-frame-button"
            onClick={handleSaveCardData}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.continue")}
          </PrimaryButton>

          <PageOverlayLoader
            hidden={!isLoading}
            label={IntlUtil.getText(
              _intl_ns_oc_common,
              "content.loadingInprogress"
            )}
          />
        </div>
      </>
    );
  }
};

const OrderCheckoutCard = (props) => {
  let stripePromise = "";
  if (
    props.orderConnectedAccount !== null &&
    props.orderConnectedAccount !== ""
  ) {
    stripePromise = loadStripe(props.publisherKey, {
      stripeAccount: props.orderConnectedAccount,
    });
  } else {
    stripePromise = loadStripe(props.publisherKey);
  }
  const [clientSecret, setclientSecret] = useState(props.clientSecret);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (clientSecret === null) {
      resertData();
      getStripeIntent();
    } else {
      setIsPageLoading(true);
    }
    return () => {
      resertData();
    };
  }, []);

  const resertData = () => {
    setErrorStatus(null);
    setclientSecret(null);
    setIsPageLoading(true);
  };

  const getStripeIntent = async () => {
    if (
      props &&
      props.subscriberQuoteRecords &&
      props.subscriberQuoteRecords.customerEmail &&
      props.subscriberQuoteRecords.quoteNumber
    ) {
      if (
        parseFloat(props.subscriberQuoteRecords?.totalCost ?? "0").toFixed(2) >
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.MINIMUM_PAYABLE_AMOUNT
      ) {
        if (
          parseFloat(props.subscriberQuoteRecords?.totalCost ?? "0").toFixed(
            2
          ) <
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.MAXIMUM_PAYABLE_AMOUNT
        ) {
          setIsPageLoading(false);
          let amount = {
            paymentAmount: parseFloat(
              props.subscriberQuoteRecords.totalCost
            ).toFixed(2),
            currencyCode:
              props.subscriberQuoteRecords?.products[0]?.currencyCode?.toLowerCase(),
            paymentMethod: "card",
            customerEmail: props.subscriberQuoteRecords.customerEmail,
            customerName: props.subscriberQuoteRecords.customerName,
            quoteNumber: props.subscriberQuoteRecords.quoteNumber,
          };
          await setStripePaymentIntent(amount, _cancelToken)
            .then(async (res) => {
              if (
                res &&
                res.status === AppConfigProps.httpStatusCode.ok &&
                res.data &&
                res.data.result
              ) {
                setclientSecret(res.data.result.secretKey);
                props.setOrderConnectedAccount(
                  res.data.result.connectAccountId
                );
                props.setOrderPaymentInfo(res.data.result);
                props.setOrderPublisherKey(res.data.result.publisherKey);
                setIsPageLoading(true);
              }
              setIsPageLoading(true);
            })
            .catch(async (err) => {
              setErrorStatus(
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE
              );
              setErrorMessage(err.data.errors);
              setIsPageLoading(true);
            });
        } else {
          setErrorStatus(OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE);
          setErrorMessage([
            {
              message: IntlUtil.getSubstituteText(
                _intl_ns_oc_quote,
                "notification.error.paymentAmountLessFailure",
                [
                  {
                    key: "<AMOUNT>",
                    value:
                      props.subscriberQuoteRecords?.products[0]
                        ?.currencySymbol +
                      parseFloat(
                        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT
                          .MAXIMUM_PAYABLE_AMOUNT
                      ).toFixed(2),
                  },
                ]
              ),
            },
          ]);
          setIsPageLoading(true);
        }
      }
    }
  };

  const handleZeroAmountSubmit = async () => {
    let paymentStatus = {
      status: OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE,
      paymentDetails: {
        status: OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE,
        amount: parseFloat(
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ZERO_AMOUNT
        ).toFixed(2),
        transactionId: null,
        paymentMethodType:
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ORDER_TYPE.CREDIT_CARD,
        paymentNumber: null,
        paymentDescription: null,
        currencyCode: props.subscriberQuoteRecords?.products[0]?.currencyCode,
        currencySymbol:
          props.subscriberQuoteRecords?.products[0]?.currencySymbol,
      },
    };
    let obj = {
      paymentDetails: paymentStatus.paymentDetails,
      isPaymentDialogHidden: true,
    };
    await props.setpaymentCardFormData(obj);
  };

  const options = {
    clientSecret: clientSecret,
    loader: "auto",
    locale: getLanguage(),
  };

  return (
    <>
      {errorStatus === OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE ? (
        <MessageBar
          messageBarType={MessageBarType.error}
          className="notification-messagebar m-t-20"
        >
          {errorMessage && errorMessage.length > 0 ? (
            <span>
              {errorMessage.map((error, index) => {
                return (
                  <div
                    className="text-ff-semibold"
                    key={`key-profile-error-${index}`}
                  >
                    {error.message}
                  </div>
                );
              })}
            </span>
          ) : null}
        </MessageBar>
      ) : parseFloat(props.subscriberQuoteRecords?.totalCost ?? "0").toFixed(
          2
        ) <
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.MINIMUM_PAYABLE_AMOUNT ? (
        <div className="m-l-5">
          <div className="m-t-10">
            <Text className="text-fs-medium m-b-10">
              {IntlUtil.getSubstituteText(
                _intl_ns_oc_quote,
                "content.amountPaid",
                [
                  {
                    key: "<AMOUNT_PAID>",
                    value: renderTotalAmount(
                      props?.subscriberQuoteRecords?.products[0]?.currencyCode,
                      "0.00"
                    ),
                  },
                ]
              )}
            </Text>
          </div>
          <div className="m-t-15">
            <Text className="text-fs-medium">
              {IntlUtil.getText(
                _intl_ns_oc_quote,
                "notification.info.submitInfo"
              )}
            </Text>
          </div>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              props.websiteTemplate
            )}`}
          ></div>
          <PrimaryButton
            className="m-t-20 page-frame-button"
            onClick={handleZeroAmountSubmit}
          >
            {IntlUtil.getText(_intl_ns_oc_quote, "content.submit")}
          </PrimaryButton>
        </div>
      ) : (
        <div
          className={classNames({
            "p-t-15 p-b-30": isPageLoading === false,
          })}
        >
          <PageLoader
            status={isPageLoading}
            size="medium"
            labelPosition="right"
            label={IntlUtil.getText(
              _intl_ns_oc_common,
              "content.loadingInprogress"
            )}
            type="inline"
          >
            {options.clientSecret &&
            props.orderPaymentInfo !== null &&
            stripePromise !== null ? (
              <Elements options={options} stripe={stripePromise}>
                <OrderCheckOutForm {...props} />
              </Elements>
            ) : (
              <MessageBar
                className="m-t-20"
                messageBarType={MessageBarType.info}
              >
                {IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "notification.info.creditCardPaymentOptionNotAvailable"
                )}
              </MessageBar>
            )}
          </PageLoader>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  publisherKey: state.quoteStore.orderPublisherKey,
  orderPaymentInfo: state.quoteStore.orderPaymentInfo,
  orderConnectedAccount: state.quoteStore.orderConnectedAccount,
});
const mapActionToProps = {
  setOrderPaymentInfo,
  setOrderConnectedAccount,
  setOrderPublisherKey,
};

export default connect(mapStateToProps, mapActionToProps)(OrderCheckoutCard);
