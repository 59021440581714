import React, { useState } from "react";
import { connect } from "react-redux";
import { AppPageTitle } from "../../../core/components/app-page-title";
import { DefaultButton, mergeStyleSets } from "@fluentui/react";
import IntlUtil from "../../../core/helpers/intl-util";
import SignInDialog from "./sigin-page-modal";
import CustomStyles from "./custom-styles";
import { OperatorConnectURLProps } from "../../common/settings/operator-connect-urls";

const PageHeader = (props) => {
  const _intl_ns_oc_home = "oc_home";
  const _intl_ns_common = "oc_common";
  let [isSignInDialogHidden, setSignInDialogHidden] = useState(true);
  const handleSelectedLanguageData = (records, selectedlanguageCode) => {
    let filteredText = "";
    if (records && records.length > 0) {
      records.forEach((language) => {
        if (language.languageCode === selectedlanguageCode) {
          filteredText = language.translation;
        }
      });
    }
    return filteredText;
  };

  const loadQuoteCreatePage = () => {
    props.history.push(OperatorConnectURLProps.orderManagement.quoteAdd);
    props.setPageExit(false);
    return;
  };

  const mergeContentStyles = mergeStyleSets({
    dropdown: {
      minWidth: "10px !important",
      padding: "2px",
      button: {
        minWidth: "10px !important",
      },
    },
  });

  const handleSignInDialogOpen = () => {
    setSignInDialogHidden(false);
    props.setPageExit(false);
  };

  const handleSignInDialogClose = () => {
    setSignInDialogHidden(true);
    props.setPageExit(false);
  };

  const renderSignInDialog = () => {
    if (isSignInDialogHidden === false) {
      return (
        <SignInDialog
          {...props}
          setPageExit={() => props.setPageExit(false)}
          websiteTemplate={props.webTemplateConfigData.websiteTemplate}
          isSignInDialogHidden={isSignInDialogHidden}
          handleSignInDialogClose={() => handleSignInDialogClose()}
          intlNamespace={_intl_ns_common}
          loadQuoteCreatePage={() => loadQuoteCreatePage()}
        />
      );
    }
  };

  return (
    <>
      <AppPageTitle
        pageTitle={handleSelectedLanguageData(
          props.websitePageTitle?.pageTitle,
          props.selectedLanguageData?.key
        )}
      />

      <div
        className={CustomStyles.getStyle(
          "page-title-wrapper",
          props.webTemplateConfigData?.websiteTemplate
        )}
      >
        {props.websitePageTitle &&
        props.websitePageTitle.heading?.length > 0 ? (
          <div className="oc-product-section">
            <div
              className={CustomStyles.getStyle(
                "page-heading",
                props.webTemplateConfigData?.websiteTemplate
              )}
            >
              <span
                style={{ marginTop: "-3px" }}
                dangerouslySetInnerHTML={{
                  __html: handleSelectedLanguageData(
                    props.websitePageTitle.heading,
                    props.selectedLanguageData?.key
                  ),
                }}
              />
            </div>
            <div>
              {props.showCurrencyStatus === true ? (
                <DefaultButton
                  className={`${mergeContentStyles["dropdown"]} m-r-10`}
                  text={props.selectedCurrencyData?.text ?? ""}
                  menuProps={{
                    items: props.currencyData,
                    alignTargetEdge: true,
                    shouldFocusOnMount: true,
                    beakWidth: 0,
                    onItemClick: async (e, option) => {
                      if (
                        props.selectedCurrencyData?.text.toLowerCase() !==
                        option.text.toLowerCase()
                      ) {
                        await props.onChange(option);
                      }
                    },
                  }}
                />
              ) : null}
              <DefaultButton
                onClick={() => handleSignInDialogOpen()}
                text={IntlUtil.getText(_intl_ns_common, "content.signInInfo")}
              />
            </div>
          </div>
        ) : null}
        {props.websitePageTitle &&
        props.websitePageTitle.caption?.length > 0 &&
        handleSelectedLanguageData(
          props.websitePageTitle?.caption,
          props.selectedLanguageData?.key
        ) !== "" ? (
          <span
            className={CustomStyles.getStyle(
              "page-caption",
              props.webTemplateConfigData?.websiteTemplate
            )}
            dangerouslySetInnerHTML={{
              __html: handleSelectedLanguageData(
                props.websitePageTitle?.caption,
                props.selectedLanguageData?.key
              ),
            }}
          />
        ) : null}

        <div className="page-main-header-actions">
          {/* Please start coding here for buttons*/}
        </div>
      </div>
      {renderSignInDialog()}
    </>
  );
};

const mapStateToProps = (state) => ({
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  selectedCurrencyData: state.generalStore.selectedCurrencyData,
  currencyData: state.generalStore.currencyData,
});
const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(PageHeader);
