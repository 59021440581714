import IntlUtil from "../../../core/helpers/intl-util";

const _oc_common = "oc_common";

export const OperatorConnectConstants = {
  COUNTRY_LIST: {
    FAVOURITE_COUNTRIES: ["United States", "United Kingdom", "Canada"],
    DISPLAY_TYPE: {
      ONE: "1",
      TWO: "2",
    },
  },
  SEARCH_TYPE: {
    BEGIN_WITH: "BeginWith",
    CONTAINS: "Contains",
  },
  BUTTON_TYPE: {
    MULTIPLE: "Multiple",
    SINGLE: "Single",
  },
  TEMPLATE_CODE: {
    TEMPLATE_ONE: "OC-T1",
    TEMPLATE_TWO: "OC-T2",
    TEMPLATE_THREE: "OC-T3",
  },
  PARTNER_SUPPORT_COUNTRIES: ["AR", "PE", "EC", "BR", "CL", "CO", "MX", "US"],
  LANGUAGE_DATA: [
    {
      key: "en",
      countryCode: "US",
      language: IntlUtil.getText(_oc_common, "languageCode.en"),
    },
    {
      key: "es",
      countryCode: "AR",
      language: IntlUtil.getText(_oc_common, "languageCode.es"),
    },
    {
      key: "es",
      countryCode: "PE",
      language: IntlUtil.getText(_oc_common, "languageCode.es"),
    },
    {
      key: "es",
      countryCode: "EC",
      language: IntlUtil.getText(_oc_common, "languageCode.es"),
    },
    {
      key: "pt",
      countryCode: "BR",
      language: IntlUtil.getText(_oc_common, "languageCode.pt"),
    },
    {
      key: "fr",
      countryCode: "FR",
      language: IntlUtil.getText(_oc_common, "languageCode.fr"),
    },
  ],
  FORM_SUBMIT_STATUS: {
    SUCCESS: "S",
    FAILURE: "F",
    INFO: "I",
  },
  GENERAL: {
    LIST_DATA_REFRESH_INTERVAL_SECONDS: 3600, // 60 Mins
    YES: "Yes",
    NO: "No",
    FORMATTER_TYPE: {
      NUMBER: "Number",
    },
    TIME_ZONES: [
      {
        countryCode: "US",
        countryName: "United States",
        timezone: "America/New_York",
      },
      {
        countryCode: "CA",
        countryName: "Canada",
        timezone: "America/New_York",
      },
    ],
  },
  PRODUCT_GROUP_ADDON: "Addon",
  QUOTE: {
    PROGRESS: {
      SELECT_PRODUCTS: 0,
      CREATE_ACCOUNT: 1,
      CHECKOUT: 2,
      ORDER_CONFIRMATION: 3,
    },
    ORDER_CHECKOUT: {
      ZERO_AMOUNT: 0,
      MINIMUM_PAYABLE_AMOUNT: 1,
      MAXIMUM_PAYABLE_AMOUNT: 999999,
      ATTACHMENT_FILE_MAX_SIZE: 1000, // in KB
      ATTACHMENT_FILE_TYPES: [".doc", ".docx"],
      SERVICE_ORDER_FORM_URL:
        "https://7361942.fs1.hubspotusercontent-na1.net/hubfs/7361942/Lumen%20LATAM%20Docs/Spanish/Service%20Order%20Form%20Spanish.docx",
      PAYMENT_TABS: [
        { tabName: "Purchase Order", tabValue: "companyPo" },
        { tabName: "Credit Card", tabValue: "creditCard" },
        { tabName: "ACH Bank Debit", tabValue: "achDirectDebit" },
      ],
      PAYMENT_TYPE: {
        CARD: "Card",
        DIRECT_DEBIT: "ACH Direct Debit",
      },
      ORDER_TYPE: {
        PURCHASE_ORDER: "Purchase Order",
        CREDIT_CARD: "Credit Card",
        ACH_BANK_DEBIT: "ACH Bank Debit",
      },
    },
    CHARGE_TYPE: {
      ONE_TIME: "Onetime",
      RECURRING: "Recurring",
    },
    CHARGE_NAME: {
      PLATFORM_CHARGES: "Platform Charges",
      ACTIVATION_FEE: "Activation Fee",
      PORTING_FEE: "Porting Fee",
      DID_CHARGES: "DID Charges",
      MOVE_FEE: "Move Fee",
    },
    QUOTE_TYPE_LIST: [
      { key: "New", text: "New Order" },
      { key: "Move", text: "Move/Change" },
    ],
    ITEM_TYPE: {
      MOVE: "Move",
      SOURCE: "Source",
      TARGET: "Target",
    },
    FOC_COUNTRIES: ["United States", "Canada"],
  },
  PAYMENT_STATUS: {
    FAILED: "Failed",
    SUCCEEDED: "Succeeded",
    PENDING: "Pending",
    PROCESSING: "Processing",
    UNAVAILABLE: "unavailable",
  },
  PROCESS_STATUS: {
    PAYMENT_SUBMIT: "PaymentSubmit",
    ACCOUNT_CREATE: "AccountCreate",
    QUOTE_CREATE: "QuoteCreate",
    SUBSCRIBER_CREATE: "SubscriberCreate",
  },
  AZURE_APPLICATION_INSIGHTS_LOG_MESSAGE: {
    QUOTE_CUSTOMER_DATA_SUBMITTED: "Quote - Customer data is submitted",
    QUOTE_PRODUCT_DATA_ADDED: "Quote - Product data is added",
    QUOTE_PRODUCT_DATA_UPDATED: "Quote - Product data is updated",
    QUOTE_PRODUCT_DATA_DELETED: "Quote - Product data is deleted",
  },
};
