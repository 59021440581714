import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageUtil from "../helpers/page-util";
import { getLanguage } from "../../pages/common/actions/common-actions";

const AppPageTitle = ({ pageTitle }) => {
    return (
        <Helmet>
            <html lang={getLanguage()} />
            <title>{PageUtil.getHeadTitle(pageTitle)}</title>
        </Helmet>
    );
};

export { AppPageTitle };