import { DropdownMenuItemType } from "@fluentui/react";
import {
  COUNTRY_STATES_DATA,
  LANGUAGE_DATA,
  CURRENCY_DATA,
  SELECTED_LANGUAGE_DATA,
  SELECTED_CURRENCY_DATA,
  TENANT_ID,
  WEB_TEMPLATE_CONFIG_DATA,
} from "../../../store/constants";
import { OperatorConnectConstants } from "../settings/operator-connect-constants";
import momentZone from "moment-timezone";


export function getTimeDifferenceSeconds(startTimestamp, endTimestamp) {
  let durationSeconds = null;
  if (startTimestamp !== null && startTimestamp !== undefined && endTimestamp) {
    durationSeconds = parseInt(
      (endTimestamp.getTime() - startTimestamp.getTime()) / 1000
    );
  }
  return durationSeconds;
}

export function setWebTemplateConfigData(webTemplateConfigData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: WEB_TEMPLATE_CONFIG_DATA,
        payload: webTemplateConfigData,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setCountryStatesData(countryStatesData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: COUNTRY_STATES_DATA,
        payload: countryStatesData,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setLanguageData(languageData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: LANGUAGE_DATA,
        payload: languageData,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setTenantId(tenantId) {
  return async function (dispatch) {
    try {
      dispatch({
        type: TENANT_ID,
        payload: tenantId,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setSelectedLanguageData(languageData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: SELECTED_LANGUAGE_DATA,
        payload: languageData,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function getCountryQuoteStatesList(countryStatesData) {
  let countryStatesList = [{ key: "", text: "" }];
  if (countryStatesData && countryStatesData.length > 0) {
    let partnerSupportedCountries =
      OperatorConnectConstants.PARTNER_SUPPORT_COUNTRIES;
    countryStatesData.forEach((country) => {
      if (partnerSupportedCountries.includes(country.countryCode) === true) {
        countryStatesList.push({
          key: country?.countryName,
          text: country.countryName,
        });
      }
    });
  }
  return countryStatesList;
}

export function redirectUrl(url) {
  if (url) {
    if (
      url?.startsWith("http://") === false &&
      url?.startsWith("https://") === false
    ) {
      window.open("http://" + url, "_blank");
    } else {
      window.open(url, "_blank");
    }
  }
}

export function getCountryStatesList(countryStatesData) {
  let countryStatesList = [];
  if (countryStatesData && countryStatesData.length > 0) {
    countryStatesData.forEach((country) => {
      countryStatesList.push({
        key: country?.countryName,
        text: country.countryName,
      });
    });
  }

  return countryStatesList;
}

export async function handleSelectedLanguageData(
  records,
  selectedlanguageCode
) {
  let filteredText = "";
  if (records && records.length > 0) {
    records.forEach((language) => {
      if (language.languageCode === selectedlanguageCode) {
        filteredText = language.translation;
      }
    });
  }
  return filteredText;
}
export function getCountryList(countryList) {
  let countries = [];
  if (countryList && countryList.length > 0) {
    countryList.forEach((country) => {
      countries.push({
        id: country.text,
        key: country.text,
        text: country.text,
      });
    });
  }
  return countries;
}

export function buildDynamicQueryUrl(url, parameters) {
  if (parameters !== null && Object.keys(parameters).length > 0) {
    let queryString = new URLSearchParams(parameters);
    return url + "?" + queryString.toString();
  }
  return url;
}

export function setCurrencyData(currencyData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: CURRENCY_DATA,
        payload: currencyData,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setSelectedCurrencyData(currencyData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: SELECTED_CURRENCY_DATA,
        payload: currencyData,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setFontFamily(fontFamily) {
  return `${fontFamily}, sans-serif`;
}

export function handleImageSource(src) {
  if (src && !src?.startsWith("https://")) {
    src = `data:image;base64,${src}`;
  }
  return src;
}
export function getTimezone(date, time, country) {
  let zuluTime = null;
  let zoneName = null;
  if (date && time && country) {
    let timezoneObject = null;
    timezoneObject = OperatorConnectConstants.GENERAL.TIME_ZONES.find(
      (timezone) =>
        timezone.countryName?.toLowerCase().trim() ===
        country?.toLowerCase().trim()
    );
    if (timezoneObject) {
      zoneName = momentZone(
        `${momentZone(date).format("YYYY-MM-DD")}T${time}:00+00:00`
      )
        .tz(timezoneObject.timezone)
        .format("z");
      zuluTime = momentZone(
        `${momentZone(date).format("YYYY-MM-DD")}T${time}:00+00:00`
      )
        .tz(timezoneObject.timezone)
        .format("Z");
    }
  }
  if (
    zoneName !== undefined &&
    zoneName !== null &&
    zuluTime !== undefined &&
    zuluTime !== null
  ) {
    return { zuluTime: zuluTime, zoneName: zoneName };
  } else {
    return { zuluTime: "-05:00", zoneName: "EST" };
  }
}
