import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { manageError } from "../../../core/actions/common-actions";
import { AppPageTitle } from "../../../core/components/app-page-title";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import TelemetryUtil from "../../../core/helpers/telemetry-util";
import { AppConfigProps } from "../../../core/settings/app-config";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import {
  Text,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Pivot,
  PivotItem,
  PrimaryButton,
} from "@fluentui/react";
import {
  savePayment,
  setQuoteProgressPage,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setOrderPublisherKey,
  setOrderConnectedAccount,
  setOrderPaymentInfo,
} from "../actions/quote-action";
import OrderCheckoutCard from "../helper/order-checkout-card";
import OrderCheckoutBank from "../helper/order-checkout-bank";
import OrderCheckoutCompanyPO from "../helper/order-checkout-company";
import CustomStyles from "../../common/helpers/custom-styles";
import CustomDialog from "../../common/helpers/custom-dialog";
import PageLoader from "../../common/helpers/page-loader";
import classNames from "classnames";

class OrderCheckout extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_oc_quote = "oc_quote";
  _intl_ns_oc_common = "oc_common";

  constructor(props) {
    super(props);
    this.orderCheckoutRef = React.createRef();
    this.state = {
      paymentTabs: OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS,
      tabSelected:
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[0],
      paymentObj: null,
      clientSecret: null,
      isTabDisabled: false,
      isPageDataFetched: false,
      isPaymentDialogHidden: true,
      websiteTemplate: null,
      isFromDataSubmitted: true,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtil.scrollToTop();
    TelemetryUtil.trackPageView(
      IntlUtil.getText(this._intl_ns_oc_quote, "title.checkout")
    );
    await this.loadPageData();
    await this.setStateAsync({ isPageDataFetched: true });
  }

  loadPageData = async () => {
    await this.setStateAsync({
      websiteTemplate: this.props.webTemplateConfigData?.websiteTemplate,
    });
    let options = OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS;
    let tabSelected = options[0];

    if (this.props.webTemplateConfigData?.paymentMethods?.length > 0) {
      let methods = this.props.webTemplateConfigData?.paymentMethods;
      methods = methods.map((meth) => meth.name);
      options = options.filter((op) => methods.includes(op?.tabName));
      tabSelected = options[0];
    }
    await this.setStateAsync({ paymentTabs: options, tabSelected });
    if (this.props.tabSelected && this.props.clientSecret) {
      await this.setStateAsync({
        tabSelected: this.props.tabSelected,
        clientSecret: this.props.clientSecret,
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }

  handleTabSelect = async (tab) => {
    let tabSelected = null;
    let headerText = tab.props.itemKey;
    if (headerText !== this.state.tabSelected?.tabName) {
      if (this.state.paymentTabs) {
        tabSelected = this.state.paymentTabs.find((tab) => {
          return tab.tabName === headerText;
        });
      }
      await this.props.setOrderConnectedAccount(null);
      await this.props.setOrderPaymentInfo(null);
      await this.setStateAsync({
        tabSelected: tabSelected,
        clientSecret: null,
      });
    }
  };

  closePaymentDialog = async () => {
    await this.setStateAsync({ isPaymentDialogHidden: true });
  };

  capitalizeLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  setpaymentData = async (paymentObjData) => {
    if (paymentObjData) {
      let paymentObj = null;
      if (
        this.state.tabSelected.tabName ===
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[0].tabName
      ) {
        paymentObj = {
          languageCode: this.props.selectedLanguageData?.key,
          paymentType:
            OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ORDER_TYPE
              .PURCHASE_ORDER,
          paymentData: {
            transactionNumber:
              paymentObjData.companyPoFormInputFIelds?.companyPO?.value?.trim(),
            name: paymentObjData.companyPoFormInputFIelds?.quoteName?.value?.trim(),
            email:
              paymentObjData.companyPoFormInputFIelds?.email?.value?.trim(),
            phone: paymentObjData.companyPoFormInputFIelds.phone?.value?.trim(),
            transactionStatus: null,
          },
          attachments: paymentObjData.attachmentsFile,
        };
      } else if (
        this.state.tabSelected.tabName ===
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[1].tabName
      ) {
        paymentObj = {
          languageCode: this.props.selectedLanguageData?.key,
          paymentType:
            OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ORDER_TYPE
              .CREDIT_CARD,
          paymentData: {
            name: null,
            email: null,
            phone: null,
            transactionNumber: paymentObjData.paymentDetails.transactionId,
            currencyCode: paymentObjData.paymentDetails.currencyCode,
            paymentAmount: paymentObjData.paymentDetails.amount,
            transactionStatus:
              paymentObjData.paymentDetails.status !==
              OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE
                ? this.capitalizeLetter(paymentObjData.paymentDetails.status)
                : OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE,
          },
          attachments: [],
        };
      } else if (
        this.state.tabSelected.tabName ===
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[2].tabName
      ) {
        paymentObj = {
          languageCode: this.props.selectedLanguageData?.key,
          paymentType:
            OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ORDER_TYPE
              .ACH_BANK_DEBIT,
          paymentData: {
            name: null,
            email: null,
            phone: null,
            transactionNumber: paymentObjData.paymentDetails.transactionId,
            currencyCode: paymentObjData.paymentDetails.currencyCode,
            paymentAmount: paymentObjData.paymentDetails.amount,
            transactionStatus: this.capitalizeLetter(
              paymentObjData.paymentDetails.status
            ),
          },
          attachments: [],
        };
      }
      await this.setStateAsync({ paymentObj: paymentObj });
    }
  };

  renderSelectedtab = (tabName) => {
    switch (tabName) {
      case OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[0]
        .tabName:
        return (
          <OrderCheckoutCompanyPO
            websiteTemplate={this.state.websiteTemplate}
            intlNamespace={this._intl_ns_oc_quote}
            orderCheckoutRef={this.orderCheckoutRef}
            quoteEstimatorInfo={this.props.quoteEstimatorInfo}
            setPageExit={(val) => {
              this.props.setPageExit(val);
            }}
            setTabDisabled={(tabDisabled) =>
              this.setStateAsync({ isTabDisabled: tabDisabled })
            }
            setCompanyFormData={async (val) => {
              await this.setStateAsync({
                isPaymentDialogHidden: val.isPaymentDialogHidden,
              });
              await this.setpaymentData(val);
            }}
          />
        );
      case OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[1]
        .tabName:
        return (
          <OrderCheckoutCard
            clientSecret={this.state.clientSecret}
            websiteTemplate={this.state.websiteTemplate}
            subscriberQuoteRecords={
              this.props.quoteEstimatorInfo.subscriberQuoteRecords
            }
            setTabDisabled={(tabDisabled) =>
              this.setStateAsync({ isTabDisabled: tabDisabled })
            }
            setpaymentCardFormData={async (val) => {
              await this.setStateAsync({
                isPaymentDialogHidden: val.isPaymentDialogHidden,
              });
              await this.setpaymentData(val);
              if (this.state.isPaymentDialogHidden === true) {
                this.handlePaymentFormSubmit();
              }
            }}
          />
        );
      case OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[2]
        .tabName:
        return (
          <OrderCheckoutBank
            clientSecret={this.state.clientSecret}
            websiteTemplate={this.state.websiteTemplate}
            subscriberQuoteRecords={
              this.props.quoteEstimatorInfo.subscriberQuoteRecords
            }
            setTabDisabled={(tabDisabled) =>
              this.setStateAsync({ isTabDisabled: tabDisabled })
            }
            setpaymentAchFormData={async (val) => {
              await this.setStateAsync({
                isPaymentDialogHidden: val.isPaymentDialogHidden,
              });
              await this.setpaymentData(val);
              if (this.state.isPaymentDialogHidden === true) {
                this.handlePaymentFormSubmit();
              }
            }}
          />
        );
      default:
        return (
          <OrderCheckoutCompanyPO
            websiteTemplate={this.state.websiteTemplate}
            intlNamespace={this._intl_ns_oc_quote}
            orderCheckoutRef={this.orderCheckoutRef}
            quoteEstimatorInfo={this.props.quoteEstimatorInfo}
            setPageExit={(val) => {
              this.props.setPageExit(val);
            }}
            setTabDisabled={(tabDisabled) =>
              this.setStateAsync({ isTabDisabled: tabDisabled })
            }
            setCompanyFormData={async (val) => {
              await this.setStateAsync({
                isPaymentDialogHidden: val.isPaymentDialogHidden,
              });
              await this.setpaymentData(val);
            }}
          />
        );
    }
  };

  renderPaymentSaveDialog = () => {
    return (
      <CustomDialog
        hidden={this.state.isPaymentDialogHidden}
        title={IntlUtil.getText(
          this._intl_ns_oc_quote,
          "notification.warning.checkoutOrderTitle"
        )}
        modalPropsClassName="quote-dialog-wrapper"
        onPrimaryButtonClick={this.handlePaymentFormSubmit}
        onDefaultButtonClick={this.closePaymentDialog}
      >
        {
          <>
            <Text>
              {IntlUtil.getText(
                this._intl_ns_oc_quote,
                "notification.warning.checkoutOrderTextPrefix"
              )}
              {"?"}
            </Text>
          </>
        }
      </CustomDialog>
    );
  };

  handlePaymentFormSubmit = async () => {
    await this.setStateAsync({ isFromDataSubmitted: false });
    await this.setStateAsync({ isPaymentDialogHidden: true });
    let quoteId =
      this.props?.quoteEstimatorInfo?.subscriberQuoteRecords?.quoteId;
    await savePayment(quoteId, this.state.paymentObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          let productList = [];
          let customerDetailsObject = {
            quoteName: res.data.result.customerName,
            email: res.data.result.customerEmail,
            phone: res.data.result.customerPhone,
          };
          await this.props.setQuoteEstimatorInfoQuoteSubscriberDetails(
            this.props.quoteEstimatorInfo,
            customerDetailsObject
          );
          await res.data.result.products.forEach((rec) => {
            rec.selectedCountry = rec.availabilityName;
            rec.connection = rec.planName.trim();
            productList.push({ ...rec });
          });
          let quoteRecord = { ...res.data.result, products: productList };
          let resourceRecords = [];
          let onboardServiceDocumentRecords = [];
          if (
            quoteRecord &&
            quoteRecord.resources &&
            quoteRecord.resources.length > 0
          ) {
            quoteRecord.resources.forEach((rec) => {
              resourceRecords.push(rec);
            });
          }
          if (
            quoteRecord &&
            quoteRecord.evidences &&
            quoteRecord.evidences.length > 0
          ) {
            quoteRecord.evidences.forEach((doc) => {
              onboardServiceDocumentRecords.push({
                countryName: doc.category,
                documents: doc.documents,
              });
            });
          }
          let nrcSubTotal = 0;
          let mrcSubTotal = 0;
          let total = 0;
          let allServiceRecords = [];
          if (
            quoteRecord &&
            quoteRecord.products &&
            quoteRecord.products.length > 0
          ) {
            await quoteRecord.products.forEach((obj, index) => {
              let mrcCount = 0;
              let nrcCount = 0;
              let nrcPartnerCost = 0;
              let mrcPartnerCost = 0;
              let mrcDiscount = 0;
              let nrcDiscount = 0;
              obj.pricing.forEach((price) => {
                if (
                  price.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                ) {
                  nrcCount =
                    nrcCount +
                    parseFloat(obj?.quantity ?? "0") *
                      parseFloat(price?.sellingPrice ?? "0");
                  nrcPartnerCost = price?.retailPrice ?? "0";
                }
                if (
                  price.chargeType?.toLowerCase().trim() ===
                  OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                ) {
                  mrcCount =
                    mrcCount +
                    parseFloat(obj?.quantity ?? "0") *
                      parseFloat(price?.sellingPrice ?? "0");
                  mrcPartnerCost = price?.retailPrice ?? "0";
                }
              });

              nrcSubTotal = nrcSubTotal + nrcCount;
              mrcSubTotal = mrcSubTotal + mrcCount;
              allServiceRecords.push({
                ...obj,
                connection: obj?.planName,
                nrcPartnerCost: nrcPartnerCost,
                mrcPartnerCost: mrcPartnerCost,
                nrcDiscount: nrcDiscount,
                mrcDiscount: mrcDiscount,
                id: index,
                mrc: mrcCount.toString(),
                nrc: nrcCount.toString(),
              });
            });
            total = total + nrcSubTotal + mrcSubTotal;
            await this.setStateAsync({
              quoteRecord: {
                ...quoteRecord,
                products: allServiceRecords,
                nrcSubTotal: nrcSubTotal,
                mrcSubTotal: mrcSubTotal,
                totalCost: total,
                resourceRecords: resourceRecords,
                onboardServiceDocumentRecords: onboardServiceDocumentRecords,
              },
            });
            await this.props.setQuoteEstimatorInfoSubscriberQuoteRecords(
              this.props.quoteEstimatorInfo,
              this.state.quoteRecord
            );
            await this.props.setQuoteProgressPage(
              OperatorConnectConstants.QUOTE.PROGRESS.ORDER_CONFIRMATION
            );
            await this.setStateAsync({ isFromDataSubmitted: true });
          } else {
            await this.setStateAsync({ isFromDataSubmitted: true });
          }
          await this.setStateAsync({ paymentObj: null });
          await this.setStateAsync({ isFromDataSubmitted: true });
        } else {
          await this.setStateAsync({ isFromDataSubmitted: true });
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ isFromDataSubmitted: true });
        await this.props.setPageExit(false);
        await manageError(err, this.props.history);
      });
  };

  render() {
    return (
      <>
        <div
          className="page-frame-content frame-content-quotes"
          ref={this.orderCheckoutRef}
        >
          <span
            className={
              "quote-page-text-wrapper text-fw-semibold text-fs-large m-l-10 " +
              CustomStyles.getStyle(
                this.state.websiteTemplate?.templateCode
                  ?.toLowerCase()
                  .trim() !==
                  OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? "text-fc-primary"
                  : "text-fc-secondary",
                this.state.websiteTemplate
              )
            }
          >
            {IntlUtil.getSubstituteText(
              this._intl_ns_oc_quote,
              "content.quoteReferenceNumber",
              [
                {
                  key: "<QUOTE_NUMBER>",
                  value:
                    this.props?.quoteEstimatorInfo?.subscriberQuoteRecords
                      ?.quoteNumber,
                },
              ]
            )}
          </span>
        </div>
        <div
          className={`${CustomStyles.getStyle(
            "page-content-separator",
            this.state.websiteTemplate
          )}`}
        ></div>
        <div>
          <Pivot
            className="order-payment-stats-tabs"
            selectedKey={this.state.tabSelected?.tabName}
            onLinkClick={(props) => {
              this.handleTabSelect(props);
            }}
            headersOnly={true}
            linkFormat="links"
            overflowBehavior="menu"
          >
            {this.state.paymentTabs.map((tab, index) => {
              return (
                <PivotItem
                  key={`key-user-tab-${index}`}
                  headerButtonProps={{
                    disabled: this.state.isTabDisabled,
                  }}
                  headerText={IntlUtil.getText(
                    this._intl_ns_oc_quote,
                    `content.${tab.tabValue}`
                  )}
                  itemKey={tab.tabName}
                />
              );
            })}
          </Pivot>
          <PageLoader
            status={this.state.isPageDataFetched}
            size="medium"
            labelPosition="right"
            label={IntlUtil.getText(
              this._intl_ns_oc_common,
              "content.loadingInprogress"
            )}
            type="inline"
          >
            <div
              className={classNames({
                "p-30": this.state.isPageDataFetched === false,
              })}
            >
              {this.renderSelectedtab(this.state.tabSelected.tabName)}
            </div>
          </PageLoader>
          <PageOverlayLoader
            hidden={this.state.isFromDataSubmitted}
            label={IntlUtil.getText(
              this._intl_ns_oc_common,
              "content.loadingInprogress"
            )}
          />

          {/* <AppPageTitle
            pageTitle={IntlUtil.getText(
              this._intl_ns_oc_quote,
              "title.checkout"
            )}
          /> */}
          {this.renderPaymentSaveDialog()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
});
const mapActionToProps = {
  setQuoteProgressPage,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setOrderPublisherKey,
  setOrderConnectedAccount,
  setOrderPaymentInfo,
};

export default connect(mapStateToProps, mapActionToProps)(OrderCheckout);
