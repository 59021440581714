import api from "../helpers/api-services";
import TokenUtil from "../helpers/token-util";
import { AppConfigProps } from "../settings/app-config";
import { logMessage } from "./common-actions";
import { TOKEN_EXPIRY_TIME } from "../../store/constants";
import RequestUtil from "../helpers/request-util";

const actionFileName = "identity-actions.js";

export function authenticateToken(websiteUrl, cancelToken) {
  let headers = {
    "Content-Type": "application/json",
    OperatorWebsiteUrl: websiteUrl,
  };
  return new Promise(async function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/authentication/token",
        { data: "" },
        { headers: headers },
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">authenticateToken()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function checkTokenSession() {
  const tokenData = TokenUtil.getIdentitySippioAccessTokenData();
  if (
    tokenData &&
    tokenData.accountName &&
    tokenData.accountNumber &&
    tokenData.partnerId &&
    tokenData.expiry >= Date.now() / 1000
  ) {
    return true;
  } else {
    return false;
  }
}

export function setTokenData(tokenExpiryTime) {
  return async function (dispatch) {
    try {
      dispatch({
        type: TOKEN_EXPIRY_TIME,
        payload: tokenExpiryTime,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function renewIdentitySession(websiteUrl, cancelToken) {
  return new Promise(async function (resolve, reject) {
    let headers = {
      "Content-Type": "application/json",
      OperatorWebsiteUrl: websiteUrl,
    };

    await api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/authentication/token",
        { data: "" },
        { headers: headers },
        cancelToken
      )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result &&
          res.data.result.accessToken
        ) {
          TokenUtil.setIdentityToken(res.data.result.accessToken);
          RequestUtil.setRequestHeaders();
        } else {
          //reject(new Error());
        }
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">renewIdentitySession()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          //reject(err.response);
        }
      });
  });
}
