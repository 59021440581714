import IntlUtil from "../../../core/helpers/intl-util";

export const QuoteOrderTableColumns = (intlNamespace) => {
  return [
    {
      key: "quoteNumber",
      name: IntlUtil.getText(intlNamespace, "content.referenceNumber"),
      fieldName: "quoteNumber",
      data: "string",
      minWidth: 230,
      maxWidth: 230,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "customerName",
      name: IntlUtil.getText(intlNamespace, "content.name"),
      fieldName: "customerName",
      data: "string",
      minWidth: 230,
      maxWidth: 230,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "customerEmail",
      name: IntlUtil.getText(intlNamespace, "content.email"),
      fieldName: "customerEmail",
      data: "string",
      minWidth: 230,
      maxWidth: 230,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "createTimestamp",
      name: IntlUtil.getText(intlNamespace, "content.createdDate"),
      fieldName: "createTimestamp",
      data: "string",
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: "actions",
      name: IntlUtil.getText(intlNamespace, "content.actions"),
      fieldName: "actions",
      data: "",
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: false,
      isSortable: false,
      isSorted: false,
      isSortedDescending: false,
    },
  ];
};
