import { SearchBox } from "@fluentui/react";
import IntlUtil from "../../../core/helpers/intl-util";
import React, { useState, useEffect } from "react";
import { OperatorConnectURLProps } from "../settings/operator-connect-urls";
import { useHistory, generatePath } from "react-router-dom";
import { setFontFamily } from "../../common/actions/general-actions";
import { connect } from "react-redux";

const Welcome = (props) => {
  let _oc_common = "oc_common";
  let history = useHistory();
  let searchkeyword = new URLSearchParams(props.location?.search).get("search");
  let [searchValue, setSearch] = useState(searchkeyword);

  useEffect(() => {
    if (
      searchkeyword === null ||
      searchkeyword === "" ||
      searchkeyword === undefined
    ) {
      setSearch("");
    }
  }, []);

  const setquoteSearch = (e) => {
    if (e && e.target) {
      setSearch(e.target.value);
    }
  };

  const useNavigateQueryStringParams = () => {
    const history = useHistory();
    return (url, params) => {
      const path = generatePath(":url?:queryString", {
        url,
        queryString: params,
      });
      history.push(path);
    };
  };

  const navigate = useNavigateQueryStringParams();

  const handleSearchSubmit = () => {
    if (
      searchValue.trim() !== "" &&
      searchValue.trim() !== null &&
      searchValue.trim().length > 0
    ) {
      history.push({
        pathname: OperatorConnectURLProps.orderManagement.quoteOrders,
        search: `?search=${searchValue?.trim()}`,
      });
    }
  };

  const welcomeWrapperStyles = {
    color: `${props?.webTemplateConfigData?.websiteTemplate?.bannerSettings?.bannerForegroundColor}`,
    backgroundColor: `${props?.webTemplateConfigData?.websiteTemplate?.bannerSettings?.bannerBackgroundColor}`,
    fontFamily: setFontFamily(
      props?.webTemplateConfigData?.websiteTemplate?.generalSettings?.font
    ),
  };

  const handleSearchCancel = () => {
    setSearch("");
  };
  const handleSelectedLanguageData = (records, selectedlanguageCode) => {
    let filteredText = "";
    if (records && records.length > 0) {
      records.forEach((language) => {
        if (language.languageCode === selectedlanguageCode) {
          filteredText = language.translation;
        }
      });
    }
    return filteredText;
  };

  return (
    <>
      <div
        style={{ ...welcomeWrapperStyles }}
        className="oc-t1-banner-welcome-wrapper"
      >
        <div
          className="oc-welcome-text"
          dangerouslySetInnerHTML={{
            __html: handleSelectedLanguageData(
              props?.webTemplateConfigData.websiteTemplate.bannerSettings
                ?.bannerText,
              props.selectedLanguageData?.key
            ),
          }}
        ></div>
        <div className="oc-welcome-actions">
          <span className="m-r-20 oc-welcome-info">
            {IntlUtil.getText(_oc_common, "content.Retrieve")}
          </span>
          <SearchBox
            styles={{ root: { width: 230 } }}
            className="oc-search-box"
            id="quoteKeyword"
            name="quoteKeyword"
            autoComplete="none"
            value={searchValue}
            onChange={setquoteSearch}
            placeholder={IntlUtil.getText(
              _oc_common,
              "content.referenceNumber"
            )}
            disableAnimation={false}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearchSubmit();
              }
            }}
            onClear={() => handleSearchCancel()}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLanguageData: state.generalStore.selectedLanguageData,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
});

const mapActionToProps = {
};

export default connect(mapStateToProps, mapActionToProps)(Welcome);
