import { SearchBox, Image, ImageFit } from "@fluentui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import IntlUtil from "../../../core/helpers/intl-util";
import { OperatorConnectURLProps } from "../settings/operator-connect-urls";
import classnames from "classnames";
import { connect } from "react-redux";
import { handleImageSource } from "../actions/general-actions";
import { OperatorConnectConstants } from "../settings/operator-connect-constants";

const Banner = (props) => {
  const [webTemplate, setWebTemplate] = useState(
    props?.webTemplateConfigData?.websiteTemplate
  );
  const classNames = classnames("", {
    "oc-t1-banner-wrapper":
      webTemplate?.templateCode?.toLowerCase() ===
      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_ONE?.toLowerCase().trim(),
    "oc-t2-banner-wrapper":
      webTemplate?.templateCode?.toLowerCase() ===
      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_TWO?.toLowerCase().trim(),
    "oc-t3-banner-wrapper":
      webTemplate?.templateCode?.toLowerCase() ===
      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim(),
  });
  const welcomePageClassNames = classnames("", {
    "oc-t1-welcome-wrapper":
      webTemplate?.templateCode?.toLowerCase() ===
      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_ONE?.toLowerCase().trim(),
    "oc-t2-welcome-wrapper":
      webTemplate?.templateCode?.toLowerCase() ===
      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_TWO?.toLowerCase().trim(),
    "oc-t3-welcome-wrapper":
      webTemplate?.templateCode?.toLowerCase() ===
      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim(),
  });
  useEffect(() => {}, [props.webTemplateConfigData]);
  let _oc_common = "oc_common";
  let history = useHistory();
  let searchkeyword = new URLSearchParams(props.location?.search).get("search");
  let [searchValue, setSearch] = useState(searchkeyword);

  useEffect(() => {
    if (
      searchkeyword === null ||
      searchkeyword === "" ||
      searchkeyword === undefined
    ) {
      setSearch("");
    }
  }, []);

  const setquoteSearch = (e) => {
    if (e && e.target) {
      setSearch(e.target.value);
    }
  };

  const useNavigateQueryStringParams = () => {
    const history = useHistory();
    return (url, params) => {
      const path = generatePath(":url?:queryString", {
        url,
        queryString: params,
      });
      history.push(path);
    };
  };

  const navigate = useNavigateQueryStringParams();

  const handleSearchSubmit = () => {
    if (
      searchValue.trim() !== "" &&
      searchValue.trim() !== null &&
      searchValue.trim().length > 0
    ) {
      history.push({
        pathname: OperatorConnectURLProps.orderManagement.quoteOrders,
        search: `?search=${searchValue?.trim()}`,
      });
    }
  };

  const handleSearchCancel = () => {
    setSearch("");
  };
  const handleSelectedLanguageData = (records, selectedlanguageCode) => {
    let filteredText = "";
    if (records && records.length > 0) {
      records.forEach((language) => {
        if (language.languageCode === selectedlanguageCode) {
          filteredText = language.translation;
        }
      });
    }
    return filteredText;
  };
  const welcomeWrapperStyles = {
    color: `${webTemplate?.bannerSettings?.bannerForegroundColor}`,
    backgroundColor: `${webTemplate?.bannerSettings?.backgroundColor}`,
    fontFamily: `${webTemplate?.generalSettings?.font}`,
  };

  const welcomeWrapperStylesTemplate2 = {
    color: `${webTemplate?.bannerSettings?.bannerForegroundColor}`,
    backgroundColor: `${webTemplate?.bannerSettings?.backgroundColor}`,
    fontFamily: `${webTemplate?.generalSettings?.font}`,
  };

  const bannerImage = {
    objectFit: "cover",
    maxHeight: "100%",
  };

  const bannerStylesTemplate1 = {
    height: `${
      webTemplate?.bannerSettings?.bannerHeight > 280
        ? webTemplate?.bannerSettings?.bannerHeight
        : 280
    }px`,
    backgroundColor: `${webTemplate?.bannerSettings?.bannerBackgroundColor}`,
    color: `${webTemplate?.bannerSettings?.bannerForgroundColor}`,
    fontFamily: `${webTemplate?.generalSettings?.font}`,
  };

  const bannerStylesTemplate2 = {
    height: `${
      webTemplate?.bannerSettings?.bannerHeight > 280
        ? webTemplate?.bannerSettings?.bannerHeight
        : 280
    }px`,
  };

  const bannerWrapperStylesTemplate1 = {
    minHeight: `${
      webTemplate?.bannerSettings?.bannerHeight > 280
        ? webTemplate?.bannerSettings?.bannerHeight
        : 280
    }px`,
    backgroundColor: `${webTemplate?.bannerSettings?.bannerBackgroundColor}`,
    color: `${webTemplate?.bannerSettings?.bannerForgroundColor}`,
  };

  const bannerWrapperStylesTemplate2 = {
    minHeight: `${
      webTemplate?.bannerSettings?.bannerHeight > 280
        ? webTemplate?.bannerSettings?.bannerHeight
        : 280
    }px`,
    backgroundColor: `${webTemplate?.bannerSettings?.bannerBackgroundColor}`,
    color: `${webTemplate?.bannerSettings?.bannerForgroundColor}`,
  };

  const welcomeTextStyles = {
    fontFamily: `${webTemplate?.generalSettings?.font}`,
    fontSize: "large",
    verticalAlign: "middle",
    alignItems: "center",
  };

  return (
    <>
      {webTemplate.templateCode === "OC-T1" ? (
        <div style={{ ...bannerWrapperStylesTemplate1 }} className={classNames}>
          <div>
            <div
              style={{ ...welcomeWrapperStyles }}
              className={`${welcomePageClassNames} oc-t1-banner-welcome`}
            >
              <div style={{ ...welcomeTextStyles }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: handleSelectedLanguageData(
                      webTemplate.bannerSettings?.bannerText,
                      props.selectedLanguageData?.key
                    ),
                  }}
                ></div>
              </div>
              <div className="oc-t1-welcome-actions">
                <span className="m-r-20 oc-welcome-info">
                  {IntlUtil.getText(_oc_common, "content.Retrieve")}
                </span>
                <SearchBox
                  className="oc-search-box"
                  styles={{ root: { width: 230 } }}
                  id="quoteKeyword"
                  name="quoteKeyword"
                  autoComplete="none"
                  value={searchValue}
                  onChange={setquoteSearch}
                  placeholder={IntlUtil.getText(
                    _oc_common,
                    "content.referenceNumber"
                  )}
                  disableAnimation={false}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearchSubmit();
                    }
                  }}
                  onClear={() => handleSearchCancel()}
                />
              </div>
            </div>
          </div>
          {webTemplate?.bannerSettings?.bannerImage ? (
            <div style={{ ...bannerStylesTemplate1 }}>
              <Image
                style={{ ...bannerImage }}
                src={handleImageSource(
                  webTemplate?.bannerSettings?.bannerImage
                )}
              />
            </div>
          ) : null}
          <div></div>
        </div>
      ) : (
        <div style={{ ...bannerWrapperStylesTemplate2 }} className={classNames}>
          <div
            style={{ ...welcomeWrapperStylesTemplate2 }}
            className={`${welcomePageClassNames} oc-banner-columns`}
          >
            <div className="oc-welcome-wrapper">
              <div
                style={{ ...welcomeTextStyles }}
                dangerouslySetInnerHTML={{
                  __html: handleSelectedLanguageData(
                    webTemplate.bannerSettings?.bannerText,
                    props.selectedLanguageData?.key
                  ),
                }}
              ></div>
              <div className="m-t-15">
                <span className="m-r-20  text-fs-regular">
                  {IntlUtil.getText(_oc_common, "content.Retrieve")}
                </span>
                <SearchBox
                  styles={{ root: { width: 230 } }}
                  id="quoteKeyword"
                  name="quoteKeyword"
                  autoComplete="none"
                  value={searchValue}
                  onChange={setquoteSearch}
                  placeholder={IntlUtil.getText(
                    _oc_common,
                    "content.referenceNumber"
                  )}
                  disableAnimation={false}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearchSubmit();
                    }
                  }}
                  onClear={() => handleSearchCancel()}
                  className="m-t-10 oc-search-box"
                />
              </div>
            </div>
          </div>
          {webTemplate?.bannerSettings?.bannerImage ? (
            <div style={{ ...bannerStylesTemplate2 }}>
              <Image
                className="oc-banner-columns"
                style={{ ...bannerImage }}
                src={handleImageSource(
                  webTemplate?.bannerSettings?.bannerImage
                )}
                // imageFit={ImageFit.cover}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLanguageData: state.generalStore.selectedLanguageData,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(Banner);
