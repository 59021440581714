import {
  PrimaryButton,
  TooltipHost,
  Text,
  Image,
  DefaultButton,
  Callout,
  DirectionalHint,
  mergeStyleSets,
  TooltipOverflowMode,
  css,
} from "@fluentui/react";
import axios from "axios";
import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import _ from "lodash";
import { Row, Col } from "react-flexbox-grid";
import {
  AddToShoppingListIcon,
  Link12Icon,
  PhoneIcon,
  TextDocumentIcon,
  ReportWarningIcon,
} from "@fluentui/react-icons";
import { OperatorConnectURLProps } from "../../common/settings/operator-connect-urls";
import { useParams } from "react-router-dom";
import { AppPageTitle } from "../../../core/components/app-page-title";
import { getProduct } from "../actions/product-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import { manageError } from "../../../core/actions/common-actions";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import {
  getCountryStates,
  getProducts,
  setProductRecords,
} from "../../common/actions/common-actions";
import {
  setCurrencyData,
  setCountryStatesData,
  setSelectedCurrencyData,
  handleImageSource,
} from "../../common/actions/general-actions";
import { setSelectedProductId } from "../../quotes/actions/quote-action";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import Welcome from "../../common/components/welcome";
import { useState } from "react";
import CustomStyles from "../../common/helpers/custom-styles";
import PageHeader from "../../common/helpers/page-header";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ReactCountryFlag from "react-country-flag";
import { setFontFamily } from "../../common/actions/general-actions";

const ProductView = (props) => {
  const _axiosSource = axios.CancelToken.source();
  const _cancelToken = { cancelToken: _axiosSource.token };
  const _intl_ns_common = "oc_common";
  const _intl_ns_oc_product = "oc_product";
  const setCalloutWidth =
    document.querySelector("#oc-product-align")?.offsetWidth;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { isPageDataFetched: true, productRecord: null }
  );
  const [products, setProducts] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.selectedCurrencyData
  );
  const [websitePageTitle, setWebsitePageTitle] = useState({});
  const [webTemplate, setWebTemplate] = useState(
    props?.webTemplateConfigData?.websiteTemplate
  );
  const [isProductListCalloutVisible, setIsProductListCalloutVisible] =
    React.useState(false);

  const productViewRef = useRef();
  const productId = useParams().pid;

  useEffect(() => {
    const getData = async () => {
      await setState({ isPageDataFetched: false });
      await fetchProductData(productId);
      if (
        props.countryStatesData === null ||
        props.countryStatesData.length === 0
      ) {
        await fetchCountries();
      }
      let websiteTitle = webTemplate?.contentPages.find(
        (rec) => rec.pageCode?.toLowerCase().trim() === "oc-p2"
      );
      setWebsitePageTitle(websiteTitle);
      await setState({ isPageDataFetched: true });
    };
    getData();
  }, [props.selectedLanguageData.key]);

  const getCountryCode = (countryName) => {
    let countryCode = "";
    if (props.countryStatesData?.length > 0) {
      props.countryStatesData.forEach((rec) => {
        if (
          rec.countryName?.toLowerCase().trim() ===
          countryName?.toLowerCase().trim()
        ) {
          countryCode = rec.countryCode;
        }
      });
    }
    return countryCode;
  };

  const fetchProductData = async (productId) => {
    await axios
      .all([
        getProduct(productId, props.selectedLanguageData.key, _cancelToken),
        getProducts(props.selectedLanguageData.key, _cancelToken),
      ])
      .then(
        axios.spread(async (product, products) => {
          if (
            product &&
            product.status === AppConfigProps.httpStatusCode.ok &&
            product.data &&
            product.data.result &&
            products &&
            products.status === AppConfigProps.httpStatusCode.ok &&
            products.data &&
            products.data.records
          ) {
            if (product.data.result) {
              setState({ productRecord: product.data.result });
              setState({ isPageDataFetched: true });
            }
            if (product.data) {
              await props.setProductRecords(products.data);
              await setProductCurrenciesList(products.data.records);
              await setProductCurrencyList(
                products.data.records,
                selectedCurrency
              );
              setState({ isPageDataFetched: true });
            }
          }
        })
      )
      .catch(async (err) => {
        if (
          err &&
          err.status &&
          err.status === AppConfigProps.httpStatusCode.badRequest
        ) {
          setState({ productRecord: null });
          setState({ isPageDataFetched: true });
        } else {
          setState({ isPageDataFetched: true });
          await manageError(err, props.history);
        }
      });
  };

  const fetchCountries = async () => {
    if (
      props.countryStatesData === null ||
      props.countryStatesData.length === 0
    ) {
      await getCountryStates(_cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            await props.setCountryStatesData(res.data.records);

            await setState({ isPageDataFetched: true });
          }
        })
        .catch(async (err) => {
          await manageError(err, props.history);
        });
    }
  };

  const setProductCurrencyList = async (productRecords, selectedCurrency) => {
    setSelectedCurrency(selectedCurrency);
    await props.setSelectedCurrencyData(selectedCurrency);
    let records = [];
    if (productRecords && productRecords.length > 0) {
      records = productRecords.filter(
        (rec) =>
          rec.currencyCode?.toLowerCase() ===
          selectedCurrency?.key?.toLowerCase()
      );
    }
    let productOrderList = _.orderBy(records, (rec) => rec.productName);
    setProducts(productOrderList);
  };

  const currencyProductChange = async (productRecords, selectedCurrency) => {
    let records = [];
    if (productRecords && productRecords.length > 0) {
      records = productRecords.filter(
        (rec) =>
          rec.currencyCode?.toLowerCase() ===
          selectedCurrency?.key?.toLowerCase()
      );
    }
    let productOrderList = _.orderBy(records, (rec) => rec.productName);
    await handleProductView(productOrderList[0]);
  };

  const setProductCurrenciesList = async (productRecords) => {
    let productsCurrenciesList = [];
    if (productRecords && productRecords.length > 0) {
      productRecords.forEach((rec) => {
        productsCurrenciesList.push({
          key: rec.currencyCode,
          text: `${rec.currencyCode} (${rec.currencySymbol})`,
        });
      });
    }

    let productsCurrenciesUniqueList = _.uniqBy(
      productsCurrenciesList,
      (rec) => rec.key
    );

    props.setCurrencyData(productsCurrenciesUniqueList);
    await props.setSelectedCurrencyData(
      productsCurrenciesUniqueList.length > 1
        ? selectedCurrency
        : productsCurrenciesUniqueList[0]
    );
  };

  const renderCountryIcon = (countryName) => {
    return (
      <div className="country-icon-align">
        <div className="country-img">
          <ReactCountryFlag
            countryCode={getCountryCode(countryName)}
            svg
            cdnSuffix="svg"
            title="US"
          />
        </div>
        {/* <div className="country-img-chevron">
          <FontIcon
            aria-label="Compass"
            iconName={isCountryCalloutVisible ? "ChevronUp" : "ChevronDown"}
            className="country-icon-chevron"
          />
        </div> */}
      </div>
    );
  };

  const mergedProductDetailStyles = mergeStyleSets({
    "page-background": {
      backgroundColor: `${webTemplate?.generalSettings?.pageBackgroundColor}`,
    },
    "oc-t3-description-content-wrapper": {
      "@media (min-width:768px)": {
        marginLeft: "5px !important",
      },
    },
    "product-title-wrapper": {
      paddingLeft: 40,
      paddingRight: 40,
      backgroundColor: `${
        webTemplate?.generalSettings?.sectionBackgroundColor ?? ""
      }`,
      alignItems: "center",
      display: "grid",
      minHeight: 25,
      paddingTop: 10,
      paddingBottom: 10,
      "@media (max-width:767px)": {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    "product-heading": {
      padding: 0,
      fontSize: "large",
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      margin: 0,
      color: `${webTemplate?.generalSettings?.primaryColor}`,
      wordWrap: "break-word",
      display: "inline-flex",
    },
    "product-caption": {
      padding: 0,
      paddingBottom: 10,
      margin: 0,
      wordWrap: "break-word",
      display: "flex",
      flexDirection: "column",
      "div > P": {
        margin: "0px",
      },
      "span > p": {
        margin: "0px",
      },
    },

    "section-background-color": {
      backgroundColor: `${
        webTemplate?.generalSettings?.sectionBackgroundColor ?? ""
      }`,
    },
    "oc-t3-product-detail-content-info": {
      borderRight: `1px solid ${webTemplate?.generalSettings?.sectionBorderColor} !important`,
      marginRight: "13px",
      "@media (max-width:767px)": {
        borderRight: "none !important",
        marginRight: "0px !important",
        marginBottom: "20px !important",
      },
    },
    "product-name-wrapper": {
      fontSize: "large",
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      verticalAlign: "middle",
      "@media (max-width:767px)": {
        marginTop: 0,
        width: "100 !important",
      },
    },
    "product-section-wrapper": {
      borderBottom: `1px solid ${webTemplate?.generalSettings?.sectionBorderColor}`,
      paddingBottom: 10,
    },
    "product-price-wrapper": {
      // color:`${webTemplate?.generalSettings?.primaryColor}`,
      fontSize: "large",
      borderLeft: `1px solid ${webTemplate?.generalSettings?.sectionBorderColor}`,
      paddingLeft: "20px",
      "@media (max-width:767px)": {
        borderLeft: "none",
        paddingLeft: "0px",
      },
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),

      // borderRight:`2px solid ${webTemplate?.generalSettings?.sectionBorderColor}`
    },
    "product-font": {
      fontFamily: `${setFontFamily(
        webTemplate?.generalSettings?.font
      )} !important`,
    },
    "product-price-content-info": {
      fontSize: "large",
      paddingLeft: "20px",
      borderLeft: `1px solid ${webTemplate?.generalSettings?.sectionBorderColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      "@media (max-width:767px)": {
        borderLeft: "none",
        paddingLeft: "0px",
      },
    },
    "product-primary-color": {
      color: `${webTemplate?.generalSettings?.primaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-secondary-color": {
      color: `${webTemplate?.generalSettings?.secondaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "product-text-link": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),

      "&:hover": {
        color: `${webTemplate?.generalSettings?.primaryColor}`,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    "oc-t3-product-primary-color": {
      color: `${webTemplate?.generalSettings?.secondaryColor}`,
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
    },
    "oc-t3-product-text-link": {
      fontFamily: setFontFamily(webTemplate?.generalSettings?.font),
      "&:hover": {
        color: `${webTemplate?.generalSettings?.secondaryColor}`,
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  });
  const fetchProducts = async () => {
    await getProducts(props.selectedLanguageData.key, _cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await props.setProductRecords(res.data);
          setState({ isPageDataFetched: true });
        }
      })
      .catch(async (err) => {
        setState({ isPageDataFetched: true });
        await manageError(err, props.history);
      });
  };

  const handleSelectedLanguageData = (records, selectedlanguageCode) => {
    let filteredText = "";
    if (records && records.length > 0) {
      records.forEach((language) => {
        if (language.languageCode === selectedlanguageCode) {
          filteredText = language.translation;
        }
      });
    }
    return filteredText;
  };

  const fetchProduct = async (productId) => {
    await getProduct(productId, props.selectedLanguageData.key, _cancelToken)
      .then((res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          setState({ productRecord: res.data.result });
          setState({ isPageDataFetched: true });
        }
      })
      .catch(async (err) => {
        setState({ isPageDataFetched: true });
        await manageError(err, props.history);
      });
  };

  const renderProductIcon = (type) => {
    if (
      state?.productRecord?.productIcon &&
      state?.productRecord?.productIcon.length > 0
    ) {
      return (
        <div
          className={
            `${webTemplate?.templateCode?.toLowerCase()}-` +
            "product-icon-image"
          }
        >
          <div className="p-r-20">
            {state?.productRecord?.productIcon ? (
              <Image
                loading="lazy"
                src={handleImageSource(state?.productRecord?.productIcon)}
                alt={state?.productRecord?.productName}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="product-icon-wrapper product-icon-wrapper-hide"></div>
      );
    }
  };

  const renderProductDescription = () => {
    if (
      state?.productRecord?.productDescription &&
      state?.productRecord?.productDescription.length > 0
    ) {
      return (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              className={
                "product-desc-wrapper " +
                mergedProductDetailStyles["product-font"]
              }
            >
              <div className="product-desc-title m-b-10">
                <Text
                  className={`text-bold  text-fs-medium-plus ${
                    props?.webTemplateConfigData.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() ===
                    OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? mergedProductDetailStyles["oc-t3-product-primary-color"]
                      : mergedProductDetailStyles["product-primary-color"]
                  }`}
                >
                  {IntlUtil.getText(_intl_ns_oc_product, "content.description")}
                </Text>
              </div>
              <div className="product-desc-content">
                {state?.productRecord?.productDescription}
              </div>
            </div>
          </Col>
        </Row>
      );
    } else return null;
  };

  const renderProductInformation = () => {
    if (
      state?.productRecord?.productInformation &&
      state?.productRecord?.productInformation.length > 0
    ) {
      return (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div
              className={
                "product-info-wrapper " +
                mergedProductDetailStyles["product-font"]
              }
            >
              <div className="product-info-title">
                <Text
                  className={`text-bold text-fs-medium-plus ${
                    props?.webTemplateConfigData.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() ===
                    OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? mergedProductDetailStyles["oc-t3-product-primary-color"]
                      : mergedProductDetailStyles["product-primary-color"]
                  }`}
                >
                  {IntlUtil.getText(
                    _intl_ns_oc_product,
                    "content.marketingDescription"
                  )}
                </Text>
              </div>
              <div className="product-info-content">
                <div
                  className="m-t-15"
                  dangerouslySetInnerHTML={{
                    __html: state?.productRecord?.productInformation,
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      );
    } else return null;
  };

  const renderProductCountries = (type) => {
    if (
      state?.productRecord?.availableCountries &&
      state?.productRecord?.availableCountries.length > 0
    ) {
      let displayRecords = [];
      var sortedData = _.orderBy(
        state?.productRecord?.availableCountries,
        ["countryName"],
        ["asc"]
      );
      let displayCountries = [...sortedData];

      return (
        <div
          className={`product-country-wrapper ${mergedProductDetailStyles["product-font"]}`}
        >
          <div className="product-country-title">
            <Text
              className={`text-bold  ${
                webTemplate?.templateCode?.toLowerCase().trim() ===
                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? mergedProductDetailStyles["oc-t3-product-primary-color"]
                  : mergedProductDetailStyles["product-primary-color"]
              }`}
            >
              {IntlUtil.getText(
                _intl_ns_oc_product,
                "content.availableCountries"
              )}
            </Text>
          </div>
          <Row className="product-country-content m-l-0 p-l-0">
            {displayCountries.map((country, index) => {
              return (
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  key={`key-product-country-${index}`}
                  className="m-l-0 p-l-0 m-r-0 m-t-10 p-r-0"
                >
                  <div className="country-wrapper">
                    <span>{renderCountryIcon(country.countryName)}</span>
                    <div>
                      <span>{country.countryName}</span>
                      {country && country.conditions ? (
                        <TooltipHost content={country.conditions}>
                          {"  "}
                          <span className="cursor-pointer">{"*"}</span>
                        </TooltipHost>
                      ) : null}
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      );
    } else return null;
  };

  const handleResourceDocuments = async (resource) => {
    window.open(resource.resourceUrl, "_blank");
  };
  const handleLinkDocuments = async (link) => {
    window.open(link.linkUrl, "_blank");
  };

  const renderProductResources = (type) => {
    if (
      state?.productRecord?.resources &&
      state?.productRecord?.resources.length > 0
    ) {
      return (
        <div className={`product-resource-wrapper m-b-25`}>
          <div className="product-resource-title p-b-10">
            <Text
              className={`text-bold m-b-5  ${
                webTemplate?.templateCode?.toLowerCase().trim() ===
                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? mergedProductDetailStyles["oc-t3-product-primary-color"]
                  : mergedProductDetailStyles["product-primary-color"]
              }`}
            >
              {IntlUtil.getText(_intl_ns_oc_product, "content.resources")}
            </Text>
          </div>
          <div className="product-resource-content">
            {state.productRecord?.resources.map((resource, index) => {
              return resource &&
                resource.resourceTitle &&
                resource.resourceUrl ? (
                <div key={`key-product-resource-${index}`} className="m-b-10">
                  <span
                    onClick={() => handleResourceDocuments(resource)}
                    className={
                      webTemplate?.templateCode?.toLowerCase().trim() ===
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                        ? mergedProductDetailStyles["oc-t3-product-text-link"]
                        : mergedProductDetailStyles["product-text-link"]
                    }
                    href={resource.linkUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <TextDocumentIcon className="m-r-10" />
                    {resource.resourceTitle}
                  </span>
                </div>
              ) : null;
            })}
          </div>
        </div>
      );
    } else return null;
  };

  const renderProductTitle = () => {
    return (
      <>
        <div
          className={
            `oc-t1-product-title-wrapper ` +
            mergedProductDetailStyles["product-section-wrapper"]
          }
        >
          <div
            className={
              mergedProductDetailStyles["product-name-wrapper"] +
              " " +
              mergedProductDetailStyles["product-primary-color"]
            }
          >
            <div id="oc-product-align" className="oc-product-text-info a">
              <span>{state.productRecord.productName}</span>
              <DefaultButton
                id="product-name"
                className="oc-product-view-btn"
                onClick={handleDisplayProductList}
                iconProps={{
                  iconName: isProductListCalloutVisible
                    ? "ChevronUp"
                    : "ChevronDown",
                }}
              />
            </div>
            <div>
              {isProductListCalloutVisible && (
                <Callout
                  gapSpace={0}
                  target={`#product-name`}
                  setInitialFocus
                  onDismiss={handleProductListCalloutVisible}
                  className="oc-product-calout-info"
                  directionalHint={DirectionalHint.bottomRightEdge}
                  directionalHintFixed={true}
                  calloutWidth={setCalloutWidth}
                >
                  <div className="p-10">
                    {products && products.length > 0 ? (
                      <PerfectScrollbar
                        options={{ scrollingThreshold: 0 }}
                        className="content-scroll"
                      >
                        {products.map((product, index) => {
                          return (
                            <div className="p-5" key={index}>
                              <span
                                className={CustomStyles.getStyle(
                                  "text-link",
                                  webTemplate
                                )}
                                onClick={() => handleProductView(product)}
                              >
                                {product.productName}
                              </span>
                            </div>
                          );
                        })}
                      </PerfectScrollbar>
                    ) : (
                      <div className="p-10">
                        <span
                          className={CustomStyles.getStyle(
                            "text-link",
                            webTemplate
                          )}
                          onClick={() =>
                            handleProductView(state?.productRecord)
                          }
                        >
                          {state?.productRecord?.productName}
                        </span>
                      </div>
                    )}
                  </div>
                </Callout>
              )}
            </div>
          </div>
          <div className="m-l-25 b">
            <div className={mergedProductDetailStyles["product-price-wrapper"]}>
              {IntlUtil.getText(_intl_ns_oc_product, "content.price")}
            </div>
            <div
              className={
                mergedProductDetailStyles["product-price-content-info"] +
                " " +
                mergedProductDetailStyles["product-primary-color"]
              }
            >
              {state.productRecord.retailPrice !== undefined &&
              state.productRecord.retailPrice !== null ? (
                state.productRecord.currencySymbol +
                parseFloat(state.productRecord.retailPrice).toFixed(2)
              ) : (
                <TooltipHost
                  content={IntlUtil.getText(
                    _intl_ns_oc_product,
                    "content.support"
                  )}
                >
                  {" "}
                  <PhoneIcon />
                </TooltipHost>
              )}
            </div>
          </div>
          <div className="c">
            <PrimaryButton
              onClick={() => loadQuoteCreatePage(state.productRecord)}
              className={"float-right page-frame-button"}
            >
              {" "}
              <AddToShoppingListIcon className="m-r-5 page-frame-icon" />
              <TooltipHost
                hostClassName={"text-truncated"}
                overflowMode={TooltipOverflowMode.Self}
                content={IntlUtil.getText(
                  _intl_ns_oc_product,
                  "content.createQuote"
                )}
              >
                {IntlUtil.getText(_intl_ns_oc_product, "content.createQuote")}
              </TooltipHost>
            </PrimaryButton>
          </div>
        </div>
      </>
    );
  };

  const renderProductLinks = () => {
    if (state.productRecord?.links && state.productRecord?.links.length > 0) {
      return (
        <div className={`p-b-10`}>
          <div className="product-link-title p-b-10">
            <Text
              className={`text-bold  ${
                webTemplate?.templateCode?.toLowerCase().trim() ===
                OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? mergedProductDetailStyles["oc-t3-product-primary-color"]
                  : mergedProductDetailStyles["product-primary-color"]
              }`}
            >
              {IntlUtil.getText(_intl_ns_oc_product, "content.usefulLinks")}
            </Text>
          </div>
          <div className="product-link-content">
            {state.productRecord?.links.map((link, index) => {
              return (
                <div key={`key-product-link-${index}`} className="m-b-10">
                  <span
                    onClick={() => handleLinkDocuments(link)}
                    className={
                      webTemplate?.templateCode?.toLowerCase().trim() ===
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                        ? mergedProductDetailStyles["oc-t3-product-text-link"]
                        : mergedProductDetailStyles["product-text-link"]
                    }
                    href={link.url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Link12Icon className="m-r-10" />
                    <span>{link.linkTitle}</span>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else return null;
  };

  const loadQuoteCreatePage = (product) => {
    props.setSelectedProductId(product.productId, product.currencyCode);
    props.history.push(OperatorConnectURLProps.orderManagement.quoteAdd);
    return;
  };

  const handleDisplayProductList = () => {
    if (isProductListCalloutVisible) {
      setIsProductListCalloutVisible(false);
    } else {
      setIsProductListCalloutVisible(true);
    }
  };
  const handleProductListCalloutVisible = () => {
    setIsProductListCalloutVisible(false);
  };

  const handleProductView = async (product) => {
    setState({ isPageDataFetched: false });
    await props.history.push(
      OperatorConnectURLProps.productManagement.productView.replace(
        ":pid",
        product.productId
      )
    );
    await fetchProduct(product.productId);
  };
  return (
    <>
      <div ref={productViewRef}>
        <AppPageTitle
          pageTitle={handleSelectedLanguageData(
            websitePageTitle?.pageTitle,
            props.selectedLanguageData?.key
          )}
        />
        <div>
          {webTemplate?.bannerDisplayStatus?.toLowerCase().trim() ===
          OperatorConnectConstants.GENERAL.YES?.toLowerCase() ? (
            <Welcome {...props} />
          ) : null}
          <PageHeader
            {...props}
            showCurrencyStatus={props.currencyData.length > 1 ? true : false}
            websitePageTitle={websitePageTitle}
            onChange={async (currency) => {
              await setProductCurrencyList(
                props.productRecords.records,
                currency,
                state?.productRecord
              );
              await currencyProductChange(
                props.productRecords.records,
                currency,
                state?.productRecord
              );
            }}
          />
        </div>
        <div
          id="oc-page-container"
          className={mergedProductDetailStyles["page-background"]}
        >
          {webTemplate?.templateCode?.toLowerCase().trim() ===
          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_ONE?.toLowerCase().trim() ? (
            state.productRecord !== null ? (
              <>
                {renderProductTitle()}
                <div
                  className={
                    webTemplate?.templateCode?.toLowerCase() +
                    "-product-detail-content-wrapper m-b-40"
                  }
                >
                  <div>
                    <div>{renderProductDescription()}</div>
                    <div className="product-detail-information-wrapper m-t-25 m-b-20">
                      {renderProductInformation()}
                    </div>
                  </div>
                  <div className="">{renderProductIcon()}</div>
                </div>
                {state?.productRecord?.links.length > 0 ||
                state?.productRecord?.resources.length > 0 ||
                state?.productRecord?.availableCountries.length > 0 ? (
                  <div
                    className={
                      "product-detail-footer-content-wrapper " +
                      mergedProductDetailStyles["section-background-color"]
                    }
                  >
                    <div>{renderProductLinks()}</div>
                    <div>{renderProductResources()}</div>
                    <div
                      className={
                        state.productRecord.resources.length > 0
                          ? "oc-t1-country-wrapper"
                          : ""
                      }
                    >
                      {renderProductCountries()}
                    </div>
                  </div>
                ) : null}
              </>
            ) : state?.isPageDataFetched ? (
              <div className="page-no-data">
                <div>
                  <div className="text-no-data page-no-data-icon">
                    <ReportWarningIcon className="no-data-icon" />
                  </div>
                  <div>
                    <span>
                      {IntlUtil.getText(
                        _intl_ns_common,
                        "notification.info.noProductDetailsFound"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {webTemplate?.templateCode?.toLowerCase().trim() ===
          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_TWO?.toLowerCase().trim() ? (
            state.productRecord !== null ? (
              <>
                {renderProductTitle()}
                <div
                  className={`${webTemplate?.templateCode
                    ?.toLowerCase()
                    .trim()}-product-detail-content-wrapper m-b-40`}
                >
                  <div
                    className={`${webTemplate?.templateCode
                      ?.toLowerCase()
                      .trim()}-product-detail-content-info`}
                  >
                    <div className="m-b-20 p-r-20">
                      {renderProductIcon()}
                      <div>{renderProductDescription()}</div>
                    </div>
                    <div>{renderProductInformation()}</div>
                  </div>
                  {state?.productRecord?.links.length > 0 ||
                  state?.productRecord?.resources.length > 0 ||
                  state?.productRecord?.availableCountries.length > 0 ? (
                    <div
                      className={
                        `oc-t2-links-wrapper ` +
                        mergedProductDetailStyles["section-background-color"]
                      }
                    >
                      <div>{renderProductLinks()}</div>
                      <div>{renderProductResources()}</div>
                      <div className="oc-t2-country-content-wrapper">
                        {renderProductCountries()}
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            ) : state?.isPageDataFetched ? (
              <div className="page-no-data">
                <div>
                  <div className="page-no-data-icon text-no-data">
                    <ReportWarningIcon className="no-data-icon" />
                  </div>
                  <div>
                    <span>
                      {IntlUtil.getText(
                        _intl_ns_common,
                        "notification.info.noProductDetailsFound"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {webTemplate?.templateCode?.toLowerCase().trim() ===
          OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim() ? (
            state.productRecord !== null ? (
              <>
                {renderProductTitle()}
                <div
                  className={`${webTemplate?.templateCode
                    ?.toLowerCase()
                    .trim()}-product-detail-content-wrapper m-b-40`}
                >
                  <div
                    className={`${
                      mergedProductDetailStyles[
                        "oc-t3-product-detail-content-info"
                      ]
                    } ${webTemplate?.templateCode
                      ?.toLowerCase()
                      .trim()}-product-detail-content-info`}
                  >
                    <div className="m-t-5 m-b-20">{renderProductIcon()}</div>
                    {state?.productRecord?.links.length > 0 ||
                    state?.productRecord?.resources.length > 0 ||
                    state?.productRecord?.availableCountries.length > 0 ? (
                      <div className="oc-t3-product-docs-wrapper-large">
                        <div>{renderProductLinks()}</div>
                        <div>{renderProductResources()}</div>
                        <div>{renderProductCountries()}</div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      mergedProductDetailStyles[
                        "oc-t3-description-content-wrapper"
                      ] + " m-b-20"
                    }
                  >
                    <div>{renderProductDescription()}</div>
                    <div className="p-t-20">{renderProductInformation()}</div>
                  </div>
                  <div className="oc-t3-product-docs-wrapper-small m-b-30">
                    <div>{renderProductLinks()}</div>
                    <div>{renderProductResources()}</div>
                    <div>{renderProductCountries()}</div>
                  </div>
                </div>
              </>
            ) : state?.isPageDataFetched ? (
              <div className="page-no-data">
                <div>
                  <div className="text-no-data page-no-data-icon">
                    <ReportWarningIcon className="no-data-icon" />
                  </div>
                  <div>
                    <span>
                      {IntlUtil.getText(
                        _intl_ns_common,
                        "notification.info.noProductDetailsFound"
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : null
          ) : null}
          {state?.isPageDataFetched === true ? (
            <div className="page-frame-content"></div>
          ) : null}
          <PageOverlayLoader
            hidden={state.isPageDataFetched}
            label={IntlUtil.getText(
              _intl_ns_common,
              "content.loadingInprogress"
            )}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isProductRecordsDataFetched: state.productStore.isProductRecordsDataFetched,
  productRecordsDataFetchTimestamp:
    state.productStore.productRecordsDataFetchTimestamp,
  productRecords: state.productStore.productRecords,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  countryStatesData: state.generalStore.countryStatesData,
  selectedCurrencyData: state.generalStore.selectedCurrencyData,
  currencyData: state.generalStore.currencyData,
});
const mapActionToProps = {
  setCurrencyData,
  setProductRecords,
  setCountryStatesData,
  setSelectedCurrencyData,
  setSelectedProductId,
};

export default connect(mapStateToProps, mapActionToProps)(ProductView);
