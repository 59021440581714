import api from "../../../core/helpers/api-services";
import _ from "lodash";
import { logMessage } from "../../../core/actions/common-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
const actionFileName = "order-actions.js";

export function getProduct(productId, languageCode, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .get(
        AppConfigProps.operatorConnectAPIPrefix +
          "/catalog/products/" +
          productId +
          "?languageCode=" +
          languageCode,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getQuoteVersions()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}
