import React, { Component } from "react";
import axios from "axios";
import {
  Modal,
  TextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  TooltipHost,
  Text,
} from "@fluentui/react";
import { InfoIcon } from "@fluentui/react-icons";
import { Col, Grid, Row } from "react-flexbox-grid";
import IntlUtil from "../../../core/helpers/intl-util";
import CustomStyles from "../../common/helpers/custom-styles";
import { ClearIcon } from "@fluentui/react-icons";
import { AppConfigProps } from "../../../core/settings/app-config";
import { requestSupport } from "../actions/quote-action";
import { manageError } from "../../../core/actions/common-actions";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import { CustomerRepresentativeFormFields } from "../settings/customer-representative-fields";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { modalStyles } from "../../common/helpers/styles";

class CustomerRepresentativeForm extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.contactNameRef = React.createRef();
    this.companyNameRef = React.createRef();
    this.contactEmailRef = React.createRef();
    this.contactPhoneRef = React.createRef();
    this.requestDescriptionRef = React.createRef();
    this.state = {
      requestNumber: null,
      isModalDataFetched: true,
      requestSubmitStatus: null,
      requestSubmitErrors: [],
      formInputFields: this.initFormInputFields(),
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this.props.quoteUserFormInputFields) {
      await this.setFormFieldValue(
        "contactName",
        this.props.quoteUserFormInputFields?.quoteName?.value
      );
      await this.setFormFieldValue(
        "contactEmail",
        this.props.quoteUserFormInputFields?.email?.value
      );
      await this.setFormFieldValue(
        "contactPhone",
        this.props.quoteUserFormInputFields?.phone?.value
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this.props.intlNsCommon,
        "notification.warning.requestCancelled"
      )
    );
  }

  initFormInputFields = () => {
    let refCollection = {
      contactName: this.contactNameRef,
      companyName: this.companyNameRef,
      contactEmail: this.contactEmailRef,
      contactPhone: this.contactPhoneRef,
      requestDescription: this.requestDescriptionRef,
    };
    return CustomerRepresentativeFormFields(
      this.props.intlNsOCQuote,
      refCollection
    );
  };

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  setFormFieldValue = async (fieldName, fieldValue) => {
    if (fieldName === "contactPhone") {
      const regExp = /[0-9]*$/;
      if (regExp.test(fieldValue) === true) {
        fieldValue = fieldValue.replace(/\D/g, "");
      }
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          [fieldName]: {
            ...this.state.formInputFields[fieldName],
            value: fieldValue,
          },
        },
      });
    } else {
      await this.setStateAsync({
        formInputFields: {
          ...this.state.formInputFields,
          [fieldName]: {
            ...this.state.formInputFields[fieldName],
            value: fieldValue,
          },
        },
      });
    }
    await this.clearAlert();
  };

  handleFormFieldBlur = async (e) => {
    if (e && e.target.id && this.state.formInputFields) {
      await this.validateFormField(e.target.id);
    }
  };

  validateFormField = async (fieldName) => {
    if (fieldName && this.state.formInputFields) {
      let fieldObj = this.state.formInputFields[fieldName];
      if (fieldObj.isRequired === true) {
        let errorStatus = false;
        if (fieldObj.validate(fieldObj.value) === false) {
          errorStatus = true;
        }

        if (fieldName === "contactEmail") {
          if (
            this.state.formInputFields.contactEmail.value !== "" &&
            this.state.formInputFields.contactEmail.value !== null
          ) {
            const reg =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let emailValid = reg.test(
              String(
                this.state.formInputFields.contactEmail.value
              ).toLowerCase()
            );
            errorStatus = false;
            if (!emailValid) {
              errorStatus = true;
            }

            await this.setStateAsync({
              formInputFields: {
                ...this.state.formInputFields,
                [fieldName]: {
                  ...this.state.formInputFields[fieldName],
                  isError: errorStatus,
                  errorMessage: IntlUtil.getText(
                    this.props.intlNsOCQuote,
                    "notification.error.contactEmailNotValid"
                  ),
                },
              },
            });
          }
        }

        await this.setStateAsync({
          formInputFields: {
            ...this.state.formInputFields,
            [fieldName]: {
              ...this.state.formInputFields[fieldName],
              isError: errorStatus,
            },
          },
        });
      }
    }
  };

  clearAlert = async () => {
    await this.setStateAsync({
      requestSubmitStatus: null,
      requestSubmitErrors: [],
    });
  };

  handleFormFieldChange = async (e) => {
    if (e && e.target) {
      await this.setFormFieldValue(e.target.id, e.target.value);
    }
  };

  isFormFilled = async () => {
    let isFormFilled = true;
    for (const fieldName in this.state.formInputFields) {
      await this.validateFormField(fieldName);
    }
    for (const fieldName in this.state.formInputFields) {
      let fieldObj = this.state.formInputFields[fieldName];
      if (fieldObj?.isRequired === true && fieldObj?.isError === true) {
        isFormFilled = false;
        break;
      }
    }
    return isFormFilled;
  };

  handleSupportRequest = async () => {
    this.props.handleRefreshReCaptcha();
    let isFormFilled = await this.isFormFilled();
    if (isFormFilled === true) {
      await this.setStateAsync({
        isModalDataFetched: false,
      });
      let contactObj = {
        contactName: this.state.formInputFields.contactName.value
          ? this.state.formInputFields.contactName.value.trim()
          : null,
        companyName: this.state.formInputFields.companyName.value
          ? this.state.formInputFields.companyName.value.trim()
          : null,
        contactEmail: this.state.formInputFields.contactEmail.value
          ? this.state.formInputFields.contactEmail.value.trim()
          : null,
        contactPhone: this.state.formInputFields.contactPhone.value
          ? this.state.formInputFields.contactPhone.value.trim()
          : null,
        requestDescription: this.state.formInputFields.requestDescription.value
          ? this.state.formInputFields.requestDescription.value.trim()
          : null,
        captchaToken: this.props.captchaToken,
      };
      await requestSupport(contactObj, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.result
          ) {
            await this.setStateAsync({
              formInputFields: this.initFormInputFields(),
              requestSubmitStatus:
                OperatorConnectConstants.FORM_SUBMIT_STATUS.SUCCESS,
              requestNumber: res.data.result.requestNumber,
              isModalDataFetched: true,
            });
          } else {
            await this.setStateAsync({
              requestSubmitStatus:
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
              isModalDataFetched: true,
            });
          }
        })
        .catch(async (err) => {
          if (
            err &&
            err.status &&
            err.status === AppConfigProps.httpStatusCode.badRequest
          ) {
            await this.setStateAsync({
              requestSubmitStatus:
                OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
            });
            if (err.data && err.data.errors) {
              await this.setStateAsync({
                requestSubmitErrors: err.data.errors,
              });
            }
            await this.setStateAsync({ isModalDataFetched: true });
          } else {
            await this.setStateAsync({ isModalDataFetched: true });
            await manageError(err, this.props.history);
          }
        });
    } else {
      for (const fieldName in this.state.formInputFields) {
        let fieldObj = this.state.formInputFields[fieldName];
        if (fieldObj.isError === true) {
          fieldObj.refObject?.current?.focus();
          break;
        }
      }
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isCustomerRepresentative}
        className={`create-account-modal ${modalStyles.container}`}
        layerProps={{ eventBubblingEnabled: true }}
      >
        <div className="modal-wrapper">
          <div
            className={
              modalStyles.header +
              ` modal-header-wrapper ${CustomStyles.getStyle(
                this.props.websiteTemplate?.templateCode
                  ?.toLowerCase()
                  .trim() !==
                  OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                  ? "primary-background-color"
                  : "secondary-background-color",
                this.props.websiteTemplate
              )}`
            }
          >
            <div className="p-t-5 p-b-5">
              {IntlUtil.getText(this.props.intlNsOCQuote, "content.contact")}
            </div>
            <ClearIcon
              onClick={async () => {
                await this.setStateAsync({
                  formInputFields: this.initFormInputFields(),
                });
                await this.props.handleCustomerRepresentativeModalClose();
              }}
              className="m-l-10 m-r-10 m-t-10 m-b-10 cursor-pointer"
            />
          </div>
          <PageOverlayLoader
            hidden={this.state.isModalDataFetched}
            label={IntlUtil.getText(
              this._intl_ns_oc_common,
              "content.loadingInprogress"
            )}
          />
          <div className="modal-content">
            <div className="m-t-10">
              {this.state.requestSubmitStatus ===
              OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE ? (
                <div className=" p-l-15 p-r-15">
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    className="notification-messagebar"
                  >
                    <span className="text-ff-semibold">
                      {IntlUtil.getText(
                        this.props.intlNsOCQuote,
                        "notification.error.serviceRequestFailure"
                      )}
                    </span>
                    {this.state.requestSubmitErrors &&
                    this.state.requestSubmitErrors.length > 0 ? (
                      <span>
                        {this.state.requestSubmitErrors.map((error, index) => {
                          return (
                            <div key={`key-error-${index}`}>
                              {error.message}
                            </div>
                          );
                        })}
                      </span>
                    ) : null}
                  </MessageBar>
                </div>
              ) : null}
              {this.state.requestSubmitStatus ===
              OperatorConnectConstants.FORM_SUBMIT_STATUS.SUCCESS ? (
                <div className=" p-l-15 p-r-15">
                  <MessageBar
                    messageBarType={MessageBarType.success}
                    className="notification-messagebar"
                  >
                    <span className="text-ff-semibold">
                      {IntlUtil.getText(
                        this.props.intlNsOCQuote,
                        "notification.success.serviceRequestSuccessfully"
                      )}{" "}
                      {this.state.requestNumber
                        ? IntlUtil.getSubstituteText(
                            this.props.intlNsOCQuote,
                            "notification.success.requestNumber",
                            [
                              {
                                key: "<REQUEST_NUMBER>",
                                value: this.state.requestNumber,
                              },
                            ]
                          )
                        : null}
                    </span>
                  </MessageBar>
                </div>
              ) : null}
            </div>
            <Grid fluid className="modal-form modal-form-grid">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-b-10">
                  <Text>
                    {IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.speakToCustomerRepresentative"
                    )}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    autoFocus={false}
                    maxLength={200}
                    componentRef={this.contactNameRef}
                    id="contactName"
                    name="contactName"
                    value={this.state.formInputFields.contactName.value}
                    required={this.state.formInputFields.contactName.isRequired}
                    errorMessage={
                      this.state.formInputFields.contactName.isError
                        ? this.state.formInputFields.contactName.errorMessage
                        : null
                    }
                    onChange={this.handleFormFieldChange}
                    onBlur={this.handleFormFieldBlur}
                    label={IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.name"
                    )}
                    className="modal-form-textfield m-b-10"
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    maxLength={200}
                    componentRef={this.companyNameRef}
                    id="companyName"
                    name="companyName"
                    value={this.state.formInputFields.companyName.value}
                    required={this.state.formInputFields.companyName.isRequired}
                    errorMessage={
                      this.state.formInputFields.companyName.isError
                        ? this.state.formInputFields.companyName.errorMessage
                        : null
                    }
                    onChange={this.handleFormFieldChange}
                    onBlur={this.handleFormFieldBlur}
                    label={IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.company"
                    )}
                    className="modal-form-textfield m-b-10"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    maxLength={200}
                    componentRef={this.contactEmailRef}
                    id="contactEmail"
                    name="contactEmail"
                    value={this.state.formInputFields.contactEmail.value}
                    required={
                      this.state.formInputFields.contactEmail.isRequired
                    }
                    errorMessage={
                      this.state.formInputFields.contactEmail.isError
                        ? this.state.formInputFields.contactEmail.errorMessage
                        : null
                    }
                    onChange={this.handleFormFieldChange}
                    onBlur={this.handleFormFieldBlur}
                    label={IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.email"
                    )}
                    className="modal-form-textfield m-b-10"
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    componentRef={this.contactPhoneRef}
                    id="contactPhone"
                    name="contactPhone"
                    prefix={"+"}
                    maxLength={20}
                    value={this.state.formInputFields.contactPhone.value}
                    required={
                      this.state.formInputFields.contactPhone.isRequired
                    }
                    errorMessage={
                      this.state.formInputFields.contactPhone.isError
                        ? this.state.formInputFields.contactPhone.errorMessage
                        : null
                    }
                    onChange={this.handleFormFieldChange}
                    onBlur={this.handleFormFieldBlur}
                    label={
                      <span>
                        {IntlUtil.getText(
                          this.props.intlNsOCQuote,
                          "content.phone"
                        )}
                        <TooltipHost
                          content={
                            <>
                              <div>
                                {IntlUtil.getText(
                                  this.props.intlNsOCQuote,
                                  "content.tooltip.phone"
                                )}
                              </div>
                              <div>
                                {IntlUtil.getText(
                                  this.props.intlNsOCQuote,
                                  "content.tooltip.phoneExample"
                                )}
                              </div>
                            </>
                          }
                        >
                          <InfoIcon className="m-l-10" />
                        </TooltipHost>
                      </span>
                    }
                    className="modal-form-textfield m-b-10"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    maxLength={200}
                    componentRef={this.requestDescriptionRef}
                    id="requestDescription"
                    name="requestDescription"
                    multiline
                    value={this.state.formInputFields.requestDescription.value}
                    required={
                      this.state.formInputFields.requestDescription.isRequired
                    }
                    errorMessage={
                      this.state.formInputFields.requestDescription.isError
                        ? this.state.formInputFields.requestDescription
                            .errorMessage
                        : null
                    }
                    onChange={this.handleFormFieldChange}
                    onBlur={this.handleFormFieldBlur}
                    label={IntlUtil.getText(
                      this.props.intlNsOCQuote,
                      "content.supportRequest"
                    )}
                    className="modal-form-textfield m-b-10"
                  />
                </Col>
              </Row>
            </Grid>
            <div className="modal-footer-frame-separator"></div>
            <div className="modal-form-actions">
              <PrimaryButton
                className="m-r-10"
                text={IntlUtil.getText(
                  this.props.intlNsOCQuote,
                  "content.submit"
                )}
                onClick={async () => await this.handleSupportRequest()}
                disabled={this.state.isModalDataSubmitted}
              />
              <DefaultButton
                text={IntlUtil.getText(
                  this.props.intlNsOCQuote,
                  "content.close"
                )}
                onClick={async () => {
                  await this.setStateAsync({
                    formInputFields: this.initFormInputFields(),
                  });
                  await this.props.handleCustomerRepresentativeModalClose();
                }}
                disabled={this.state.isModalDataSubmitted}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CustomerRepresentativeForm;
