export const QuoteConstants = {
  PRODUCT_GROUP: {
    CALLING_PLAN: "Calling Plan",
    AUXILIARY_PLAN: "Auxiliary Plan",
    ADDON: "Addon",
  },
  INITIATOR_TYPE: {
    PARTNER: "Partner",
    SUBSCRIBER: "Subscriber",
    DISTRIBUTOR: "Distributor",
    SPONSOR: "Sponsor",
  },
  QUOTE_REDUX_TYPE: {
    ADD: "Add",
    UPDATE: "Update",
  },
  QUOTE_NUMBERS: {
    DATA_TYPE: {
      SUBSCRIBER_NUMBERS: "SubscriberNumbers",
      WAREHOUSE_NUMBERS: "WarehouseNumbers",
    },
    ITEM_TYPE: {
      MOVE_PARKED_NUMBERS: "MoveParkedNumbers",
      ACTIVATE_AVAILABLE_NUMBERS: "ActivateAvailableNumbers",
    },
  },

  FORMS: {
    CUSTOM_HOST: "Custom Host",
    PORTING: "Porting",
    ACTIVATION: "Activation",
    MOVE: "Move",
  },
  CHARGE_TYPE: {
    ONE_TIME: "Onetime",
    RECURRING: "Recurring",
  },
  CHARGE_TYPE_LIST: [
    { key: "Onetime", text: "NRC" },
    { key: "Recurring", text: "MRC" },
  ],
  QUOTE_TO_DATE: [
    { code: "MTD", text: "Month to Date" },
    { code: "QTD", text: "Quarter to Date" },
    { code: "YTD", text: "Year to Date" },
  ],
  CHARGE_NAME: {
    PLATFORM_CHARGES: "Platform Charges",
    ACTIVATION_FEE: "Activation Fee",
    PORTING_FEE: "Porting Fee",
    DID_CHARGES: "DID Charges",
    MOVE_FEE: "Move Fee",
  },
  connectionOptions: [
    { key: "Porting", text: "Porting" },
    { key: "Activation", text: "Activation" },
  ],
  CONNECTION_TYPE: {
    PORTING: "Porting",
    ACTIVATION: "Activation",
  },
  ACTION_TYPES: [
    { key: "PORT", text: "Port" },
    { key: "KEEP", text: "Keep" },
  ],
  PORTING_TYPES: [
    { key: "PARTIAL", text: "Partial" },
    { key: "FULL", text: "Full" },
  ],
  sippioTermsAndConditionsURL: "https://www.sippio.io/contact/legal/",
  PROCESS_STATUS: {
    QUOTE_CREATE: "Quote Created",
    ORDER_CONFIRM: "Order Confirmed",
    QUOTE_ORDER_SUBMIT: "Order Submitted",
    QUOTE_REQUEST: "Request for Quote",
    QUOTE_DRAFT: "Draft",
    QUOTE_UPDATE: "Quote Updated",
    QUOTE_CONFIRM: "Quote Confirmed",
    ORDER_REJECT: "Order Rejected",
    QUOTE_EXPIRE: "Quote Expired",
  },
  QUOTE_NODE_LIST: [
    { key: "", text: "" },
    { key: "East2-US1", text: "East2-US1" },
    { key: "Central-CA2", text: "Central-CA2" },
    { key: "SouthEast-Asia", text: "SouthEast-Asia" },
    { key: "Central-AUS", text: "Central-AUS" },
    { key: "South-UK1", text: "South-UK1" },
    { key: "WestCentral-DE", text: "WestCentral-DE" },
  ],
  SAVE_TYPE: {
    QUOTE_CREATE: "QuoteCreate",
    QUOTE_ORDER_SUBMIT: "OrderSubmit",
    QUOTE_REQUEST: "QuoteRequest",
    QUOTE_CONFIRM: "QuoteConfirm",
    QUOTE_DRAFT: "QuoteDraft",
    QUOTE_UPDATE: "QuoteUpdate",
    ORDER_CONFIRM: "OrderConfirm",
    ORDER_REJECT: "OrderReject",
    QUOTE_EXPIRE: "QuoteExpire",
  },
};
