import "./App.css";
import React, { Component } from "react";
import { Provider } from "react-redux";
import MainRoute from "./app/routes/main-route";
import { configureStore } from "./app/store/store";
import { initializeIcons } from "@fluentui/react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { PersistGate } from "redux-persist/integration/react";

const { store, persistor } = configureStore();

class App extends Component {
  render() {
    initializeIcons();
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_KEY}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MainRoute />
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    );
  }
}

export default App;
