import { Image } from "@fluentui/react";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";

const _intl_ns_oc_common = "oc_common";

const Footer = (props) => {
  const [webTemplate, setWebTemplate] = useState(
    props?.webTemplateConfigData?.websiteTemplate
  );
  const footerWrapperStyles = {
    backgroundColor: webTemplate?.footerSettings?.footerBackgroundColor ?? "",
    color: webTemplate?.footerSettings?.footerForegroundColor ?? "",
    minHeight: `${
      webTemplate?.footerSettings?.footerHeight &&
      webTemplate?.footerSettings?.footerHeight
        ? webTemplate?.footerSettings?.footerHeight
        : 70
    }px`,
    fontFamily: `${webTemplate?.generalSettings?.font}, sans-serif`,
  };

  const handleSelectedLanguageData = (records, selectedlanguageCode) => {
    let filteredText = "";
    if (records && records.length > 0) {
      records.forEach((language) => {
        if (language.languageCode === selectedlanguageCode) {
          filteredText = language.translation;
        }
      });
    }
    return filteredText;
  };

  const handleImageSource = (src) => {
    if (src && !src?.startsWith("https://")) {
      src = `data:image;base64,${src}`;
    }
    return src;
  };

  // useEffect(()=>{

  // },[props.selectedLanguageData.key])

  // const footerStyles={
  //   display: "grid",
  //   gridTemplateColumns:"1fr 1fr",
  //   width:"100%",
  //   alignItems:"center",
  //   flexWrap: "wrap",

  // }

  const footerBrandLogoWrapperStyles = {
    //height:"20px",
    // maxHeight:"100px"
  };
  const footerBrandLogoStyles = {
    objectFit: "contain",
    padding: "10px 10px 10px 0px",
    display: "flex",
    alignItems: "center",
    height: "auto",
    maxHeight: `${
      webTemplate?.footerSettings?.footerHeight &&
      webTemplate.footerSettings?.footerHeight
        ? webTemplate.footerSettings?.footerHeight - 20
        : 70
    }px`,
    maxWidth: "100% !important",

    // margin:"20px",
    // height:"50px",
  };

  return (
    <>
      <div style={{ ...footerWrapperStyles }} className="oc-footer-wrapper">
        <div
          // style={{...footerStyles}}
          className="oc-footer-content"
        >
          <div>
            {webTemplate?.headerSettings?.headerLogo &&
            props?.logoDisplay === "Yes" ? (
              <Image
                loading="lazy"
                onClick={() => props.history.push("/")}
                style={{ ...footerBrandLogoStyles }}
                src={handleImageSource(webTemplate?.headerSettings?.headerLogo)}
              />
            ) : null}
          </div>
          <div>
            <span className="float-right">
              <div
                dangerouslySetInnerHTML={{
                  __html: handleSelectedLanguageData(
                    webTemplate?.footerSettings?.footerText,
                    props.selectedLanguageData?.key
                  ),
                }}
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLanguageData: state.generalStore.selectedLanguageData,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(Footer);
