import {
  Label,
  TooltipHost,
  Text,
  DefaultButton,
  // Dropdown,
  PrimaryButton,
  TextField,
  Dialog,
  DialogFooter,
  DialogType,
  Link,
  Stack,
} from "@fluentui/react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import React, { Component, Fragment } from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import { CreateAccountFormFields } from "../settings/account-form-fields";
import _ from "lodash";
import axios from "axios";
import {
  addSubscriber,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  setQuoteProgressPage,
} from "../actions/quote-action";
import { AddFriendIcon, InfoIcon } from "@fluentui/react-icons";
import {
  getCountryList,
  getCountryStatesList,
  setCountryStatesData,
} from "../../common/actions/general-actions";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { AppConfigProps } from "../../../core/settings/app-config";
import SearchDropdown from "../../common/helpers/search-dropdown";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import { AppPageTitle } from "../../../core/components/app-page-title";
import { manageError } from "../../../core/actions/common-actions";
import CustomPageAppInsights from "../../common/helpers/custom-page-app-title";
import TelemetryUtil from "../../../core/helpers/telemetry-util";
import CustomStyles from "../../common/helpers/custom-styles";
import CustomDialog from "../../common/helpers/custom-dialog";
import { getCountryStates } from "../../common/actions/common-actions";

class AccountCreate extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_oc_quote = "oc_quote";
  _intl_ns_oc_common = "oc_common";

  constructor(props) {
    super(props);
    this.subscriberAddPageRef = React.createRef();
    this.businessNameRef = React.createRef();
    this.tenantIdRef = React.createRef();
    this.customerDomainsRef = React.createRef();
    // this.billingAccountNumberRef = React.createRef();
    this.addLine1Ref = React.createRef();
    this.cityRef = React.createRef();
    this.countryRef = React.createRef();
    this.postalCodeRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.stateRef = React.createRef();
    this.state = {
      accountFormInputFields: this.initAccountFormInputFields(),
      connectionList: [],
      productServices: [],
      communicationPlatformList: [],
      refreshReCaptcha: false,
      isAccountAddDialogHidden: true,
      quoteServiceSubmitStatus: null,
      quoteRecord: null,
      isPageDataFetched: false,
      websiteTemplate: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    TelemetryUtil.trackPageView(
      IntlUtil.getText(this._intl_ns_oc_quote, "title.createAccount")
    );
    PageUtil.scrollIntoView(this.subscriberAddPageRef);
    await this.setStateAsync({
      websiteTemplate: this.props.webTemplateConfigData?.websiteTemplate,
    });
    await this.fetchCountries();
    await this.setStateAsync({ isPageDataFetched: true });
    await this.setTenetCustomValidation();
    await this.loadPageData();
  }

  loadPageData = async () => {
    if (
      this.props.quoteEstimatorInfo &&
      this.props.quoteEstimatorInfo.subscriberDetails
    ) {
      await this.setFormFieldValues(
        this.props.quoteEstimatorInfo.subscriberDetails
      );
    }
  };

  fetchCountries = async () => {
    if (
      this.props.countryStatesData === null ||
      this.props.countryStatesData.length === 0
    ) {
      await getCountryStates(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            await this.props.setCountryStatesData(res.data.records);
          }
        })
        .catch(async (err) => {
          await this.setStateAsync({ isPageDataFetched: true });
          await manageError(err, this.props.history);
        });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }

  handleFormFieldBlur = async (e) => {
    if (e && e.target.id && this.state.accountFormInputFields) {
      await this.validateFormField(e.target.id);
    }
  };

  validateFormField = async (fieldName) => {
    if (fieldName && this.state.accountFormInputFields) {
      let fieldObj = this.state.accountFormInputFields[fieldName];
      if (fieldObj.isRequired === true) {
        let errorStatus = false;
        if (fieldObj.validate(fieldObj.value) === false) {
          errorStatus = true;
        }

        await this.setStateAsync({
          accountFormInputFields: {
            ...this.state.accountFormInputFields,
            [fieldName]: {
              ...this.state.accountFormInputFields[fieldName],
              isError: errorStatus,
            },
          },
        });
      }
    }
  };

  setFormFieldValues = async (serviceRecord) => {
    if (serviceRecord) {
      await this.setStateAsync({
        accountFormInputFields: {
          ...this.state.accountFormInputFields,
          tenantId: {
            ...this.state.accountFormInputFields["tenantId"],
            value: this.props?.tenantId ?? "",
          },

          email: {
            ...this.state.accountFormInputFields["email"],
            value: serviceRecord?.email ?? "",
          },
          phone: {
            ...this.state.accountFormInputFields["phone"],
            value: serviceRecord?.phone ?? "",
          },
        },
      });
    }
  };

  setFormFieldValue = async (fieldName, fieldValue) => {
    if (fieldName && this.state.accountFormInputFields) {
      await this.setStateAsync({
        accountFormInputFields: {
          ...this.state.accountFormInputFields,
          [fieldName]: {
            ...this.state.accountFormInputFields[fieldName],
            value: fieldValue,
          },
        },
      });
    }
  };

  initAccountFormInputFields = () => {
    let refCollection = {
      businessName: this.businessNameRef,
      tenantId: this.tenantIdRef,
      customerDomains: this.customerDomainsRef,
      // billingAccountNumber: this.billingAccountNumberRef,
      addLine1: this.addLine1Ref,
      city: this.cityRef,
      country: this.countryRef,
      postalCode: this.postalCodeRef,
      firstName: this.firstNameRef,
      lastName: this.lastNameRef,
      email: this.emailRef,
      state: this.stateRef,
      voiceRegionsTemplate: this.voiceRegionsTemplateRef,
      communicationPlatform: this.communicationPlatformsRef,
    };
    return CreateAccountFormFields(this._intl_ns_oc_quote, refCollection);
  };

  setTenetCustomValidation = async () => {
    let accountFormInputFields = _.cloneDeep(this.state.accountFormInputFields);
    if (this.props?.webTemplateConfigData?.websiteType === "Operator Connect") {
      accountFormInputFields.tenantId.isRequired = true;
    } else {
      accountFormInputFields.tenantId.isRequired = false;
      accountFormInputFields.tenantId.isError = false;
    }
    await this.setStateAsync({
      accountFormInputFields: accountFormInputFields,
    });
  };

  handleFindMyTenantId = () => {
    window.open("https://www.whatismytenantid.com", "_blank");
  };

  handleFormSubmit = async () => {
    let isFormFilled = true;
    if (this.state.accountFormInputFields) {
      PageUtil.scrollToTop();
      await this.setTenetCustomValidation();
      await this.setStateAsync({ isAccountAddDialogHidden: true });
      for (const fieldName in this.state.accountFormInputFields) {
        await this.validateFormField(fieldName);
      }
      for (const fieldName in this.state.accountFormInputFields) {
        let fieldObj = this.state.accountFormInputFields[fieldName];
        if (fieldObj.isRequired === true && fieldObj.isError === true) {
          isFormFilled = false;
          break;
        }
      }
      if (isFormFilled === true) {
        await this.setStateAsync({ isPageDataFetched: false });
        let customerObj = {
          captchaToken: this.state.captchaToken,
          languageCode: this.props.selectedLanguageData?.key,
          businessName: this.state.accountFormInputFields?.businessName.value
            ? this.state.accountFormInputFields?.businessName.value.trim()
            : "",
          tenantId: this.state.accountFormInputFields?.tenantId.value
            ? this.state.accountFormInputFields?.tenantId.value.trim()
            : "",
          customerDomains: this.state.accountFormInputFields?.customerDomains
            .value
            ? this.state.accountFormInputFields?.customerDomains.value.trim()
            : "",
          billingAccountNumber: this.state.accountFormInputFields
            ?.billingAccountNumber.value
            ? this.state.accountFormInputFields?.billingAccountNumber.value.trim()
            : "",
          address: {
            addLine1: this.state.accountFormInputFields?.addLine1.value
              ? this.state.accountFormInputFields?.addLine1.value.trim()
              : "",
            addLine2: this.state.accountFormInputFields?.addLine2.value
              ? this.state.accountFormInputFields?.addLine2.value.trim()
              : "",
            city: this.state.accountFormInputFields?.city.value
              ? this.state.accountFormInputFields?.city.value.trim()
              : "",
            state: this.state.accountFormInputFields?.state.value
              ? this.state.accountFormInputFields?.state.value.trim()
              : "",
            country: this.state.accountFormInputFields?.country.value
              ? this.state.accountFormInputFields?.country.value.trim()
              : "",
            postalCode: this.state.accountFormInputFields?.postalCode.value
              ? this.state.accountFormInputFields?.postalCode.value.trim()
              : "",
          },
          contact: {
            firstName: this.state.accountFormInputFields?.firstName.value
              ? this.state.accountFormInputFields?.firstName.value.trim()
              : "",
            lastName: this.state.accountFormInputFields?.lastName.value
              ? this.state.accountFormInputFields?.lastName.value.trim()
              : "",
            email: this.state.accountFormInputFields?.email.value
              ? this.state.accountFormInputFields?.email.value.trim()
              : "",
            phone: this.state.accountFormInputFields?.phone.value
              ? this.state.accountFormInputFields?.phone.value.trim()
              : "",
          },
        };
        let quoteId =
          this.props?.quoteEstimatorInfo?.subscriberQuoteRecords?.quoteId;
        await addSubscriber(quoteId, customerObj, this._cancelToken)
          .then(async (res) => {
            if (
              res &&
              res.status === AppConfigProps.httpStatusCode.ok &&
              res.data &&
              res.data.result
            ) {
              let productList = [];
              let customerDetailsObject = {
                quoteName: res.data.result.customerName,
                email: res.data.result.customerEmail,
                phone: res.data.result.customerPhone,
                quoteNumber: res.data.result.quoteNumber,
                quoteId: res.data.result.quoteId,
                partnerId: res.data.result.partnerId,
                subscriberAccount: null,
              };
              await this.props.setQuoteEstimatorInfoQuoteSubscriberDetails(
                this.props.quoteEstimatorInfo,
                customerDetailsObject
              );
              await res.data.result.products.forEach((rec) => {
                rec.selectedCountry = rec.availabilityName;
                rec.connection = rec.planName.trim();
                productList.push({ ...rec });
              });
              let quoteRecord = { ...res.data.result, products: productList };
              let onboardServiceDocumentRecords = [];
              let resourceRecords = [];
              await quoteRecord.evidences.forEach((country) => {
                if (country.category === "Country") {
                  onboardServiceDocumentRecords.push(country);
                }
              });
              await quoteRecord.resources.forEach((resource) => {
                resourceRecords.push(resource);
              });
              let nrcSubTotal = 0;
              let mrcSubTotal = 0;
              let total = 0;
              let allServiceRecords = [];
              if (
                quoteRecord &&
                quoteRecord.products &&
                quoteRecord.products.length > 0
              ) {
                await quoteRecord.products.forEach((obj, index) => {
                  let mrcCount = 0;
                  let nrcCount = 0;
                  let nrcPartnerCost = 0;
                  let mrcPartnerCost = 0;
                  let mrcDiscount = 0;
                  let nrcDiscount = 0;
                  obj.pricing.forEach((price) => {
                    if (
                      price.chargeType?.toLowerCase().trim() ===
                      OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
                    ) {
                      nrcCount =
                        nrcCount +
                        parseFloat(obj?.quantity ?? "0") *
                          parseFloat(price?.sellingPrice ?? "0");
                      nrcPartnerCost = price?.partnerCost ?? "0";
                    }
                    if (
                      price.chargeType?.toLowerCase().trim() ===
                      OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
                    ) {
                      mrcCount =
                        mrcCount +
                        parseFloat(obj?.quantity ?? "0") *
                          parseFloat(price?.sellingPrice ?? "0");
                      mrcPartnerCost = price?.partnerCost ?? "0";
                    }
                  });

                  nrcSubTotal = nrcSubTotal + nrcCount;
                  mrcSubTotal = mrcSubTotal + mrcCount;
                  allServiceRecords.push({
                    ...obj,
                    connection: obj?.planName,
                    nrcPartnerCost: nrcPartnerCost,
                    mrcPartnerCost: mrcPartnerCost,
                    nrcDiscount: nrcDiscount,
                    mrcDiscount: mrcDiscount,
                    id: index,
                    mrc: mrcCount.toString(),
                    nrc: nrcCount.toString(),
                  });
                });
                total = total + nrcSubTotal + mrcSubTotal;
                await this.setStateAsync({
                  quoteRecord: {
                    ...quoteRecord,
                    products: allServiceRecords,
                    nrcSubTotal: nrcSubTotal,
                    mrcSubTotal: mrcSubTotal,
                    totalCost: total,
                    onboardServiceDocumentRecords:
                      onboardServiceDocumentRecords,
                    resourceRecords: resourceRecords,
                  },
                });
                await this.props.setQuoteEstimatorInfoSubscriberQuoteRecords(
                  this.props.quoteEstimatorInfo,
                  this.state.quoteRecord
                );
                await this.props.setQuoteProgressPage(
                  OperatorConnectConstants.QUOTE.PROGRESS.CHECKOUT
                );
                await this.setStateAsync({ isPageDataFetched: true });
              } else {
                await this.setStateAsync({ isPageDataFetched: true });
                //await manageError(res, this.props.history);
              }

              await this.setStateAsync({ isPageDataFetched: true });
            } else {
              //PageUtil.scrollIntoView(this.subscriberAddPageRef);
              await this.setStateAsync({ isFormDataSubmitted: false });
            }
          })
          .catch(async (err) => {
            if (
              err &&
              err.status &&
              err.status === AppConfigProps.httpStatusCode.badRequest
            ) {
              if (err.data && err.data.errors) {
                await this.setStateAsync({
                  quoteServiceError: err.data.errors,
                });
              }
              await this.setStateAsync({
                quoteServiceSubmitStatus:
                  OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE,
                isPageDataFetched: true,
              });
            } else {
              await this.setStateAsync({ isPageDataFetched: true });
              await manageError(err, this.props.history);
              await this.props.setPageExit(false);
            }
          });
      } else {
        for (const fieldName in this.state.accountFormInputFields) {
          let fieldObj = this.state.accountFormInputFields[fieldName];
          if (fieldObj.isError === true) {
            if (fieldObj.refObject) {
              fieldObj.refObject.current.focus();
              break;
            }
          }
        }
      }
    }
  };

  handlePhoneFormFieldChange = async (e) => {
    if (e && e.target.id && this.state.accountFormInputFields) {
      const reg = /[0-9]*$/;
      if (reg.test(e.target.value) === true) {
        e.target.value = e.target.value.replace(/\D/g, "");
      }
      await this.setFormFieldValue(e.target.id, e.target.value);
      await this.setStateAsync({ isContinueDisabled: false });
      await this.props.setPageExit(true);
    }
  };

  handleFormFieldChange = async (e) => {
    if (e && e.target) {
      if (e.target.id === "quantity") {
        const regex = new RegExp("^[0-9]*$");
        if (regex.test(e.target.value) === true) {
          if (e.target.value.startsWith("0") === true) {
            e.target.value = "";
          }
        } else {
          e.target.value = "";
        }
      }
      await this.setFormFieldValue(e.target.id, e.target.value);
      await this.validateFormField(e.target.id);
      await this.clearAlert();
      await this.props.setPageExit(true);
    }
  };

  handleAccountAddDialog = async () => {
    let isFormFilled = true;
    if (this.state.accountFormInputFields) {
      PageUtil.scrollIntoView(this.subscriberAddPageRef);
      for (const fieldName in this.state.accountFormInputFields) {
        await this.validateFormField(fieldName);
      }
      for (const fieldName in this.state.accountFormInputFields) {
        let fieldObj = this.state.accountFormInputFields[fieldName];
        if (fieldObj.isRequired === true && fieldObj.isError === true) {
          isFormFilled = false;
          if (fieldObj.refObject) {
            fieldObj.refObject.current.focus();
            break;
          }
        }
      }
      if (isFormFilled === true) {
        this.setStateAsync({
          refreshReCaptcha: !this.state.refreshReCaptcha,
          isAccountAddDialogHidden: false,
        });
      }
    }
  };

  clearAlert = async () => {
    await this.setStateAsync({
      quoteServiceError: null,
      quoteServiceSubmitStatus: null,
    });
  };

  renderSubscriberAddDialog = () => {
    return (
      <CustomDialog
        hidden={this.state.isAccountAddDialogHidden}
        title={IntlUtil.getText(
          this._intl_ns_oc_quote,
          "notification.warning.addCustomerTitle"
        )}
        modalPropsClassName="quote-dialog-wrapper"
        onPrimaryButtonClick={this.handleFormSubmit}
        onDefaultButtonClick={() => {
          this.setStateAsync({ isAccountAddDialogHidden: true });
          this.setStateAsync({ isFormDataSubmitted: false });
        }}
      >
        {
          <>
            <Text>
              {IntlUtil.getText(
                this._intl_ns_oc_quote,
                "notification.warning.addCustomerTextPrefix"
              )}
            </Text>
          </>
        }
      </CustomDialog>
    );
  };

  onRenderLabel = (props, render) => {
    return (
      <Stack horizontal verticalAlign="center">
        {render(props)}
        <Link
          onClick={() => this.handleFindMyTenantId()}
          className={classNames(
            `${CustomStyles.getStyle(
              "text-link",
              this.state.websiteTemplate
            )} text-fs-small-plus`,
            { "m-l-10": props.required === false }
          )}
        >
          {IntlUtil.getText(this._intl_ns_oc_quote, "content.findMyTenantId")}
        </Link>
      </Stack>
    );
  };

  renderCompanyDetails = () => {
    return (
      <>
        <Grid fluid className="page-frame-content m-0 p-0">
          <Row>
            <Col>
              <Text
                className={
                  "m-l-15 text-fw-semibold text-fs-large quote-page-text-wrapper " +
                  `${CustomStyles.getStyle(
                    this.state.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    this.state.websiteTemplate
                  )}`
                }
              >
                {IntlUtil.getSubstituteText(
                  this._intl_ns_oc_quote,
                  "content.quoteReferenceNumber",
                  [
                    {
                      key: "<QUOTE_NUMBER>",
                      value:
                        this.props?.quoteEstimatorInfo?.subscriberQuoteRecords
                          ?.quoteNumber,
                    },
                  ]
                )}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col className="m-t-10">
              <Text className="m-l-15 quote-page-text-wrapper">
                {IntlUtil.getSubstituteText(
                  this._intl_ns_oc_quote,
                  "content.quoteText",
                  [
                    {
                      key: "<EMAIL>",
                      value:
                        this.props?.quoteEstimatorInfo?.subscriberQuoteRecords
                          ?.customerEmail,
                    },
                  ]
                )}
              </Text>
            </Col>
          </Row>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              this.state.websiteTemplate
            )}`}
          ></div>
          <Row>
            <Col className="m-t-15">
              <Text
                className={
                  "m-l-15 text-bold text-fs-large quote-page-text-wrapper " +
                  `${CustomStyles.getStyle(
                    this.state.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    this.state.websiteTemplate
                  )}`
                }
              >
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.provideCompany"
                )}
              </Text>
            </Col>
          </Row>
          <Row className="m-0 p-0 m-t-15">
            <Col xl={12} className="m-t-20">
              <Text
                className={
                  "text-fw-semibold text-fs-medium " +
                  `${CustomStyles.getStyle(
                    this.state.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    this.state.websiteTemplate
                  )}`
                }
              >
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.companyDetails"
                )}
              </Text>
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                required
                componentRef={this.businessNameRef}
                id="businessName"
                name="businessName"
                value={this.state.accountFormInputFields.businessName.value}
                errorMessage={
                  this.state.accountFormInputFields.businessName.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.businessName
                          .errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.businessName"
                )}
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                required={
                  this.props?.webTemplateConfigData?.websiteType ===
                  "Operator Connect"
                    ? true
                    : false
                }
                componentRef={this.tenantIdRef}
                id="tenantId"
                name="tenantId"
                value={this.state.accountFormInputFields.tenantId.value}
                errorMessage={
                  this.state.accountFormInputFields.tenantId.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.tenantId.errorMessage
                      )
                    : null
                }
                // onRenderLabel={this.onRenderLabel}
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.tenantId"
                )}
                className="page-form-textfield"
              />
              <Stack horizontal verticalAlign="center">
                <Link
                  onClick={() => this.handleFindMyTenantId()}
                  className={`${CustomStyles.getStyle(
                    "text-link",
                    this.state.websiteTemplate
                  )} text-fs-small-plus p-t-10 p-b-10`}
                >
                  {IntlUtil.getText(
                    this._intl_ns_oc_quote,
                    "content.findMyTenantId"
                  )}
                </Link>
              </Stack>
            </Col>
            {/* <Col className="m-t-35">
              
            </Col> */}
          </Row>
          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                id="customerDomains"
                name="customerDomains"
                componentRef={this.customerDomainsRef}
                onBlur={this.handleFormFieldBlur}
                errorMessage={
                  this.state.accountFormInputFields.customerDomains.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.customerDomains
                          .errorMessage
                      )
                    : null
                }
                value={this.state.accountFormInputFields.customerDomains.value}
                onChange={this.handleFormFieldChange}
                label={
                  <div className="quote-account-form-tooltip">
                    <Label className="m-0 p-0" required={true}>
                      {IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        "content.customerDomains"
                      )}
                    </Label>
                    <TooltipHost
                      content={IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        "content.tooltip.customerDomainsInfo"
                      )}
                    >
                      <InfoIcon />
                    </TooltipHost>
                  </div>
                }
                className="page-form-textfield"
              />
            </Col>
            {/* <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                required
                componentRef={this.billingAccountNumberRef}
                id="billingAccountNumber"
                name="billingAccountNumber"
                value={
                  this.state.accountFormInputFields.billingAccountNumber.value
                }
                errorMessage={
                  this.state.accountFormInputFields.billingAccountNumber.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.billingAccountNumber
                          .errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.billingAccountNumber"
                )}
                className="page-form-textfield"
              />
            </Col> */}
          </Row>
          <Row className="m-0 p-0 m-t-15">
            <Col xl={12} className="m-t-20">
              <Text
                className={
                  "text-fw-semibold text-fs-medium " +
                  `${CustomStyles.getStyle(
                    this.state.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    this.state.websiteTemplate
                  )}`
                }
              >
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.addressDetails"
                )}
              </Text>
            </Col>
          </Row>

          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={100}
                required
                componentRef={this.addLine1Ref}
                id="addLine1"
                name="addLine1"
                value={this.state.accountFormInputFields.addLine1.value}
                errorMessage={
                  this.state.accountFormInputFields.addLine1.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.addLine1.errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.streetAddress1"
                )}
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={100}
                id="addLine2"
                name="addLine2"
                value={this.state.accountFormInputFields.addLine2.value}
                errorMessage={
                  this.state.accountFormInputFields.addLine2.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.addLine2.errorMessage
                      )
                    : null
                }
                onBlur={this.handleFormFieldBlur}
                onChange={this.handleFormFieldChange}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.streetAddress2"
                )}
                className="page-form-textfield"
              />
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                required
                id="city"
                name="city"
                componentRef={this.cityRef}
                value={this.state.accountFormInputFields.city.value}
                errorMessage={
                  this.state.accountFormInputFields.city.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.city.errorMessage
                      )
                    : null
                }
                onBlur={this.handleFormFieldBlur}
                onChange={this.handleFormFieldChange}
                label={IntlUtil.getText(this._intl_ns_oc_quote, "content.city")}
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                required
                id="state"
                name="state"
                componentRef={this.stateRef}
                value={this.state.accountFormInputFields.state.value}
                errorMessage={
                  this.state.accountFormInputFields.state.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.state.errorMessage
                      )
                    : null
                }
                onBlur={this.handleFormFieldBlur}
                onChange={this.handleFormFieldChange}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.state"
                )}
                className="page-form-textfield"
              />
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <SearchDropdown
                required
                componentRef={this.countryRef}
                id="country"
                name="country"
                selectedKey={this.state.accountFormInputFields.country.value}
                onChange={(e, option) => {
                  this.setFormFieldValue("country", option.key);
                }}
                onBlur={(e, option) => this.validateFormField(e.target.id)}
                errorMessage={
                  this.state.accountFormInputFields.country.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.country.errorMessage
                      )
                    : null
                }
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.country"
                )}
                className="page-form-dropdown"
                options={getCountryList(
                  _.orderBy(
                    getCountryStatesList(this.props.countryStatesData),
                    "text",
                    "asc"
                  ),
                  {
                    showFavouriteCountries: true,
                    showBlankValue: true,
                    showDivider: true,
                  }
                )}
              />
            </Col>
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={10}
                required
                componentRef={this.postalCodeRef}
                id="postalCode"
                name="postalCode"
                onBlur={this.handleFormFieldBlur}
                value={this.state.accountFormInputFields.postalCode.value}
                errorMessage={
                  this.state.accountFormInputFields.postalCode.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.postalCode
                          .errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.zipCode"
                )}
                className="page-form-textfield"
              />
            </Col>
          </Row>
          <Row className="m-0 p-0 m-t-15">
            <Col xl={12} className="m-t-20">
              <Text
                className={
                  "text-fw-semibold text-fs-medium " +
                  `${CustomStyles.getStyle(
                    this.state.websiteTemplate?.templateCode
                      ?.toLowerCase()
                      .trim() !==
                      OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                      ? "text-fc-primary"
                      : "text-fc-secondary",
                    this.state.websiteTemplate
                  )}`
                }
              >
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.contactDetails"
                )}
              </Text>
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={50}
                required
                componentRef={this.firstNameRef}
                id="firstName"
                name="firstName"
                value={this.state.accountFormInputFields.firstName.value}
                errorMessage={
                  this.state.accountFormInputFields.firstName.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.firstName.errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.firstName"
                )}
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                required
                componentRef={this.lastNameRef}
                id="lastName"
                name="lastName"
                value={this.state.accountFormInputFields.lastName.value}
                errorMessage={
                  this.state.accountFormInputFields.lastName.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.lastName.errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                onBlur={this.handleFormFieldBlur}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.lastName"
                )}
                className="page-form-textfield"
              />
            </Col>
          </Row>
          <Row className="m-0 p-0">
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10">
              <TextField
                maxLength={100}
                required
                id="email"
                name="email"
                componentRef={this.emailRef}
                onBlur={this.handleFormFieldBlur}
                value={this.state.accountFormInputFields.email.value}
                errorMessage={
                  this.state.accountFormInputFields.email.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.email.errorMessage
                      )
                    : null
                }
                onChange={this.handleFormFieldChange}
                label={IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.emailAddress"
                )}
                className="page-form-textfield"
              />
            </Col>
            <Col xl={3} lg={3} md={5} xs={12} className="m-t-10 m-r-10 m-b-30">
              <TextField
                maxLength={20}
                id="phone"
                name="phone"
                prefix={"+"}
                label={
                  <span>
                    {IntlUtil.getText(this._intl_ns_oc_quote, "content.phone")}
                    <TooltipHost
                      content={
                        <>
                          <div>
                            {IntlUtil.getText(
                              this._intl_ns_oc_quote,
                              "content.tooltip.phone"
                            )}
                          </div>
                          <div>
                            {IntlUtil.getText(
                              this._intl_ns_oc_quote,
                              "content.tooltip.phoneExample"
                            )}
                          </div>
                        </>
                      }
                    >
                      <InfoIcon className="m-l-10" />
                    </TooltipHost>
                  </span>
                }
                value={this.state.accountFormInputFields.phone.value}
                errorMessage={
                  this.state.accountFormInputFields.phone.isError
                    ? IntlUtil.getText(
                        this._intl_ns_oc_quote,
                        this.state.accountFormInputFields.phone.errorMessage
                      )
                    : null
                }
                onChange={this.handlePhoneFormFieldChange}
                className="page-form-textfield"
              />
            </Col>
          </Row>
          {this.state.quoteServiceSubmitStatus ===
          OperatorConnectConstants.FORM_SUBMIT_STATUS.FAILURE ? (
            <Row className="m-0 p-l-0 p-r-0">
              <Col
                xl={12}
                lg={12}
                md={12}
                xs={12}
                className="text-fc-error m-b-40"
              >
                {this.state.quoteServiceError &&
                this.state.quoteServiceError.length > 0 ? (
                  <span>
                    {this.state.quoteServiceError.map((error, index) => {
                      return (
                        <div key={`key-quote-error-${index}`}>
                          {error.message}
                        </div>
                      );
                    })}
                  </span>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </Grid>
      </>
    );
  };

  handleExecuteRecaptcha = async (token) => {
    await this.setStateAsync({ captchaToken: token });
  };

  render() {
    return (
      <>
        <div className="page-frame-content frame-content-quotes">
          <div>
            {this.renderCompanyDetails()}
            <div
              className={`${CustomStyles.getStyle(
                "page-content-separator",
                this.state.websiteTemplate
              )}`}
            ></div>
            <div className="m-t-10">
              <PrimaryButton
                onClick={this.handleAccountAddDialog}
                className={
                  "oc-quote-page-footer-actions page-frame-button " +
                  `${CustomStyles.getStyle(
                    "primary-button",
                    this.state.websiteTemplate
                  )}`
                }
                iconProps={{ iconName: "AddFriend" }}
              >
                {IntlUtil.getText(
                  this._intl_ns_oc_quote,
                  "content.createAccount"
                )}
              </PrimaryButton>
            </div>
          </div>
          {this.renderSubscriberAddDialog()}
          <PageOverlayLoader
            hidden={this.state.isPageDataFetched}
            label={IntlUtil.getText(
              this._intl_ns_oc_common,
              "content.loadingInprogress"
            )}
          />
          <CustomPageAppInsights
            pageTitle={IntlUtil.getText(
              this._intl_ns_oc_quote,
              "title.createAccount"
            )}
          />
          <GoogleReCaptcha
            action="accountCreate"
            onVerify={this.handleExecuteRecaptcha}
            refreshReCaptcha={this.state.refreshReCaptcha}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  countryStatesData: state.generalStore.countryStatesData,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  tenantId: state.generalStore.tenantId,
});
const mapActionToProps = {
  setQuoteProgressPage,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setCountryStatesData,
};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(AccountCreate)
);
