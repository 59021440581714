import React, { useEffect, useState } from "react";
import {
  Image,
  Dropdown,
  DefaultButton,
  Callout,
  FontIcon,
  IconButton,
  mergeStyleSets,
  Text,
} from "@fluentui/react";
import { Divider } from "@fluentui/react-divider";
import i18n from "../../../../i18n";
import ReactCountryFlag from "react-country-flag";
import { useId } from "@fluentui/react-hooks";
import { Grid, Row, Col } from "react-flexbox-grid";
import { OperatorConnectConstants } from "../settings/operator-connect-constants";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  setSelectedLanguageData,
  handleImageSource,
} from "../actions/general-actions";
import { quoteEstimatorDataReset } from "../../quotes/actions/quote-action";
import { HomeSolidIcon, ReturnKeyIcon } from "@fluentui/react-icons";
import CustomStyles from "../helpers/custom-styles";
import classNames from "classnames";

const Header = (props) => {
  const [isCountryCalloutVisible, setIsCountryCalloutVisible] = useState(false);
  const [countryCode, setCountryCode] = useState("US");
  const countryButtonId = useId("country-callout-button");
  const [selectedlanguage, setSelectedLanguage] = useState(
    props.selectedLanguageData
  );

  const headerWrapperStyles = {
    backgroundColor: props.headerSettings?.headerBackgroundColor ?? "",
    color: props.headerSettings?.headerForegroundColor ?? "",
    minHeight: `${
      props.headerSettings?.headerHeight
        ? props.headerSettings?.headerHeight
        : 70
    }px`,
    display: "flex",
    alignItems: "center",
  };

  const mergeContentStyles = mergeStyleSets({
    dropdown: {
      minWidth: "10px !important",
      padding: "2px",
      button: {
        minWidth: "10px !important",
      },
    },
  });

  const headerBrandLogoWrapperStyles = {
    //height:"20px",
    // maxHeight:"100px"
  };
  const headerBrandLogoStyles = {
    objectFit: "contain",
    padding: "10px 10px 10px 0px",
    display: "flex",
    alignItems: "center",
    height: "auto",
    maxHeight: `${
      props.headerSettings?.headerHeight
        ? props.headerSettings?.headerHeight - 20
        : 70
    }px`,
    maxWidth: "100% !important",
    // margin:"20px",
    // height:"50px",
  };

  const getLanguageValue = (languageCode) => {
    let languageObject = "";
    OperatorConnectConstants.LANGUAGE_DATA.forEach((rec) => {
      if (
        rec.key?.toLowerCase().trim() === languageCode?.toLowerCase().trim()
      ) {
        languageObject = { languageCode: rec.key, text: rec.language };
      }
    });
    return languageObject;
  };

  const handleAllLanguagesData = (websiteLanguages) => {
    let languageList = [];
    websiteLanguages.forEach((lang) => {
      let language = getLanguageValue(lang.languageCode);
      if (language) {
        languageList.push({
          key: language.languageCode,
          text: `${language.languageCode} (${language.text})`,
        });
      }
    });
    return languageList;
  };

  const renderCountryIcon = () => {
    return (
      <div className="country-icon-align">
        <div className="country-img">
          <ReactCountryFlag
            countryCode={countryCode}
            svg
            cdnSuffix="svg"
            title="US"
          />
        </div>
        <div className="country-img-chevron">
          <FontIcon
            aria-label="Compass"
            iconName={isCountryCalloutVisible ? "ChevronUp" : "ChevronDown"}
            className="country-icon-chevron"
          />
        </div>
      </div>
    );
  };
  const handleCountryCalloutVisible = () => {
    setIsCountryCalloutVisible(false);
  };
  const handleLanguageData = async (e, option) => {
    i18n.changeLanguage(option.key);
    setSelectedLanguage(option);
    props.setSelectedLanguageData(option);
  };

  const headerLogoClick = async () => {
    if (
      props?.headerSettings?.headerLogoUrl &&
      props?.headerSettings?.headerLogoUrl !== ""
    ) {
      window.open(props?.headerSettings?.headerLogoUrl, "_self");
    }
  };

  return (
    <>
      <header className="oc-header-wrapper" style={{ ...headerWrapperStyles }}>
        <div className="oc-header-content">
          <div>
            {props.headerSettings?.headerLogo ? (
              <Image
                loading="lazy"
                style={{ ...headerBrandLogoStyles }}
                className={classNames({
                  "cursor-pointer":
                    props?.headerSettings?.headerLogoUrl &&
                    props?.headerSettings?.headerLogoUrl !== "",
                })}
                alt="header-logo"
                onClick={headerLogoClick}
                src={handleImageSource(props.headerSettings?.headerLogo)}
              />
            ) : null}
          </div>
          <div className="main-header-actions">
            <div
              className={
                CustomStyles.getStyle(
                  "main-header-home-icon",
                  props.webTemplateConfigData?.websiteTemplate
                ) + " cursor-pointer"
              }
              onClick={() => {
                localStorage.removeItem("persist:root");
                props.history.push("/");
              }}
            >
              <ReturnKeyIcon />
            </div>

            {props.showLanguage === true ? (
              <>
                <div className="oc-header-lang-wraper-align">
                  <div className="oc-header-country-wraper">
                    <DefaultButton
                      className={mergeContentStyles["dropdown"]}
                      // checked={isDefaultButtonFocused}
                      //  onMenuClick={(e)=>console.log(e.target)}
                      text={selectedlanguage?.key ?? ""}
                      disabled={props.languageDisableStatus}
                      menuProps={{
                        items: handleAllLanguagesData(
                          props?.webTemplateConfigData?.websiteLanguages
                        ),
                        alignTargetEdge: true,
                        shouldFocusOnMount: true,
                        beakWidth: 0,
                        onItemClick: (e, option) => {
                          handleLanguageData(e, option);
                        },
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {props.showHrLine === false ? (
          <>
            <Divider className="header-devider" />
          </>
        ) : null}
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLanguageData: state.generalStore.selectedLanguageData,
  languageData: state.generalStore.languageData,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
});

const mapActionToProps = {
  setSelectedLanguageData,
  quoteEstimatorDataReset,
};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(Header)
);
