/**
Project: Operator Connect (c)
Title: Main Route  
Description: Component for hadling the routes of the application
Copyrights: This file is subject to the terms and conditions defined in file 'LICENSE.txt', which is part of this source code package.
*/
import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import FullLayout from "../layout/full-layout";
import { HelmetProvider } from "react-helmet-async";
import { initializeIcons } from "@fluentui/react";
import RequestUtil from "../core/helpers/request-util";
import withAITracking from "../core/settings/app-insights";
import BlankLayout from "../layout/blank-layout";
import ErrorBoundary from "../core/views/error-boundary";
import TokenCheck from "../core/components/token-check";

const MainRoute = (props) => {
  initializeIcons();
  RequestUtil.setRequestHeaders();
  return (
    <BrowserRouter basename="/">
      <HelmetProvider>
        <ErrorBoundary>
          <TokenCheck>
            <Switch>
              <Route path="/error" component={BlankLayout} />
              <Route path="/404" component={BlankLayout} />
              <Route path="/page-not-found" component={BlankLayout} />
              <Route path="/" component={FullLayout} />
            </Switch>
          </TokenCheck>
        </ErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default withAITracking(MainRoute);
