import {
  COUNTRY_STATES_DATA,
  WEB_TEMPLATE_CONFIG_DATA,
  LANGUAGE_DATA,
  CURRENCY_DATA,
  SELECTED_LANGUAGE_DATA,
  SELECTED_CURRENCY_DATA,
  TOKEN_EXPIRY_TIME,
  TENANT_ID,
} from "../constants";

const INIT_STATE = {
  countryStatesData: [],
  webTemplateConfigData: null,
  languageData: null,
  currencyData: [],
  selectedLanguageData: [{ key: "en", text: "English" }],
  tokenExpiryTime: 0,
  selectedCurrencyData: {
    key: "USD",
    text: "USD ($)",
  },
  tenantId: null,
};

const generalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COUNTRY_STATES_DATA:
      return {
        ...state,
        countryStatesData: action.payload,
      };
    case WEB_TEMPLATE_CONFIG_DATA:
      return {
        ...state,
        webTemplateConfigData: action.payload,
      };
    case TENANT_ID:
      return {
        ...state,
        tenantId: action.payload,
      };
    case LANGUAGE_DATA:
      return {
        ...state,
        languageData: action.payload,
      };

    case SELECTED_LANGUAGE_DATA:
      return {
        ...state,
        selectedLanguageData: action.payload,
      };
    case CURRENCY_DATA:
      return {
        ...state,
        currencyData: action.payload,
      };
    case SELECTED_CURRENCY_DATA:
      return {
        ...state,
        selectedCurrencyData: action.payload,
      };
    case TOKEN_EXPIRY_TIME:
      return {
        ...state,
        tokenExpiryTime: action.payload,
      };
    default:
      return state;
  }
};

export default generalReducer;
